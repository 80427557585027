import React from "react";

const FilterIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      fill="none"
      {...props}
    >
      <path
        fill="#E95B25"
        d="M7.457 15.5H6V7.932L.75 1.682V.5H15v1.175l-5 6.25v5.032L7.457 15.5zM7 14.5h.043L9 12.543V7.575L13.86 1.5H1.903L7 7.568V14.5z"
      ></path>
    </svg>
  );
};

export default FilterIcon;
