import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  wholesaler: null,
  productsList: [],
  productsMeta: {},
  wholesalerProduct: {},
  productsWithOffersList: [],
  productsWithOffersMeta: {},
  deliveryMethodsList: []
};

const wholesalersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_WHOLESALERS_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };
    case types.GET_WHOLESALER_RESPONSE:
      return { ...state, wholesaler: action.payload };
    case types.GET_WHOLESALER_PRODUCTS_RESPONSE:
      return {
        ...state,
        productsList: action.payload.wholesalerProducts,
        productsMeta: action.payload.meta
      };
    case types.GET_WHOLESALER_PRODUCTS_WITH_OFFERS_INSIDE_ORDER_RESPONSE:
      return {
        ...state,
        productsWithOffersList:
          action.payload.wholesalerProductsWithOffers,
        productsWithOffersMeta: action.payload.meta
      };
    case types.GET_WHOLESALER_PRODUCT_RESPONSE:
      return { ...state, wholesalerProduct: action.payload };
    case types.GET_DELIVERY_METHODS_RESPONSE:
      return {
        ...state,
        deliveryMethodsList: action.payload
      };
    default:
      return state;
  }
};

export default wholesalersReducer;
