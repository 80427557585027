import React from "react";
import { Router, Switch, Redirect } from "react-router-dom";
import history from "./History";
import { ROUTE_PATHS } from "../utils/RoutesPaths";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
// ========= Components =========
import Wholesalers from "../containers/Wholesalers";
import Warehouses from "../containers/Warehouses";
import ProductCategories from "../containers/ProductCategories";
import Login from "../containers/Auth/Login";
import AddEditWholesaler from "../containers/Wholesalers/AddEditWholesaler";
import AddEditWarehouse from "../containers/Warehouses/AddEditWarehouse";
import PickLocation from "../containers/Warehouses/PickLocation";
import WholesalerDetails from "../containers/Wholesalers/WholesalerDetails";
import WholesalerProducts from "../containers/Wholesalers/WholesalerProducts";
import ViewWholesalerProduct from "../containers/Wholesalers/WholesalerProducts/ViewWholesalerProduct";
import ViewWarehouse from "../containers/Warehouses/ViewWarehouse";
import CovargeAreas from "../containers/Warehouses/CovargeAreas";
import Retailers from "../containers/Retailers";
import ViewRetailer from "../containers/Retailers/ViewRetailer";
import SuperProducts from "../containers/SuperProducts";
import SubProducts from "../containers/SubProducts";
import Products from "../containers/Products";
import AddEditSubProduct from "../containers/SubProducts/AddEditSubProduct";
import Orders from "../containers/Orders";
import ViewOrder from "../containers/Orders/ViewOrder";
import Notifications from "../containers/Notifications";
import OrderProducts from "../containers/Orders/OrderProducts";
import PromoCodes from "../containers/Promocodes";
import Banners from "../containers/Banners";
import AddEditBanner from "../containers/Banners/AddEditBanner";
import Offers from "../containers/Offers";
import AddEditOffer from "../containers/Offers/AddEditOffer";
const Routes = (
  <Router history={history}>
    <Switch>
      <PublicRoute
        component={Login}
        path={[ROUTE_PATHS.login, ROUTE_PATHS.root]}
        exact
      />
      <PrivateRoute
        component={Wholesalers}
        path={ROUTE_PATHS.wholesalers}
        exact
      />
      <PrivateRoute
        component={AddEditWholesaler}
        path={[ROUTE_PATHS.addWholesaler, ROUTE_PATHS.editWholesaler]}
        exact
      />

      <PrivateRoute
        component={WholesalerDetails}
        path={ROUTE_PATHS.wholesalerDetails}
        exact
      />
      <PrivateRoute
        component={WholesalerProducts}
        path={ROUTE_PATHS.wholesalerProducts}
        exact
      />
      <PrivateRoute
        component={ViewWholesalerProduct}
        path={ROUTE_PATHS.viewWholesalerProduct}
        exact
      />
      <PrivateRoute
        component={Warehouses}
        path={ROUTE_PATHS.warehouses}
        exact
      />
      <PrivateRoute
        component={AddEditWarehouse}
        path={[ROUTE_PATHS.addWarehouse, ROUTE_PATHS.editWarehouse]}
        exact
      />
      <PrivateRoute
        component={ViewWarehouse}
        path={ROUTE_PATHS.viewWarehouse}
        exact
      />
      <PrivateRoute
        component={PickLocation}
        path={[
          ROUTE_PATHS.warehouseAddLocation,
          ROUTE_PATHS.warehouseEditLocation
        ]}
        exact
      />
      <PrivateRoute
        component={CovargeAreas}
        path={[
          ROUTE_PATHS.warehouseAddCoverageAreas,
          ROUTE_PATHS.warehouseEditCoverageAreas
        ]}
        exact
      />
      <PrivateRoute
        component={ProductCategories}
        path={ROUTE_PATHS.productCategories}
        exact
      />
      <PrivateRoute
        component={Products}
        path={ROUTE_PATHS.allProducts}
        exact
      />
      <PrivateRoute
        component={SuperProducts}
        path={ROUTE_PATHS.superProducts}
        exact
      />
      <PrivateRoute
        component={SubProducts}
        path={ROUTE_PATHS.subProducts}
        exact
      />
      <PrivateRoute
        component={AddEditSubProduct}
        path={[ROUTE_PATHS.addSubProduct, ROUTE_PATHS.editSubProduct]}
        exact
      />

      <PrivateRoute
        component={Retailers}
        path={ROUTE_PATHS.retailers}
        exact
      />
      <PrivateRoute
        component={ViewRetailer}
        path={ROUTE_PATHS.viewRetailer}
      />
      <PrivateRoute
        component={Orders}
        path={ROUTE_PATHS.orders}
        exact
      />
      <PrivateRoute
        component={ViewOrder}
        path={ROUTE_PATHS.viewOrder}
        exact
      />
      <PrivateRoute
        component={OrderProducts}
        path={ROUTE_PATHS.ordersProducts}
        exact
      />
      <PrivateRoute
        component={Notifications}
        path={ROUTE_PATHS.notifications}
      />
      <PrivateRoute
        component={PromoCodes}
        path={ROUTE_PATHS.promoCodes}
      />
      <PrivateRoute
        component={Banners}
        path={ROUTE_PATHS.banners}
        exact
      />
      <PrivateRoute
        component={AddEditBanner}
        path={[ROUTE_PATHS.addBanner, ROUTE_PATHS.editBanner]}
        exact
      />
      <PrivateRoute
        component={Offers}
        path={ROUTE_PATHS.offers}
        exact
      />
      <PrivateRoute
        component={AddEditOffer}
        path={[ROUTE_PATHS.addOffer, ROUTE_PATHS.editOffer]}
        exact
      />
      <Redirect from="**" to={ROUTE_PATHS.wholesalers} />
    </Switch>
  </Router>
);

export default Routes;
