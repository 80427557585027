import React from "react";

const AlertIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
        fill="url(#paint0_linear_4163_9177)"
        d="M14 0C6.272 0 0 6.272 0 14s6.272 14 14 14 14-6.272 14-14S21.728 0 14 0zm0 23.1c-1.162 0-2.1-.938-2.1-2.1h4.2c0 1.162-.938 2.1-2.1 2.1zm5.6-3.5H8.4c-.77 0-1.4-.63-1.4-1.4 0-.77.63-1.4 1.4-1.4v-4.2c0-2.604 1.792-4.774 4.2-5.404V6.3c0-.77.63-1.4 1.4-1.4.77 0 1.4.63 1.4 1.4v.896c2.408.63 4.2 2.8 4.2 5.404v4.2c.77 0 1.4.63 1.4 1.4 0 .77-.63 1.4-1.4 1.4z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_4163_9177"
          x1="9"
          x2="28"
          y1="6"
          y2="40.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E95B25"></stop>
          <stop
            offset="1"
            stopColor="#003857"
            stopOpacity="0.76"
          ></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AlertIcon;
