export const GET_RETAILERS_REQUEST = "GET_RETAILERS_REQUEST";
export const GET_RETAILERS_RESPONSE = "GET_RETAILERS_RESPONSE";

export const GET_RETAILER_REQUEST = "GET_RETAILER_REQUEST";
export const GET_RETAILER_RESPONSE = "GET_RETAILER_RESPONSE";

export const GET_RETAILERS_LOOKUP_REQUEST =
  "GET_RETAILERS_LOOKUP_REQUEST";
export const GET_RETAILERS_LOOKUP_RESPONSE =
  "GET_RETAILERS_LOOKUP_RESPONSE";

export const UPDATE_RETAILER_REQUEST = "UPDATE_RETAILER_REQUEST";
