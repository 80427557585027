import { axiosInstance } from "./";

const getSuperProducts = async (params) =>
  await axiosInstance.get(`super_products`, { params });

const getSuperProduct = async (id) =>
  await axiosInstance.get(`super_products/${id}`);

const addSuperProduct = async (payload) =>
  await axiosInstance.post(`super_products`, payload);

const editSuperProduct = async ({ data, id }) =>
  await axiosInstance.patch(`super_products/${id}`, data);

export {
  getSuperProducts,
  addSuperProduct,
  editSuperProduct,
  getSuperProduct
};
