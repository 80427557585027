import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import messages from "../../../assets/locale/messages";
import {
  addProductCategoryRequest,
  editProductCategoryRequest
} from "../../../store/ProductCategories/actions";
import { ARABIC_REGEX } from "../../../utils/Patterns";
import Input from "../../../components/Input";
import Button from "../../../components/Button";

const CreateCategory = ({
  handleClose,
  editMode,
  categoryName,
  categoryId,
  page,
  searchValue
}) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const { productCategories } = messages[lang];
  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
    setValues
  } = useFormik({
    initialValues: {
      name: ""
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(productCategories.validations.categoryName)
        .matches(ARABIC_REGEX, productCategories.validations.arabic)
    }),
    onSubmit: (values) => {
      if (editMode) {
        dispatch(
          editProductCategoryRequest({
            data: values,
            id: categoryId,
            page,
            searchValue
          })
        );
      } else {
        dispatch(
          addProductCategoryRequest({
            data: values,
            page
          })
        );
      }

      handleClose();
    }
  });

  useEffect(() => {
    if (editMode) {
      setValues({
        name: categoryName
      });
    }
  }, [editMode]);

  return (
    <div className="createFormContainer">
      <form noValidate onSubmit={handleSubmit}>
        <Input
          id="name"
          label={productCategories.categoryName}
          labelAdornment={
            <span className="text-bg-primary-mid fsize-14 font-reguler">
              {productCategories.arabicLang}
            </span>
          }
          name="name"
          type="text"
          placeholder={productCategories.placeholder}
          required
          onChange={(value) => {
            setFieldTouched("name");
            setFieldValue("name", value);
          }}
          value={values["name"]}
          inputClass="mb-1"
          labelClassName="font-medium mb-3 text-start"
          inputWrapperClass="my-4"
          isInputHasErr={!!(touched["name"] && errors["name"])}
          errMsg={errors["name"]}
        />
        <div className="d-flex justify-content-center mt-5 btn-container">
          <Button
            label={
              editMode
                ? productCategories.save
                : productCategories.create
            }
            type="submit"
            labelClass="fsize-14 text-white py-1 px-5"
            className="createBtn"
            disabled={!dirty || !isValid}
          />
        </div>
      </form>
    </div>
  );
};

export default CreateCategory;
CreateCategory.propTypes = {
  handleClose: PropTypes.func,
  editMode: PropTypes.bool,
  categoryName: PropTypes.string,
  categoryId: PropTypes.number,
  page: PropTypes.number,
  searchValue: PropTypes.string
};
