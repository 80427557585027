import * as types from "./types";

export const getPromoCodesRequest = (data) => ({
  type: types.GET_PROMOCODES_REQUEST,
  payload: data
});

export const getPromoCodesResponse = (data) => ({
  type: types.GET_PROMOCODES_RESPONSE,
  payload: data
});

export const getOnlyCodesRequest = (data) => ({
  type: types.GET_ONLY_CODES_REQUEST,
  payload: data
});

export const getOnlyCodesResponse = (data) => ({
  type: types.GET_ONLY_CODES_RESPONSE,
  payload: data
});

export const addPromoCodeRequest = (data) => ({
  type: types.ADD_PROMOCODE_REQUEST,
  payload: data
});

export const addPromoCodeResponse = (data) => ({
  type: types.ADD_PROMOCODE_RESPONSE,
  payload: data
});

export const getPromoCodeRequest = (data) => ({
  type: types.GET_PROMOCODE_REQUEST,
  payload: data
});

export const getPromoCodeResponse = (data) => ({
  type: types.GET_PROMOCODE_RESPONSE,
  payload: data
});

export const editPromoCodeRequest = (payload) => ({
  type: types.EDIT_PROMOCODE_REQUEST,
  payload
});

export const editPromoCodeResponse = (data) => ({
  type: types.EDIT_PROMOCODE_RESPONSE,
  payload: data
});

export const deletePromoCodeRequest = (data) => ({
  type: types.DELETE_PROMOCODE_REQUEST,
  payload: data
});
