import React from "react";
import PropTypes from "prop-types";

export const Banners = ({ selectedIcon }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 297 297"
      version="1.1"
      viewBox="0 0 360 297"
      xmlSpace="preserve"
      fill="#fff"
    >
      <path
        fill="#fff"
        fillOpacity={selectedIcon ? "1" : "0.5"}
        d="M201.578 207.587c0 6.683 5.397 12.168 12.08 12.168h71.241c3.905 0 7.572-1.953 9.844-5.131a12.165 12.165 0 001.606-10.985l-9.738-28.52 9.714-27.842a12.102 12.102 0 00-11.426-16.089h-10.468a2767.644 2767.644 0 00-5.068 16.089l-.033.105c-3.453 11.035-7.023 22.446-10.597 32.884a27.104 27.104 0 01-25.639 18.326c-2.989 0-5.944-.493-8.778-1.465a235.709 235.709 0 00-22.739-6.507v16.967z"
      ></path>
      <path
        fill="#fff"
        fillOpacity={selectedIcon ? "1" : "0.5"}
        d="M233.096 183.591c5.03 0 9.727-3.161 11.448-8.185 3.52-10.281 7.07-21.631 10.505-32.608 3.385-10.822 6.886-22.014 10.299-31.988a12.101 12.101 0 00-7.527-15.369 336.798 336.798 0 00-109.32-18.196A336.814 336.814 0 0039.182 95.441a12.1 12.1 0 00-7.528 15.369c3.413 9.974 6.915 21.166 10.3 31.988 3.434 10.978 6.984 22.327 10.504 32.608 1.72 5.025 6.419 8.185 11.447 8.185 1.301 0 2.622-.211 3.921-.656a248.537 248.537 0 0180.676-13.427c27.508 0 54.651 4.518 80.676 13.427 1.296.444 2.618.656 3.918.656z"
      ></path>
      <path
        fill="#fff"
        fillOpacity={selectedIcon ? "1" : "0.5"}
        d="M94.512 190.857c-7.307 1.739-14.681 3.822-21.826 6.268a27.031 27.031 0 01-8.782 1.466 27.098 27.098 0 01-25.638-18.326c-3.584-10.468-7.164-21.913-10.641-33.028a2734.486 2734.486 0 00-5.057-16.05H12.101A12.104 12.104 0 00.675 147.276l9.713 27.843L.65 203.604c-1.264 3.696-.665 7.843 1.606 11.02 2.272 3.178 5.938 5.131 9.845 5.131H82.41c6.683 0 12.101-5.486 12.101-12.168v-16.73z"
      ></path>
    </svg>
  );
};

Banners.propTypes = {
  selectedIcon: PropTypes.bool
};
