import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { TableCell, TableRow } from "@material-ui/core";
import { Visibility, Delete } from "@material-ui/icons";
import debounce from "lodash.debounce";

import {
  getOffersRequest,
  editOfferRequest,
  deleteOfferRequest
} from "../../../store/Offers/actions";
import { setOffersRoute } from "../../../store/Routing/actions";
import { getWholesalersRequest } from "../../../store/Wholesalers/actions";
import messages from "../../../assets/locale/messages";
import OffersFiltersAndSearch from "../OffersFiltersAndSearch";
import EmptyState from "../../../components/EmptyState";
import Table from "../../../components/Table";
import Switch from "../../../components/Switch";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import Pagination from "../../../components/Pagination";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { formatDate } from "../../../utils/Helpers";
import DeleteIcon from "../../../utils/Icons/Delete";
import {
  offersStatus,
  offersSubStatus,
  offerActiveStatus,
  offerPendingStatus,
  offerExpiredStatus
} from "../../../utils/Constants";
import "./OffersList.scss";

const OffersList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const isLoading = useSelector((state) => state.loader);
  const wholesalers = useSelector((state) => state.wholesalers.list);
  const list = useSelector((state) => state.offers.list);
  const meta = useSelector((state) => state.offers.meta);
  const { offers, general } = messages[lang];
  const offersRoutingData = useSelector(
    (state) => state.routing.offers
  );

  const [filterStatus, setFilterStatus] = useState(
    offersRoutingData?.filterStatus || null
  );
  const [filterSubStatus, setFilterSubStatus] = useState(
    offersRoutingData?.filterSubStatus || null
  );
  const [wholsalersOptions, setWholsalersOptions] = useState([]);
  const [filterWholsaler, setFilterWholsaler] = useState(
    offersRoutingData?.filterWholsaler || null
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(offersRoutingData?.page || 1);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [deleteOfferId, setDeleteOfferId] = useState(null);
  const [deleteOfferTitle, setDeleteOfferTitle] = useState("");

  const updateSearchQuery = () => {
    setSearchValue(searchQuery);
  };
  const delayedSearchQuery = useCallback(
    debounce(updateSearchQuery, 500),
    [searchQuery]
  );
  useEffect(() => {
    delayedSearchQuery();
    // Cancel the debounce on useEffect cleanup.
    return delayedSearchQuery.cancel;
  }, [searchQuery, delayedSearchQuery]);

  useEffect(() => {
    dispatch(
      getWholesalersRequest({
        lookup: "options",
        page: -1,
        items: 1,
        visible_wholesalers: true
      })
    );
  }, []);

  useEffect(() => {
    const options = wholesalers.map((wholesaler) => ({
      label: wholesaler.username,
      id: wholesaler.id
    }));
    setWholsalersOptions(options);
  }, [wholesalers]);

  const getOffers = (
    pageNumber,
    status,
    is_enabled,
    wholesaler_id,
    search
  ) => {
    dispatch(
      getOffersRequest({
        page: pageNumber,
        items: 8,
        status,
        is_enabled,
        wholesaler_id,
        product_name: search
      })
    );
  };

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      getOffers(
        page,
        filterStatus?.value,
        filterSubStatus?.value,
        filterWholsaler?.id,
        searchValue
      );
    }
  }, [
    page,
    filterStatus,
    filterSubStatus,
    filterWholsaler?.id,
    searchValue
  ]);

  const handleDeleteClick = (offerIdToDelete, offerTitleToDelete) => {
    setDeleteOfferId(offerIdToDelete);
    setDeleteOfferTitle(offerTitleToDelete);
    setOpenDeleteConfirm(true);
  };

  const handleCloseDeleteConfirmModal = () => {
    setOpenDeleteConfirm(false);
  };

  const confirmDeleteBtn = () => {
    dispatch(
      deleteOfferRequest({
        id: deleteOfferId,
        page,
        filterStatus,
        filterSubStatus,
        filterWholsaler,
        searchValue
      })
    );
    setOpenDeleteConfirm(false);
  };

  const checkOfferStatus = (status) => {
    if (offerActiveStatus.includes(status)) {
      return "active";
    } else if (offerPendingStatus.includes(status)) {
      return "pending";
    } else if (offerExpiredStatus.includes(status)) {
      return "expired";
    }
  };

  const handleViewEditClick = (id) => {
    dispatch(
      setOffersRoute({
        page,
        filterStatus,
        filterSubStatus,
        filterWholsaler
      })
    );
    history.push(ROUTE_PATHS["editOffer"].replace(":id", id));
  };

  const toggleEnabledOffers = (isEnabled, id) => {
    dispatch(
      editOfferRequest({
        data: { is_enabled: !isEnabled },
        id,
        page,
        filterStatus,
        filterSubStatus,
        filterWholsaler,
        searchValue
      })
    );
  };

  if (
    !isLoading &&
    list.length == 0 &&
    !searchValue &&
    !filterStatus &&
    !filterSubStatus &&
    !filterWholsaler
  ) {
    return (
      <div className=" bg-white offers-page d-flex justify-content-center align-items-center flex-column m-4">
        <EmptyState
          subTitle={offers.offersList.emptyState}
          showActionButton={true}
          ButtonLabel={offers.list.add}
          handelCreate={() => {
            history.push(ROUTE_PATHS.addOffer);
          }}
        />
      </div>
    );
  } else if (
    list.length == 0 &&
    (searchValue ||
      filterStatus ||
      filterSubStatus ||
      filterWholsaler)
  ) {
    return (
      <>
        <OffersFiltersAndSearch
          status={{
            options: offersStatus,
            value: filterStatus,
            onChangeHandler: (value) => {
              setFilterStatus(value);
              setPage(1);
            }
          }}
          subStatus={{
            options: offersSubStatus,
            value: filterSubStatus,
            onChangeHandler: (value) => {
              setFilterSubStatus(value);
              setPage(1);
            }
          }}
          wholsalers={{
            options: wholsalersOptions,
            value: filterWholsaler,
            onChangeHandler: (value) => {
              setFilterWholsaler(value);
              setPage(1);
            }
          }}
          search={{
            value: searchQuery,
            onChangeHandler: (value) => {
              setPage(1);
              setSearchQuery(value);
            }
          }}
        />
        <div className=" bg-white offers-page d-flex justify-content-center align-items-center flex-column m-4">
          <EmptyState
            subTitle={general.noSearch}
            showActionButton={false}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <OffersFiltersAndSearch
          status={{
            options: offersStatus,
            value: filterStatus,
            onChangeHandler: (value) => {
              setFilterStatus(value);
              setPage(1);
            }
          }}
          subStatus={{
            options: offersSubStatus,
            value: filterSubStatus,
            onChangeHandler: (value) => {
              setFilterSubStatus(value);
              setPage(1);
            }
          }}
          wholsalers={{
            options: wholsalersOptions,
            value: filterWholsaler,
            onChangeHandler: (value) => {
              setFilterWholsaler(value);
              setPage(1);
            }
          }}
          search={{
            value: searchQuery,
            onChangeHandler: (value) => {
              setPage(1);
              setSearchQuery(value);
            }
          }}
        />
        <div className=" bg-white offers-page d-flex justify-content-between align-items-center flex-column m-4 mt-0">
          <Table
            headlines={[
              offers.offersList.table.headlines.title,
              offers.offersList.table.headlines.offerdBy,
              offers.offersList.table.headlines.date,
              offers.offersList.table.headlines.time,
              offers.offersList.table.headlines.status,
              offers.offersList.table.headlines.viewAndEdit,
              offers.offersList.table.headlines.enabled,
              offers.offersList.table.headlines.delete
            ]}
            hasActions={false}
            notHasSizeLimitaions={true}
            rows={list.map((row, index) => (
              <TableRow key={index}>
                <TableCell className="font-cairo-medium text-orange fsize-14  ">
                  <div
                    className="offer-title-hover"
                    onClick={() => {
                      handleViewEditClick(row.id);
                    }}
                  >
                    {row.name}
                  </div>
                </TableCell>
                <TableCell className="font-reguler gray-shade fsize-15 ">
                  {row.wholesaler_name}
                </TableCell>
                <TableCell className="font-reguler gray-shade fsize-14  ">
                  <p>
                    <span className="me-3 gray-text ">
                      {offers.offersList.start}
                    </span>
                    <span className="font-light ">
                      {formatDate(row.start_datetime, "DD/MM/YYYY")}
                    </span>
                  </p>
                  <p>
                    <span className="me-4 gray-text ">
                      {offers.offersList.end}
                    </span>
                    <span className="font-light">
                      {row?.expiration_date &&
                      row?.expiration_date !== row?.end_datetime
                        ? formatDate(
                            row.expiration_date,
                            "DD/MM/YYYY"
                          )
                        : formatDate(row.end_datetime, "DD/MM/YYYY")}
                    </span>
                  </p>
                </TableCell>
                <TableCell className="font-reguler gray-shade fsize-14  ">
                  <p>
                    <span className="font-light white-space-nowrap">
                      {formatDate(row.start_datetime, "hh:mm A")}
                    </span>
                  </p>
                  <p>
                    <span className="font-light white-space-nowrap">
                      {row?.expiration_date &&
                      row?.expiration_date !== row?.end_datetime
                        ? formatDate(row.expiration_date, "hh:mm A")
                        : formatDate(row.end_datetime, "hh:mm A")}
                    </span>
                  </p>
                </TableCell>
                <TableCell className="font-reguler text-dark-blue fsize-14">
                  <div className="d-flex flex-column justify-content-center align-items-start">
                    <div
                      className={`status-${checkOfferStatus(
                        row.status
                      )} px-3 py-2 border-radius-4 general-status`}
                    >
                      {row.status}
                    </div>
                    {!row.is_in_stock && (
                      <div className="out-of-stock-label">
                        {offers.offersList.outOfStock}
                      </div>
                    )}
                  </div>
                </TableCell>

                <TableCell className="px-0 pt-2">
                  <div className="w-75 d-flex justify-content-center">
                    <Visibility
                      color="secondary"
                      fontSize="large"
                      className="opacity_7 pointer"
                      onClick={() => {
                        handleViewEditClick(row.id);
                      }}
                    />
                  </div>
                </TableCell>
                <TableCell className="px-0 pt-0">
                  <div className="w-75 d-flex justify-content-center">
                    <Switch
                      checked={row.is_enabled}
                      name="enabled"
                      onChange={() => {
                        toggleEnabledOffers(row?.is_enabled, row?.id);
                      }}
                      disabled={offerExpiredStatus.includes(
                        row.status
                      )}
                    />
                  </div>
                </TableCell>
                <TableCell className=" px-0 pt-2">
                  <div className="w-75 d-flex justify-content-center">
                    <Delete
                      fontSize="large"
                      className={`${
                        offerPendingStatus.includes(row.status)
                          ? "pointer text-red"
                          : "text-light-dimmed-red"
                      }`}
                      onClick={() => {
                        if (offerPendingStatus.includes(row.status)) {
                          handleDeleteClick(row.id, row.name);
                        }
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          />
          <div className="d-flex justify-content-center align-items-center pt-4 pb-3">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={meta.currentPage || page}
                handleChange={(event, page) => {
                  setPage(page);
                }}
                defaultPage={1}
              />
            )}
          </div>
          <Modal
            open={openDeleteConfirm}
            modalWrapperClass="delete-offer-modal"
            onClose={handleCloseDeleteConfirmModal}
            modalBody={
              <>
                <DeleteIcon className="delete-icon mt-2 mb-4" />
                <p>
                  {`${offers.deleteConfirmationMsg}“ 
              ${deleteOfferTitle} “ ? `}
                </p>
              </>
            }
            actionBtns={
              <div className="container px-4">
                <div className="row">
                  <div className="col-md-6">
                    <div className=" mx-2 d-flex justify-content-center align-items-center">
                      <Button
                        label={offers.delete}
                        type="button"
                        labelClass="fsize-14 text-white"
                        onClick={confirmDeleteBtn}
                        block={true}
                        className="py-2"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="mx-2 d-flex justify-content-center align-items-center pointer"
                      onClick={handleCloseDeleteConfirmModal}
                    >
                      <div>
                        <p className="fsize-14 text-orange py-2">
                          {offers.offersList.cancel}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </>
    );
  }
};

export default OffersList;
