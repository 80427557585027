export const GET_WHOLESALERS_REQUEST = "GET_WHOLESALERS_REQUEST";
export const GET_WHOLESALERS_RESPONSE = "GET_WHOLESALERS_RESPONSE";

export const ADD_WHOLESALER_REQUEST = "ADD_WHOLESALER_REQUEST";

export const GET_WHOLESALER_REQUEST = "GET_WHOLESALER_REQUEST";
export const GET_WHOLESALER_RESPONSE = "GET_WHOLESALER_RESPONSE";

export const EDIT_WHOLESALER_REQUEST = "EDIT_WHOLESALER_REQUEST";

export const GET_WHOLESALER_PRODUCTS_REQUEST =
  "GET_WHOLESALER_PRODUCTS_REQUEST";
export const GET_WHOLESALER_PRODUCTS_RESPONSE =
  "GET_WHOLESALER_PRODUCTS_RESPONSE";

export const GET_WHOLESALER_PRODUCT_REQUEST =
  "GET_WHOLESALER_PRODUCT_REQUEST";
export const GET_WHOLESALER_PRODUCT_RESPONSE =
  "GET_WHOLESALER_PRODUCT_RESPONSE";

export const CREATE_WHOLESALER_PRODUCT_REQUEST =
  "CREATE_WHOLESALER_PRODUCT_REQUEST";

export const EDIT_WHOLESALER_PRODUCT_REQUEST =
  "EDIT_WHOLESALER_PRODUCT_REQUEST";

export const DELETE_WHOLESALER_PRODUCT_REQUEST =
  "DELETE_WHOLESALER_PRODUCT_REQUEST";

export const GET_WHOLESALER_PRODUCTS_WITH_OFFERS_INSIDE_ORDER_REQUEST =
  "GET_WHOLESALER_PRODUCTS_WITH_OFFERS_INSIDE_ORDER_REQUEST";
export const GET_WHOLESALER_PRODUCTS_WITH_OFFERS_INSIDE_ORDER_RESPONSE =
  "GET_WHOLESALER_PRODUCTS_WITH_OFFERS_INSIDE_ORDER_RESPONSE";

export const GET_DELIVERY_METHODS_REQUEST =
  "GET_DELIVERY_METHODS_REQUEST";
export const GET_DELIVERY_METHODS_RESPONSE =
  "GET_DELIVERY_METHODS_RESPONSE";
