import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Swiper } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { SwiperSlide } from "swiper/react";

import messages from "../../../assets/locale/messages";
import "./OfferProductCard.scss";

SwiperCore.use([Navigation]);

const ProductCardTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.tooltip.backgroundGrey,
    color: theme.tooltip.backgroundBlue,
    fontSize: "1rem"
  },
  arrow: {
    color: theme.tooltip.backgroundGrey,
    fontSize: "1rem"
  }
}))(Tooltip);

const OfferProductCard = ({
  selectedProduct,
  showDesc,
  discountType,
  discountValue
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    productCard: { description, category, noProducts }
  } = messages[lang].subProducts;
  const { offers } = messages[lang];

  const [newPriceAfterOffer, setNewPriceAfterOffer] = useState(
    selectedProduct.price
  );
  useEffect(() => {
    let newPrice;
    if (discountType === "amount" && !isNaN(discountValue)) {
      newPrice = selectedProduct.price - discountValue;
      setNewPriceAfterOffer(parseFloat(newPrice).toFixed(2));
    } else if (
      discountType === "percentage" &&
      !isNaN(discountValue)
    ) {
      let discountAmount =
        (discountValue / 100) * selectedProduct.price;
      newPrice = selectedProduct.price - discountAmount;
      setNewPriceAfterOffer(parseFloat(newPrice).toFixed(2));
    }
  }, [discountValue, discountType]);

  const productImagesSlides = (imagesArray) => {
    let slides = [];
    if (imagesArray.length) {
      slides = imagesArray.map((image) => (
        <SwiperSlide key={image.id}>
          <div
            className="product-card-image-container "
            style={{
              backgroundImage: `url(${image.url})`
            }}
          ></div>
        </SwiperSlide>
      ));
    } else {
      slides.push(
        <SwiperSlide key={1}>
          <div
            className="product-card-image-container product-card-image-container-empty "
            style={{
              backgroundImage: `url(${process.env.REACT_APP_PRODUCTS_PLACEHOLDER})`
            }}
          ></div>
        </SwiperSlide>
      );
    }
    return slides;
  };
  return (
    <div className="product-card py-4">
      {!selectedProduct.is_in_stock && (
        <div className="out-of-stock-label">
          {offers.addEditPage.outOfStock}
        </div>
      )}

      <div>
        <div className="px-4">
          <Swiper
            className="mb-2 item-swiper "
            navigation={
              productImagesSlides(selectedProduct.product.images)
                ?.length > 1
            }
            slidesPerView={1}
            initialSlide={selectedProduct.product.images?.findIndex(
              (image) => image.is_default
            )}
          >
            {productImagesSlides(selectedProduct.product.images)}
          </Swiper>
        </div>
        {selectedProduct.product.concatenated_name?.length > 20 ? (
          <ProductCardTooltip
            title={
              <div>{selectedProduct.product.concatenated_name}</div>
            }
            placement="left"
            arrow
          >
            <div className="text-dark-blue font-cairo-semibold fsize-14 item-parent-name px-4">
              <span>
                {selectedProduct.product.concatenated_name?.substring(
                  0,
                  20
                ) + "..."}
              </span>
            </div>
          </ProductCardTooltip>
        ) : (
          <div className="text-dark-blue font-cairo-semibold fsize-14 item-parent-name px-4">
            <span>{selectedProduct.product.concatenated_name}</span>
          </div>
        )}
      </div>
      <div className="details-container mt-2 px-4 pb-2">
        {showDesc && (
          <div className="mt-2 font-cairo-regular row">
            <div className=" fsize-12 col-3 pt-02 text-product-card-dark ps-0">
              {selectedProduct.product.description &&
              selectedProduct.product.description?.length > 40 ? (
                <ProductCardTooltip
                  title={
                    <div className="Tooltip-desc">
                      {selectedProduct.product.description}
                    </div>
                  }
                  placement="left"
                  arrow
                >
                  <span>{description}</span>
                </ProductCardTooltip>
              ) : (
                <span>{description}</span>
              )}
            </div>
            <div className=" fsize-12 col-9 pe-0 text-product-card-dark  ">
              {!selectedProduct.product.description ||
              selectedProduct.product.description?.length == 0
                ? noProducts
                : selectedProduct.product.description?.length < 40
                ? selectedProduct.product.description
                : selectedProduct.product.description.substring(
                    0,
                    40
                  ) + "..."}
            </div>
          </div>
        )}

        {selectedProduct.product.super_product.product_category
          .name && (
          <div className="mt-2 font-cairo-regular row">
            <div className="row align-items-center">
              <div className="col-3 fsize-12  ps-0 text-product-card-text">
                {selectedProduct.product.super_product
                  .product_category.name &&
                selectedProduct.product.super_product.product_category
                  .name?.length > 40 ? (
                  <ProductCardTooltip
                    title={
                      <div className="Tooltip-desc">
                        {
                          selectedProduct.product.super_product
                            .product_category.name
                        }
                      </div>
                    }
                    placement="left"
                    arrow
                  >
                    <span>{category}</span>
                  </ProductCardTooltip>
                ) : (
                  <span>{category}</span>
                )}
              </div>
              <div className="text-product-card-blue col-9 fsize-12">
                {selectedProduct.product.super_product
                  .product_category.name?.length < 40
                  ? selectedProduct.product.super_product
                      .product_category.name
                  : selectedProduct.product.super_product.product_category.name.substring(
                      0,
                      40
                    ) + "..."}
              </div>
            </div>
          </div>
        )}

        <div className="mt-4 position-relative prices-container">
          {selectedProduct.price && (
            <div className="font-cairo-regular row">
              <div className="row align-items-center">
                <div className="col-5 fsize-12  ps-0 text-product-card-text">
                  <span>{offers.addEditPage.originalPrice}</span>
                </div>
                <div className="text-product-card-blue col-7 fsize-12 px-0">
                  <span className="ms-2 ">
                    {selectedProduct.price}
                  </span>
                  <span>{offers.addEditPage.pound}</span>
                </div>
              </div>
            </div>
          )}

          {!isNaN(discountValue) && (
            <div className="mt-2 font-cairo-regular row">
              <div className="row align-items-center">
                <div className="col-5 fsize-12  ps-0 text-product-card-text">
                  <span>{offers.addEditPage.currentPrice}</span>
                </div>
                <div className="text-product-card-blue col-7 fsize-14 font-cairo-semibold px-0">
                  <span className="ms-2 ">{newPriceAfterOffer}</span>
                  <span>{offers.addEditPage.pound}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

OfferProductCard.propTypes = {
  selectedProduct: PropTypes.object,
  prdNameChild: PropTypes.string,
  showDesc: PropTypes.bool,
  isOutOfStock: PropTypes.bool,
  viewProduct: PropTypes.element,
  discountType: PropTypes.string,
  discountValue: PropTypes.number
};

OfferProductCard.defaultProps = {
  showDesc: true
};
export default OfferProductCard;
