import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { TableCell, TableRow } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";

import Filter from "../Filter";
import Table from "../Table";
import SearchInput from "../SearchInput";
import messages from "../../assets/locale/messages";
import Input from "../Input";
import Checkbox from "../Checkbox";
import Button from "../Button";
import EmptyState from "../EmptyState";
import { addOrderProductsRequest } from "../../store/Orders/actions";
import { getWholesalerProductsWithOffersInsideOrderRequest } from "../../store/Wholesalers/actions";
import { PRICE_REGEX } from "../../utils/Patterns";
import "./AddOrderProducts.scss";

const AddOrderProducts = ({ order_id, wholesaler_id, onClose }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const { orders, general } = messages[lang];

  const [searchValue, setSearchValue] = useState("");
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [productsArr, setProductsArr] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [disabledBtn, setDisabledBTn] = useState(false);
  const [category, setCategory] = useState(null);

  const categoriesList = useSelector(
    (state) => state.productCategories.list
  );
  const productsList = useSelector(
    (state) => state.wholesalers.productsWithOffersList
  );

  useEffect(() => {
    if (productsList) {
      let checkedArray = productsList.map((product) => {
        return {
          ...product,
          checked: false,
          quantity: 1,
          total_price:
            product.new_price !== null
              ? product.new_price
              : product.price
        };
      });
      if (selectedProduct.length > 0 && checkedArray.length > 0) {
        selectedProduct.forEach((pr) => {
          const index = checkedArray.findIndex(
            (pro) => pro.id == pr.id
          );
          if (index > -1) {
            checkedArray[index].checked = true;
            checkedArray[index].price = pr.price;
            checkedArray[index].quantity = pr.quantity;
          }
        });
      }

      setProductsArr(checkedArray);
    }
  }, [productsList]);

  const selectAddProduct = (id, checked) => {
    const index = productsArr.findIndex(
      (product) => product.id === id
    );
    productsArr[index].checked = checked;
    setProductsArr([...productsArr]);
    if (checked) {
      selectedProduct.push(productsArr[index]);
      setSelectedProduct([...selectedProduct]);
    } else {
      const removedIndex = selectedProduct.findIndex(
        (product) => product.id === id
      );
      selectedProduct.splice(removedIndex, 1);
      setSelectedProduct([...selectedProduct]);
    }
  };

  const handleQuentityPerProduct = (id, value) => {
    if (/^[1-9][0-9]*$/.test(+value) || value === "") {
      const index = productsArr.findIndex(
        (product) => product.id === id
      );

      productsArr[index].quantity = value;

      if (productsArr[index].new_price !== null) {
        productsArr[index].total_price =
          productsArr[index].quantity * productsArr[index].new_price;
      } else {
        productsArr[index].total_price =
          productsArr[index].quantity * productsArr[index].price;
      }

      setProductsArr([...productsArr]);

      const editedIndex = selectedProduct.findIndex(
        (product) => product.id === id
      );

      if (editedIndex !== -1) {
        selectedProduct[editedIndex].quantity = value;
        setSelectedProduct([...selectedProduct]);
      }
    }
  };

  const getWholesalerProducts = (
    query,
    product_category_id,
    order_id
  ) => {
    dispatch(
      getWholesalerProductsWithOffersInsideOrderRequest({
        wholesaler_id,
        params: {
          page: -1,
          query,
          product_category_id,
          order_id
        }
      })
    );
  };

  useEffect(() => {
    getWholesalerProducts(searchValue, category?.id, order_id);
  }, [order_id, wholesaler_id]);

  useEffect(() => {
    getWholesalerProducts(searchValue, category?.id, order_id);
  }, [category?.id]);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      getWholesalerProducts(searchValue, category?.id, order_id);
    }
  }, [searchValue]);

  useEffect(() => {
    const options = categoriesList.map((category) => ({
      id: category.id,
      label: category.name
    }));
    setCategoriesOptions(options);
  }, [categoriesList]);

  const handlePricePerUnit = (id, value) => {
    if (value.match(PRICE_REGEX) || value === "") {
      if (value == 0) {
        setDisabledBTn(true);
      } else {
        setDisabledBTn(false);
      }

      const index = productsArr.findIndex(
        (product) => product.id === id
      );
      productsArr[index].price = value;

      if (productsArr[index].new_price !== null) {
        productsArr[index].total_price =
          productsArr[index].quantity * productsArr[index].new_price;
      } else {
        productsArr[index].total_price =
          productsArr[index].quantity * productsArr[index].price;
      }

      setProductsArr([...productsArr]);
      const editedIndex = selectedProduct.findIndex(
        (product) => product.id === id
      );
      if (editedIndex !== -1) {
        selectedProduct[editedIndex].price = value;
        setSelectedProduct([...selectedProduct]);
      }
    }
  };

  const handleBulkAdd = () => {
    const data = selectedProduct.map((product) => ({
      wholesalers_product_with_offer_id: product.id,
      price_per_product:
        product.new_price !== null
          ? product.new_price
          : product.price,
      quantity: product.quantity || 1
    }));
    dispatch(
      addOrderProductsRequest({
        order_id,
        orders_products: data
      })
    );
    onClose(false);
  };

  const renderModalBody = () => {
    if (productsList?.length == 0 && !searchValue && !category?.id) {
      return (
        <div className="my-5">
          <EmptyState
            subTitle={orders.emptyState}
            showActionButton={false}
          />
        </div>
      );
    } else if (
      productsList?.length == 0 &&
      (searchValue || category?.id)
    ) {
      return (
        <div className="my-5">
          <EmptyState
            subTitle={general.noSearch}
            showActionButton={false}
          />
        </div>
      );
    } else {
      return (
        <>
          <Table
            headlines={[
              "",
              orders.viewOrder.productName,
              orders.viewOrder.priceUnit,
              orders.viewOrder.quentity,
              orders.viewOrder.total
            ]}
            rows={productsArr?.map((row, index) => (
              <TableRow key={index}>
                <TableCell className="font-medium  text-dark-blue fsize-14   d-flex justify-content-start position-relative">
                  {!row.is_in_stock && (
                    <div className="out-stock">
                      {orders.viewOrder.outOfStock}
                    </div>
                  )}
                  <div className="ms-4">
                    <Checkbox
                      color="secondary"
                      labelClass="ms-5 mt-2"
                      id={`orderProduct${row.id}`}
                      name={row.name}
                      checked={row.checked || false}
                      onChange={(checked) => {
                        selectAddProduct(row.id, checked);
                      }}
                    />
                  </div>
                  <div
                    className="order-product-image"
                    style={{
                      backgroundImage: `url(${
                        row.image ||
                        process.env.REACT_APP_PRODUCTS_PLACEHOLDER
                      })`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat"
                    }}
                  ></div>
                </TableCell>
                <TableCell className="font-medium  text-dark-blue fsize-14 ">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>{row.concatenated_name}</div>
                    {row.is_offer && (
                      <div className="ms-3 p-2 offer-label-product">
                        {general.offer}
                      </div>
                    )}
                  </div>
                </TableCell>
                <TableCell className="font-medium  text-dark-blue fsize-14  d-flex position-relative">
                  <Input
                    id="price"
                    name="price"
                    value={
                      row.new_price !== null
                        ? row.new_price
                        : row.price
                    }
                    inputWrapperClass="w-60 price-input"
                    isInputHasErr={row.price == 0}
                    errMsg={orders.orderProducts.piceErrorMsg}
                    onChange={(value) => {
                      handlePricePerUnit(row.id, value);
                    }}
                  />
                  <span className="price">{orders.viewOrder.le}</span>
                </TableCell>
                <TableCell className="font-medium  text-dark-blue fsize-14 quantity ">
                  <Input
                    id="quantity"
                    name="quantity"
                    startAdornment={
                      <Remove
                        onClick={() => {
                          handleQuentityPerProduct(
                            row.id,
                            row.quantity - 1
                          );
                        }}
                      />
                    }
                    endAdornment={
                      <Add
                        onClick={() => {
                          handleQuentityPerProduct(
                            row.id,
                            row.quantity + 1
                          );
                        }}
                      />
                    }
                    value={row.quantity || 1}
                    inputWrapperClass="w-75 quantity-input"
                    onChange={(value) => {
                      handleQuentityPerProduct(row.id, value);
                    }}
                  />
                </TableCell>
                <TableCell className="font-medium  total-price fsize-14  px-0 ">
                  {row.total_price} {orders.viewOrder.le}
                </TableCell>
              </TableRow>
            ))}
          />
          <div className="btn-submit">
            <Button
              label={
                <div className="d-flex align-items-center">
                  {orders.orderProducts.save}
                </div>
              }
              labelClass="fsize-16 text-white"
              className="w-25"
              disabled={selectedProduct.length < 1 || disabledBtn}
              onClick={() => {
                handleBulkAdd();
              }}
            />
          </div>
        </>
      );
    }
  };
  return (
    <div className="add-order-products">
      <div className="search-bar d-flex align-items-center ms-5 ">
        <Filter
          placeholder={orders.orderProducts.categories}
          options={categoriesOptions}
          name="Category"
          onChange={(value) => {
            setCategory(value);
          }}
          value={category}
          inputClassName="me-4 "
          filterWrapperClass="mt-2"
        />
        <SearchInput
          name="search"
          value={searchValue}
          onChange={(value) => {
            setSearchValue(value);
          }}
          placeholder={orders.orderProducts.searchPlaceholder}
          inputWrapperClass=" px-0"
          inputClass="mt-2"
        />
      </div>
      <div className="d-flex justify-content-center align-items-center">
        {renderModalBody()}
      </div>
    </div>
  );
};

export default AddOrderProducts;
AddOrderProducts.propTypes = {
  order_id: PropTypes.string,
  wholesaler_id: PropTypes.number,
  onClose: PropTypes.func
};
