export const GET_WAREHOUSES_REQUEST = "GET_WAREHOUSES_REQUEST";
export const GET_WAREHOUSES_RESPONSE = "GET_WAREHOUSES_RESPONSE";

export const ADD_WAREHOUSE_REQUEST = "ADD_WAREHOUSE_REQUEST";

export const GET_WAREHOUSE_REQUEST = "GET_WAREHOUSE_REQUEST";
export const GET_WAREHOUSE_RESPONSE = "GET_WAREHOUSE_RESPONSE";

export const EDIT_WAREHOUSE_REQUEST = "EDIT_WAREHOUSE_REQUEST";

export const GET_WEEK_DAYS_REQUEST = "GET_WEEK_DAYS_REQUEST";
export const GET_WEEK_DAYS_RESPONSE = "GET_WEEK_DAYS_RESPONSE";

export const CREATE_ZONE_REQUEST = "CREATE_ZONE_REQUEST";

export const EDIT_ZONE_REQUEST = "EDIT_ZONE_REQUEST";

export const DELETE_ZONE_REQUEST = "DELETE_ZONE_REQUEST";
