import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Delete } from "@material-ui/icons";

import messages from "../../../../assets/locale/messages";
import {
  offerActiveStatus,
  offerPendingStatus
} from "../../../../utils/Constants";
import { formatDate } from "../../../../utils/Helpers";
import DeleteIcon from "../../../../utils/Icons/Delete";
import Switch from "../../../../components/Switch";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import {
  editOfferRequest,
  deleteOfferRequest
} from "../../../../store/Offers/actions";
import "./AppliedOffersCard.scss";

const AppliedOffersCard = ({ offer, wholesalerProductId }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const { general, offers } = messages[lang];

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [deleteOfferId, setDeleteOfferId] = useState(null);
  const [deleteOfferTitle, setDeleteOfferTitle] = useState("");

  const checkOfferStatus = (status) => {
    if (offerActiveStatus.includes(status)) {
      return "active";
    } else if (offerPendingStatus.includes(status)) {
      return "pending";
    }
  };

  const toggleEnabledOffers = (isEnabled, id) => {
    dispatch(
      editOfferRequest({
        data: { is_enabled: !isEnabled },
        id,
        wholesalerProductId
      })
    );
  };

  const handleDeleteClick = (offerIdToDelete, offerTitleToDelete) => {
    setDeleteOfferId(offerIdToDelete);
    setDeleteOfferTitle(offerTitleToDelete);
    setOpenDeleteConfirm(true);
  };

  const handleCloseDeleteConfirmModal = () => {
    setOpenDeleteConfirm(false);
  };

  const confirmDeleteBtn = () => {
    dispatch(
      deleteOfferRequest({
        id: deleteOfferId,
        wholesalerProductId
      })
    );
    setOpenDeleteConfirm(false);
  };

  return (
    <div className="applied-offer-card-container">
      <div className="row mx-0">
        <div className="col-12 px-0">
          <div className="offer-card">
            <div className="d-flex flex-column justify-content-center align-items-start me-4">
              <div
                className={`status-${checkOfferStatus(
                  offer.status
                )} status-general px-3 py-2 border-radius-4 text-capitalize fsize-16 text-color-blue `}
              >
                {offer.status}
              </div>
              {!offer.is_in_stock && (
                <div className="out-of-stock-label">
                  {offers.offersList.outOfStock}
                </div>
              )}
            </div>
            <div className="d-flex flex-column me-3 offer-value-text">
              <span className="fsize-12 value-title">
                {general.offerValue}
              </span>
              <div className="fsize-30 value-number">
                <span className="me-1">{offer.discount_value}</span>
                {offer.discount_type === "amount" ? general.le : "%"}
              </div>
            </div>
            <div className="d-flex flex-column me-4 applied-offers-dates">
              <div className="d-flex justify-content-between align-items-baseline start-date mb-3">
                <span className="me-3 fsize-12 date-text">
                  {general.startDate}
                </span>
                <span className="date-value">
                  {formatDate(offer.start_datetime, "DD/MM/YYYY")}
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-baseline end-date">
                <span className="me-3 fsize-12 date-text">
                  {general.endDate}
                </span>
                <span className="date-value">
                  {formatDate(offer.end_datetime, "DD/MM/YYYY")}
                </span>
              </div>
            </div>

            <div className="d-flex flex-column me-4 applied-offers-dates">
              <div className="d-flex justify-content-between align-items-baseline start-date mb-3">
                <span className="me-3 fsize-12 date-text">
                  {general.startTime}
                </span>
                <span className="date-value">
                  {formatDate(offer.start_datetime, "hh:mm A")}
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-baseline end-date">
                <span className="me-3 fsize-12 date-text">
                  {general.endTime}
                </span>
                <span className="date-value">
                  {formatDate(offer.end_datetime, "hh:mm A")}
                </span>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center ms-4 me-4 switch-container">
              <Switch
                checked={offer.is_enabled}
                name="enabled"
                onChange={() => {
                  toggleEnabledOffers(offer.is_enabled, offer?.id);
                }}
              />
              <span className="mt-1">
                {offer.is_enabled
                  ? general.enabled
                  : general.disabled}
              </span>
            </div>

            <div className="d-flex justify-content-center delete-container">
              <Delete
                fontSize="large"
                className={`${
                  offerPendingStatus.includes(offer.status)
                    ? "pointer text-red"
                    : "text-light-dimmed-red"
                }`}
                onClick={() => {
                  if (offerPendingStatus.includes(offer.status)) {
                    handleDeleteClick(offer.id, offer.name);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={openDeleteConfirm}
        modalWrapperClass="delete-offer-modal"
        onClose={handleCloseDeleteConfirmModal}
        modalBody={
          <>
            <DeleteIcon className="delete-icon mt-2 mb-4" />
            <p>
              {`${offers.deleteConfirmationMsg}“ 
              ${deleteOfferTitle} “ ? `}
            </p>
          </>
        }
        actionBtns={
          <div className="container px-4">
            <div className="row">
              <div className="col-md-6">
                <div className=" mx-2 d-flex justify-content-center align-items-center">
                  <Button
                    label={offers.delete}
                    type="button"
                    labelClass="fsize-14 text-white"
                    onClick={confirmDeleteBtn}
                    block={true}
                    className="py-2"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="mx-2 d-flex justify-content-center align-items-center pointer"
                  onClick={handleCloseDeleteConfirmModal}
                >
                  <div>
                    <p className="fsize-14 text-orange py-2">
                      {offers.offersList.cancel}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default AppliedOffersCard;

AppliedOffersCard.propTypes = {
  offer: PropTypes.object,
  wholesalerProductId: PropTypes.number
};
