import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MaterialAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import "./Accordion.scss";

const Accordion = ({
  label,
  body,
  wrapperClassName,
  actionButton,
  setArrowRotate,
  arrowRotate,
  isExpanded
}) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (isExpanded !== undefined) {
      setExpanded(isExpanded);
    }
  }, [isExpanded]);
  return (
    <div className={`${wrapperClassName}`}>
      <MaterialAccordion expanded={expanded}>
        <AccordionSummary
          expandIcon={
            <span
              onClick={() => {
                setExpanded(!expanded);
                setArrowRotate(!arrowRotate);
              }}
              className="me-3"
            >
              {actionButton}
            </span>
          }
          aria-label="Expand"
          aria-controls={`additional-actions1-content`}
          id={`additional-actions1-header`}
        >
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={<span />}
            className="ms-4"
            label={label}
          />
        </AccordionSummary>
        <AccordionDetails>{body}</AccordionDetails>
      </MaterialAccordion>
    </div>
  );
};
export default Accordion;

Accordion.propTypes = {
  label: PropTypes.element,
  body: PropTypes.element,
  actionButton: PropTypes.element,
  wrapperClassName: PropTypes.string,
  setArrowRotate: PropTypes.func,
  arrowRotate: PropTypes.bool,
  isExpanded: PropTypes.bool
};
