import React from "react";
import PropTypes from "prop-types";

import Button from "../Button";
import "./EmptyState.scss";

const EmptyState = ({
  subTitle,
  description,
  ButtonLabel,
  handelCreate,
  showActionButton
}) => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column ">
      <img
        src={process.env.REACT_APP_LOGO_EMPTY_STATE}
        alt="provider-logo"
        className="noListImage"
      />
      <p className="fsize-18 font-medium subTitle">{subTitle}</p>
      <p className="fsize-16 font-medium description">
        {description}
      </p>
      {showActionButton && (
        <Button
          label={ButtonLabel}
          type="button"
          labelClass="fsize-16 text-white"
          onClick={handelCreate}
          block={true}
        />
      )}
    </div>
  );
};

export default EmptyState;

EmptyState.propTypes = {
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  description: PropTypes.string,
  ButtonLabel: PropTypes.string,
  handelCreate: PropTypes.func,
  showActionButton: PropTypes.bool
};
EmptyState.defaultProps = {
  showActionButton: true
};
