import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Map,
  GoogleApiWrapper,
  Marker,
  Polygon
} from "google-maps-react";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import PolygonHint from "../../assets/images/polygon_hint.png";
import SearchMap from "../SearchMap";
import messages from "../../assets/locale/messages";
import Button from "../Button";
import "./GoogleMaps.scss";

const GoogleMapTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.tooltip.backgroundBlue
  },
  arrow: {
    color: theme.tooltip.backgroundBlue,
    fontSize: "1rem"
  }
}))(Tooltip);

const GoogleMaps = ({
  className,
  viewOnly,
  hasSearch,
  isMarker,
  getCoords,
  savedCoords,
  isPolygon,
  getPolygon,
  savedPolygon,
  previouslyCreatedPolygons,
  isTooltip,
  resetMap,
  resetButtonText
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { warehouseCoverageAreas } = messages[lang];
  // the user positin in ordinary map
  const [position, setPosition] = useState({
    coords: { lng: 0, lat: 0 }
  });

  // the user initial positin and after search
  const [initialPolygonPath, setInitialPolygonPath] = useState([]);

  // after the user selects the area
  // this one can't be provided to the Polygon props, it gets the updated value from the click handler
  const [updatedPolygonPath, setUpdatedPolygonPath] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  // This is used to reset polygon cashed updated coords
  const [resetPolygon, setResetPolygon] = useState(false);

  useEffect(() => {
    if (resetPolygon) {
      setTimeout(() => {
        setResetPolygon(false);
      }, 1);
    }
  }, [resetPolygon]);

  useEffect(() => {
    if (isMarker && savedCoords.coords) {
      // to set the user updated position in edit mode
      setPosition(savedCoords);
    } else if (isPolygon && savedPolygon && savedPolygon.length) {
      // to set user updated area in edit mode
      // and also set position with on of the polygon values
      // to set map center to show the selected area
      setPosition({
        coords: {
          lng: savedPolygon[0].lng,
          lat: savedPolygon[0].lat
        }
      });
      setInitialPolygonPath(savedPolygon);
    } else {
      // to set the initial user position
      navigator.geolocation.getCurrentPosition((userPosition) => {
        const {
          coords: { longitude, latitude }
        } = userPosition;
        setPosition({
          coords: {
            lng: longitude,
            lat: latitude
          }
        });
        if (isPolygon) {
          // to set user initial are in case of polygon
          setInitialPolygonPath([
            {
              lat: latitude,
              lng: longitude
            },
            {
              lat: latitude + 0.02,
              lng: longitude
            },
            {
              lat: latitude + 0.02,
              lng: longitude + 0.02
            },
            {
              lat: latitude,
              lng: longitude
            }
          ]);
        }
      });
    }
  }, []);

  // updating coords in parent in case of marker map
  useEffect(() => {
    if (!viewOnly && isMarker) {
      getCoords(position);
    }
  }, [position.coords]);

  // updating coords in parent in case of polygon map
  useEffect(() => {
    if (!viewOnly && isPolygon) {
      if (updatedPolygonPath.length) {
        getPolygon(updatedPolygonPath);
      } else {
        getPolygon(initialPolygonPath);
      }
    }
  }, [updatedPolygonPath, initialPolygonPath]);

  const onMoveMarker = (props, marker) => {
    // DO NOT remove the props param even if it's not used
    setPosition({
      coords: {
        lng: marker.position.lng(),
        lat: marker.position.lat()
      }
    });
  };

  const onSearchMap = (SearchLat, SearchLng, address) => {
    setSearchValue(address);
    setPosition({
      coords: {
        lat: SearchLat,
        lng: SearchLng
      }
    });
    if (isPolygon) {
      const newPolygonPath = [
        {
          lng: SearchLng,
          lat: SearchLat
        },
        {
          lng: SearchLng,
          lat: SearchLat + 0.02
        },
        {
          lng: SearchLng + 0.02,
          lat: SearchLat + 0.02
        },
        {
          lng: SearchLng,
          lat: SearchLat
        }
      ];
      setInitialPolygonPath(newPolygonPath);
      setResetPolygon(true);
      getPolygon(newPolygonPath);
    }
  };

  const onResetPolygon = () => {
    setSearchValue("");

    navigator.geolocation.getCurrentPosition((userPosition) => {
      const {
        coords: { longitude, latitude }
      } = userPosition;
      setPosition({
        coords: {
          lng: longitude,
          lat: latitude
        }
      });
      const newPolygon = [
        {
          lat: latitude,
          lng: longitude
        },
        {
          lat: latitude + 0.02,
          lng: longitude
        },
        {
          lat: latitude + 0.02,
          lng: longitude + 0.02
        },
        {
          lat: latitude,
          lng: longitude
        }
      ];
      // to set user initial are in case of polygon
      setInitialPolygonPath(newPolygon);
      getPolygon(newPolygon);
    });

    setResetPolygon(true);
  };

  const handlePolygonChange = (props, polygon) => {
    // DO NOT remove the props param even if it's not used
    let geoJSON = {
      type: "Polygon",
      coordinates: []
    };

    const paths = polygon.getPaths().getArray();

    for (let path of paths) {
      let pathArray = [];
      let points = path.getArray();
      let firstPoint = false;

      for (let point of points) {
        if (firstPoint === false) {
          firstPoint = point;
        }
        pathArray.push([point.lng(), point.lat()]);
      }

      pathArray.push([firstPoint.lng(), firstPoint.lat()]);
      geoJSON.coordinates.push(pathArray);
    }

    const newCoords = geoJSON.coordinates[0].map((elt) => ({
      lng: elt[0],
      lat: elt[1]
    }));
    setPosition({
      coords: {
        lng: newCoords[0].lng,
        lat: newCoords[0].lat
      }
    });
    setUpdatedPolygonPath(newCoords);
    return geoJSON;
  };
  const renderToolTip = (children) => {
    if (isTooltip) {
      return (
        <GoogleMapTooltip
          className="MapToolTip"
          title={
            <div className=" d-flex  align-items-center p-1 ">
              <div className="p-2 bg-white polygon-image me-3">
                <img src={PolygonHint} alt="polygonHint" />
              </div>
              <p className="fsize-12">
                {warehouseCoverageAreas.polygonHint}
              </p>
            </div>
          }
          placement="top"
          arrow
        >
          <div> {children}</div>
        </GoogleMapTooltip>
      );
    } else {
      return <>{children}</>;
    }
  };

  return (
    <div className="google-maps-container">
      <div
        className={
          resetMap && "reset-map-wrapper d-flex align-items-center"
        }
      >
        {hasSearch && (
          <SearchMap
            onSearchMap={onSearchMap}
            searchValue={searchValue}
          />
        )}
        {resetMap && (
          <Button
            onClick={onResetPolygon}
            label={resetButtonText}
            className="reset-map-btn mb-4 me-2 ms-4"
          />
        )}
      </div>
      {renderToolTip(
        <Map
          google={window.google}
          zoom={14}
          center={{
            lat: position.coords.lat,
            lng: position.coords.lng
          }}
          className={className}
        >
          {isMarker && (
            <Marker
              name="Current location"
              draggable={!viewOnly}
              onDragend={onMoveMarker}
              position={{
                lat: position.coords.lat,
                lng: position.coords.lng
              }}
            />
          )}
          {isPolygon && !resetPolygon && (
            <Polygon
              draggable={!viewOnly}
              editable={!viewOnly}
              paths={initialPolygonPath}
              strokeColor="#e95b25"
              strokeOpacity={0.8}
              strokeWeight={4}
              fillColor="#e95b25"
              fillOpacity={0.35}
              onClick={handlePolygonChange}
            />
          )}

          {isPolygon &&
            previouslyCreatedPolygons &&
            previouslyCreatedPolygons.map((polygon, index) => (
              <Polygon
                draggable={viewOnly}
                editable={viewOnly}
                paths={polygon}
                strokeColor="#767676"
                strokeOpacity={0.8}
                strokeWeight={4}
                fillColor="#767676"
                fillOpacity={0.35}
                key={`polygon-${index}`}
              />
            ))}
        </Map>
      )}
    </div>
  );
};
export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
})(GoogleMaps);

GoogleMaps.propTypes = {
  className: PropTypes.string,
  viewOnly: PropTypes.bool,
  hasSearch: PropTypes.bool,
  isMarker: PropTypes.bool,
  getCoords: PropTypes.func,
  savedCoords: PropTypes.object,
  isPolygon: PropTypes.bool,
  getPolygon: PropTypes.func,
  savedPolygon: PropTypes.arrayOf(
    PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number })
  ),
  previouslyCreatedPolygons: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number
      })
    )
  ),
  isTooltip: PropTypes.bool,
  resetMap: PropTypes.bool,
  resetButtonText: PropTypes.object
};
GoogleMaps.defaultProps = {
  hasSearch: true,
  viewOnly: false,
  isTooltip: false
};
