export const GET_PROMOCODES_REQUEST = "GET_PROMOCODES_REQUEST";

export const GET_PROMOCODES_RESPONSE = "GET_PROMOCODES_RESPONSE";

export const ADD_PROMOCODE_REQUEST = "ADD_PROMOCODE_REQUEST";

export const ADD_PROMOCODE_RESPONSE = "ADD_PROMOCODE_RESPONSE";

export const GET_PROMOCODE_REQUEST = "GET_PROMOCODE_REQUEST";

export const GET_PROMOCODE_RESPONSE = "GET_PROMOCODE_RESPONSE";

export const EDIT_PROMOCODE_REQUEST = "EDIT_PROMOCODE_REQUEST";

export const EDIT_PROMOCODE_RESPONSE = "EDIT_PROMOCODE_RESPONSE";

export const DELETE_PROMOCODE_REQUEST = "DELETE_PROMOCODE_REQUEST";

export const GET_ONLY_CODES_REQUEST = "GET_ONLY_CODES_REQUEST";

export const GET_ONLY_CODES_RESPONSE = "GET_ONLY_CODES_RESPONSE";
