import { put, call, takeEvery } from "redux-saga/effects";
import amplitude from "amplitude-js";

import * as apis from "../../network/apis/wholesalers";
import {
  getWholesalerResponse,
  getWholesalersRequest,
  getWholesalersResponse,
  getWholesalerProductsResponse,
  getWholesalerProductsRequest,
  getWholsalerProductResponse,
  getWholsalerProductRequest,
  getWholesalerProductsWithOffersInsideOrderResponse,
  getDeliveryMethodsResponse
} from "./actions";
import { getPreviousProductOffersRequest } from "../Offers/actions";
import * as WholesalersTypes from "./types";
import messages from "../../assets/locale/messages";
import { showHideSnackbar } from "../Snackbar/actions";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import history from "../../routes/History";

export function* getWholesalersSaga({ payload }) {
  try {
    const response = yield call(apis.getWholesalers, payload);
    yield put(
      getWholesalersResponse({
        list: response.data.wholesalers,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export function* addWholesalerSaga({ payload }) {
  try {
    const response = yield call(apis.addWholesaler, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].Wholesalers.addEdit.addSuccess
      })
    );
    history.push(ROUTE_PATHS["wholesalers"]);

    const eventProperties = {
      "wholesaler id": response.data.wholesaler.id,
      "wholesaler name": response.data.wholesaler.username
    };

    amplitude
      .getInstance()
      .logEvent("admin update: wholesaler added", eventProperties);
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export function* getWholesalerSaga({ payload }) {
  try {
    const response = yield call(apis.getWholesaler, payload);
    yield put(getWholesalerResponse(response.data.wholesaler));
  } catch (err) {
    console.log(err);
  }
}

export function* editWholesalerSaga({ payload }) {
  try {
    const response = yield call(apis.editWholesaler, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].Wholesalers.addEdit.editSuccess
      })
    );
    history.push(ROUTE_PATHS["wholesalers"]);
    const { page, searchValue } = payload;
    yield put(
      getWholesalersRequest({
        page,
        items: 10,
        wholesaler_data: searchValue
      })
    );

    if (
      payload.data.is_activated === false &&
      response.data.success === true
    ) {
      const eventProperties = {
        "wholesaler id": response.data.wholesaler.id,
        "wholesaler name": response.data.wholesaler.username
      };

      amplitude
        .getInstance()
        .logEvent(
          "admin update: wholesaler deactivated",
          eventProperties
        );
    }
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export function* getWholesalerProductsSaga({ payload }) {
  try {
    const response = yield call(apis.getWholesalerProducts, payload);
    yield put(
      getWholesalerProductsResponse({
        wholesalerProducts: response.data.wholesalers_products,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export function* getWholesalerProductsWithOffersSaga({ payload }) {
  try {
    const response = yield call(
      apis.getWholesalerProductsWithoffersInsideOrder,
      payload
    );
    yield put(
      getWholesalerProductsWithOffersInsideOrderResponse({
        wholesalerProductsWithOffers:
          response.data.wholesalers_products_with_offers,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export function* getWholesalerProductSaga({ payload }) {
  try {
    const response = yield call(apis.getWholesalerProduct, payload);
    yield put(
      getWholsalerProductResponse(response.data.wholesalers_product)
    );
    const eventProperties = {
      "product id": response.data.wholesalers_product.id,
      "product name":
        response.data.wholesalers_product.product.concatenated_name
    };

    amplitude
      .getInstance()
      .logEvent(
        "admin browse: view product details",
        eventProperties
      );
  } catch (err) {
    console.log(err);
  }
}

export function* createWholesalerProductSaga({ payload }) {
  try {
    const response = yield call(
      apis.createWholesalerProduct,
      payload
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].Wholesalers.products.addToWholsaler
      })
    );

    const eventProperties = {
      "product id": response.data.wholesalers_product.id,
      "product name":
        response.data.wholesalers_product.product.concatenated_name,

      "wholesaler id":
        response.data.wholesalers_product.wholesaler.id,

      "wholesaler name":
        response.data.wholesalers_product.wholesaler.username
    };

    amplitude
      .getInstance()
      .logEvent(
        "admin update: add product to wholesaler",
        eventProperties
      );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export function* editWholesalerProductSaga({ payload }) {
  try {
    yield call(apis.editWholesalerProduct, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].Wholesalers.products.editProduct
      })
    );
    const {
      wholsalerId,
      productId,
      page,
      product_category_id,
      query,
      insideWholesalerProductDetails
    } = payload;
    yield put(getWholsalerProductRequest({ wholsalerId, productId }));

    yield put(
      getWholesalerProductsRequest({
        id: wholsalerId,
        params: { page, items: 16, query, product_category_id }
      })
    );

    if (insideWholesalerProductDetails) {
      yield put(
        getPreviousProductOffersRequest({
          wholesalers_product_id: productId,
          applied_offers: true
        })
      );
    }
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export function* deleteWholesalerProductSaga({ payload }) {
  try {
    yield call(apis.deleteWholesalerProduct, payload);
    const { wholsalerId, page, product_category_id, query } = payload;
    history.push(
      ROUTE_PATHS["wholesalerProducts"].replace(":id", wholsalerId)
    );
    yield put(
      getWholesalerProductsRequest({
        id: wholsalerId,
        params: { page, items: 16, product_category_id, query }
      })
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].Wholesalers.products.deleteProduct
      })
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export function* getDeliveryMethodsSaga({ payload }) {
  try {
    const response = yield call(apis.getDeliveryMethods, payload);
    yield put(
      getDeliveryMethodsResponse(response.data.delivery_methods)
    );
  } catch (err) {
    console.log(err);
  }
}

export default function* WholesalersSagas() {
  yield takeEvery(
    WholesalersTypes.GET_WHOLESALERS_REQUEST,
    getWholesalersSaga
  );
  yield takeEvery(
    WholesalersTypes.ADD_WHOLESALER_REQUEST,
    addWholesalerSaga
  );
  yield takeEvery(
    WholesalersTypes.GET_WHOLESALER_REQUEST,
    getWholesalerSaga
  );
  yield takeEvery(
    WholesalersTypes.EDIT_WHOLESALER_REQUEST,
    editWholesalerSaga
  );
  yield takeEvery(
    WholesalersTypes.CREATE_WHOLESALER_PRODUCT_REQUEST,
    createWholesalerProductSaga
  );
  yield takeEvery(
    WholesalersTypes.GET_WHOLESALER_PRODUCTS_REQUEST,
    getWholesalerProductsSaga
  );
  yield takeEvery(
    WholesalersTypes.GET_WHOLESALER_PRODUCT_REQUEST,
    getWholesalerProductSaga
  );
  yield takeEvery(
    WholesalersTypes.EDIT_WHOLESALER_PRODUCT_REQUEST,
    editWholesalerProductSaga
  );
  yield takeEvery(
    WholesalersTypes.DELETE_WHOLESALER_PRODUCT_REQUEST,
    deleteWholesalerProductSaga
  );

  yield takeEvery(
    WholesalersTypes.GET_WHOLESALER_PRODUCTS_WITH_OFFERS_INSIDE_ORDER_REQUEST,
    getWholesalerProductsWithOffersSaga
  );

  yield takeEvery(
    WholesalersTypes.GET_DELIVERY_METHODS_REQUEST,
    getDeliveryMethodsSaga
  );
}
