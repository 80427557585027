import axios from "axios";

import store from "../../store";
import { loginResponse } from "../../store/Auth/actions";
import { clearAdminData } from "../../store/Auth/helpers";
import {
  clearFirebaseToken,
  generateFirebaseToken
} from "../../firebase/helpers";
import history from "../../routes/History";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import {
  requestHandler,
  successHandler,
  errorHandler
} from "../interceptors";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

// Handle request process
axiosInstance.interceptors.request.use((request) =>
  requestHandler(request)
);

// Handle response process
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(loginResponse({}));
      clearAdminData();
      clearFirebaseToken();
      generateFirebaseToken();
      history.push(ROUTE_PATHS["login"]);
    }
    return errorHandler(error);
  }
);
