import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Delete, Star, StarOutline } from "@material-ui/icons";
import { useParams, useLocation } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import Tooltip from "@material-ui/core/Tooltip";
import { FormHelperText } from "@material-ui/core";

import messages from "../../../assets/locale/messages";
import AlertNote from "../../../components/AlertNote";
import Navbar from "../../../components/Navbar";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { ARABIC_REGEX } from "../../../utils/Patterns";
import { uploadToS3, directories } from "../../../utils/S3";
import Breadcrumb from "../../../components/Breadcrumb";
import Input from "../../../components/Input";
import Upload from "../../../components/Upload";
import Button from "../../../components/Button";
import Switch from "../../../components/Switch";
import ProductsImagesSwiper from "../../../components/ProductImagesSwiper";
import { getSuperProductRequest } from "../../../store/SuperProducts/actions";
import { getProductCategoryRequest } from "../../../store/ProductCategories/actions";
import {
  addSubProductRequest,
  getSubProductRequest,
  editSubProductRequest,
  getSubProductResponse
} from "../../../store/SubProducts/actions";
import "./AddEditSubProduct.scss";

const AddEditSubProduct = () => {
  const dispatch = useDispatch();
  const uploadElementRef = useRef();
  const { id, superId, prdId } = useParams();
  const location = useLocation();

  const lang = useSelector((state) => state.locale.lang);
  const { categories, addEdit } = messages[lang].subProducts;
  const { general } = messages[lang];
  const productCategory = useSelector(
    (state) => state.productCategories.productCategories
  );
  const superProduct = useSelector(
    (state) => state.superProducts.superProduct
  );
  const subProduct = useSelector(
    (state) => state.subProducts.subProduct
  );

  const [imagesArray, setImagesArray] = useState([]);
  const [uploadError, setUploadError] = useState(null);

  useEffect(() => {
    dispatch(getProductCategoryRequest(id));
  }, [id]);

  useEffect(() => {
    dispatch(getSuperProductRequest(superId));
  }, [superId]);

  useEffect(() => {
    if (prdId) {
      dispatch(getSubProductRequest(prdId));
    }
    return () => {
      dispatch(getSubProductResponse(null));
    };
  }, [prdId]);

  useEffect(() => {
    if (subProduct && prdId) {
      setTouched({
        subProductName: true,
        subProductDesc: true,
        subProductSpecs: true
      });
      setImagesArray(
        subProduct?.images.map((image) => {
          return {
            id: image.id, // indicator that this image is coming from backend
            url: image.url,
            isDefault: image.is_default
          };
        })
      );
      setValues({
        subProductName: subProduct?.name,
        subProductActive: subProduct?.status === "active",
        // set formik images to upload with null to not upload fetched images again
        subProductImages: subProduct?.images.map(() => null),
        // handle null description case
        subProductDesc: subProduct?.description || "",
        subProductSpecs: subProduct?.specifications?.join(", ")
      });
    }
  }, [subProduct]);

  const handleDeleteImage = (index) => {
    // if the deleted image is the default one set the first image as the default after splice
    let isDefaultFlag = false;
    if (imagesArray[index].isDefault) {
      imagesArray[index].isDefault = false;
      isDefaultFlag = true;
    }
    if (imagesArray[index].id) {
      // coming from backend
      imagesArray[index]._destroy = true;
    } else {
      imagesArray.splice(index, 1);
    }
    if (isDefaultFlag && imagesArray.length) {
      // first not _destroy image is default image
      for (let i = 0; i < imagesArray.length; i++) {
        if (!imagesArray[i]._destroy) {
          imagesArray[i].isDefault = true;
          break;
        }
      }
    }
    setImagesArray([...imagesArray]);
    if (values["subProductImages"][index]) {
      // dont remove it if coming from backend
      values["subProductImages"].splice(index, 1);
      setFieldValue("subProductImages", values["subProductImages"]);
    }
  };

  const handleDefaultImage = (index) => {
    imagesArray.forEach((image) => {
      if (image.isDefault) image.isDefault = false;
    });
    imagesArray[index].isDefault = true;
    setImagesArray([...imagesArray]);
  };

  const breadcrumb = () => (
    <Breadcrumb
      linksArray={[
        {
          link: ROUTE_PATHS["productCategories"],
          name: categories
        },
        {
          link: ROUTE_PATHS["superProducts"].replace(":id", id),
          name: productCategory?.name || "",
          isArabic: true
        },
        {
          link: ROUTE_PATHS["subProducts"]
            .replace(":id", id)
            .replace(":superId", superId),
          name: superProduct?.name || "",
          isArabic: true
        }
      ]}
    />
  );
  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
    setValues,
    setTouched
  } = useFormik({
    initialValues: {
      subProductName: "",
      subProductImages: [],
      subProductSpecs: "",
      subProductDesc: "",
      subProductActive: true
    },
    validationSchema: Yup.object({
      subProductName: Yup.string()
        .required(addEdit.validations.subProductName)
        .matches(ARABIC_REGEX, addEdit.validations.arabic),
      subProductSpecs: Yup.string().matches(
        ARABIC_REGEX,
        addEdit.validations.arabic
      ),
      subProductDesc: Yup.string()
    }),
    onSubmit: async (values) => {
      for (const [i, image] of values.subProductImages.entries()) {
        if (image) {
          const data = await uploadToS3(
            image,
            // unique file name to avoid conflicts and without spaces as it makes errors
            `${new Date().getTime()}_${image.name
              .replace(/\s/g, "")
              .replace(/(\(|\))/g, "")}`,
            directories.products_images
          );
          imagesArray[i].url = data?.link;
        }
      }

      let subProductData = {
        name: values.subProductName.replace(/  +/g, " "),
        super_product_id: superId,
        specifications: values.subProductSpecs
          ?.split(/،|,/)
          .map((spec) => spec.trim()),
        description: values.subProductDesc,
        status: values.subProductActive ? "active" : "inactive", // to be confirmed
        images_attributes: imagesArray.map((image) => {
          if (image.id) {
            // coming from backend
            return {
              id: image.id,
              url: image.url,
              is_default: image.isDefault,
              _destroy: image._destroy
            };
          }
          return {
            url: image.url,
            is_default: image.isDefault
          };
        })
      };
      if (prdId) {
        dispatch(
          editSubProductRequest({
            data: subProductData,
            id,
            superId,
            prdId,
            routerFlag: location?.state?.allProducts,
            indicateThatProductWillBeDeactivated:
              values["subProductActive"] === false &&
              subProduct?.status !== "inactive"
          })
        );
      } else {
        dispatch(
          addSubProductRequest({
            data: subProductData,
            id,
            superId
          })
        );
      }
    }
  });

  return (
    <>
      <Navbar
        NavbarTitle={
          prdId ? subProduct?.concatenated_name : addEdit.title
        }
      />
      <div className="bg-white mx-4 my-3 py-4 px-5 warehouses-page">
        {breadcrumb()}

        <AlertNote
          alertText={general.noteOfChangingProductsWillAffectOffers}
          wrapperClasses="mt-5 mb-4"
        />

        <div className="mt-5 add-sub-product-container">
          <form noValidate onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-4">
                <Input
                  id="subProductName"
                  label={addEdit.subProductName}
                  labelAdornment={
                    <span className="text-bg-primary-mid fsize-14 font-reguler">
                      {addEdit.arabicLang}
                    </span>
                  }
                  name="subProductName"
                  type="text"
                  placeholder={addEdit.placeholders.subProductName}
                  required
                  onChange={(value) => {
                    setFieldTouched("subProductName");
                    setFieldValue("subProductName", value);
                  }}
                  value={values["subProductName"]}
                  inputWrapperClass="mb-4"
                  labelClassName="text-dark font-medium mb-2"
                  isInputHasErr={
                    !!(
                      touched["subProductName"] &&
                      errors["subProductName"]
                    )
                  }
                  errMsg={errors["subProductName"]}
                />
              </div>
              {imagesArray.length == 0 && (
                <div className="col-4">
                  <span className="text-dark font-medium">
                    {addEdit.prdImages}
                  </span>
                  <Tooltip
                    title={
                      <span className="fsize-12">
                        {general.filesAccepted}
                      </span>
                    }
                  >
                    <span className="text-bg-primary-mid fsize-14 font-reguler ms-2">
                      {general.conditions}
                    </span>
                  </Tooltip>
                  <Upload
                    label={
                      <div className="pt-1 d-flex flex-column align-items-center">
                        <ImageOutlinedIcon fontSize="large" />
                        <span>{addEdit.uploadLabel}</span>
                      </div>
                    }
                    onChange={(imgs, files) => {
                      setFieldValue(
                        "subProductImages",
                        Array.from(files)
                      );
                      setImagesArray(
                        imgs.map((img, index) => ({
                          url: img,
                          isDefault: index === 0
                        }))
                      );
                    }}
                    name="subProductImages"
                    multiple={true}
                  />
                </div>
              )}
              {prdId && (
                <div className="col-4">
                  <span className="text-dark font-medium">
                    {addEdit.activate}
                  </span>
                  <div className="d-flex flex-column justify-content-between">
                    <Switch
                      checked={values["subProductActive"]}
                      name="subProductActive"
                      onChange={() => {
                        setFieldTouched("subProductActive");
                        setFieldValue(
                          "subProductActive",
                          !values["subProductActive"]
                        );
                      }}
                    />
                    <span className="text-gray">
                      {values["subProductActive"]
                        ? addEdit.activated
                        : addEdit.deactivated}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {imagesArray.length > 0 && (
              <div className="row mt-2 mb-5">
                <span className="text-dark font-medium mb-3">
                  {addEdit.prdImages}
                  <Tooltip
                    title={
                      <span className="fsize-12">
                        {general.filesAccepted}
                      </span>
                    }
                  >
                    <span className="text-bg-primary-mid fsize-14 font-reguler ms-2">
                      {general.conditions}
                    </span>
                  </Tooltip>
                  <AddCircleOutlineIcon
                    className="pointer ms-3"
                    color="primary"
                    fontSize="large"
                    onClick={() => {
                      uploadElementRef.current.click();
                    }}
                  />
                  <FormHelperText
                    error={true}
                    className="font-medium fsize-12"
                  >
                    {uploadError}
                  </FormHelperText>
                </span>
                <Upload
                  label={<div ref={uploadElementRef}></div>}
                  onChange={(imgs, files) => {
                    setFieldValue("subProductImages", [
                      ...values["subProductImages"],
                      ...Array.from(files)
                    ]);
                    setImagesArray([
                      ...imagesArray,
                      ...imgs.map((img) => {
                        return {
                          url: img,
                          isDefault: false
                        };
                      })
                    ]);
                  }}
                  name="subProductImages"
                  multiple={true}
                  className="d-none"
                  onChangeError={setUploadError}
                />
                {imagesArray.length <= 5 ? (
                  <div className="d-flex flex-wrap">
                    {imagesArray.map((image, index) => {
                      if (!image._destroy)
                        return (
                          <div
                            key={index}
                            style={{
                              backgroundImage: `url(${image.url})`
                            }}
                            className="product-image ms-4 d-flex justify-content-between"
                          >
                            {image.isDefault ? (
                              <Star
                                className="pointer text-dark-blue"
                                onClick={() => {
                                  handleDefaultImage(index);
                                }}
                              />
                            ) : (
                              <Tooltip
                                title={
                                  <span className="fsize-12">
                                    {addEdit.defaultTooltip}
                                  </span>
                                }
                              >
                                <StarOutline
                                  className="pointer text-dark-blue"
                                  onClick={() => {
                                    handleDefaultImage(index);
                                  }}
                                />
                              </Tooltip>
                            )}

                            <Delete
                              className="pointer text-red"
                              onClick={() => {
                                handleDeleteImage(index);
                              }}
                            />
                          </div>
                        );
                    })}
                  </div>
                ) : (
                  <div className="mb-1">
                    <ProductsImagesSwiper
                      slides={imagesArray}
                      handleDeleteImage={(index) => {
                        handleDeleteImage(index);
                      }}
                      tooltipTitle={addEdit.defaultTooltip}
                      handleDefaultImage={(index) => {
                        handleDefaultImage(index);
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            <div className="row">
              <div className="col-8">
                <Input
                  id="subProductDesc"
                  label={addEdit.desc}
                  // labelAdornment={
                  //   <span className="text-bg-primary-mid fsize-14 font-reguler">
                  //     {addEdit.arabicLang}
                  //   </span>
                  // }
                  name="subProductDesc"
                  type="text"
                  multiline={true}
                  rows={6}
                  placeholder={addEdit.placeholders.desc}
                  onChange={(value) => {
                    setFieldTouched("subProductDesc");
                    setFieldValue("subProductDesc", value);
                  }}
                  value={values["subProductDesc"]}
                  inputWrapperClass="mb-4"
                  labelClassName="text-dark font-medium mb-2"
                  isInputHasErr={
                    !!(
                      touched["subProductDesc"] &&
                      errors["subProductDesc"]
                    )
                  }
                  errMsg={errors["subProductDesc"]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <Input
                  id="subProductSpecs"
                  label={addEdit.specs}
                  labelAdornment={
                    <span className="text-bg-primary-mid fsize-14 font-reguler">
                      {addEdit.arabicLang}
                    </span>
                  }
                  name="subProductSpecs"
                  type="text"
                  multiline={true}
                  rows={3}
                  placeholder={addEdit.placeholders.specs}
                  onChange={(value) => {
                    setFieldTouched("subProductSpecs");
                    setFieldValue("subProductSpecs", value);
                  }}
                  value={values["subProductSpecs"]}
                  inputWrapperClass="mb-4"
                  labelClassName="text-dark font-medium mb-2"
                  isInputHasErr={
                    !!(
                      touched["subProductSpecs"] &&
                      errors["subProductSpecs"]
                    )
                  }
                  errMsg={errors["subProductSpecs"]}
                  tooltip
                  tooltipPlacement="right"
                  tooltipTitle={
                    <div className="fsize-16 px-2 py-3 spec-tooltip">
                      {addEdit.specTooltip}
                    </div>
                  }
                />
              </div>
            </div>
            <div className="row mt-4 py-4 d-flex justify-content-center">
              <div className="col-4">
                <Button
                  onClick={() => {}}
                  type="submit"
                  block
                  label={prdId ? addEdit.save : addEdit.create}
                  labelClass="px-4 fsize-16 font-medium text-white"
                  disabled={!dirty || !isValid}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddEditSubProduct;
