import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import { useParams } from "react-router-dom";
import { FormHelperText } from "@material-ui/core";
import { Share } from "@material-ui/icons";

import messages from "../../../assets/locale/messages";
import EgyptFlag from "../../../assets/svgs/flag-egypt.svg";
import { formatDate } from "../../../utils/Helpers";
import { orderStatus } from "../../../utils/Constants";
import ExportIcon from "../../../utils/Icons/Export";
import ProductCard from "../../../components/ProductCard";
import Navbar from "../../../components/Navbar";
import Stepper from "../../../components/Stepper";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import GoogleMaps from "../../../components/GoogleMaps";
import { showHideSnackbar } from "../../../store/Snackbar/actions";
import {
  getOrderDetailsRequest,
  getOrderPDFRequest,
  getOrderPDFResponse
} from "../../../store/Orders/actions";
import {
  updateOrderRequest,
  getOrderDetailsResponse
} from "../../../store/Orders/actions";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import "./ViewOrder.scss";

const ViewOrder = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { orders, general } = messages[lang];
  const orderDetails = useSelector(
    (state) => state.orders.orderDetails
  );
  const pdfUrl = useSelector((state) => state.orders.pdfUrl);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [errMsg, setErrMsg] = useState(null);
  const [expectedDays, setExpectedDays] = useState();
  const [nextOrderStatus, setNextOrderStatus] = useState();
  const [retailerLocationCoords, setRetailerLocationCoords] =
    useState({ coords: null });
  const [disableEditAndDelete, setDisableEditAndDelete] =
    useState(false);

  useEffect(() => {
    return () => {
      setExpectedDays(null);
      setNextOrderStatus(null);
      dispatch(getOrderDetailsResponse(null));
    };
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getOrderDetailsRequest(id));
    }
  }, [id]);

  useEffect(() => {
    setRetailerLocationCoords({
      coords: {
        lat: orderDetails?.address?.lat,
        lng: orderDetails?.address?.lng
      }
    });
  }, [orderDetails]);

  useEffect(() => {
    if (orderDetails && orderDetails.expected_receiving_days) {
      setExpectedDays(orderDetails.expected_receiving_days);
    }
    if (
      orderDetails?.status == orderStatus.delivered ||
      orderDetails?.status == orderStatus.cancelled ||
      orderDetails?.status == orderStatus.rejected
    ) {
      setDisableEditAndDelete(true);
    }

    if (orderDetails?.status == orderStatus.pending) {
      setDisableEditAndDelete(false);
    }
  }, [orderDetails]);

  useEffect(() => {
    if (pdfUrl) {
      window.open(pdfUrl);
    }
    return () => {
      dispatch(getOrderPDFResponse(null));
    };
  }, [pdfUrl]);

  useEffect(() => {
    if (nextOrderStatus) {
      if (
        nextOrderStatus === orderStatus.inProgress &&
        !expectedDays
      ) {
        setErrMsg(orders.viewOrder.errorMsg);
      } else if (
        nextOrderStatus === orderStatus.pending &&
        expectedDays
      ) {
        setExpectedDays();
        updateOrder({
          status: nextOrderStatus
        });
      } else if (
        nextOrderStatus === orderStatus.cancelled ||
        nextOrderStatus === orderStatus.rejected
      ) {
        updateOrder({
          status: nextOrderStatus
        });
      } else {
        updateOrder({
          status: nextOrderStatus,
          deliveryDays: expectedDays
        });
      }
    }
  }, [nextOrderStatus]);

  const handleOrderStatus = (selectedStatus) => {
    setNextOrderStatus(selectedStatus.value);
  };

  const updateOrder = ({ status, deliveryDays }) => {
    setErrMsg(null);
    dispatch(
      updateOrderRequest(
        status === orderStatus.cancelled ||
          status === orderStatus.rejected
          ? { id, data: { status } }
          : {
              id,
              data: { status, expected_receiving_days: +deliveryDays }
            }
      )
    );
  };

  const handleChangeDeliveryTime = () => {
    if (expectedDays) {
      if (nextOrderStatus === orderStatus.inProgress) {
        updateOrder({
          status: nextOrderStatus,
          deliveryDays: expectedDays
        });
      } else {
        updateOrder({
          deliveryDays: expectedDays
        });
      }
    }
  };

  const productImagesSlides = (productImage) => {
    let slides = [];
    slides.push(
      <SwiperSlide key={1}>
        <div
          className={`${
            productImage
              ? "product-card-image-container "
              : "product-card-image-container product-card-image-container-empty"
          }`}
          style={{
            backgroundImage: `url(${
              productImage ||
              process.env.REACT_APP_PRODUCTS_PLACEHOLDER
            })`
          }}
        ></div>
      </SwiperSlide>
    );

    return slides;
  };

  const viewOrderProducts = (id) => {
    history.push({
      pathname: ROUTE_PATHS.ordersProducts.replace(":id", id),
      state: {
        hideActions: disableEditAndDelete
      }
    });
  };

  const handleShareClick = () => {
    const locationLink = `https://www.google.com/maps/search/?api=1&query=${retailerLocationCoords?.coords?.lat},${retailerLocationCoords?.coords?.lng}`;
    navigator.clipboard.writeText(locationLink);
    dispatch(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: orders.viewOrder.linkCopied
      })
    );
  };

  return (
    <>
      <Navbar NavbarTitle={orders.viewOrder.details}>
        <div className="pdf">
          <Button
            outlined
            className="me-5 px-4 "
            label={
              <span className="d-flex align-items-center">
                <ExportIcon className=" export-icon" />
                <span className="ps-2">
                  {orders.viewOrder.export}
                </span>
              </span>
            }
            labelClass="fsize-16 pe-2"
            onClick={() => {
              dispatch(getOrderPDFRequest(id));
            }}
          />
        </div>
      </Navbar>
      {orderDetails && (
        <div className="content-wrapper order-details p-4  ">
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="font-medium text-dark fsize-18">
                {orders.viewOrder.wholesalerDetails}
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-4">
                <div className="text-dark font-reguler">
                  {orders.viewOrder.name}
                </div>
                <div className="text-dark-blue font-semibold fsize-16">
                  {orderDetails.wholesaler_name}
                </div>
              </div>
              <div className="col-4">
                <div className="text-dark font-reguler">
                  {orders.viewOrder.warehouse}
                </div>
                <div className="text-dark-blue font-semibold fsize-16 d-flex justify-content-start align-items-center">
                  <div
                    style={{
                      backgroundImage: `url(${orderDetails.wholesaler_logo})`
                    }}
                    className="warehouse-logo "
                  ></div>
                  <p className="ms-2">
                    {orderDetails.warehouse_name}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="dividerLine my-4"></div>
          <div>
            <div className="font-medium text-dark fsize-18">
              {orders.viewOrder.retailerDetails}
            </div>
            <div className="row mt-4">
              <div className="col-4">
                <div className="text-dark font-reguler">
                  {orders.viewOrder.name}
                </div>
                <div className="text-dark-blue font-semibold fsize-16">
                  {orderDetails.retailer_name}
                </div>
              </div>
              <div className="col-4">
                <div className="text-dark font-reguler">
                  {orders.viewOrder.store}
                </div>
                <div className="text-dark-blue font-semibold fsize-16">
                  {orderDetails.retailer_storename}
                </div>
              </div>
              <div className="col-4">
                <div className="text-dark font-reguler">
                  {orders.viewOrder.phone}
                </div>
                <div className="d-flex align-items-center">
                  <div className="flag-container bg-gray-flag p-2">
                    <img
                      className="img-fluid"
                      src={EgyptFlag}
                      alt="eg"
                    />
                  </div>
                  <div className="text-dark-blue font-semibold fsize-16">
                    +20 {orderDetails.retailer_phone_number}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="text-dark font-reguler">
                {orders.viewOrder.deliveryAdress}
              </div>
              <div className="text-dark-blue font-semibold fsize-16 d-flex align-items-center">
                <p className="ms-1">
                  {orderDetails?.address?.building_number}
                </p>
                <p className="ms-1">
                  {orderDetails?.address?.street_name}
                </p>
                <p className="ms-1"> {orderDetails?.address?.area}</p>
                <p className="ms-1">
                  {orderDetails?.address?.additional_info}
                </p>
              </div>
            </div>
            <div className="mt-4">
              <div className="text-dark font-reguler">
                {orders.viewOrder.retailerLocation}
              </div>
              <div className="mt-4">
                {retailerLocationCoords?.coords?.lat &&
                  retailerLocationCoords?.coords?.lng && (
                    <>
                      <div className="position-relative">
                        <div className="shareLocationBtn ">
                          <Button
                            outlined
                            className="me-5 px-4 "
                            label={
                              <span className="d-flex align-items-center">
                                <Share className="share-icon" />
                                <span className="ps-2">
                                  {orders.viewOrder.shareLocation}
                                </span>
                              </span>
                            }
                            labelClass="fsize-16 pe-2"
                            onClick={handleShareClick}
                          />
                        </div>
                      </div>
                      <GoogleMaps
                        hasSearch={false}
                        savedCoords={retailerLocationCoords}
                        savedPolygon={[]}
                        className="w-75 position-relative orderDetailsMap"
                        viewOnly={true}
                        isMarker={true}
                        isPolygon={false}
                      />
                    </>
                  )}
              </div>
            </div>
          </div>
          <div className="dividerLine my-4"></div>
          <div className="mb-5">
            <div className="font-medium text-dark fsize-18">
              {orders.viewOrder.order}
            </div>
            <div
              className={`d-flex align-items-center mt-4 ${
                orderDetails.promo_code !== null
                  ? "justify-content-start flex-wrap"
                  : "justify-content-between"
              } `}
            >
              <div className="me-4 mt-3">
                <div className="text-dark font-reguler">
                  {orders.viewOrder.orderNumber}
                </div>
                <div className="text-dark-blue font-semibold fsize-16">
                  {orderDetails.order_number}
                </div>
              </div>
              <div className="me-4 mt-3">
                <div className="text-dark font-reguler">
                  {orders.viewOrder.deliveryMethod}
                </div>
                <div className="text-dark-blue font-semibold fsize-16">
                  {orderDetails.delivery_method}
                </div>
              </div>
              <div className="me-4 mt-3">
                <div className="text-dark font-reguler">
                  {orders.viewOrder.paymentMethod}
                </div>
                <div className="text-dark-blue font-semibold fsize-16">
                  {orderDetails.payment_method}
                </div>
              </div>
              <div className="me-4 mt-3">
                <div className="text-dark font-reguler">
                  {orders.viewOrder.date}
                </div>
                <div className="text-dark-blue font-semibold fsize-16 pe-2">
                  {formatDate(orderDetails.created_at, "DD/MM/yyyy")}
                </div>
              </div>
              {orderDetails.promo_code !== null && (
                <div className="d-flex align-items-center justify-content-between ">
                  <div className="me-4 mt-3">
                    <div className="text-dark font-reguler">
                      {orders.viewOrder.appliedPromoCode}
                    </div>
                    <div className="text-dark-blue font-semibold fsize-16 pt-2">
                      <span className="general-values-container">
                        {orderDetails.promo_code?.code}
                      </span>
                    </div>
                  </div>
                  <div className="me-4 mt-3">
                    <div className="text-dark font-reguler">
                      {orders.viewOrder.promoCodeDiscount}
                    </div>
                    <div className="text-dark-blue font-semibold fsize-16 pt-2">
                      <span className="general-values-container text-uppercase">
                        {`${orderDetails.promo_code?.discount_value} 
                                ${
                                  orderDetails.promo_code
                                    ?.discount_type === "percentage"
                                    ? "%"
                                    : orders.viewOrder.le
                                }`}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className="me-4 mt-3">
                <div className="text-dark font-reguler">
                  {orders.viewOrder.orderValue}
                </div>
                <div className="text-dark-blue font-semibold fsize-16 pt-2">
                  <span
                    className={`order-value text-uppercase ${
                      orderDetails.total_price_after_discount !==
                      orderDetails.total_price
                        ? "line-through"
                        : ""
                    } `}
                  >
                    {`${orderDetails.total_price} 
                    ${orders.viewOrder.le}`}
                  </span>
                </div>
              </div>
              {orderDetails.total_price_after_discount !==
                orderDetails.total_price && (
                <div className="mt-3">
                  <div className="text-dark font-reguler">
                    {orders.viewOrder.orderValueAfterDiscounts}
                  </div>
                  <div className="text-dark-blue font-semibold fsize-16 pt-2">
                    <span className="order-value text-uppercase">
                      {`${orderDetails.total_price_after_discount} 
                  ${orders.viewOrder.le}`}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="order-status ps-3 py-4 ms-2 me-5 pt-5">
            <div className="row me-3 mb-3">
              <div className="font-medium text-dark fsize-14 col-2  pt-2">
                {orders.viewOrder.status}
              </div>
              <div className="stepper  col-6 mb-4">
                <Stepper
                  steps={[
                    {
                      label: orders.viewOrder.pending,
                      value: orderStatus.pending,
                      disabled:
                        orderDetails.status !==
                          orderStatus.delivered &&
                        orderDetails.status !==
                          orderStatus.cancelled &&
                        orderDetails.status !== orderStatus.rejected,
                      date: (
                        <>
                          {orderDetails?.statuses_dates
                            ?.in_progress &&
                            formatDate(
                              orderDetails?.statuses_dates
                                ?.in_progress,
                              " h:mm a DD/MM/yyyy"
                            )}
                        </>
                      ),
                      flag: orderDetails?.statuses_dates?.in_progress
                        ? true
                        : false
                    },
                    {
                      label: orders.viewOrder.inProgress,
                      value: orderStatus.inProgress,
                      disabled:
                        orderDetails.status !== orderStatus.pending &&
                        orderDetails.status !==
                          orderStatus.outForDelivery,
                      date: (
                        <>
                          {orderDetails?.statuses_dates
                            ?.out_for_delivery &&
                            formatDate(
                              orderDetails?.statuses_dates
                                ?.out_for_delivery,
                              " h:mm a DD/MM/yyyy"
                            )}
                        </>
                      ),
                      flag: orderDetails?.statuses_dates
                        ?.out_for_delivery
                        ? true
                        : false
                    },
                    {
                      label: orders.viewOrder.outForDelivery,
                      value: orderStatus.outForDelivery,
                      disabled:
                        orderDetails.status !==
                        orderStatus.inProgress,
                      date: (
                        <>
                          {orderDetails?.statuses_dates?.delivered &&
                            formatDate(
                              orderDetails?.statuses_dates?.delivered,
                              " h:mm a DD/MM/yyyy"
                            )}
                        </>
                      ),
                      flag: orderDetails?.statuses_dates?.delivered
                        ? true
                        : false
                    },
                    {
                      label: orders.viewOrder.deliverd,
                      value: orderStatus.delivered,
                      disabled:
                        orderDetails.status !==
                        orderStatus.outForDelivery,
                      flag: orderDetails?.statuses_dates?.in_progress
                        ? true
                        : false
                    }
                  ]}
                  handleSelectStep={handleOrderStatus}
                  currentStep={orderDetails.status}
                  dynamicChange={false}
                />
              </div>
              <div className=" p-1 col-2 cancel-btn">
                <Button
                  className={`px-5 ${
                    orderDetails.status === orderStatus.cancelled
                      ? "active-cancell"
                      : ""
                  }`}
                  label={orders.viewOrder.cancel}
                  type="button"
                  labelClass="fsize-16 "
                  onClick={() => {
                    setNextOrderStatus(orderStatus.cancelled);
                  }}
                  outlined
                  disabled={[
                    orderStatus.cancelled,
                    orderStatus.rejected
                  ].includes(orderDetails.status)}
                />
              </div>
              <div className=" p-1 col-2 reject-btn">
                <Button
                  className={`px-5 ${
                    orderDetails.status === orderStatus.rejected
                      ? "active-reject"
                      : ""
                  }`}
                  label={orders.viewOrder.reject}
                  type="button"
                  labelClass="fsize-16 "
                  onClick={() => {
                    setNextOrderStatus(orderStatus.rejected);
                  }}
                  outlined
                  disabled={
                    ![
                      orderStatus.pending,
                      orderStatus.delivered
                    ].includes(orderDetails.status)
                  }
                />
              </div>
            </div>
            <div className="d-flex mb-4">
              <div className=" text-dark fsize-14 ">
                {orders.viewOrder.currentStatus}:
              </div>
              <div className="font-semibold second-black fsize-14 ms-2">
                {orders.filterOrder[orderDetails.status]}
              </div>
            </div>
            <div className="align-items-center row">
              <div className="col-4 pe-0">
                <div className={`row align-items-center`}>
                  <div className="font-medium text-dark fsize-14 expected-date col-7 pe-0">
                    {orders.viewOrder.expectedDelivery}
                  </div>
                  <div className="col-3 ps-0">
                    <Input
                      id="days"
                      name="days"
                      type="number"
                      onKeyDown={(e) =>
                        (e.key === "e" || e.key === ".") &&
                        e.preventDefault()
                      }
                      onChange={(value) => {
                        if (value >= 1 || !value) {
                          setExpectedDays(value);
                        }
                      }}
                      disabled={[
                        orderStatus.outForDelivery,
                        orderStatus.delivered,
                        orderStatus.cancelled,
                        orderStatus.rejected
                      ].includes(orderDetails.status)}
                      value={expectedDays}
                      inputClass="mb-1 p-2"
                      labelClassName="font-medium mb-2"
                      inputWrapperClass="delivery-days-input"
                      min="1"
                      onBlur={handleChangeDeliveryTime}
                      isInputHasErr={!!errMsg}
                    />
                  </div>
                  <div className="col-2 px-0 very-light-gray d-flex  align-items-center confirm-btn">
                    {orders.viewOrder.days}
                  </div>
                </div>
              </div>
              {errMsg && (
                <div className="error col-8 ps-0">
                  <FormHelperText
                    error={true}
                    className={"font-medium fsize-14 mt-0"}
                  >
                    {errMsg}
                  </FormHelperText>
                </div>
              )}
            </div>
          </div>
          <div className="order-product  py-4">
            <div className=" text-dark ms-2 my-2 fsize-14">
              {orders.viewOrder.orderProducts}
            </div>
            <div className="row">
              {orderDetails?.products_array?.map((product, index) => {
                if (index < 3) {
                  return (
                    <div className="col-3 mb-3" key={index}>
                      <ProductCard
                        prdImgs={productImagesSlides(product.image)}
                        prdNameParent={product.name}
                        prdPrice={product.price_per_product.toString()}
                        prdQuent={product.quantity.toString()}
                        actions
                        orderProductHasOffer={
                          product.offer_id !== null &&
                          product.discount_type !== null &&
                          product.discount_value !== null
                        }
                        product={product}
                        actionsElement={
                          <div className="d-flex justify-content-between align-items-center mt-0">
                            <p>{orders.viewOrder.totalCard}</p>
                            <p className="price-label">
                              {product.total_price_after_discount !==
                              null
                                ? product.total_price_after_discount.toFixed(
                                    2
                                  )
                                : product.total_price.toFixed(2)}
                              <span className="ps-1">
                                {general.le}
                              </span>
                            </p>
                          </div>
                        }
                        showDesc={false}
                      />
                    </div>
                  );
                }
              })}

              <div className="col-3 mt-2 ">
                <Button
                  className=" px-4 me-5"
                  label={orders.viewOrder.viewProducts}
                  type="button"
                  labelClass="fsize-16 text-orange"
                  outlined
                  onClick={() => {
                    viewOrderProducts(id);
                  }}
                />
              </div>
            </div>
            {orderDetails.delivery_notes && (
              <div
                className="delivery-notes ms-2 me-5 p-4"
                id="order-delivery-notes"
              >
                <p className="fsize-18 font-medium">{orders.Notes}</p>
                <p>{orderDetails.delivery_notes}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ViewOrder;
