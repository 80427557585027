import React from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  InputLabel,
  FormHelperText,
  TextField
} from "@material-ui/core";
import "./Select.scss";

const Select = ({
  labelClassName,
  value,
  inputClassName,
  disabled,
  label,
  options,
  placeholder,
  id,
  name,
  hasError,
  required,
  onChange,
  errMsg,
  helperText,
  helperTextClass
}) => {
  return (
    <div className="d-flex justify-content-center  flex-column selectWrapper">
      <InputLabel htmlFor={id || name} className={labelClassName}>
        <>
          {label}
          {required ? <span className="err">*</span> : ""}
        </>
      </InputLabel>
      <Autocomplete
        name={name}
        id={id || name}
        options={options}
        disabled={disabled}
        getOptionLabel={(option) => option.label || ""}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={(e, value) => {
          onChange(value);
        }}
        className={`${inputClassName} ${
          hasError ? "error-border" : ""
        }`}
        value={value}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} />
        )}
      />
      <FormHelperText
        error={hasError}
        className={helperTextClass || "font-medium fsize-12"}
      >
        {hasError ? errMsg : helperText}
      </FormHelperText>
    </div>
  );
};

export default Select;

Select.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  inputClassName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  required: PropTypes.bool,
  hasError: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  helperTextClass: PropTypes.string,
  errMsg: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired
    })
  ])
};
