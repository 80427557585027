import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SwiperSlide } from "swiper/react";
import { Add, Edit } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";

import messages from "../../assets/locale/messages";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import Navbar from "../../components/Navbar";
import SearchInput from "../../components/SearchInput";
import ProductCard from "../../components/ProductCard";
import Breadcrumb from "../../components/Breadcrumb";
import Button from "../../components/Button";
import EmptyState from "../../components/EmptyState";
import Pagination from "../../components/Pagination";
import WarehouseProductModal from "../../components/WarehouseProductModal";
import Modal from "../../components/Modal";
import {
  getSubProductsRequest,
  getSubProductsResponse
} from "../../store/SubProducts/actions";
import {
  getSuperProductRequest,
  getSuperProductResponse
} from "../../store/SuperProducts/actions";
import {
  getProductCategoryRequest,
  getProductCategoryResponse
} from "../../store/ProductCategories/actions";
import { setProductsRoute } from "../../store/Routing/actions";
import "./SubProducts.scss";

const SubProducts = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, superId } = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const { subProducts, general } = messages[lang];
  const list = useSelector((state) => state.subProducts.list);
  const meta = useSelector((state) => state.subProducts.meta);
  const [openAdd, setOpenAdd] = useState(false);
  const [productId, setProductId] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const isLoading = useSelector((state) => state.loader);
  const productCategory = useSelector(
    (state) => state.productCategories.productCategories
  );
  const superProduct = useSelector(
    (state) => state.superProducts.superProduct
  );
  const productsRoutingData = useSelector(
    (state) => state.routing.products
  );

  const [searchValue, setSearchValue] = useState(
    productsRoutingData?.search || ""
  );
  const [page, setPage] = useState(productsRoutingData?.page || 1);

  useEffect(() => {
    if (productsRoutingData) {
      dispatch(setProductsRoute(null));
    }
  }, [productsRoutingData]);

  useEffect(() => {
    dispatch(getProductCategoryRequest(id));
  }, [id]);

  useEffect(() => {
    dispatch(getSuperProductRequest(superId));
  }, [superId]);

  useEffect(() => {
    getSubProducts(page, searchValue);
  }, [page, superId]);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      setPage(productsRoutingData?.page || 1);
      getSubProducts(productsRoutingData?.page || 1, searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    return () => {
      dispatch(
        getSubProductsResponse({
          list: [],
          meta: {}
        })
      );
      dispatch(getSuperProductResponse(null));
      dispatch(getProductCategoryResponse(null));
    };
  }, []);

  const getSubProducts = (pageNumber, search) => {
    dispatch(
      getSubProductsRequest({
        page: pageNumber,
        items: 12,
        super_product_id: superId,
        name: search
      })
    );
  };

  const handleCreate = () => {
    history.push(
      ROUTE_PATHS["addSubProduct"]
        .replace(":id", id)
        .replace(":superId", superId)
    );
  };

  const handleEditClick = (subProductId) => {
    dispatch(
      setProductsRoute({
        page,
        search: searchValue
      })
    );
    history.push(
      ROUTE_PATHS["editSubProduct"]
        .replace(":id", id)
        .replace(":superId", superId)
        .replace(":prdId", subProductId)
    );
  };

  const productImagesSlides = (imagesArray) => {
    let slides = [];
    if (imagesArray.length) {
      slides = imagesArray.map((image) => (
        <SwiperSlide key={image.id}>
          <div
            className="product-card-image-container "
            style={{
              backgroundImage: `url(${image.url})`
            }}
          ></div>
        </SwiperSlide>
      ));
    } else {
      slides.push(
        <SwiperSlide key={1}>
          <div
            className="product-card-image-container product-card-image-container-empty "
            style={{
              backgroundImage: `url(${process.env.REACT_APP_PRODUCTS_PLACEHOLDER})`
            }}
          ></div>
        </SwiperSlide>
      );
    }
    return slides;
  };

  const handleCloseAddModal = () => {
    setOpenAdd(false);
  };

  const handleAdd = (product) => {
    setProductId(product.id);
    setSelectedProduct(product);
    setOpenAdd(true);
  };

  const breadcrumb = () => (
    <Breadcrumb
      linksArray={[
        {
          link: ROUTE_PATHS["productCategories"],
          name: subProducts.categories
        },
        {
          link: ROUTE_PATHS["superProducts"].replace(":id", id),
          name: productCategory?.name || "",
          isArabic: true
        },
        {
          name: superProduct?.name || "",
          isArabic: true
        }
      ]}
    />
  );

  const subProductsList = () => {
    if (!isLoading && list.length == 0 && !searchValue) {
      return (
        <>
          <Navbar NavbarTitle={productCategory?.name} arabicTitle />
          <div className="bg-white m-4 py-4 px-5">
            {breadcrumb()}
            <div className="warehouses-page d-flex justify-content-center align-items-center flex-column">
              <EmptyState
                subTitle={
                  <span>
                    {subProducts.emptyState.subTitleOne}
                    <span className="font-cairo-regular text-dark mx-2">
                      &quot;{superProduct?.name}&quot;
                    </span>
                    {subProducts.emptyState.subTitleTwo}
                  </span>
                }
                description={subProducts.emptyState.description}
                ButtonLabel={subProducts.emptyState.buttonLabel}
                handelCreate={handleCreate}
              />
            </div>
          </div>
        </>
      );
    } else if (!isLoading && searchValue && list.length == 0) {
      return (
        <>
          <Navbar NavbarTitle={productCategory?.name} arabicTitle>
            <Button
              label={
                <span className="d-flex align-items-center">
                  <Add fontSize="large" />
                  <span className="ps-1">
                    {subProducts.addProduct}
                  </span>
                </span>
              }
              labelClass="fsize-16 text-white pe-2"
              onClick={handleCreate}
            />
          </Navbar>
          <div className="d-flex justify-content-end align-items-center pe-4 mt-3">
            <SearchInput
              name="search"
              value={searchValue}
              onChange={(value) => {
                setSearchValue(value);
              }}
              placeholder={subProducts.search}
            />
          </div>
          <div className="bg-white m-4 py-4 px-5">
            {breadcrumb()}
            <div className="warehouses-page d-flex justify-content-center align-items-center flex-column">
              <EmptyState
                subTitle={general.noSearch}
                showActionButton={false}
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div>
          <Navbar NavbarTitle={productCategory?.name} arabicTitle>
            <Button
              label={
                <span className="d-flex align-items-center">
                  <Add fontSize="large" />
                  <span className="ps-1">
                    {subProducts.addProduct}
                  </span>
                </span>
              }
              labelClass="fsize-16 text-white pe-2"
              onClick={handleCreate}
            />
          </Navbar>
          <div className="d-flex justify-content-end align-items-center pe-4 mt-3">
            <SearchInput
              name="search"
              value={searchValue}
              onChange={(value) => {
                setSearchValue(value);
              }}
              placeholder={subProducts.search}
            />
          </div>
          <div className="bg-white mx-4 my-3 py-4 px-5 ">
            {breadcrumb()}
            <div className="py-4  subProducts-container row">
              {list.map((product) => {
                return (
                  <div
                    key={product.id}
                    className=" mb-4 subProducts-child  col-3"
                  >
                    <ProductCard
                      prdImgs={productImagesSlides(product.images)}
                      initialSlide={product.images?.findIndex(
                        (image) => image.is_default
                      )}
                      prdDescription={product.description}
                      prdCategory={
                        product.super_product.product_category.name
                      }
                      prdNameParent={product.concatenated_name}
                      prdSpecifications={product.specifications}
                      actions={true}
                      actionsElement={
                        <>
                          <div className="d-flex justify-content-end edit-icon-product-card">
                            <Edit
                              fontSize="medium"
                              className="pointer text-dark-blue"
                              onClick={() => {
                                handleEditClick(product.id);
                              }}
                            />
                          </div>
                          <Button
                            label={
                              <span className="d-flex align-items-center">
                                <span className="ps-1">
                                  {subProducts.addToWholsaler}
                                </span>
                                <Add fontSize="small" />
                              </span>
                            }
                            labelClass="fsize-12  add-btn-text "
                            className="add-btn"
                            outlined
                            onClick={() => {
                              handleAdd(product);
                            }}
                          />
                        </>
                      }
                      deactivated={product.status !== "active"}
                    />
                  </div>
                );
              })}
            </div>
            <div className="d-flex justify-content-center py-3">
              {meta.pages > 1 && (
                <Pagination
                  count={meta.pages}
                  page={meta.currentPage || page}
                  handleChange={(event, page) => {
                    setPage(page);
                  }}
                  defaultPage={1}
                />
              )}
            </div>
          </div>
          <Modal
            open={openAdd}
            onClose={handleCloseAddModal}
            className="Add-Modal"
            modalBody={
              <WarehouseProductModal
                productId={productId}
                onClose={handleCloseAddModal}
                isWholesalerOption={true}
                product={selectedProduct}
                isEdit={false}
              />
            }
          />
        </div>
      );
    }
  };

  return <>{subProductsList()}</>;
};

export default SubProducts;
