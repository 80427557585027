import { put, call, takeEvery } from "redux-saga/effects";
import amplitude from "amplitude-js";

import * as types from "./types";
import * as apis from "../../network/apis/warehouses";
import {
  getWarehousesResponse,
  getWarehouseResponse,
  getWeekDaysResponse,
  getWarehouseRequest
} from "./actions";

import messages from "../../assets/locale/messages";
import { showHideSnackbar } from "../Snackbar/actions";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import history from "../../routes/History";

export function* getWarehousesSaga({ payload }) {
  try {
    const response = yield call(apis.getWarehouses, payload);
    yield put(
      getWarehousesResponse({
        list: response.data.warehouses,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export function* addWharehouseSaga({ payload }) {
  try {
    const response = yield call(apis.addWarehouse, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: `${messages["en"].CreateWarhousePage.createSuccess}, ${messages["en"].CreateWarhousePage.wholsalerMsg}`
      })
    );
    history.push(ROUTE_PATHS.warehouses);

    const eventProperties = {
      "wharehouse id": response.data.warehouse.id,
      "wharehouse name": response.data.warehouse.name
    };

    amplitude
      .getInstance()
      .logEvent("admin update: wharehouse added", eventProperties);
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}
export function* editWarehouseSaga({ payload }) {
  try {
    yield call(apis.editWarehouse, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].EditWarehousePage.editSuccess
      })
    );
    history.push(ROUTE_PATHS["warehouses"]);
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export function* getWarehouseSaga({ payload }) {
  try {
    const response = yield call(apis.getWarhouse, payload);
    yield put(getWarehouseResponse(response.data.warehouse));
  } catch (err) {
    console.log(err);
  }
}

export function* getWeekDaysSaga() {
  try {
    const response = yield call(apis.getWeekDays);
    yield put(getWeekDaysResponse(response.data.week_days));
  } catch (err) {
    console.log("err", err);
  }
}

export function* createZoneSaga({ payload }) {
  try {
    yield call(apis.createZone, payload);
    const { warehouse_id } = payload;
    history.push(
      ROUTE_PATHS.editWarehouse.replace(":id", warehouse_id)
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export function* editZoneSaga({ payload }) {
  try {
    yield call(apis.editZone, payload);
    const { warehouse_id } = payload;
    history.push(
      ROUTE_PATHS.editWarehouse.replace(":id", warehouse_id)
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export function* deleteZoneSaga({
  payload: { warehouse_id, zone_id }
}) {
  try {
    yield call(apis.deleteZone, zone_id);
    yield put(getWarehouseRequest(warehouse_id));
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export default function* WarehousesSags() {
  yield takeEvery(types.GET_WAREHOUSES_REQUEST, getWarehousesSaga);
  yield takeEvery(types.ADD_WAREHOUSE_REQUEST, addWharehouseSaga);
  yield takeEvery(types.EDIT_WAREHOUSE_REQUEST, editWarehouseSaga);
  yield takeEvery(types.GET_WAREHOUSE_REQUEST, getWarehouseSaga);
  yield takeEvery(types.GET_WEEK_DAYS_REQUEST, getWeekDaysSaga);
  yield takeEvery(types.CREATE_ZONE_REQUEST, createZoneSaga);
  yield takeEvery(types.EDIT_ZONE_REQUEST, editZoneSaga);
  yield takeEvery(types.DELETE_ZONE_REQUEST, deleteZoneSaga);
}
