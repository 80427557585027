import * as types from "./types";

export const getBannersRequest = (data) => ({
  type: types.GET_BANNERS_REQUEST,
  payload: data
});

export const getBannersResponse = (data) => ({
  type: types.GET_BANNERS_RESPONSE,
  payload: data
});

export const addBannerRequest = (data) => ({
  type: types.ADD_BANNER_REQUEST,
  payload: data
});

export const addBannerResponse = (data) => ({
  type: types.ADD_BANNER_RESPONSE,
  payload: data
});

export const getBannerRequest = (data) => ({
  type: types.GET_BANNER_REQUEST,
  payload: data
});

export const getBannerResponse = (data) => ({
  type: types.GET_BANNER_RESPONSE,
  payload: data
});

export const editBannerRequest = (payload) => ({
  type: types.EDIT_BANNER_REQUEST,
  payload
});

export const editBannerResponse = (data) => ({
  type: types.EDIT_BANNER_RESPONSE,
  payload: data
});

export const deleteBannerRequest = (data) => ({
  type: types.DELETE_BANNER_REQUEST,
  payload: data
});
