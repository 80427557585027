export const GET_BANNERS_REQUEST = "GET_BANNERS_REQUEST";

export const GET_BANNERS_RESPONSE = "GET_BANNERS_RESPONSE";

export const ADD_BANNER_REQUEST = "ADD_BANNER_REQUEST";

export const ADD_BANNER_RESPONSE = "ADD_BANNER_RESPONSE";

export const GET_BANNER_REQUEST = "GET_BANNER_REQUEST";

export const GET_BANNER_RESPONSE = "GET_BANNER_RESPONSE";

export const EDIT_BANNER_REQUEST = "EDIT_BANNER_REQUEST";

export const EDIT_BANNER_RESPONSE = "EDIT_BANNER_RESPONSE";

export const DELETE_BANNER_REQUEST = "DELETE_BANNER_REQUEST";
