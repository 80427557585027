import { axiosInstance } from "./";

const getWholesalers = async (params) =>
  await axiosInstance.get(`wholesalers`, { params });

const addWholesaler = async (payload) =>
  await axiosInstance.post(`wholesalers`, payload);

const getWholesaler = async (id) =>
  await axiosInstance.get(`wholesalers/${id}`);

const editWholesaler = async ({ data, id }) =>
  await axiosInstance.patch(`wholesalers/${id}`, data);

const getWholesalerProducts = async ({ params, id }) =>
  await axiosInstance.get(`wholesalers/${id}/wholesalers_products`, {
    params
  });

const getWholesalerProductsWithoffersInsideOrder = async ({
  params,
  wholesaler_id
}) =>
  await axiosInstance.get(
    `wholesalers/${wholesaler_id}/wholesalers_products_with_offers`,
    {
      params
    }
  );

const getWholesalerProduct = async ({ productId, wholsalerId }) =>
  await axiosInstance.get(
    `wholesalers/${wholsalerId}/wholesalers_products/${productId}`
  );

const createWholesalerProduct = async ({ data, id }) =>
  await axiosInstance.post(
    `wholesalers/${id}/wholesalers_products`,
    data
  );

const editWholesalerProduct = async ({
  productId,
  wholsalerId,
  data
}) =>
  await axiosInstance.patch(
    `wholesalers/${wholsalerId}/wholesalers_products/${productId}`,
    data
  );

const deleteWholesalerProduct = async ({
  productId,
  wholsalerId,
  data
}) =>
  await axiosInstance.delete(
    `wholesalers/${wholsalerId}/wholesalers_products/${productId}`,
    data
  );

const getDeliveryMethods = async (params) =>
  await axiosInstance.get(`delivery_methods`, { params });

export {
  getWholesalers,
  addWholesaler,
  getWholesaler,
  editWholesaler,
  getWholesalerProducts,
  getWholesalerProductsWithoffersInsideOrder,
  getWholesalerProduct,
  createWholesalerProduct,
  editWholesalerProduct,
  deleteWholesalerProduct,
  getDeliveryMethods
};
