import {
  SET_RETAILERS_ROUTE,
  SET_WAREHOUSES_ROUTE,
  SET_WHOLESALERS_ROUTE,
  SET_ORDERS_ROUTE,
  SET_CATEGORIES_ROUTE,
  SET_SUPER_PRODUCTS_ROUTE,
  SET_PRODUCTS_ROUTE,
  SET_WHOLESALER_PRODUCTS_ROUTE,
  SET_PROMOCODES_ROUTE,
  SET_BANNERS_ROUTE,
  SET_OFFERS_ROUTE
} from "./types";

const INITIAL_STATE = {
  wholesalers: null,
  warehouses: null,
  retailers: null,
  orders: null,
  categories: null,
  superProducts: null,
  products: null,
  wholesalerProducts: null,
  promoCodes: null,
  banners: null,
  offers: null
};

const routing = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_WHOLESALERS_ROUTE:
      return {
        ...state,
        wholesalers: action.payload
      };
    case SET_WAREHOUSES_ROUTE:
      return {
        ...state,
        warehouses: action.payload
      };
    case SET_RETAILERS_ROUTE:
      return {
        ...state,
        retailers: action.payload
      };
    case SET_ORDERS_ROUTE:
      return {
        ...state,
        orders: action.payload
      };
    case SET_CATEGORIES_ROUTE:
      return {
        ...state,
        categories: action.payload
      };
    case SET_SUPER_PRODUCTS_ROUTE:
      return {
        ...state,
        superProducts: action.payload
      };
    case SET_PRODUCTS_ROUTE:
      return {
        ...state,
        products: action.payload
      };
    case SET_WHOLESALER_PRODUCTS_ROUTE:
      return {
        ...state,
        wholesalerProducts: action.payload
      };
    case SET_PROMOCODES_ROUTE:
      return {
        ...state,
        promoCodes: action.payload
      };
    case SET_BANNERS_ROUTE:
      return {
        ...state,
        banners: action.payload
      };

    case SET_OFFERS_ROUTE:
      return {
        ...state,
        offers: action.payload
      };
    default:
      return state;
  }
};

export default routing;

// STATE SCHEMA

// wholesalers : {
//   page: null,
//   search: null
// }

// warehouses : {
//   page: null,
//   search: null
// }

// retailers : {
//   page: null,
//   search: null,
//   retailer: null,
//   startDate: null,
//   endDate: null,
// }

// orders : {
//   page: null,
//   search: null,
//   activeTab: null,
//   orderStatus: null,
//   wholesaler: null,
//   storeName: null,
//   startDate: null,
//   endDate: null,
//   isFilterExpanded: false
// }

// categories : {
//   page: null,
//   search: null
// }

// superProducts : {
//   page: null,
//   search: null
// }

// products : {
//   page: null,
//   search: null,
//   category:null,
//   superProduct:null

// }

// wholesalerProducts : {
//   page: null,
//   search: null,
//   category: null
// }
