import { combineReducers } from "redux";
import locale from "./Lang/reducer";
import snackbar from "./Snackbar/reducers";
import loader from "./Loader/reducers";
import googleMaps from "./GoogleMaps/reducers";
import auth from "./Auth/reducers";
import wholesalers from "./Wholesalers/reducers";
import warehouses from "./Warehouses/reducers";
import retailers from "./Retailers/reducers";
import productCategories from "./ProductCategories/reducers";
import superProducts from "./SuperProducts/reducers";
import subProducts from "./SubProducts/reducers";
import notifications from "./Notifications/reducers";
import orders from "./Orders/reducers";
import routing from "./Routing/reducers";
import ToastMessage from "./ToastMessage/reducers";
import promoCodes from "./PromoCodes/reducers";
import banners from "./Banners/reducers";
import offers from "./Offers/reducers";

export default combineReducers({
  locale,
  snackbar,
  ToastMessage,
  loader,
  googleMaps,
  auth,
  wholesalers,
  warehouses,
  retailers,
  productCategories,
  superProducts,
  subProducts,
  notifications,
  orders,
  routing,
  promoCodes,
  banners,
  offers
});
