import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import {
  InputLabel,
  IconButton,
  FormHelperText
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";

import messages from "../../assets/locale/messages";
import "./DatePicker.scss";

const DatePicker = ({
  isInlineLabel,
  label,
  id,
  name,
  labelClassName,
  required,
  value,
  handleDateChange,
  disabled,
  disableFuture,
  disablePast,
  maxDate,
  minDate,
  viewedDateFormat,
  isInputHasErr,
  fullWidth,
  inputClass,
  errMsg,
  placeholder,
  helperTextClass,
  helperText,
  inputWrapperClass
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { general } = messages[lang];

  const [selectedDate, setSelectedDate] = useState(null);
  const [invalidDate, setInvalidDate] = useState(false);

  // To handle any formatting on date
  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  const onDatechange = (date) => {
    // handle date format if needed
    if (date == "Invalid Date") {
      setInvalidDate(true);
      setSelectedDate(date);
    } else {
      setInvalidDate(false);
      handleDateChange(date);
    }
  };

  const handleClear = () => {
    setSelectedDate(null);
    handleDateChange(null);
    setInvalidDate(false);
  };

  const inputLabel = (
    <>
      {label}
      {required ? <span className="err-asterisk">*</span> : ""}
    </>
  );

  return (
    <div className={`date-picker-wrapper ${inputWrapperClass}`}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <span>
          {!isInlineLabel && (
            <InputLabel
              className={labelClassName}
              htmlFor={id || name}
            >
              {inputLabel}
            </InputLabel>
          )}
        </span>
        <KeyboardDatePicker
          id={id || name}
          nam={name}
          required={required}
          autoOk
          variant="inline"
          inputVariant="filled"
          label={isInlineLabel && inputLabel}
          format={viewedDateFormat}
          value={selectedDate}
          onChange={onDatechange}
          allowKeyboardControl={false}
          disabled={disabled}
          disableFuture={disableFuture}
          disablePast={disablePast}
          disableToolbar={true}
          maxDate={maxDate || undefined}
          minDate={minDate || undefined}
          InputProps={{
            startAdornment: selectedDate ? (
              <IconButton className="close-btn" onClick={handleClear}>
                <Clear />
              </IconButton>
            ) : null
          }}
          error={invalidDate || isInputHasErr}
          fullWidth={fullWidth}
          className={`${inputClass} ${
            invalidDate || isInputHasErr ? "error-border" : ""
          }`}
          placeholder={placeholder}
          autoComplete="off"
        />
        <FormHelperText
          error={invalidDate || isInputHasErr}
          className={helperTextClass || "font-medium fsize-12"}
        >
          {invalidDate
            ? general.invalidDate
            : isInputHasErr
            ? errMsg
            : helperText}
        </FormHelperText>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DatePicker;

DatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  handleDateChange: PropTypes.func.isRequired,
  isInlineLabel: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  isInputHasErr: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  labelClassName: PropTypes.string,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  inputClass: PropTypes.string,
  placeholder: PropTypes.string,
  errMsg: PropTypes.string,
  helperTextClass: PropTypes.string,
  helperText: PropTypes.string,
  inputWrapperClass: PropTypes.string,
  viewedDateFormat: PropTypes.string
};

DatePicker.defaultProps = {
  value: null,
  viewedDateFormat: "dd/MM/yyyy"
};
