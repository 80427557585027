import { axiosInstance } from "./";

const login = async (payload) => {
  return await axiosInstance.post("sessions", payload);
};

const logout = async (payload) => {
  return await axiosInstance.delete("sessions", { data: payload });
};

export { login, logout };
