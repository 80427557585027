import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Visibility, Edit, Add } from "@material-ui/icons";
import { TableCell, TableRow } from "@material-ui/core";
import debounce from "lodash.debounce";

import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import messages from "../../assets/locale/messages";
import { getWarehousesRequest } from "../../store/Warehouses/actions";
import { setWarehousesRoute } from "../../store/Routing/actions";
import Table from "../../components/Table";
import Navbar from "../../components/Navbar";
import Button from "../../components/Button";
import SearchInput from "../../components/SearchInput";
import EmptyState from "../../components/EmptyState";
import Pagination from "../../components/Pagination";
import "./warehouses.scss";

const Warehouses = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const lang = useSelector((state) => state.locale.lang);
  const { WarehousesPage, WarehousesList, general } = messages[lang];
  const list = useSelector((state) => state.warehouses.list);
  const meta = useSelector((state) => state.warehouses.meta);
  const isLoading = useSelector((state) => state.loader);
  const warehousesRoutingData = useSelector(
    (state) => state.routing.warehouses
  );

  const [page, setPage] = useState(warehousesRoutingData?.page || 1);
  const [searchValue, setSearchValue] = useState(
    warehousesRoutingData?.search || ""
  );
  const [searchQuery, setSearchQuery] = useState(
    warehousesRoutingData?.search || ""
  );

  const updateSearchQuery = () => {
    setSearchValue(searchQuery);
  };
  const delayedSearchQuery = useCallback(
    debounce(updateSearchQuery, 500),
    [searchQuery]
  );
  useEffect(() => {
    delayedSearchQuery();
    // Cancel the debounce on useEffect cleanup.
    return delayedSearchQuery.cancel;
  }, [searchQuery, delayedSearchQuery]);

  useEffect(() => {
    getWarehouses(page, searchValue);
  }, [page]);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      setPage(warehousesRoutingData?.page || 1);
      getWarehouses(warehousesRoutingData?.page || 1, searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (warehousesRoutingData) {
      dispatch(setWarehousesRoute(null));
    }
  }, [warehousesRoutingData]);

  const getWarehouses = (pageNumber, search) => {
    dispatch(
      getWarehousesRequest({
        page: pageNumber,
        items: 10,
        search
      })
    );
  };

  const handleCreate = () => {
    history.push(ROUTE_PATHS.addWarehouse);
  };

  const viewWarehouse = (id) => {
    dispatch(
      setWarehousesRoute({
        page,
        search: searchValue
      })
    );
    history.push(ROUTE_PATHS.viewWarehouse.replace(":id", id));
  };

  const editWarehouse = (id) => {
    dispatch(
      setWarehousesRoute({
        page,
        search: searchValue
      })
    );
    history.push(ROUTE_PATHS.editWarehouse.replace(":id", id));
  };

  const List = () => {
    if (!isLoading && list.length == 0 && !searchValue) {
      return (
        <>
          <Navbar NavbarTitle={WarehousesPage.title} />

          <div className=" bg-white warehouses-page d-flex justify-content-center align-items-center flex-column m-4">
            <EmptyState
              subTitle={WarehousesPage.subTitle}
              description={WarehousesPage.description}
              ButtonLabel={WarehousesPage.CreateWarehouseButton}
              handelCreate={handleCreate}
            />
          </div>
        </>
      );
    } else if (!isLoading && searchValue && list.length == 0) {
      return (
        <>
          <Navbar NavbarTitle={WarehousesList.pageTitle}>
            <Button
              label={
                <span className="d-flex align-items-center">
                  <Add fontSize="large" />
                  <span className="ps-1">
                    {WarehousesList.create}
                  </span>
                </span>
              }
              labelClass="fsize-16 text-white pe-2"
              onClick={handleCreate}
            />
          </Navbar>

          <div className="d-flex justify-content-end pe-4 mt-3">
            <SearchInput
              name="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder={WarehousesList.search}
            />
          </div>
          <div className=" bg-white warehouses-page d-flex justify-content-center align-items-center flex-column m-4">
            <EmptyState
              subTitle={general.noSearch}
              showActionButton={false}
            />
          </div>
        </>
      );
    } else {
      return (
        <div>
          <Navbar NavbarTitle={WarehousesList.pageTitle}>
            <Button
              label={
                <span className="d-flex align-items-center">
                  <Add fontSize="large" />
                  <span className="ps-1">
                    {WarehousesList.create}
                  </span>
                </span>
              }
              labelClass="fsize-16 text-white pe-2"
              onClick={handleCreate}
            />
          </Navbar>

          <div className="d-flex justify-content-end pe-4 mt-3">
            <SearchInput
              name="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder={WarehousesList.search}
            />
          </div>
          <div className="bg-white table-list mx-4 m-3">
            <Table
              headlines={[
                WarehousesList.wholesalersName,
                WarehousesList.name
              ]}
              hasActions={true}
              rows={list.map((row, i) => (
                <TableRow key={i}>
                  <TableCell className="font-reguler text-dark-blue fsize-14 ">
                    {row.wholesaler_name}
                  </TableCell>
                  <TableCell className="font-reguler text-dark-blue fsize-14 ">
                    {row.name}
                  </TableCell>

                  <TableCell className="font-reguler text-dark-blue d-flex ">
                    <div className="pt-2 d-flex flex-column align-items-center pe-5 ">
                      <Visibility
                        color="secondary"
                        fontSize="large"
                        className="opacity_7 pointer"
                        onClick={() => viewWarehouse(row.id)}
                      />
                      <span className="text-gray">
                        {WarehousesList.view}
                      </span>
                    </div>
                    <div className="pt-2 d-flex flex-column align-items-center ">
                      <Edit
                        color="secondary"
                        fontSize="large"
                        className="opacity_7 pointer"
                        onClick={() => {
                          editWarehouse(row.id);
                        }}
                      />
                      <span className="text-gray">
                        {WarehousesList.edit}
                      </span>
                    </div>

                    <div className="pt-2 d-flex flex-column align-items-center "></div>
                  </TableCell>
                </TableRow>
              ))}
            />
            <div className="d-flex justify-content-center align-items-center pt-4 pb-3">
              {meta.pages > 1 && (
                <Pagination
                  count={meta.pages}
                  page={meta.currentPage || page}
                  handleChange={(event, page) => {
                    setPage(page);
                  }}
                  defaultPage={1}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  return <>{List()}</>;
};

export default Warehouses;
