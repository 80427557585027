import { axiosInstance } from "./";

const getOffers = async (params) =>
  await axiosInstance.get(`offers`, { params });

const addOffer = async (payload) =>
  await axiosInstance.post(`offers`, payload);

const getOffer = async (id) =>
  await axiosInstance.get(`offers/${id}`);

const editOffer = async ({ data, id }) =>
  await axiosInstance.patch(`offers/${id}`, data);

const deleteOffer = async ({ id }) =>
  await axiosInstance.delete(`offers/${id}`);

export { getOffers, addOffer, getOffer, editOffer, deleteOffer };
