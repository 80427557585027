import * as types from "./types";

export const getSubProductsRequest = (data) => ({
  type: types.GET_SUBPRODUCTS_REQUEST,
  payload: data
});

export const getSubProductsResponse = (data) => ({
  type: types.GET_SUBPRODUCTS_RESPONSE,
  payload: data
});

export const getSubProductRequest = (data) => ({
  type: types.GET_SUBPRODUCT_REQUEST,
  payload: data
});

export const getSubProductResponse = (data) => ({
  type: types.GET_SUBPRODUCT_RESPONSE,
  payload: data
});

export const addSubProductRequest = (data) => ({
  type: types.ADD_SUBPRODUCT_REQUEST,
  payload: data
});

export const editSubProductRequest = (data) => ({
  type: types.EDIT_SUBPRODUCT_REQUEST,
  payload: data
});
