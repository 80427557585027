import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Filter from "../../../components/Filter";
import SearchInput from "../../../components/SearchInput";
import messages from "../../../assets/locale/messages";

const OffersFiltersAndSearch = ({
  status,
  subStatus,
  wholsalers,
  search
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { offers } = messages[lang];
  return (
    <div className="d-flex align-items-center justify-content-between px-4">
      <div className="filters px-4 py-3 d-flex align-items-center w-70">
        <Filter
          placeholder={offers.offersList.filtersPlaceHolders.status}
          options={status.options}
          value={status.value}
          name="offer status"
          onChange={(value) => {
            status.onChangeHandler(value);
          }}
          inputClassName=" mt-1 "
          filterWrapperClass=" me-4 w-30"
        />
        <Filter
          placeholder={
            offers.offersList.filtersPlaceHolders.subStatus
          }
          options={subStatus.options}
          value={subStatus.value}
          name="offer subStatus"
          onChange={(value) => {
            subStatus.onChangeHandler(value);
          }}
          inputClassName=" mt-1 "
          filterWrapperClass=" me-4 w-30"
        />

        <Filter
          placeholder={offers.offersList.offeredBy}
          options={wholsalers.options}
          value={wholsalers.value}
          name="wholsaler name"
          onChange={(value) => {
            wholsalers.onChangeHandler(value);
          }}
          inputClassName="mt-1 "
          filterWrapperClass="me-4 w-30"
        />
      </div>
      <SearchInput
        name="search"
        value={search.value}
        onChange={(value) => {
          search.onChangeHandler(value);
        }}
        placeholder={offers.offersList.searchPlaceHolder}
        inputWrapperClass="search  px-0 w-30"
        inputClass="mt-2 "
      />
    </div>
  );
};

export default OffersFiltersAndSearch;

OffersFiltersAndSearch.propTypes = {
  status: PropTypes.object,
  subStatus: PropTypes.object,
  wholsalers: PropTypes.object,
  search: PropTypes.object
};
