import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  codes: [],
  meta: {},
  promoCode: {},
  addResponse: null,
  editResponse: null
};

const promoCodesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_PROMOCODES_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };
    case types.GET_PROMOCODE_RESPONSE:
      return { ...state, promoCode: action.payload };
    case types.GET_ONLY_CODES_RESPONSE:
      return { ...state, codes: action.payload };
    case types.ADD_PROMOCODE_RESPONSE:
      return { ...state, addResponse: action.payload };
    case types.EDIT_PROMOCODE_RESPONSE:
      return { ...state, editResponse: action.payload };
    default:
      return state;
  }
};

export default promoCodesReducer;
