import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import EmptyState from "../../../components/EmptyState";
import BannerCard from "../../../components/BannerCard";
import Switch from "../../../components/Switch";
import messages from "../../../assets/locale/messages";
import { EditIcon } from "../../../utils/Icons/EditIcon";
import DeleteIcon from "../../../utils/Icons/Delete";
import "../banners.scss";

const BannersList = ({
  isLoading,
  filterStatus,
  handleAddBannerClick,
  handleEditClick,
  handleHideClick,
  handleDeleteClick
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { banners } = messages[lang];
  const list = useSelector((state) => state.banners.list);

  if (!isLoading && list.length == 0 && !filterStatus) {
    return (
      <>
        <div className=" bg-white banners-page d-flex justify-content-center align-items-center flex-column m-4">
          <EmptyState
            subTitle={banners.emptyState.subTitle}
            ButtonLabel={banners.add}
            showActionButton={true}
            handelCreate={handleAddBannerClick}
          />
        </div>
      </>
    );
  } else if (list.length == 0 && filterStatus) {
    return (
      <>
        <div className=" bg-white banners-page d-flex justify-content-center align-items-center flex-column m-4">
          <EmptyState
            subTitle={banners.emptyState.noFilterResultsSubTitle}
            showActionButton={false}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="banners-list-container">
          <div className="container">
            <div className="row ">
              {list.map((banner, index) => (
                <div
                  className="col-4 d-flex mb-5 justify-content-center align-items-center"
                  key={index}
                >
                  <BannerCard
                    bannerImage={banner?.image}
                    bannerAlt={banner?.title}
                    actions={true}
                    actionsElement={
                      <div className="px-2 actions-card">
                        <div className="banner-titles ps-2">
                          <p className="mb-0 title">
                            {banner?.title}
                          </p>
                          <p className="fsize-10 sub-title">
                            {banner?.subtitle}
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex flex-column align-items-center">
                            <Switch
                              checked={!banner?.is_hidden}
                              name="hidden"
                              onChange={() => {
                                handleHideClick(
                                  banner?.is_hidden,
                                  banner?.id
                                );
                              }}
                            />
                            <span className="text-gray mt-1 mx-2">
                              {banners.lables.displayed}
                            </span>
                          </div>
                          <div className="d-flex">
                            <div
                              className="d-flex align-items-start flex-column  edit me-2 mt-2 cursor-pointer"
                              onClick={() => {
                                handleEditClick(banner?.id);
                              }}
                            >
                              <EditIcon />
                              <span className="fsize-10 pt-1 toggle-text">
                                {banners.edit}
                              </span>
                            </div>
                            <div
                              className="d-flex align-items-center flex-column delete me-2 mt-2 cursor-pointer"
                              onClick={() => {
                                handleDeleteClick(banner?.id);
                              }}
                            >
                              <DeleteIcon />
                              <span className="fsize-10  toggle-text">
                                {banners.delete}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default BannersList;

BannersList.propTypes = {
  isLoading: PropTypes.bool,
  filterStatus: PropTypes.any,
  handleAddBannerClick: PropTypes.func,
  handleEditClick: PropTypes.func,
  handleHideClick: PropTypes.func,
  handleDeleteClick: PropTypes.func
};
