import React from "react";

const Excell = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      fill="none"
      viewBox="0 0 29 28"
      {...props}
    >
      <path
        fill="#fff"
        d="M25.659 14.913h-4.607v-2.13h4.607v2.13zm0 1.216h-4.607v2.129h4.607v-2.129zm0-10.035h-4.607v2.128h4.607V6.094zm0 3.345h-4.607v2.129h4.607v-2.13zm0 10.035h-4.607v2.129h4.607v-2.129zm3.181 4.197c-.131.633-.954.648-1.503.669H17.104v2.737H15.06L0 24.644V3.36L15.15.924h1.954v2.424h9.88c.557.02 1.169-.016 1.652.291.34.45.306 1.022.33 1.54l-.014 15.831c-.016.885.09 1.788-.112 2.66zM12.066 18.9c-.908-1.703-1.833-3.394-2.738-5.097.895-1.658 1.777-3.321 2.656-4.985-.747.034-1.494.076-2.238.125-.556 1.25-1.204 2.466-1.638 3.759-.405-1.22-.941-2.393-1.432-3.583-.724.037-1.448.076-2.171.116.763 1.557 1.576 3.093 2.316 4.659-.872 1.52-1.688 3.062-2.534 4.592.721.027 1.442.055 2.162.064.514-1.21 1.152-2.375 1.6-3.61.4 1.326 1.082 2.551 1.641 3.82.793.051 1.583.097 2.376.14zm15.33-14.108H17.104v1.302h2.632v2.128h-2.632V9.44h2.632v2.129h-2.632v1.216h2.632v2.129h-2.632v1.216h2.632v2.129h-2.632v1.216h2.632v2.129h-2.632v1.406h10.292V4.792z"
      ></path>
    </svg>
  );
};

export default Excell;
