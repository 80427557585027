import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { TableCell, TableRow } from "@material-ui/core";
import {
  Visibility,
  FiberManualRecord,
  ArrowDropDown
} from "@material-ui/icons";
import { CSVLink } from "react-csv";
import debounce from "lodash.debounce";

import Navbar from "../../components/Navbar";
import SearchInput from "../../components/SearchInput";
import Filter from "../../components/Filter";
import DatePicker from "../../components/DatePicker";
import Tabs from "../../components/Tabs";
import Pagination from "../../components/Pagination";
import Table from "../../components/Table";
import EmptyState from "../../components/EmptyState";
import Accordion from "../../components/Accordion";
import Button from "../../components/Button";
import { formatDate } from "../../utils/Helpers";
import { orderStatusOptions } from "../../utils/Constants";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import FilterIcon from "../../utils/Icons/Filter";
import Excell from "../../utils/Icons/Excell";

import { getRetailersLookupRequest } from "../../store/Retailers/actions";
import { getWholesalersRequest } from "../../store/Wholesalers/actions";
import {
  getOrderPDFRequest,
  getOrdersFullListRequest,
  getOrderPDFResponse,
  getOrdersRequest
} from "../../store/Orders/actions";
import { setOrdersRoute } from "../../store/Routing/actions";
import messages from "../../assets/locale/messages";
import ExportIcon from "../../utils/Icons/Export";
import "./Orders.scss";

const Orders = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useSelector((state) => state.locale.lang);
  const { orders, general } = messages[lang];
  const list = useSelector((state) => state.orders.list);
  const fullList = useSelector((state) => state.orders.fullList);
  const meta = useSelector((state) => state.orders.meta);
  const pdfUrl = useSelector((state) => state.orders.pdfUrl);

  const retailersOptions = useSelector(
    (state) => state.retailers.retailersNames
  );
  const wholesalers = useSelector((state) => state.wholesalers.list);
  const ordersRoutingData = useSelector(
    (state) => state.routing.orders
  );

  const [page, setPage] = useState(ordersRoutingData?.page || 1);
  const [searchValue, setSearchValue] = useState(
    ordersRoutingData?.search || ""
  );
  const [searchQuery, setSearchQuery] = useState(
    ordersRoutingData?.search || ""
  );
  const [filterOrderStatus, setFilterOrderStatus] = useState(
    ordersRoutingData?.orderStatus || null
  );
  const [activeTab, setActiveTab] = useState(
    ordersRoutingData?.activeTab || 0
  );
  const [startDate, setStartDate] = useState(
    ordersRoutingData?.startDate || null
  );
  const [endDate, setEndDate] = useState(
    ordersRoutingData?.endDate || null
  );
  const [wholsalersOptions, setWholsalersOptions] = useState([]);
  const [arrowRotate, setArrowRotate] = useState(false);
  const [filterWholsaler, setFilterWholsaler] = useState(
    ordersRoutingData?.wholesaler || null
  );
  const [filterRetailer, setFilterRetailer] = useState(
    ordersRoutingData?.storeName || null
  );
  const [retailersLookUp, setRetailersLookUp] = useState([]);
  const [startDateErr, setStartDateErr] = useState(null);
  const [endDateErr, setEndDateErr] = useState(null);
  const [exportedList, setExportedList] = useState(fullList);

  const updateSearchQuery = () => {
    setSearchValue(searchQuery);
  };
  const delayedSearchQuery = useCallback(
    debounce(updateSearchQuery, 500),
    [searchQuery]
  );
  useEffect(() => {
    delayedSearchQuery();
    // Cancel the debounce on useEffect cleanup.
    return delayedSearchQuery.cancel;
  }, [searchQuery, delayedSearchQuery]);

  useEffect(() => {
    if (pdfUrl) {
      window.open(pdfUrl);
    }
    return () => {
      dispatch(getOrderPDFResponse(null));
    };
  }, [pdfUrl]);

  useEffect(() => {
    dispatch(
      getRetailersLookupRequest({
        page: -1,
        items: 1,
        lookup_attribute: "storename"
      })
    );
    dispatch(
      getWholesalersRequest({
        lookup: "options",
        page: -1,
        items: 1
      })
    );
  }, []);

  useEffect(() => {
    const options = wholesalers.map((wholesaler) => ({
      label: wholesaler.username,
      id: wholesaler.id
    }));
    setWholsalersOptions(options);
  }, [wholesalers]);

  useEffect(() => {
    const options = retailersOptions.map((retailer, index) => ({
      id: index,
      label: retailer
    }));
    setRetailersLookUp(options);
  }, [retailersOptions]);

  useEffect(() => {
    checkEndAfterStart();
  }, [startDate, endDate]);

  useEffect(() => {
    getOrders(
      page,
      activeTab,
      filterOrderStatus?.value,
      filterWholsaler?.label,
      filterRetailer?.label,
      searchValue,
      startDate,
      endDate
    );
  }, [page]);

  useEffect(() => {
    setSearchValue(ordersRoutingData?.search || "");
    setFilterOrderStatus(ordersRoutingData?.orderStatus || null);
    setFilterWholsaler(ordersRoutingData?.wholesaler || null);
    setFilterRetailer(ordersRoutingData?.storeName || null);
    setStartDate(ordersRoutingData?.startDate || null);
    setEndDate(ordersRoutingData?.endDate || null);
    setPage(ordersRoutingData?.page || 1);
    ordersRoutingData
      ? getOrders(
          ordersRoutingData.page,
          ordersRoutingData.activeTab,
          ordersRoutingData.orderStatus,
          ordersRoutingData.wholesaler,
          ordersRoutingData.storeName,
          ordersRoutingData.search,
          ordersRoutingData.startDate,
          ordersRoutingData.endDate
        )
      : getOrders(1, activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      getOrders(
        ordersRoutingData?.page || 1,
        activeTab,
        filterOrderStatus?.value,
        filterWholsaler?.label,
        filterRetailer?.label,
        searchValue,
        startDate,
        endDate
      );

      dispatch(
        getOrdersFullListRequest({
          page: -1,
          items: 1,
          is_history: !!activeTab,
          status: filterOrderStatus?.value,
          wholesaler_username: filterWholsaler?.label,
          retailer_storename: filterRetailer?.label,
          search_parameters: searchValue,
          from_date: startDate?.toString(),
          to_date: endDate?.toString()
        })
      );
      setPage(ordersRoutingData?.page || 1);
    }
  }, [searchValue]);

  useEffect(() => {
    getOrders(
      ordersRoutingData?.page || 1,
      activeTab,
      filterOrderStatus?.value,
      filterWholsaler?.label,
      filterRetailer?.label,
      searchValue,
      startDate,
      endDate
    );
    setPage(ordersRoutingData?.page || 1);
  }, [
    filterOrderStatus,
    filterWholsaler,
    filterRetailer,
    startDate,
    endDate
  ]);

  useEffect(() => {
    if (ordersRoutingData) {
      dispatch(setOrdersRoute(null));
    }
  }, [ordersRoutingData]);

  useEffect(() => {
    const list = fullList.map((order) => ({
      orderNumber: order.order_number,
      storeName: order.retailer_storename,
      wholesalerName: order.wholesaler_name,
      checkoutDate: formatDate(order.created_at, "DD/MM/YYYY"),
      status: orders.filterOrder[order.status],
      products: order.number_of_products
    }));
    setExportedList(list);
  }, [fullList]);

  const getOrders = (
    pageNumber,
    isHistory,
    status,
    wholsalerName,
    retailerName,
    search,
    from,
    to
  ) => {
    dispatch(
      getOrdersRequest({
        page: pageNumber,
        items: 10,
        is_history: !!isHistory,
        status,
        wholesaler_username: wholsalerName,
        retailer_storename: retailerName,
        search_parameters: search,
        from_date: from?.toString(),
        to_date: to?.toString()
      })
    );
  };

  useEffect(() => {
    dispatch(
      getOrdersFullListRequest({
        page: -1,
        items: 1,
        is_history: !!activeTab,
        status: filterOrderStatus?.value,
        wholesaler_username: filterWholsaler?.label,
        retailer_storename: filterRetailer?.label,
        search_parameters: searchValue,
        from_date: startDate?.toString(),
        to_date: endDate?.toString()
      })
    );
  }, [
    activeTab,
    filterWholsaler?.label,
    filterRetailer?.label,
    filterOrderStatus?.value,
    startDate,
    endDate
  ]);

  const headers = [
    { label: orders.orderTable.orderNumber, key: "orderNumber" },
    { label: orders.orderTable.storeName, key: "storeName" },
    {
      label: orders.orderTable.WholesalerName,
      key: "wholesalerName"
    },
    { label: orders.orderTable.date, key: "checkoutDate" },
    { label: orders.orderTable.status, key: "status" },
    { label: orders.orderTable.numberOfProducts, key: "products" }
  ];

  const checkEndAfterStart = () => {
    if (
      startDate &&
      endDate &&
      startDate.getTime() > endDate.getTime()
    ) {
      setStartDateErr(orders.startBeforeEnd);
      setEndDateErr(orders.EndAfterStart);
    } else {
      setStartDateErr(null);
      setEndDateErr(null);
    }
  };

  const tabs = [
    {
      label: orders.Ongoing,
      className: "tab1 fsize-15 font-semibold"
    },
    {
      label: orders.History,
      className: "tabs2 fsize-15 font-semibold"
    }
  ];

  const renderTable = () => {
    if (
      list.length == 0 &&
      !searchValue &&
      !filterWholsaler &&
      !filterOrderStatus &&
      !filterRetailer &&
      !startDate &&
      !endDate
    ) {
      return (
        <div className="my-5">
          <EmptyState
            subTitle={orders.orderTable.EmptyState}
            showActionButton={false}
          />
        </div>
      );
    } else if (
      list.length == 0 &&
      (searchValue ||
        filterWholsaler ||
        filterOrderStatus ||
        filterRetailer ||
        startDate ||
        endDate)
    ) {
      return (
        <div className="my-5">
          <EmptyState
            subTitle={general.noSearch}
            showActionButton={false}
          />
        </div>
      );
    } else {
      return (
        <>
          <Table
            headlines={[
              orders.orderTable.orderNumber,
              orders.orderTable.storeName,
              orders.orderTable.WholesalerName,
              orders.orderTable.date,
              orders.orderTable.status
            ]}
            hasActions={true}
            rows={list.map((row, index) => (
              <TableRow key={index}>
                <TableCell className="font-reguler text-dark-blue fsize-14">
                  <span
                    className={`${
                      row.status == "pending" ? "pendingLabel" : ""
                    }`}
                  >
                    {row.order_number}
                  </span>
                </TableCell>
                <TableCell className="font-reguler text-dark-blue fsize-14 ">
                  {row.retailer_storename}
                </TableCell>
                <TableCell className="font-reguler text-dark-blue fsize-14 ">
                  {row.wholesaler_name}
                </TableCell>
                <TableCell className="font-reguler text-dark-blue fsize-14 ">
                  {formatDate(row.created_at, "DD/MM/YYYY")}
                </TableCell>
                <TableCell className="font-reguler text-dark-blue fsize-14  ps-0 ">
                  <span className={`${row.status} `}>
                    <FiberManualRecord className="fsize-12" />
                  </span>
                  {orders.filterOrder[row.status]}
                </TableCell>
                <TableCell className="font-reguler text-dark-blue d-flex  px-0">
                  <div className="pt-2 d-flex flex-column align-items-center  ">
                    <Visibility
                      color="secondary"
                      fontSize="large"
                      className="opacity_7 pointer"
                      onClick={() => {
                        viewDetails(row.id);
                      }}
                    />
                    <span className="text-gray">{orders.view}</span>
                  </div>
                  <div className="pt-2 d-flex flex-column align-items-center ms-3  ">
                    <ExportIcon
                      color="secondary"
                      fontSize="large"
                      className="opacity_7 pointer"
                      onClick={() => {
                        dispatch(getOrderPDFRequest(row.id));
                      }}
                    />
                    <span className="text-gray mt-2">
                      {orders.export}
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          />
          <div className="d-flex justify-content-center align-items-center pt-4 pb-3">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={meta.currentPage || page}
                handleChange={(event, page) => {
                  setPage(page);
                }}
                defaultPage={1}
              />
            )}
          </div>
        </>
      );
    }
  };
  const renderFilters = () => {
    return (
      <Accordion
        label={
          <div className="  row align-items-center pe-3 filters-bar ">
            <SearchInput
              name="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder={orders.searchPlaceholder}
              inputWrapperClass="search col-4  px-0"
              inputClass="mt-2 "
            />
          </div>
        }
        body={
          <div className="row justify-content-between  mx-3 py-2  filter-body mb-2 align-items-start filters-bar">
            <div className="col-8 px-1 d-flex justify-content-center">
              <Filter
                placeholder={orders.Orderstatus}
                options={orderStatusOptions}
                value={filterOrderStatus}
                name="order status"
                onChange={(value) => {
                  setFilterOrderStatus(value);
                }}
                inputClassName=" mt-1 "
                filterWrapperClass=" me-2"
              />
              <Filter
                placeholder={orders.WholesalerName}
                options={wholsalersOptions}
                value={filterWholsaler}
                name="wholsaler name"
                onChange={(value) => {
                  setFilterWholsaler(value);
                }}
                inputClassName="mt-1 "
                filterWrapperClass="filter mx-2"
              />
              <Filter
                placeholder={orders.retailerName}
                options={retailersLookUp}
                value={filterRetailer}
                name="retailer"
                onChange={(value) => {
                  setFilterRetailer(value);
                }}
                inputClassName="mt-1 "
                filterWrapperClass="filter mx-2"
              />
            </div>

            <div className="col-4 justify-content-center d-flex ">
              <DatePicker
                label={orders.filterOrder.start}
                inputWrapperClass="me-2"
                name="start Date"
                value={startDate}
                isInlineLabel
                handleDateChange={(value) => {
                  setStartDate(value);
                }}
                maxDate={endDate}
                placeholder={formatDate(new Date(), "DD/MM/yyyy")}
                isInputHasErr={!!startDateErr}
                errMsg={startDateErr}
              />
              <DatePicker
                label={orders.filterOrder.end}
                name={"end Date"}
                value={endDate}
                isInlineLabel
                handleDateChange={(value) => {
                  setEndDate(value);
                }}
                minDate={startDate}
                placeholder={formatDate(new Date(), "DD/MM/yyyy")}
                isInputHasErr={!!endDateErr}
                errMsg={endDateErr}
              />
            </div>
          </div>
        }
        actionButton={
          <Button
            outlined
            label={
              <span className="d-flex align-items-center">
                <FilterIcon fontSize="large" />
                <span className="ps-1 pe-3">{orders.filters}</span>
                <ArrowDropDown
                  className={`${arrowRotate ? "rotate" : ""}`}
                />
              </span>
            }
            labelClass="fsize-14 py-1"
            className="mb-0 action-btn"
          />
        }
        arrowRotate={arrowRotate}
        setArrowRotate={setArrowRotate}
        wrapperClassName="accordion-bg"
        isExpanded={ordersRoutingData?.isFilterExpanded}
      />
    );
  };
  const viewDetails = (id) => {
    dispatch(
      setOrdersRoute({
        page,
        search: searchValue,
        activeTab,
        orderStatus: filterOrderStatus,
        wholesaler: filterWholsaler,
        storeName: filterRetailer,
        startDate,
        endDate,
        isFilterExpanded: !!(
          filterOrderStatus ||
          filterWholsaler ||
          filterRetailer ||
          startDate ||
          endDate
        )
      })
    );
    history.push(ROUTE_PATHS.viewOrder.replace(":id", id));
  };
  return (
    <div className="orders">
      <Navbar NavbarTitle={orders.ordersList}>
        <CSVLink
          data={exportedList}
          asyncOnClick={true}
          headers={headers}
          filename={`orders_${formatDate(
            new Date(),
            "DD/MM/YYYY"
          )}.csv`}
        >
          <Button
            label={
              <span className="d-flex align-items-center">
                <Excell />
                <span className="ps-1 pe-3">{orders.excell}</span>
              </span>
            }
            labelClass="fsize-16  py-1 text-white font-medium"
          />
        </CSVLink>
      </Navbar>

      {renderFilters()}

      <div className="content-wrapper orders-page d-flex  align-items-center flex-column">
        <Tabs
          tabs={tabs}
          content={[renderTable(), renderTable()]}
          handleChangeTabs={(tab) => {
            setActiveTab(tab);
          }}
          activeTab={ordersRoutingData?.activeTab}
        />
      </div>
    </div>
  );
};

export default Orders;
