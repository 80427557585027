import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from "@material-ui/pickers";
import {
  InputLabel,
  IconButton,
  FormHelperText
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import PropTypes from "prop-types";
import { Clear } from "@material-ui/icons";

import messages from "../../assets/locale/messages";
import "./DateAndTimePicker.scss";

const DateAndTimePicker = ({
  isInlineLabel,
  dateLabel,
  timeLabel,
  required,
  inputWrapperClass,
  labelClassName,
  id,
  name,
  value,
  handleDateChange,
  isInputHasErr,
  helperTextClass,
  errMsg,
  helperText,
  maxDate,
  minDate,
  disableFuture,
  disablePast,
  inputClass,
  disabled
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { general } = messages[lang];
  const [selectedDate, setSelectedDate] = useState(null);
  const [invalidDate, setInvalidDate] = useState(false);

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  const onDatechange = (date) => {
    // handle date format if needed
    if (date == "Invalid Date") {
      setInvalidDate(true);
      setSelectedDate(date);
    } else {
      setInvalidDate(false);
      handleDateChange(date);
    }
  };

  const handleClear = () => {
    setSelectedDate(null);
    handleDateChange(null);
    setInvalidDate(false);
  };

  const dateInputLabel = (
    <>
      {dateLabel}
      {required ? <span className="err-asterisk">*</span> : ""}
    </>
  );

  const timeInputLabel = (
    <>
      {timeLabel}
      {required ? <span className="err-asterisk">*</span> : ""}
    </>
  );
  return (
    <div className={`date-picker-wrapper ${inputWrapperClass}`}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="d-flex justify-content-between">
          <div className="me-3">
            <span>
              {!isInlineLabel && (
                <InputLabel
                  className={labelClassName}
                  htmlFor={id || `${name}-date`}
                >
                  {dateInputLabel}
                </InputLabel>
              )}
            </span>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              inputVariant="filled"
              allowKeyboardControl={false}
              format="dd/MM/yyyy"
              id="date-picker"
              label={isInlineLabel && dateInputLabel}
              value={selectedDate}
              onChange={onDatechange}
              KeyboardButtonProps={{
                "aria-label": "Change Date"
              }}
              autoOk
              maxDate={maxDate || undefined}
              minDate={minDate || undefined}
              disabled={disabled}
              disableFuture={disableFuture}
              disablePast={disablePast}
              InputProps={{
                startAdornment: selectedDate ? (
                  <IconButton
                    className={`close-btn ${disabled ? "pe-4" : ""}`}
                    onClick={handleClear}
                  >
                    <Clear className={disabled ? "d-none" : ""} />
                  </IconButton>
                ) : null,
                readOnly: true
              }}
              error={invalidDate || isInputHasErr}
              className={`${inputClass} ${
                invalidDate || isInputHasErr ? "error-border" : ""
              }`}
            />
          </div>
          <div>
            <span>
              {!isInlineLabel && (
                <InputLabel
                  className={labelClassName}
                  htmlFor={id || `${name}-time`}
                >
                  {timeInputLabel}
                </InputLabel>
              )}
            </span>
            <KeyboardTimePicker
              id="time-picker"
              label={isInlineLabel && timeInputLabel}
              value={selectedDate}
              disabled={disabled}
              onChange={onDatechange}
              KeyboardButtonProps={{
                "aria-label": "Change Time"
              }}
              InputProps={{ readOnly: true }}
              keyboardIcon={<AccessTimeIcon />}
              allowKeyboardControl={false}
              inputVariant="filled"
              error={invalidDate || isInputHasErr}
              className={`${inputClass} ${
                invalidDate || isInputHasErr ? "error-border" : ""
              }`}
            />
          </div>
        </div>
        <FormHelperText
          error={invalidDate || isInputHasErr}
          className={helperTextClass || "font-medium fsize-12"}
        >
          {invalidDate
            ? general.invalidDate
            : isInputHasErr
            ? errMsg
            : helperText}
        </FormHelperText>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DateAndTimePicker;

DateAndTimePicker.propTypes = {
  isInlineLabel: PropTypes.bool,
  dateLabel: PropTypes.string.isRequired,
  timeLabel: PropTypes.string.isRequired,
  required: PropTypes.bool,
  inputWrapperClass: PropTypes.string,
  labelClassName: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleDateChange: PropTypes.func.isRequired,
  isInputHasErr: PropTypes.bool,
  helperTextClass: PropTypes.string,
  errMsg: PropTypes.string,
  helperText: PropTypes.string,
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  inputClass: PropTypes.string,
  disabled: PropTypes.bool
};
