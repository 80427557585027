import { SET_WAREHOUSE_LOCATION } from "./types";

let INITIAL_STATE = {
  warehouse: {
    location: {
      coords: null
    }
  }
};

const googleMaps = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_WAREHOUSE_LOCATION:
      return {
        ...state,
        warehouse: {
          ...state.warehouse,
          location: {
            ...action.payload
          }
        }
      };
    default:
      return state;
  }
};

export default googleMaps;
