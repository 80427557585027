import { put, call, takeEvery } from "redux-saga/effects";

import * as apis from "../../network/apis/auth";
import * as types from "./types";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { loginResponse } from "./actions";
import { setAdmin, clearAdminData } from "./helpers";
import { showHideSnackbar } from "../Snackbar/actions";
import history from "../../routes/History";
import {
  clearFirebaseToken,
  generateFirebaseToken
} from "../../firebase/helpers";

export function* loginSaga({ payload }) {
  try {
    const response = yield call(apis.login, payload.data);
    const device_id = payload.data.device_id;
    yield put(loginResponse(response.data.admin));
    yield payload.rememberMe
      ? setAdmin(response.data, "local")
      : setAdmin(response.data, "session");
    yield localStorage.setItem("device_id", device_id);
    yield history.push(ROUTE_PATHS["wholesalers"]);
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}
export function* logoutSaga({ payload }) {
  try {
    yield call(apis.logout, payload);
    yield put(loginResponse({}));
    yield clearAdminData();
    yield clearFirebaseToken();
    yield generateFirebaseToken();
    history.push(ROUTE_PATHS["login"]);
  } catch (err) {
    yield clearAdminData();
    history.push(ROUTE_PATHS["login"]);
  }
}

export default function* AuthSagas() {
  yield takeEvery(types.LOGIN_REQUEST, loginSaga);
  yield takeEvery(types.LOGOUT_REQUEST, logoutSaga);
}
