import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputLabel } from "@material-ui/core";
import {
  Schedule,
  Delete,
  AddCircleOutline
} from "@material-ui/icons";

import messages from "../../../assets/locale/messages";
import {
  checkTimeOverlap,
  getTwelveHrFormat
} from "../../../utils/Helpers";
import Checkbox from "../../../components/Checkbox";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Navbar from "../../../components/Navbar";
import TimeWatchModal from "../../../components/TimeWatchModal";
import GoogleMaps from "../../../components/GoogleMaps";
import { ARABIC_REGEX, PRICE_REGEX } from "../../../utils/Patterns";

import {
  createZoneRequest,
  editZoneRequest,
  getWeekDaysRequest
} from "../../../store/Warehouses/actions";
import { ArrowCurvedLeft } from "../../../utils/Icons/ArrowCurvedLeft";
import "./CovargeAreas.scss";

const CovargeAreas = (props) => {
  const dispatch = useDispatch();
  const { id, zoneId } = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const { warehouseCoverageAreas, general } = messages[lang];

  const weekdays = useSelector((state) => state.warehouses.weekDays);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDayId, setSelectedDayId] = useState(null);
  const [slotIndex, setSlotIndex] = useState(null);
  const [editSlot, setEditSlot] = useState(null);
  const [slotErrMsg, setSlotErrMsg] = useState("");
  const [slotId, setSlotId] = useState(null);
  const [deletedSlots, setDeletedSlots] = useState([]);

  useEffect(() => {
    dispatch(getWeekDaysRequest());
  }, []);

  useEffect(() => {
    if (props.location?.state?.zone) {
      const zone = props.location?.state?.zone;
      setFieldValue("areaName", zone.name);
      setAvailableDaysAndTimes(zone.timetable_slots);
      setFieldValue("minOrderPrice", zone.min_delivery_order_price);
    }
  }, [props.location]);

  const setAvailableDaysAndTimes = (daysArr) => {
    const selectedDaysId = daysArr.map((day) => day.id);

    const newAvailableDays = values["availableDaysAndTimes"].map(
      (day) => {
        if (selectedDaysId.includes(day.id)) {
          const receivedDay = daysArr.find(
            (singleDay) => singleDay.id === day.id
          );
          return {
            id: receivedDay.id,
            isChosen: true,
            slots: receivedDay.time_slots.map((slot) => ({
              id: slot.id,
              from: slot.from,
              to: slot.to
            }))
          };
        } else {
          return day;
        }
      }
    );

    setFieldValue("availableDaysAndTimes", newAvailableDays);
  };

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched
  } = useFormik({
    initialValues: {
      areaCoords: [],
      areaName: "",
      minOrderPrice: "",
      availableDaysAndTimes: [
        { id: 1, isChosen: false, slots: [] },
        { id: 2, isChosen: false, slots: [] },
        { id: 3, isChosen: false, slots: [] },
        { id: 4, isChosen: false, slots: [] },
        { id: 5, isChosen: false, slots: [] },
        { id: 6, isChosen: false, slots: [] },
        { id: 7, isChosen: false, slots: [] }
      ]
    },
    validationSchema: Yup.object({
      areaCoords: Yup.array().required(
        warehouseCoverageAreas.validation.areaLocationRequired
      ),
      areaName: Yup.string()
        .required(warehouseCoverageAreas.validation.areaName)
        .matches(
          ARABIC_REGEX,
          warehouseCoverageAreas.validation.arabic
        ),
      minOrderPrice: Yup.number().min(0),
      availableDaysAndTimes: Yup.array().test(
        "at-least-one-is-true",
        warehouseCoverageAreas.validation.available,
        (value) => {
          const isSlotAdded = value.find(
            (day) => day.slots.length > 0
          );
          return !!isSlotAdded;
        }
      )
    }),
    onSubmit: ({
      availableDaysAndTimes,
      areaName,
      areaCoords,
      minOrderPrice
    }) => {
      let timetable_slots_attributes = [];
      availableDaysAndTimes.forEach((day) => {
        if (day.slots.length) {
          day.slots.forEach(({ from, to, id }) => {
            let newSlot = { week_day_id: day.id, from, to };
            if (id) {
              newSlot.id = id;
            }
            timetable_slots_attributes.push(newSlot);
          });
        }
      });

      const stringifiedCoords = areaCoords.map((coords) => ({
        lat: coords.lat.toString(),
        lng: coords.lng.toString()
      }));

      const data = {
        name: areaName,
        min_delivery_order_price: +minOrderPrice,
        latlng: [...stringifiedCoords],
        timetable_slots_attributes: [
          ...timetable_slots_attributes,
          ...deletedSlots
        ]
      };

      if (zoneId) {
        dispatch(
          editZoneRequest({ id: zoneId, data, warehouse_id: +id })
        );
      } else {
        data.warehouse_id = +id;
        dispatch(createZoneRequest(data));
      }
    }
  });

  const getArea = (area) => {
    setFieldTouched("areaCoords");
    setFieldValue("areaCoords", area);
  };

  const handleConfirmSlot = (newSlotValues) => {
    const targetDay = JSON.parse(
      JSON.stringify(
        values["availableDaysAndTimes"].find(
          (day) => day.id === selectedDayId
        )
      )
    );
    const targetDayIndex = values["availableDaysAndTimes"].findIndex(
      (day) => day.id === selectedDayId
    );

    if (slotIndex === null) {
      // add new slot
      targetDay.slots.push(newSlotValues);
    } else {
      // edit slot
      targetDay.slots.splice(slotIndex, 1, {
        ...newSlotValues,
        id: slotId
      });
    }

    const slotsOverlap = validateTimeOverlapping([
      ...targetDay.slots
    ]);
    if (slotsOverlap) {
      // TODO: add localization from business
      setSlotErrMsg(warehouseCoverageAreas.validation.slotsOverlap);
    } else {
      setSlotErrMsg("");
      const newAvailableDays = [...values["availableDaysAndTimes"]];
      newAvailableDays.splice(targetDayIndex, 1, targetDay);
      setFieldValue("availableDaysAndTimes", newAvailableDays);
      handleCloseModal();
    }
  };

  const validateTimeOverlapping = (slots) => {
    // [{from, to}]
    let timeSlots = [];
    slots.forEach(({ from, to }) => {
      timeSlots.push([from, to]);
    });
    return checkTimeOverlap(timeSlots);
  };

  const handleDeleteSlot = (dayId, index, slot) => {
    if (slot.id) {
      setDeletedSlots([
        ...deletedSlots,
        { ...slot, week_day_id: dayId, _destroy: true }
      ]);
    }
    const targetDay = JSON.parse(
      JSON.stringify(
        values["availableDaysAndTimes"].find(
          (day) => day.id === dayId
        )
      )
    );
    const targetDayIndex = values["availableDaysAndTimes"].findIndex(
      (day) => day.id === dayId
    );
    targetDay.slots.splice(index, 1);
    const newAvailableDays = [...values["availableDaysAndTimes"]];
    newAvailableDays.splice(targetDayIndex, 1, targetDay);
    setFieldValue("availableDaysAndTimes", newAvailableDays);
  };

  const handleRemoveDaySlots = (dayId) => {
    const targetDay = JSON.parse(
      JSON.stringify(
        values["availableDaysAndTimes"].find(
          (day) => day.id === dayId
        )
      )
    );
    const targetDayIndex = values["availableDaysAndTimes"].findIndex(
      (day) => day.id === dayId
    );
    const targetDaySlots = [...targetDay.slots];

    let deletedSlotsWithIds = [];
    targetDaySlots.forEach((slot) => {
      if (slot.id) {
        deletedSlotsWithIds.push({
          ...slot,
          week_day_id: dayId,
          _destroy: true
        });
      }
    });
    setDeletedSlots([...deletedSlots, ...deletedSlotsWithIds]);

    targetDay.isChosen = false;
    targetDay.slots = [];
    const newAvailableDays = [...values["availableDaysAndTimes"]];
    newAvailableDays.splice(targetDayIndex, 1, targetDay);
    setFieldValue("availableDaysAndTimes", newAvailableDays);
  };

  const renderTimeSlots = (dayId) => {
    const renderedDay = values["availableDaysAndTimes"].find(
      (day) => day.id === dayId
    );
    return (
      <>
        {renderedDay.slots.length ? (
          <>
            {renderedDay.slots.map((slot, i) => (
              <div
                key={i}
                className="slot-wrapper mb-3 d-flex align-items-center justify-content-between"
              >
                <Schedule
                  className="schedule-icon-map"
                  onClick={() => {
                    setSelectedDayId(dayId);
                    setSlotIndex(i);
                    setSlotId(slot.id);
                    setEditSlot(slot);
                    setModalOpen(true);
                  }}
                />
                <div className="slot-time-wrapper">
                  <p className="slot-time mb-0">
                    {getTwelveHrFormat(slot.from)}
                  </p>
                  <p className="slot-time mb-0">
                    {getTwelveHrFormat(slot.to)}
                  </p>
                </div>
                <Delete
                  className="delete-icon"
                  onClick={() => handleDeleteSlot(dayId, i, slot)}
                />
              </div>
            ))}
            <AddCircleOutline
              className="add-slot-icon mx-2"
              onClick={() => {
                setModalOpen(true);
                setSelectedDayId(dayId);
                setSlotIndex(null);
              }}
            />
          </>
        ) : (
          <Schedule
            className="schedule-icon-map"
            onClick={() => {
              setModalOpen(true);
              setSelectedDayId(dayId);
              setSlotIndex(null);
            }}
          />
        )}
      </>
    );
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEditSlot(null);
    setSlotErrMsg("");
  };

  const getSavedPolygon = () => {
    if (props.location?.state?.zone) {
      const newCoords = props.location?.state?.zone?.polygon.map(
        (singleCoord) => ({
          lat: +singleCoord.lat,
          lng: +singleCoord.lng
        })
      );
      return newCoords;
    }
    return [];
  };

  const previouslyCreatedPolygons = () => {
    if (props.location?.state?.otherZones) {
      const oldZones = props.location?.state?.otherZones.map((zone) =>
        zone?.polygon.map((singleCoord) => ({
          lat: +singleCoord.lat,
          lng: +singleCoord.lng
        }))
      );
      return oldZones;
    }
    return [];
  };

  return (
    <div className="coverage-zones-page">
      <Navbar NavbarTitle={warehouseCoverageAreas.CoverageAreas} />
      <div className="content-wrapper p-4 covargeAreas d-flex justify-content-between flex-column">
        <div className="covargeAreas-map">
          <GoogleMaps
            savedCoords={location}
            className="w-75 h-50 "
            isPolygon={true}
            getPolygon={getArea}
            savedPolygon={getSavedPolygon()}
            previouslyCreatedPolygons={previouslyCreatedPolygons()}
            isTooltip={true}
            resetMap={true}
            resetButtonText={
              <>
                <ArrowCurvedLeft />
                <span className="fsize-14 font-medium fweight-500 ms-2">
                  {warehouseCoverageAreas.resetPolygon}
                </span>
              </>
            }
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row mb-4 align-items-start">
            <div className="col-4">
              <Input
                id="text"
                label={warehouseCoverageAreas.areaName}
                name="text"
                type="text"
                placeholder={
                  warehouseCoverageAreas.areaNamePlaceholder
                }
                labelClassName="pb-2"
                required
                value={values["areaName"]}
                onChange={(value) => {
                  setFieldTouched("areaName");
                  setFieldValue("areaName", value);
                }}
                isInputHasErr={
                  !!(touched["areaName"] && errors["areaName"])
                }
                errMsg={errors["areaName"]}
              />
            </div>
            <div className="col-2 d-flex align-items-center">
              <Input
                id="text"
                label={warehouseCoverageAreas.minOrderPrice}
                name="text"
                type="text"
                labelClassName="pb-2 "
                value={values["minOrderPrice"]}
                className="py-2 w-50"
                inputEndAdorment={
                  <span className="price-label px-2">
                    {general.le}
                  </span>
                }
                onChange={(value) => {
                  setFieldTouched("minOrderPrice");
                  if (value.match(PRICE_REGEX) || value === "") {
                    setFieldValue("minOrderPrice", value);
                  }
                }}
                isInputHasErr={
                  !!(
                    touched["minOrderPrice"] &&
                    errors["minOrderPrice"]
                  )
                }
                errMsg={errors["minOrderPrice"]}
              />
            </div>
          </div>
          <div className="availableDays">
            <InputLabel className="font-medium pb-3 availableDayLabel">
              {warehouseCoverageAreas.available}
              <span className="err">*</span>
            </InputLabel>
            <span className="fsize-12 text-error font-medium">
              {touched["availableDaysAndTimes"] &&
                errors["availableDaysAndTimes"]}
            </span>

            <div className="d-flex justify-content-between align-items-start">
              {weekdays.map((day, i) => (
                <div
                  className="d-flex  flex-column justify-content-between "
                  key={i}
                >
                  <Checkbox
                    color="secondary"
                    label={day.name_en}
                    labelClass="font-reguler pt-3 text-dark fsize-16 checkbox-addwholesaler"
                    name={day.name}
                    checked={values.availableDaysAndTimes[i].isChosen}
                    onChange={(checked) => {
                      setFieldTouched("availableDaysAndTimes");
                      if (checked) {
                        setFieldValue(
                          `availableDaysAndTimes[${i}].isChosen`,
                          true
                        );
                      } else {
                        handleRemoveDaySlots(day.id);
                      }
                    }}
                  />
                  {values.availableDaysAndTimes[i].isChosen && (
                    <div className="scheduleWrapper">
                      {renderTimeSlots(day.id)}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="ms-5 w-25 text-white"
              label={
                <span className="d-flex  align-items-center">
                  <span className="ps-1 fsize-16">
                    {warehouseCoverageAreas.save}
                  </span>
                </span>
              }
              labelClass="fsize-16 px-3 text-center bg-transparent"
              type="submit"
              onClick={handleSubmit}
            />
          </div>
        </form>
        {modalOpen && (
          <TimeWatchModal
            open={modalOpen}
            handleClose={handleCloseModal}
            handleConfirmTime={handleConfirmSlot}
            defaultValues={editSlot}
            defaultErrMsg={slotErrMsg}
          />
        )}
      </div>
    </div>
  );
};

export default CovargeAreas;

CovargeAreas.propTypes = {
  location: PropTypes.object
};
