import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Add, Visibility, Delete } from "@material-ui/icons";
import { TableCell, TableRow } from "@material-ui/core";
import debounce from "lodash.debounce";

import {
  getPromoCodesRequest,
  deletePromoCodeRequest,
  getPromoCodeRequest,
  getPromoCodeResponse,
  editPromoCodeRequest
} from "../../store/PromoCodes/actions";
import { setPromoCodesRoute } from "../../store/Routing/actions";
import Navbar from "../../components/Navbar";
import Button from "../../components/Button";
import Filter from "../../components/Filter";
import SearchInput from "../../components/SearchInput";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import Switch from "../../components/Switch";
import Modal from "../../components/Modal";
import AddEditViewPromocode from "../../components/AddEditViewPromocode";
import DeleteIcon from "../../utils/Icons/Delete";
import EmptyState from "../../components/EmptyState";
import messages from "../../assets/locale/messages";
import { formatDate } from "../../utils/Helpers";
import {
  promoCodeStatus,
  promoCodeOfferdBy
} from "../../utils/Constants";

import "./promocodes.scss";

const Promocodes = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const { promocodes, general } = messages[lang];

  const promoCodesRoutingData = useSelector(
    (state) => state.routing.promoCodes
  );
  const list = useSelector((state) => state.promoCodes.list);
  const meta = useSelector((state) => state.promoCodes.meta);
  const isLoading = useSelector((state) => state.loader);
  const promoCode = useSelector(
    (state) => state.promoCodes.promoCode
  );

  const [filterStatus, setFilterStatus] = useState(
    promoCodesRoutingData?.filterStatus || null
  );
  const [filterOffer, setFilterOffer] = useState(
    null || promoCodesRoutingData?.filterOffer
  );
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [deletePromoCodeId, setDeletePromoCodeId] = useState(null);
  const [deletePromoCodeTitle, setDeletePromoCodeTitle] =
    useState("");
  const [openAddEditPromocode, setOpenAddEditPromocode] =
    useState(false);
  const [vieweditPromoCode, setViewEditPromoCode] = useState(false);
  const [editPromoCodeId, setEditPromoCodeId] = useState(null);

  const [page, setPage] = useState(promoCodesRoutingData?.page || 1);
  const [searchValue, setSearchValue] = useState(
    promoCodesRoutingData?.search || ""
  );
  const [searchQuery, setSearchQuery] = useState(
    promoCodesRoutingData?.search || ""
  );

  const updateSearchQuery = () => {
    setSearchValue(searchQuery);
  };
  const delayedSearchQuery = useCallback(
    debounce(updateSearchQuery, 500),
    [searchQuery]
  );
  useEffect(() => {
    delayedSearchQuery();
    // Cancel the debounce on useEffect cleanup.
    return delayedSearchQuery.cancel;
  }, [searchQuery, delayedSearchQuery]);

  useEffect(() => {
    if (editPromoCodeId) {
      dispatch(getPromoCodeRequest(editPromoCodeId));
    }
    return () => {
      dispatch(getPromoCodeResponse(null));
    };
  }, [editPromoCodeId]);

  const getPromoCodes = (
    pageNumber,
    is_activated,
    offered_by,
    search
  ) => {
    dispatch(
      getPromoCodesRequest({
        page: pageNumber,
        items: 8,
        is_activated,
        offered_by,
        promo_code_data: search
      })
    );
  };

  useEffect(() => {
    if (promoCodesRoutingData) {
      dispatch(setPromoCodesRoute(null));
    }
  }, [promoCodesRoutingData]);

  useEffect(() => {
    getPromoCodes(
      page,
      filterStatus?.value,
      filterOffer?.value,
      searchValue
    );
  }, [page]);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      setPage(promoCodesRoutingData?.page || 1);
      getPromoCodes(
        promoCodesRoutingData?.page || 1,
        filterStatus?.value,
        filterOffer?.value,
        searchValue
      );
    }
  }, [searchValue]);

  useEffect(() => {
    getPromoCodes(
      page,
      filterStatus?.value,
      filterOffer?.value,
      searchValue
    );
  }, [filterStatus, filterOffer, searchValue]);

  const handleEditClick = (promoCodeId) => {
    setEditPromoCodeId(promoCodeId);
    setViewEditPromoCode(true);
    setOpenAddEditPromocode(true);
  };

  const handleDeleteClick = (
    promoCodeIdToDelete,
    promoCodeTitleToDelete
  ) => {
    setDeletePromoCodeId(promoCodeIdToDelete);
    setDeletePromoCodeTitle(promoCodeTitleToDelete);
    setOpenDeleteConfirm(true);
  };

  const confirmDeleteBtn = () => {
    dispatch(
      deletePromoCodeRequest({
        id: deletePromoCodeId,
        page,
        searchValue,
        filterStatus,
        filterOffer
      })
    );
    setOpenDeleteConfirm(false);
  };

  const handleCloseDeleteConfirmModal = () => {
    setOpenDeleteConfirm(false);
  };
  const handleCloseAddEditPromocode = () => {
    setViewEditPromoCode(false);
    setOpenAddEditPromocode(false);
    dispatch(getPromoCodeResponse(null));
  };

  const toggleActivatePromoCode = (isEnabled, id) => {
    dispatch(
      editPromoCodeRequest({
        data: { is_enabled: !isEnabled },
        id,
        page,
        searchValue,
        filterStatus,
        filterOffer
      })
    );
  };

  const renderTable = () => {
    if (
      !isLoading &&
      list.length == 0 &&
      !searchValue &&
      !filterOffer &&
      !filterStatus
    ) {
      return (
        <div className="my-5">
          <EmptyState
            subTitle={promocodes.emptyState}
            showActionButton={true}
            ButtonLabel={promocodes.add}
            handelCreate={() => {
              setViewEditPromoCode(false);
              setOpenAddEditPromocode(true);
            }}
          />
        </div>
      );
    } else if (
      list.length == 0 &&
      (searchValue || filterOffer || filterStatus)
    ) {
      return (
        <EmptyState
          subTitle={general.noSearch}
          showActionButton={false}
        />
      );
    } else {
      return (
        <Table
          headlines={[
            promocodes.listTitle,
            promocodes.listOfferedBy,
            promocodes.listPromoCode,
            promocodes.listValue,
            promocodes.listDate,
            promocodes.listStatus
          ]}
          hasActions={true}
          rows={list.map((row, index) => (
            <TableRow key={index}>
              <TableCell className="font-cairo-medium text-dark-blue fsize-14  ">
                <div
                  className="promo-code-title-hover"
                  onClick={() => {
                    handleEditClick(row.id);
                  }}
                >
                  {row.title}
                </div>
              </TableCell>
              <TableCell className="font-reguler gray-shade fsize-15 ">
                {row.offered_by === "wholesaler"
                  ? row.wholesaler?.username
                  : row.offered_by}
              </TableCell>
              <TableCell className="font-reguler text-dark-blue fsize-14 ">
                <div className="promocode px-3 py-2 border-radius-4 w-fit">
                  {row.code}
                </div>
              </TableCell>
              <TableCell className="font-reguler text-orange fsize-14  ">
                {row.discount_value}
                <span className="ms-1">
                  {row.discount_type === "percentage"
                    ? general.percentage
                    : general.le}
                </span>
              </TableCell>
              <TableCell className="font-reguler gray-shade fsize-14  ">
                <p>
                  <span className="me-3 gray-text ">
                    {promocodes.start}
                  </span>
                  <span className="font-light ">
                    {formatDate(row.start_datetime, "DD/MM/YYYY")}
                  </span>
                </p>
                <p>
                  <span className="me-4 gray-text ">
                    {promocodes.end}
                  </span>
                  <span className="font-light">
                    {formatDate(row.end_datetime, "DD/MM/YYYY")}
                  </span>
                </p>
              </TableCell>
              <TableCell className="font-reguler text-dark-blue fsize-14">
                <div
                  className={`${
                    row.is_activated && row.is_enabled
                      ? "active"
                      : "inactive"
                  } px-3 py-2 border-radius-4 w-fit`}
                >
                  {`${
                    row.is_activated && row.is_enabled
                      ? "Active"
                      : "Inactive"
                  }`}
                </div>
              </TableCell>

              <TableCell className="font-reguler text-dark-blue d-flex  px-0">
                <div className="pt-2 d-flex flex-column align-items-center  justify-content-between ">
                  <Visibility
                    color="secondary"
                    fontSize="large"
                    className="opacity_7 pointer"
                    onClick={() => {
                      handleEditClick(row.id);
                    }}
                  />
                  <span className="text-gray">{promocodes.view}</span>
                </div>
                <div className="pt-2 d-flex flex-column align-items-center ms-5 w-20 ">
                  {!row.is_expired && row.is_activated && (
                    <>
                      <Switch
                        checked={row.is_enabled}
                        name="enabled"
                        onChange={() => {
                          toggleActivatePromoCode(
                            row.is_enabled,
                            row.id
                          );
                        }}
                      />
                      <span className="text-gray mt-1 mx-2">
                        {row.is_enabled
                          ? promocodes.enabled
                          : promocodes.disabled}
                      </span>
                    </>
                  )}
                </div>
                <div
                  className={` pt-2 ${
                    row.is_activated || row.is_expired
                      ? "d-none"
                      : "d-flex"
                  } flex-column align-items-center ms-5 `}
                >
                  <Delete
                    fontSize="large"
                    className="pointer text-red "
                    onClick={() => {
                      handleDeleteClick(row.id, row.title);
                    }}
                  />
                  <span className="text-gray">
                    {promocodes.delete}
                  </span>
                </div>
              </TableCell>
            </TableRow>
          ))}
        />
      );
    }
  };
  return (
    <div>
      <Navbar NavbarTitle={promocodes.list}>
        <Button
          label={
            <span className="d-flex align-items-center">
              <Add fontSize="large" />
              <span className="ps-1">{promocodes.add}</span>
            </span>
          }
          labelClass="fsize-16 text-white pe-2"
          onClick={() => {
            setViewEditPromoCode(false);
            setOpenAddEditPromocode(true);
          }}
        />
      </Navbar>
      <div className="filters px-4 py-3 d-flex align-items-center ">
        <Filter
          placeholder={promocodes.status}
          options={promoCodeStatus}
          value={filterStatus?.value}
          name="promocode status"
          onChange={(value) => {
            setFilterStatus(value);
          }}
          inputClassName=" mt-1 "
          filterWrapperClass=" me-4 "
        />
        <Filter
          placeholder={promocodes.offerd}
          options={promoCodeOfferdBy}
          value={filterOffer?.value}
          name="promocode offerd"
          onChange={(value) => {
            setFilterOffer(value);
          }}
          inputClassName=" mt-1 "
          filterWrapperClass=" me-4"
        />
        <SearchInput
          name="search"
          value={searchQuery}
          onChange={(value) => {
            setSearchQuery(value);
          }}
          placeholder={promocodes.searchPlaceholder}
          inputWrapperClass="search  px-0 w-33"
          inputClass="mt-2 "
        />
      </div>
      <div className="mx-4 mb-3 promocodes-list d-flex pt-2  align-items-center  justify-content-between flex-column bg-white">
        {renderTable()}
        <div className="d-flex justify-content-center align-items-center pt-4 pb-3">
          {meta.pages > 1 && (
            <Pagination
              count={meta.pages}
              page={meta.currentPage || page}
              handleChange={(event, page) => {
                setPage(page);
              }}
              defaultPage={1}
            />
          )}
        </div>
      </div>
      <Modal
        open={openDeleteConfirm}
        modalWrapperClass="delete-promocode-modal"
        onClose={handleCloseDeleteConfirmModal}
        modalBody={
          <>
            <DeleteIcon className="delete-icon mt-2 mb-4" />
            <p>
              {`${promocodes.deleteConfirmationMsg}“ 
              ${deletePromoCodeTitle} “ ? `}
            </p>
          </>
        }
        actionBtns={
          <div className="container px-4">
            <div className="row">
              <div className="col-md-6">
                <div className=" mx-2 d-flex justify-content-center align-items-center">
                  <Button
                    label={promocodes.delete}
                    type="button"
                    labelClass="fsize-14 text-white"
                    onClick={confirmDeleteBtn}
                    block={true}
                    className="py-2"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="mx-2 d-flex justify-content-center align-items-center pointer"
                  onClick={handleCloseDeleteConfirmModal}
                >
                  <div>
                    <p className="fsize-14 text-orange py-2">
                      {promocodes.cancel}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <Modal
        open={openAddEditPromocode}
        modalWrapperClass="add-edit-promocode-modal"
        onClose={handleCloseAddEditPromocode}
        modalBody={
          <AddEditViewPromocode
            closeOnSubmit={() => setOpenAddEditPromocode(false)}
            viewEditMode={vieweditPromoCode}
            promoCode={promoCode}
            page={page}
            searchValue={searchValue}
            filterStatus={filterStatus}
            filterOffer={filterOffer}
          />
        }
      />
    </div>
  );
};

export default Promocodes;
