import { axiosInstance } from "./";

const getPromoCodes = async (params) => {
  return await axiosInstance.get(`promo_codes`, { params });
};

const addPromoCode = async (payload) =>
  await axiosInstance.post(`promo_codes`, payload);

const getPromoCode = async (id) =>
  await axiosInstance.get(`promo_codes/${id}`);

const editPromoCode = async ({ data, id }) =>
  await axiosInstance.patch(`promo_codes/${id}`, data);

const deletePromoCode = async ({ id }) =>
  await axiosInstance.delete(`promo_codes/${id}`);

export {
  getPromoCodes,
  addPromoCode,
  getPromoCode,
  editPromoCode,
  deletePromoCode
};
