import React from "react";

export const ArrowCurvedLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M4.472.86a.666.666 0 010 .945l-1.53 1.528H10a6 6 0 110 12H4.666a.666.666 0 110-1.333H10a4.667 4.667 0 000-9.334H2.942l1.53 1.528a.667.667 0 11-.944.944L.86 4.472a.667.667 0 010-.944L3.528.86a.667.667 0 01.944 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
