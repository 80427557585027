import React, {
  useState
  // , useEffect
} from "react";
import {
  useSelector
  // ,useDispatch
} from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";

import messages from "../../assets/locale/messages";
import {
  formatDate,
  handleNotificationsClickActions,
  setNotificationSeen
} from "../../utils/Helpers";
import { NotificationBg } from "../../utils/Icons/NotificationBg";
// import {
//   getNotificationsRequest,
//   getNotificationsResponse
// } from "../../store/Notifications/actions";
import Navbar from "../../components/Navbar";
import EmptyState from "../../components/EmptyState";
import Button from "../../components/Button";
import "./Notifications.scss";

const NotifcationTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.tooltip.backgroundGrey,
    color: theme.tooltip.orangeColor,
    fontSize: "1rem"
  },
  arrow: {
    color: theme.tooltip.backgroundGrey,
    fontSize: "1rem"
  }
}))(Tooltip);

const Notifications = () => {
  // const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const notificationsList = useSelector(
    (state) => state.notifications.list
  );
  const notificationsMeta = useSelector(
    (state) => state.notifications.meta
  );

  const { notifications } = messages[lang];

  const [page, setPage] = useState(
    notificationsMeta?.currentPage || 1
  );

  // useEffect(() => {
  //   dispatch(
  //     getNotificationsRequest({
  //       items: 10,
  //       page
  //     })
  //   );
  // }, [page]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(
  //       getNotificationsResponse({
  //         list: [],
  //         meta: {},
  //         clear: true
  //       })
  //     );
  //   };
  // }, []);

  const handleNotificationSeen = (e, id) => {
    e.stopPropagation();
    setNotificationSeen(id);
  };

  return (
    <div className="notifications-page">
      <Navbar NavbarTitle={notifications.title} />
      <div className="content-wrapper">
        {!notificationsList.length ? (
          <EmptyState
            subTitle={notifications.noNotifications}
            showActionButton={false}
          />
        ) : (
          <>
            <div className="notifications-list">
              {notificationsList.map(
                (
                  {
                    id,
                    title,
                    body,
                    click_action,
                    action_id,
                    created_at,
                    seen
                  },
                  index
                ) => (
                  <div
                    className={`notification-item pointer ps-4 pt-4 border-bottom border-gray-hr-light d-flex ${
                      seen
                        ? "seen-notification"
                        : "unseen-notification"
                    }`}
                    key={index}
                    onClick={() =>
                      handleNotificationsClickActions({
                        id,
                        click_action,
                        action_id,
                        seen
                      })
                    }
                  >
                    <span>
                      <NotificationBg />
                    </span>
                    <div className="ms-3">
                      <p className={`notification-content mb-4`}>
                        <span className="d-block font-bold fsize-16 fweight-700 mb-2">
                          {title}
                        </span>
                        <span className="d-block fsize-16 fweight-400 font-reguler">
                          {body}
                        </span>
                        <span className="adorment-notifaction">
                          {!seen ? (
                            <NotifcationTooltip
                              title={
                                <div>{notifications.markRead}</div>
                              }
                              placement="right"
                              arrow
                            >
                              <FiberManualRecord
                                className="seen-adorment"
                                onClick={(e) => {
                                  handleNotificationSeen(e, id);
                                }}
                              />
                            </NotifcationTooltip>
                          ) : null}
                        </span>
                      </p>
                      <p className="notification-date font-medium fsize-14 fweight-500 mb-2">
                        {formatDate(created_at, "DD MMMM YYYY")}
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
            {notificationsMeta?.count &&
              page < notificationsMeta?.pages && (
                <div className="show-more-btn-wrapper text-center">
                  <Button
                    label={notifications.showMore}
                    outlined
                    className="show-more-btn"
                    onClick={() => setPage(page + 1)}
                  />
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default Notifications;
