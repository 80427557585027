import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SwiperSlide } from "swiper/react";
import { Edit, Add } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import debounce from "lodash.debounce";

import AlertNote from "../../components/AlertNote";
import Filter from "../../components/Filter";
import Navbar from "../../components/Navbar";
import ProductCard from "../../components/ProductCard";
import SearchInput from "../../components/SearchInput";
import { getProductCategoriesRequest } from "../../store/ProductCategories/actions";
import { getSubProductsRequest } from "../../store/SubProducts/actions";
import Button from "../../components/Button";
import Pagination from "../../components/Pagination";
import WarehouseProductModal from "../../components/WarehouseProductModal";
import Modal from "../../components/Modal";
import { getSuperProductsRequest } from "../../store/SuperProducts/actions";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { setProductsRoute } from "../../store/Routing/actions";
import messages from "../../assets/locale/messages";
import EmptyState from "../../components/EmptyState";
import "./Products.scss";

const Products = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const { subProducts, general } = messages[lang];
  const history = useHistory();

  const productsRoutingData = useSelector(
    (state) => state.routing.products
  );
  const categoriesList = useSelector(
    (state) => state.productCategories.list
  );
  const list = useSelector((state) => state.subProducts.list);
  const meta = useSelector((state) => state.subProducts.meta);
  const superProductsList = useSelector(
    (state) => state.superProducts.list
  );

  const [page, setPage] = useState(productsRoutingData?.page || 1);
  const [openAdd, setOpenAdd] = useState(false);
  const [productId, setProductId] = useState();
  const [selectedProduct, setSelectedProduct] = useState();

  const [searchValue, setSearchValue] = useState(
    productsRoutingData?.search || ""
  );
  const [category, setCategory] = useState(
    productsRoutingData?.category || null
  );
  const [superProduct, setSuperProduct] = useState(
    productsRoutingData?.superProduct || null
  );

  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [superProductsOptions, setSuperProductsOptions] = useState(
    []
  );

  const [searchQuery, setSearchQuery] = useState(
    productsRoutingData?.search || ""
  );

  const handleCloseAddModal = () => {
    setOpenAdd(false);
  };

  const updateSearchQuery = () => {
    setSearchValue(searchQuery);
  };

  const delayedSearchQuery = useCallback(
    debounce(updateSearchQuery, 500),
    [searchQuery]
  );

  useEffect(() => {
    delayedSearchQuery();

    // Cancel the debounce on useEffect cleanup.
    return delayedSearchQuery.cancel;
  }, [searchQuery, delayedSearchQuery]);

  useEffect(() => {
    const options = categoriesList.map((category) => ({
      id: category.id,
      label: category.name
    }));
    setCategoriesOptions(options);
  }, [categoriesList]);

  useEffect(() => {
    const options = superProductsList.map((superProduct) => ({
      id: superProduct.id,
      label: superProduct.name
    }));
    setSuperProductsOptions(options);
  }, [superProductsList]);

  useEffect(() => {
    dispatch(
      getProductCategoriesRequest({
        page: -1,
        items: 1
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getSuperProductsRequest({
        page: -1,
        items: 1,
        product_category_id: category?.id
      })
    );
  }, [category?.id]);

  const getSubProducts = (pageNumber, search) => {
    if (!category?.id) {
      dispatch(
        getSubProductsRequest({
          page: pageNumber,
          items: 12,
          name: search
        })
      );
      setSuperProduct(null);
    } else if (category?.id) {
      dispatch(
        getSubProductsRequest({
          page: pageNumber,
          items: 12,
          name: search,
          product_category_id: category?.id,
          super_product_id: superProduct?.id
        })
      );
    }
  };
  useEffect(() => {
    getSubProducts(page, searchValue);
  }, [page]);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      setPage(productsRoutingData?.page || 1);
      getSubProducts(
        productsRoutingData?.page || page,
        searchValue,
        category?.id,
        superProduct?.id
      );
    }
  }, [searchValue, category, superProduct]);

  const productImagesSlides = (imagesArray) => {
    let slides = [];
    if (imagesArray.length) {
      slides = imagesArray.map((image) => (
        <SwiperSlide key={image.id}>
          <div
            className="product-card-image-container "
            style={{
              backgroundImage: `url(${image.url})`
            }}
          ></div>
        </SwiperSlide>
      ));
    } else {
      slides.push(
        <SwiperSlide key={1}>
          <div
            className="product-card-image-container product-card-image-container-empty "
            style={{
              backgroundImage: `url(${process.env.REACT_APP_PRODUCTS_PLACEHOLDER})`
            }}
          ></div>
        </SwiperSlide>
      );
    }
    return slides;
  };

  const handleEditClick = (product) => {
    dispatch(
      setProductsRoute({
        page,
        search: searchValue,
        category,
        superProduct
      })
    );
    history.push({
      pathname: ROUTE_PATHS["editSubProduct"]
        .replace(":id", product.product_category_id)
        .replace(":superId", product.super_product.id)
        .replace(":prdId", product.id),
      state: {
        allProducts: true
      }
    });
  };

  const handleAdd = (product) => {
    setProductId(product.id);
    setSelectedProduct(product);
    setOpenAdd(true);
  };

  const renderProductsList = () => {
    if (list.length == 0 && !searchValue && !category?.id) {
      return (
        <div className="my-5 w-50 m-auto pt-5">
          <EmptyState
            subTitle={subProducts.emptyState.totalTitle}
            description={subProducts.emptyState.description}
            showActionButton={false}
          />
        </div>
      );
    } else if (
      list.length == 0 &&
      (searchValue || superProduct?.id || category?.id)
    ) {
      return (
        <div className="my-5 w-50 m-auto pt-5">
          <EmptyState
            subTitle={general.noSearch}
            showActionButton={false}
          />
        </div>
      );
    } else {
      return (
        <>
          <div className="py-4  subProducts-container row">
            {list.map((product) => {
              return (
                <div
                  key={product.id}
                  className=" mb-4 subProducts-child  col-3"
                >
                  <ProductCard
                    prdImgs={productImagesSlides(product.images)}
                    initialSlide={product.images?.findIndex(
                      (image) => image.is_default
                    )}
                    prdDescription={product.description}
                    prdCategory={
                      product.super_product.product_category.name
                    }
                    prdNameParent={product.concatenated_name}
                    prdSpecifications={product.specifications}
                    actions={true}
                    actionsElement={
                      <>
                        <div className="d-flex justify-content-end edit-icon-product-card">
                          <Edit
                            fontSize="medium"
                            className="pointer text-dark-blue"
                            onClick={() => {
                              handleEditClick(product);
                            }}
                          />
                        </div>
                        <Button
                          label={
                            <span className="d-flex align-items-center">
                              <span className="ps-1">
                                {subProducts.addToWholsaler}
                              </span>
                              <Add fontSize="small" />
                            </span>
                          }
                          labelClass="fsize-12  add-btn-text "
                          className="add-btn"
                          outlined
                          disabled={product.status !== "active"}
                          onClick={() => {
                            handleAdd(product);
                          }}
                        />
                      </>
                    }
                    deactivated={product.status !== "active"}
                  />
                </div>
              );
            })}
          </div>
          <div className="d-flex justify-content-center py-3">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={meta.currentPage || page}
                handleChange={(event, page) => {
                  setPage(page);
                }}
                defaultPage={1}
              />
            )}
          </div>
          <Modal
            open={openAdd}
            onClose={handleCloseAddModal}
            className="Add-Modal"
            modalBody={
              <WarehouseProductModal
                productId={productId}
                onClose={handleCloseAddModal}
                isWholesalerOption={true}
                product={selectedProduct}
                isEdit={false}
              />
            }
          />
        </>
      );
    }
  };
  return (
    <>
      <Navbar NavbarTitle={subProducts.products} />
      <div className="d-flex justify-content-end align-items-center pe-4 mt-3">
        {category?.id && (
          <Filter
            placeholder={subProducts.superProducts}
            options={superProductsOptions}
            name="superProduct"
            onChange={(value) => {
              setSuperProduct(value);
            }}
            value={superProduct}
            inputClassName="me-4 mt-1"
            filterWrapperClass="filter"
          />
        )}
        <Filter
          placeholder={subProducts.categories}
          options={categoriesOptions}
          name="Category"
          onChange={(value) => {
            setCategory(value);
          }}
          value={category}
          inputClassName="me-4 mt-1"
          filterWrapperClass="filter"
        />
        <SearchInput
          name="search"
          value={searchQuery}
          onChange={(value) => {
            setSearchQuery(value);
          }}
          placeholder={subProducts.search}
        />
      </div>
      <div className="bg-white mx-4 my-3 py-4 px-5 products  ">
        <AlertNote
          alertText={general.noteOfChangingProductsWillAffectOffers}
          wrapperClasses="mt-3"
        />

        <>{renderProductsList()}</>
      </div>
    </>
  );
};

export default Products;
