import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import "./Navbar.scss";

const Navbar = ({ NavbarTitle, children, arabicTitle }) => {
  const lang = useSelector((state) => state.locale.lang);
  const dir = lang === "ar" ? "rtl" : "ltr";

  return (
    <div
      className="Navbar-container bg-white d-flex   justify-content-between align-items-center"
      dir={dir}
    >
      <div
        className={`navbar-title fsize-24 font-bold ${
          arabicTitle ? "font-cairo-bold" : ""
        }`}
      >
        {NavbarTitle}
      </div>
      <div>{children}</div>
    </div>
  );
};
Navbar.propTypes = {
  children: PropTypes.node,
  NavbarTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  arabicTitle: PropTypes.bool
};

export default Navbar;
