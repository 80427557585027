import store from "../../store";
import { showHideLoader } from "../../store/Loader/actions";

export const isHandlerEnabled = (config = {}) => {
  return Object.prototype.hasOwnProperty.call(
    config,
    "handlerEnabled"
  ) && !config.handlerEnabled
    ? false
    : true;
};

// This is used to handle remove loader only if all pending requests are resolved
let numberOfAjaxCAllPending = 0;

export const requestHandler = (request) => {
  numberOfAjaxCAllPending++;
  if (isHandlerEnabled(request)) {
    !request.params?.search && store.dispatch(showHideLoader(true));
    const adminData =
      JSON.parse(localStorage.getItem("admin")) ||
      JSON.parse(sessionStorage.getItem("admin"));
    const token = adminData?.auth_token;
    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  return request;
};

export const successHandler = (response) => {
  numberOfAjaxCAllPending--;
  if (isHandlerEnabled(response)) {
    if (numberOfAjaxCAllPending === 0) {
      store.dispatch(showHideLoader(false));
    }
  }
  return response;
};

export const errorHandler = (error) => {
  numberOfAjaxCAllPending--;
  if (isHandlerEnabled(error.config)) {
    if (numberOfAjaxCAllPending === 0) {
      store.dispatch(showHideLoader(false));
    }
  }
  return Promise.reject({ ...error });
};
