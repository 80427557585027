import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  subProduct: null
};

const subProductsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_SUBPRODUCTS_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };
    case types.GET_SUBPRODUCT_RESPONSE:
      return { ...state, subProduct: action.payload };
    default:
      return state;
  }
};

export default subProductsReducer;
