import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Navbar from "../../../components/Navbar";
import GoogleMaps from "../../../components/GoogleMaps";
import Button from "../../../components/Button";
import messages from "../../../assets/locale/messages";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { setWarehouseLocation } from "../../../store/GoogleMaps/actions";
import "./PickLocation.scss";

const PickLocation = (props) => {
  const lang = useSelector((state) => state.locale.lang);
  const location = useSelector(
    (state) => state.googleMaps.warehouse.location
  );
  const { WarehousePickLocation } = messages[lang];
  const dispatch = useDispatch();
  const [coords, setCoords] = useState({
    coords: null
  });
  const { id } = useParams();
  const history = useHistory();
  const handleSaveChanges = () => {
    dispatch(setWarehouseLocation(coords));
    if (id) {
      history.push({
        pathname: ROUTE_PATHS.editWarehouse.replace(":id", id),
        state: props.location.state
      });
    } else {
      history.push({
        pathname: ROUTE_PATHS.addWarehouse,
        state: props.location.state
      });
    }
  };

  const getCoords = (position) => {
    setCoords(position);
  };

  return (
    <>
      <Navbar NavbarTitle={WarehousePickLocation.title} />
      <div className="content-wrapper px-4  py-3 pickLocation d-flex flex-column justify-content-between">
        <div className="map">
          <p className="font-medium fsize-16">
            {WarehousePickLocation.subTitle}
          </p>
          <GoogleMaps
            getCoords={getCoords}
            savedCoords={
              location.coords
                ? location
                : props.location.state.location
            }
            className="h-50 w-75"
            isMarker={true}
          />
        </div>
        <div className="SaveChanges text-center">
          <Button
            label={
              <span className=" w-25">
                <span className="ps-1">
                  {WarehousePickLocation.saveChanges}
                </span>
              </span>
            }
            labelClass="fsize-16 text-white"
            onClick={handleSaveChanges}
          />
        </div>
      </div>
    </>
  );
};

export default PickLocation;

PickLocation.propTypes = {
  location: PropTypes.object
};
