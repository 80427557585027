import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { DeleteOutline } from "@material-ui/icons";

import messages from "../../../assets/locale/messages";
import "./DeleteConfirmation.scss";

const DeleteConfiramtion = ({ onClose }) => {
  const lang = useSelector((state) => state.locale.lang);
  const { productCategories } = messages[lang];

  return (
    <div className="deleteConfiramtionContainer">
      <div className="d-flex justify-content-center mb-2">
        <DeleteOutline className="deleteIcon" onClick={onClose} />
      </div>
      <div className="d-flex justify-content-center mb-3">
        <p className="font-semibold text-dark fsize-14 text-center">
          {productCategories.deleteConfirmation.message}
        </p>
      </div>
    </div>
  );
};

export default React.forwardRef((props, ref) => (
  <DeleteConfiramtion innerRef={ref} {...props} />
));

DeleteConfiramtion.propTypes = {
  onClose: PropTypes.func
};
