import * as types from "./types";

export const getWarehousesRequest = (payload) => ({
  type: types.GET_WAREHOUSES_REQUEST,
  payload
});

export const getWarehousesResponse = (payload) => ({
  type: types.GET_WAREHOUSES_RESPONSE,
  payload
});

export const addWharehouseRequest = (payload) => ({
  type: types.ADD_WAREHOUSE_REQUEST,
  payload
});

export const getWarehouseRequest = (payload) => ({
  type: types.GET_WAREHOUSE_REQUEST,
  payload
});

export const getWarehouseResponse = (payload) => ({
  type: types.GET_WAREHOUSE_RESPONSE,
  payload
});

export const editWarehouseRequest = (payload) => ({
  type: types.EDIT_WAREHOUSE_REQUEST,
  payload
});

export const getWeekDaysRequest = () => ({
  type: types.GET_WEEK_DAYS_REQUEST
});

export const getWeekDaysResponse = (payload) => ({
  type: types.GET_WEEK_DAYS_RESPONSE,
  payload
});

export const createZoneRequest = (payload) => ({
  type: types.CREATE_ZONE_REQUEST,
  payload
});

export const editZoneRequest = (payload) => ({
  type: types.EDIT_ZONE_REQUEST,
  payload
});

export const deleteZoneRequest = (payload) => ({
  type: types.DELETE_ZONE_REQUEST,
  payload
});
