import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FormHelperText,
  Modal as MaterialModal
} from "@material-ui/core";
import { Close, ErrorOutline } from "@material-ui/icons";

import {
  getTwelveHrFormat,
  getTwentyFourHrFormat
} from "../../utils/Helpers";
import { dayTime } from "../../utils/Constants";
import messages from "../../assets/locale/messages";
import Input from "../Input";
import Button from "../Button";
import "./TimeWatchModal.scss";

const TimeWatchModal = ({
  open,
  handleClose,
  handleConfirmTime,
  defaultValues,
  defaultErrMsg
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { warehouseCoverageAreas } = messages[lang];

  const [errMsg, setErrMsg] = useState("");

  const initialValues = {
    fromHour: 1,
    fromMin: 0,
    fromTime: dayTime.am,
    toHour: 1,
    toMin: 0,
    toTime: dayTime.am
  };
  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    setValues
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      fromHour: Yup.number().required(
        warehouseCoverageAreas.validation.isrequired
      ),
      toHour: Yup.number().required(
        warehouseCoverageAreas.validation.isrequired
      ),
      fromMin: Yup.number().required(
        warehouseCoverageAreas.validation.isrequired
      ),
      toMin: Yup.number().required(
        warehouseCoverageAreas.validation.isrequired
      )
    }),
    onSubmit: ({
      fromHour,
      fromMin,
      fromTime,
      toHour,
      toMin,
      toTime
    }) => {
      const from = getTwentyFourHrFormat(
        `${fromHour}:${fromMin}`,
        fromTime === dayTime.am
      );
      const to = getTwentyFourHrFormat(
        `${toHour}:${toMin}`,
        toTime === dayTime.am
      );
      if (to <= from) {
        setErrMsg(
          warehouseCoverageAreas.validation.slotFromToInvalid
        );
      } else {
        handleConfirmTime({ from, to });
      }
    }
  });

  useEffect(() => {
    setErrMsg(defaultErrMsg);
  }, [defaultErrMsg]);

  useEffect(() => {
    if (defaultValues) {
      const { from, to } = defaultValues;
      const [fromHour, fromMin, fromTime] =
        getTwelveHrFormat(from).split(/[:\s]/);
      const [toHour, toMin, toTime] =
        getTwelveHrFormat(to).split(/[:\s]/);
      setValues({
        fromHour: Number(fromHour),
        fromMin: Number(fromMin),
        fromTime,
        toHour: Number(toHour),
        toMin: Number(toMin),
        toTime
      });
    } else {
      setValues(initialValues);
    }
  }, [defaultValues]);

  return (
    <MaterialModal
      open={open}
      onClose={handleClose}
      className="time-watch-modal"
    >
      <div className="pt-3 d-flex jusitfy-content-center align-items-center">
        <div className="d-flex justify-content-center align-items-center vh-100 w-100 ">
          <div className="bg-white time-wrapper position-relative">
            <Close className="closeIcon" onClick={handleClose} />
            <form onSubmit={handleSubmit} noValidate>
              <div className="Watcher-container">
                <div className="row mb-3 align-items-start watcher-from">
                  <div className="col-3 text-center">
                    <span>{warehouseCoverageAreas.from}</span>
                  </div>
                  <div className="col-3">
                    <Input
                      id="fromHour"
                      type="number"
                      name="fromHour"
                      value={values["fromHour"].toString()}
                      onChange={(value) => {
                        if (value && value >= 1 && value <= 12) {
                          setFieldTouched("fromHour");
                          setFieldValue("fromHour", value);
                        }
                      }}
                      isInputHasErr={
                        !!(touched["fromHour"] && errors["fromHour"])
                      }
                      errMsg={errors["fromHour"]}
                      min="1"
                      max="12"
                    />
                  </div>
                  <div className="col-1">:</div>
                  <div className="col-3">
                    <Input
                      id="fromMin"
                      type="number"
                      name="fromMin"
                      value={values["fromMin"].toString()}
                      onChange={(value) => {
                        if (value && value >= 0 && value <= 59) {
                          setFieldTouched("fromMin");
                          setFieldValue("fromMin", value.trim());
                        }
                      }}
                      isInputHasErr={
                        !!(touched["fromMin"] && errors["fromMin"])
                      }
                      errMsg={errors["fromMin"]}
                      min="0"
                      max="59"
                    />
                  </div>
                  <div className="col-2">
                    <div className="time d-flex flex-column align-items-center">
                      <span
                        onClick={() => {
                          setFieldValue("fromTime", dayTime.am);
                        }}
                        className={` time ${
                          values.fromTime === dayTime.am
                            ? "PicktTime"
                            : ""
                        }  `}
                      >
                        {warehouseCoverageAreas.am}
                      </span>
                      <span
                        onClick={() => {
                          setFieldValue("fromTime", dayTime.pm);
                        }}
                        className={`time ${
                          values.fromTime === dayTime.pm
                            ? "PicktTime"
                            : ""
                        }  `}
                      >
                        {warehouseCoverageAreas.pm}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row align-items-start watcher-to">
                  <div className="col-3  text-center">
                    <span>{warehouseCoverageAreas.to}</span>
                  </div>
                  <div className="col-3">
                    <Input
                      id="toHour"
                      type="number"
                      name="toHour"
                      value={values["toHour"].toString()}
                      onChange={(value) => {
                        if (value && value >= 1 && value <= 12) {
                          setFieldTouched("toHour");
                          setFieldValue("toHour", value.trim());
                        }
                      }}
                      isInputHasErr={
                        !!(touched["toHour"] && errors["toHour"])
                      }
                      errMsg={errors["toHour"]}
                      min="1"
                      max="12"
                    />
                  </div>
                  <div className="col-1">:</div>
                  <div className="col-3">
                    <Input
                      id="toMin"
                      type="number"
                      name="toMin"
                      value={values["toMin"].toString()}
                      onChange={(value) => {
                        if (value && value >= 0 && value <= 59) {
                          setFieldTouched("toMin");
                          setFieldValue("toMin", value.trim());
                        }
                      }}
                      isInputHasErr={
                        !!(touched["toMin"] && errors["toMin"])
                      }
                      errMsg={errors["toMin"]}
                      min="0"
                      max="59"
                    />
                  </div>
                  <div className="col-2">
                    <div className="time d-flex flex-column align-items-center">
                      <span
                        onClick={() => {
                          setFieldValue("toTime", dayTime.am);
                        }}
                        className={` time ${
                          values.toTime === dayTime.am
                            ? "PicktTime"
                            : ""
                        }  `}
                      >
                        {warehouseCoverageAreas.am}
                      </span>
                      <span
                        onClick={() => {
                          setFieldValue("toTime", dayTime.pm);
                        }}
                        className={` time ${
                          values.toTime === dayTime.pm
                            ? "PicktTime"
                            : ""
                        }  `}
                      >
                        {warehouseCoverageAreas.pm}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="actions text-center mt-4">
                  <Button
                    outlined
                    className="ms-5 mt-2"
                    type="submit"
                    label={
                      <span className="d-flex  align-items-center">
                        <span className="ps-1 fsize-14">
                          {warehouseCoverageAreas.confirm}
                        </span>
                      </span>
                    }
                    labelClass="fsize-12  px-3 text-center bg-transparent"
                  />
                </div>
              </div>
              {errMsg && (
                <FormHelperText
                  error={!!errMsg}
                  className="font-medium fsize-12 errorMsg"
                >
                  <ErrorOutline />
                  {errMsg}
                </FormHelperText>
              )}
            </form>
          </div>
        </div>
      </div>
    </MaterialModal>
  );
};

export default TimeWatchModal;

TimeWatchModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleConfirmTime: PropTypes.func,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  defaultErrMsg: PropTypes.string,
  defaultValues: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string
  })
};
