import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { browserName } from "react-device-detect";

import messages from "../../../assets/locale/messages";
import Input from "../../../components/Input";
import Checkbox from "../../../components/Checkbox";
import Button from "../../../components/Button";
import { loginRequest } from "../../../store/Auth/actions";
import { generateDeviceId } from "../../../utils/Helpers";
import { getFirebaseToken } from "../../../firebase/helpers";
import "./Login.scss";

const Login = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const { auth } = messages[lang];

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(auth.validations.validEmail)
        .required(auth.validations.email),
      password: Yup.string().required(auth.validations.password)
    }),
    onSubmit: ({ email, password, rememberMe }) => {
      dispatch(
        loginRequest({
          data: {
            email,
            password,
            device_id: `${browserName}-${generateDeviceId()}`,
            fcm_token: getFirebaseToken()
          },
          rememberMe
        })
      );
    }
  });
  return (
    <>
      <div className="container d-flex justify-content-center pt-3">
        <img
          src={process.env.REACT_APP_AUTH_SCREEN_SIDE_LEFT_IMAGE}
          alt="service-provider-logo"
          className="k-logo"
        />
        <div className="d-flex flex-column align-items-center">
          <img
            src={process.env.REACT_APP_AUTH_SCREEN_LOGO}
            alt="service-provider-logo"
            className="my-3 w-60"
          />
          <div
            className={`login-card d-flex flex-column align-items-center`}
          >
            <h3 className="title font-bold fsize-32">
              {auth.welcome}
            </h3>
            <p className="subTitle my-2 fsize-14 font-medium">
              {auth.subTitle}
            </p>
            <form
              className="form-login"
              onSubmit={handleSubmit}
              noValidate
            >
              <Input
                id="email"
                label={auth.labels.email}
                name="email"
                type="email"
                placeholder={auth.placeholders.email}
                required
                onChange={(value) => {
                  setFieldTouched("email");
                  setFieldValue("email", value.trim());
                }}
                value={values["email"]}
                inputClass="mb-1"
                labelClassName="font-medium mb-2"
                inputWrapperClass="my-4"
                isInputHasErr={
                  !!(touched["email"] && errors["email"])
                }
                errMsg={errors["email"]}
              />
              <Input
                id="password"
                label={auth.labels.password}
                name="password"
                type="password"
                placeholder={auth.placeholders.password}
                required={true}
                onChange={(value) => {
                  setFieldTouched("password");
                  setFieldValue("password", value.trim());
                }}
                value={values["password"]}
                inputClass="mb-1"
                labelClassName="font-medium mb-2"
                inputWrapperClass="mt-4 mb-5"
                isInputHasErr={
                  !!(touched["password"] && errors["password"])
                }
                errMsg={errors["password"]}
              />
              <Checkbox
                id="rememberMe"
                label={auth.labels.remember}
                labelClass="checkbox-label-login fsize-14"
                name="rememberMe"
                checked={values["rememberMe"]}
                onChange={(checked) => {
                  setFieldTouched("rememberMe");
                  setFieldValue("rememberMe", checked);
                }}
              />
              <Button
                label={auth.labels.signin}
                type="submit"
                labelClass="fsize-14 text-white py-1"
                className="mb-4"
                block
                disabled={!dirty || !isValid}
              />
            </form>
          </div>
        </div>
        <img
          src={process.env.REACT_APP_AUTH_SCREEN_SIDE_RIGHT_IMAGE}
          alt="service-provider-logo"
          className="z-logo"
        />
      </div>
    </>
  );
};

export default Login;
