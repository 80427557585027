import * as types from "./types";

export const getWholesalersRequest = (data) => ({
  type: types.GET_WHOLESALERS_REQUEST,
  payload: data
});

export const getWholesalersResponse = (data) => ({
  type: types.GET_WHOLESALERS_RESPONSE,
  payload: data
});

export const addWholesalerRequest = (data) => ({
  type: types.ADD_WHOLESALER_REQUEST,
  payload: data
});

export const getWholesalerRequest = (data) => ({
  type: types.GET_WHOLESALER_REQUEST,
  payload: data
});

export const getWholesalerResponse = (data) => ({
  type: types.GET_WHOLESALER_RESPONSE,
  payload: data
});

export const editWholesalerRequest = (data) => ({
  type: types.EDIT_WHOLESALER_REQUEST,
  payload: data
});

export const getWholesalerProductsRequest = (data) => ({
  type: types.GET_WHOLESALER_PRODUCTS_REQUEST,
  payload: data
});

export const getWholesalerProductsResponse = (data) => ({
  type: types.GET_WHOLESALER_PRODUCTS_RESPONSE,
  payload: data
});

export const getWholesalerProductsWithOffersInsideOrderRequest = (
  data
) => ({
  type: types.GET_WHOLESALER_PRODUCTS_WITH_OFFERS_INSIDE_ORDER_REQUEST,
  payload: data
});

export const getWholesalerProductsWithOffersInsideOrderResponse = (
  data
) => ({
  type: types.GET_WHOLESALER_PRODUCTS_WITH_OFFERS_INSIDE_ORDER_RESPONSE,
  payload: data
});

export const getWholsalerProductRequest = (data) => ({
  type: types.GET_WHOLESALER_PRODUCT_REQUEST,
  payload: data
});

export const getWholsalerProductResponse = (data) => ({
  type: types.GET_WHOLESALER_PRODUCT_RESPONSE,
  payload: data
});

export const createWholesalerProduct = (data) => ({
  type: types.CREATE_WHOLESALER_PRODUCT_REQUEST,
  payload: data
});

export const editWholesalerProductRequest = (data) => ({
  type: types.EDIT_WHOLESALER_PRODUCT_REQUEST,
  payload: data
});

export const deleteWholesalerProductRequest = (data) => ({
  type: types.DELETE_WHOLESALER_PRODUCT_REQUEST,
  payload: data
});

export const getDeliveryMethodsRequest = (data) => ({
  type: types.GET_DELIVERY_METHODS_REQUEST,
  payload: data
});

export const getDeliveryMethodsResponse = (data) => ({
  type: types.GET_DELIVERY_METHODS_RESPONSE,
  payload: data
});
