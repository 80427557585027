import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";

import { getWholesalersRequest } from "../../../store/Wholesalers/actions";
import messages from "../../../assets/locale/messages";
import Navbar from "../../../components/Navbar";
import RadioButtonsGroup from "../../../components/RadioButton";
import Select from "../../../components/Select";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import DateAndTimePicker from "../../../components/DateAndTimePicker";
import Switch from "../../../components/Switch";
import DeleteIcon from "../../../utils/Icons/Delete";
import { formatDate } from "../../../utils/Helpers";
import SelectOfferProduct from "../SelectOfferProduct";
import OfferProductCard from "../OfferProductCard";
import PreviousOffersCard from "../PreviousOffers/Card";
import PreviousOffersModal from "../PreviousOffers/Modal";
import { addEditOfferSchema, getInitialValues } from "../validations";
import { formatDateWithTime } from "../../../utils/Helpers";
import {
  offerActiveStatus,
  offerPendingStatus,
  offerExpiredStatus
} from "../../../utils/Constants";
import {
  addOfferRequest,
  getPreviousProductOffersRequest,
  getOfferRequest,
  getOfferResponse,
  editOfferRequest,
  deleteOfferRequest
} from "../../../store/Offers/actions";
import "./AddEditOffer.scss";

const AddEditOffer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const wholesalers = useSelector((state) => state.wholesalers.list);
  const previousProductOffers = useSelector(
    (state) => state.offers.previousProductOffers
  );
  const offersRoutingData = useSelector(
    (state) => state.routing.offers
  );
  const offer = useSelector((state) => state.offers.offer);
  const { offers } = messages[lang];
  const [wholsalersOptions, setWholsalersOption] = useState([]);
  const [wholesalerId, setWholesalerId] = useState("");
  const [wholesalerUserName, setWholesalerUserName] = useState("");
  const [openProductsModal, setOpenProductsModal] = useState(false);
  const [openPreviousOfferModal, setOpenPreviousOfferModal] =
    useState(false);
  const [previousOffer, setPreviousOffer] = useState(null);
  const [addedProduct, setAddedProduct] = useState(null);
  const [openActionConfirm, setOpenActionConfirm] = useState(false);

  // Handle Timing logic

  const [
    currentTimeWithAddedFiftenMinutes,
    setCurrentTimeWithAddedFiftenMinutes
  ] = useState(new Date());

  const [
    currentTimeWithAddedThirtyMinutes,
    setCurrentTimeWithAddedThirtyMinutes
  ] = useState(new Date());
  useEffect(() => {
    const updateTimeForStart = setInterval(() => {
      let minutesToAdd = 15;
      let currentDate = new Date();
      let futureDate = new Date(
        currentDate.getTime() + minutesToAdd * 60000
      );
      setCurrentTimeWithAddedFiftenMinutes(futureDate);
    }, 1000);

    const updateTimeForEnd = setInterval(() => {
      let minutesToAdd = 30;
      let currentDate = new Date();
      let futureDate = new Date(
        currentDate.getTime() + minutesToAdd * 60000
      );
      setCurrentTimeWithAddedThirtyMinutes(futureDate);
    }, 1000);

    return () => {
      clearInterval(updateTimeForStart);
      clearInterval(updateTimeForEnd);
      dispatch(getOfferResponse(null));
    };
  }, []);

  useEffect(() => {
    if (id) {
      if (offerExpiredStatus.includes(offer?.status)) {
        dispatch(
          getWholesalersRequest({
            lookup: "options",
            page: -1,
            items: 1
          })
        );
      } else if (
        offerActiveStatus.includes(offer?.status) ||
        offerPendingStatus.includes(offer?.status)
      ) {
        dispatch(
          getWholesalersRequest({
            lookup: "options",
            page: -1,
            items: 1,
            visible_wholesalers: true
          })
        );
      }
    } else if (!id) {
      dispatch(
        getWholesalersRequest({
          lookup: "options",
          page: -1,
          items: 1,
          visible_wholesalers: true
        })
      );
    }
  }, [id, offer]);

  useEffect(() => {
    const options = wholesalers.map((wholesaler) => ({
      label: wholesaler.username,
      id: wholesaler.id
    }));
    setWholsalersOption(options);
  }, [wholesalers]);

  useEffect(() => {
    if (id) {
      dispatch(getOfferRequest(id));
      dispatch(
        getPreviousProductOffersRequest({
          offer_id: id
        })
      );
    }
  }, [id]);

  useEffect(() => {
    if (addedProduct && !id) {
      dispatch(
        getPreviousProductOffersRequest({
          wholesalers_product_id: addedProduct.id,
          applied_offers: true
        })
      );
    }
  }, [addedProduct]);

  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    touched,
    setFieldTouched,
    dirty,
    isValid,
    setValues,
    setTouched
  } = useFormik({
    initialValues: getInitialValues(),
    validationSchema: addEditOfferSchema(addedProduct?.price),
    onSubmit: (values) => {
      if (id && offer) {
        dispatch(
          editOfferRequest({
            data: values,
            id,
            insideSaveOfferChanges: true
          })
        );
      } else {
        dispatch(addOfferRequest({ data: values }));
      }
    }
  });

  useEffect(() => {
    if (offer && id) {
      // set fields to be touched to show error messages directly
      setTouched({
        wholesaler_id: true,
        discount_type: true,
        discount_value: true,
        start_datetime: true,
        end_datetime: true
      });
      setValues(getInitialValues(offer));
      setAddedProduct(offer?.wholesalers_product);
      setWholesalerId(offer?.wholesalers_product?.wholesaler?.id);
    }
  }, [offer]);

  const handleCloseProductsModal = () => {
    setOpenProductsModal(false);
  };

  const handleClosePreviousOfferModal = () => {
    setOpenPreviousOfferModal(false);
  };

  const listOfOfferTypes = [
    {
      value: "amount",
      label: offers.addEditPage.amount,
      disabled: !addedProduct,
      children: (
        <div className="d-flex flex-column custom-discount-input-margin">
          <div className="d-flex align-items-center justify-content-start ">
            <Input
              id="amount"
              name="discount_value"
              labelClassName=" mb-3 text-gray-title fsize-14"
              inputWrapperClass="w-75 value-input-wrapper"
              inputClass={`offer-value-input ${
                !addedProduct && "disabled-border disabled-text"
              }`}
              disabled={
                !addedProduct ||
                (id &&
                  offer &&
                  (offerExpiredStatus.includes(offer?.status) ||
                    offerActiveStatus.includes(offer?.status)))
              }
              onChange={(value) => {
                setFieldTouched("discount_value");
                setFieldValue("discount_value", value);
              }}
              value={values["discount_value"]}
              isInputHasErr={
                errors.discount_value?.length > 0 &&
                touched.discount_value
              }
              errMsg={errors.discount_value}
            />
            <div className="d-flex align-items-center justify-content-start ">
              <span
                className={`mx-2 ${!addedProduct && "disabled-text"}`}
              >
                {offers.addEditPage.le}
              </span>
            </div>
          </div>
        </div>
      )
    },
    {
      value: "percentage",
      label: offers.addEditPage.percentage,
      disabled: !addedProduct,
      children: (
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center justify-content-start">
            <Input
              id="percentage"
              name="discount_value"
              labelClassName=" mb-3 text-gray-title fsize-14"
              inputWrapperClass="w-75 value-input-wrapper"
              inputClass={`offer-value-input ${
                !addedProduct && "disabled-border disabled-text"
              }`}
              disabled={
                !addedProduct ||
                (id &&
                  offer &&
                  (offerExpiredStatus.includes(offer?.status) ||
                    offerActiveStatus.includes(offer?.status)))
              }
              onChange={(value) => {
                setFieldTouched("discount_value");
                setFieldValue("discount_value", value);
              }}
              value={values["discount_value"]}
              isInputHasErr={
                errors.discount_value?.length > 0 &&
                touched.discount_value
              }
              errMsg={errors.discount_value}
            />
            <div className="d-flex align-items-center justify-content-start">
              <span
                className={`mx-2 ${!addedProduct && "disabled-text"}`}
              >
                %
              </span>
            </div>
          </div>
        </div>
      )
    }
  ];

  const checkOfferStatus = (status) => {
    if (offerActiveStatus.includes(status)) {
      return "active";
    } else if (offerPendingStatus.includes(status)) {
      return "pending";
    } else if (offerExpiredStatus.includes(status)) {
      return "expired";
    }
  };

  const handleSelectWholesalerChange = (value) => {
    setFieldValue("wholesalers_product_id", "");
    setFieldValue("discount_value", "");
    setFieldTouched("wholesaler_id");
    setFieldValue("wholesaler_id", value ? value.id : "");
    setWholesalerId(value ? value.id : "");
    setWholesalerUserName(value ? value.label : "");
    setAddedProduct(null);
  };

  const handleStartDateChange = (value) => {
    setFieldTouched("start_datetime");
    if (value) {
      const formatedValue = formatDateWithTime(value);
      let nowDate = new Date();
      let nowYear = nowDate.getFullYear();
      let nowMonth = nowDate.getMonth() + 1;
      let nowDay = nowDate.getDate();
      let nowHour = nowDate.getHours();
      let nowMinute = nowDate.getMinutes();

      let startHour = parseInt(
        formatedValue.split(" ")[1].split(":")[0]
      );
      let startMinute = parseInt(
        formatedValue.split(" ")[1].split(":")[1]
      );

      let startYear = parseInt(formatedValue.split("-")[0]);
      let startMonth = parseInt(formatedValue.split("-")[1]);
      let startDay = parseInt(
        formatedValue.split("-")[2].split(" ")[0]
      );

      if (
        startYear === nowYear &&
        startMonth === nowMonth &&
        startDay === nowDay &&
        startHour === nowHour &&
        startMinute <= nowMinute
      ) {
        setFieldValue(
          "start_datetime",
          formatDateWithTime(currentTimeWithAddedFiftenMinutes)
        );
      } else {
        setFieldValue(
          "start_datetime",
          value === null ? value : formatDateWithTime(value)
        );
      }
    }

    if (value === null) {
      setFieldValue("start_datetime", null);
    }
  };

  const handleEndDateChange = (value) => {
    setFieldTouched("end_datetime");
    if (value) {
      const formatedValue = formatDateWithTime(value);
      let nowDate = new Date();
      let nowYear = nowDate.getFullYear();
      let nowMonth = nowDate.getMonth() + 1;
      let nowDay = nowDate.getDate();
      let nowHour = nowDate.getHours();
      let nowMinute = nowDate.getMinutes();

      let endHour = parseInt(
        formatedValue.split(" ")[1].split(":")[0]
      );
      let endMinute = parseInt(
        formatedValue.split(" ")[1].split(":")[1]
      );

      let endYear = parseInt(formatedValue.split("-")[0]);
      let endMonth = parseInt(formatedValue.split("-")[1]);
      let endDay = parseInt(
        formatedValue.split("-")[2].split(" ")[0]
      );

      if (
        endYear === nowYear &&
        endMonth === nowMonth &&
        endDay === nowDay &&
        endHour === nowHour &&
        endMinute <= nowMinute
      ) {
        setFieldValue(
          "end_datetime",
          formatDateWithTime(currentTimeWithAddedThirtyMinutes)
        );
      } else {
        setFieldValue(
          "end_datetime",
          value === null ? value : formatDateWithTime(value)
        );
      }
    }

    if (value === null) {
      setFieldValue("end_datetime", null);
    }
  };

  const handleViewPreviousOrderClick = (prevOffer) => {
    setPreviousOffer(prevOffer);
    setOpenPreviousOfferModal(true);
  };

  const toggleEnabledOffers = (isEnabled, id) => {
    dispatch(
      editOfferRequest({
        data: { is_enabled: !isEnabled },
        id
      })
    );
  };

  const handleActionClick = () => {
    setOpenActionConfirm(true);
  };

  const handleCloseActionConfirmModal = () => {
    setOpenActionConfirm(false);
  };

  const deleteAction = (id) => {
    dispatch(
      deleteOfferRequest({
        id,
        insideViewEditOfferTopContainer: true,
        page: offersRoutingData?.page || 1
      })
    );
    setOpenActionConfirm(false);
  };

  return (
    <div className="pb-4 add-edit-offer-page">
      <Navbar
        NavbarTitle={
          id && offerExpiredStatus.includes(offer?.status)
            ? offers.addEditPage.viewOffer
            : id &&
              (offerActiveStatus.includes(offer?.status) ||
                offerPendingStatus.includes(offer?.status))
            ? offers.addEditPage.editPageTitle
            : offers.addEditPage.addPageTitle
        }
      />
      <form noValidate onSubmit={handleSubmit}>
        <div className="px-5 pt-4 bg-white mx-4 my-4 form-container">
          <div className="row">
            <div className="col-8 form-data">
              {id && offer && (
                <div className="d-flex justify-content-between edit-view-top-container">
                  <div>
                    <div className="d-flex justify-content-start align-items-start my-3">
                      <div className="d-flex justify-content-start me-3">
                        <div className="d-flex flex-column">
                          <div
                            className={`status-${checkOfferStatus(
                              offer?.status
                            )} px-3 py-2 border-radius-4`}
                          >
                            {offer?.status}
                          </div>
                          {!offer?.wholesalers_product
                            ?.is_in_stock && (
                            <div className="ms-2 out-of-stock-label">
                              {offers.offersList.outOfStock}
                            </div>
                          )}
                        </div>
                      </div>
                      <p className="my-0 ms-4 fweight-700 fsize-24">
                        {addedProduct
                          ? addedProduct?.product?.concatenated_name
                          : offers.addEditPage.productNamePlaceHolder}
                      </p>
                    </div>
                  </div>
                  {!offerExpiredStatus.includes(offer?.status) && (
                    <div className="d-flex flex-column align-items-center mx-4">
                      <Switch
                        checked={offer?.is_enabled === true}
                        name="enabled"
                        onChange={() => {
                          toggleEnabledOffers(
                            offer?.is_enabled,
                            offer?.id
                          );
                        }}
                      />
                      <span className="text-gray mt-1 mx-2">
                        {offer?.is_enabled
                          ? offers.addEditPage.enabled
                          : offers.addEditPage.disabled}
                      </span>
                    </div>
                  )}
                </div>
              )}
              <div className="row ms-1 align-items-start flex-nowrap pt-3 align-items-center">
                <div className="text-gray-title ps-0 col-3">
                  {id ? (
                    <>
                      {offers.addEditPage.wholesaler}
                      {offerPendingStatus.includes(offer?.status) && (
                        <span className="err-asterisk">*</span>
                      )}
                    </>
                  ) : (
                    <>
                      {offers.addEditPage.chooseWholesaler}
                      <span className="err-asterisk">*</span>
                    </>
                  )}
                </div>
                <div
                  className={`col-5 select-wholesaler-wrapper ${
                    id !== undefined &&
                    offer !== undefined &&
                    !offerPendingStatus.includes(offer?.status)
                      ? "view-only-mode"
                      : ""
                  }`}
                >
                  <Select
                    name="wholesaler_id"
                    options={wholsalersOptions}
                    variant
                    block
                    disabled={
                      id !== undefined &&
                      offer !== undefined &&
                      !offerPendingStatus.includes(offer?.status)
                    }
                    placeholder={offers.addEditPage.wholesalerName}
                    required
                    value={
                      wholsalersOptions.find(
                        (option) =>
                          option.id == values["wholesaler_id"]
                      ) || null
                    }
                    onChange={(value) => {
                      handleSelectWholesalerChange(value);
                    }}
                    hasError={
                      !!(
                        touched["wholesaler_id"] &&
                        errors["wholesaler_id"]
                      )
                    }
                    errMsg={errors["wholesaler_id"]}
                  />
                </div>
              </div>
              <div className="row ms-1 align-items-start flex-nowrap mt-4 pt-3">
                <div className="text-gray-title ps-0 col-3 pt-2">
                  {id ? (
                    <>
                      {offers.addEditPage.product}
                      {offerPendingStatus.includes(offer?.status) && (
                        <span className="err-asterisk">*</span>
                      )}
                    </>
                  ) : (
                    <>
                      {offers.addEditPage.chooseProduct}
                      <span className="err-asterisk">*</span>
                    </>
                  )}
                </div>
                <div className="col-6 select-product-wrapper">
                  <div className="d-flex">
                    <Button
                      outlined
                      className={`me-5 px-2  ${
                        id &&
                        !offerPendingStatus.includes(offer?.status)
                          ? "select-product-view-mode w-80"
                          : "w-82"
                      }`}
                      label={
                        <p className="ps-2 m-0 add-product-btn-text">
                          {addedProduct
                            ? addedProduct.product.concatenated_name
                            : offers.addEditPage.chooseProduct}
                        </p>
                      }
                      labelClass="fsize-16 pe-2 w-100 d-flex justify-content-center"
                      onClick={() => {
                        if (wholesalerId) {
                          setOpenProductsModal(true);
                        }
                      }}
                      disabled={
                        !wholesalerId ||
                        (id !== undefined &&
                          offer !== undefined &&
                          !offerPendingStatus.includes(offer?.status))
                      }
                    />
                    <div
                      className={`cursor-pointer pt-2 ${
                        (id !== undefined && offer !== undefined) ||
                        !addedProduct
                          ? "d-none"
                          : ""
                      }`}
                      onClick={() => {
                        setAddedProduct(null);
                        setFieldValue("discount_value", "");
                      }}
                    >
                      <DeleteIcon />
                    </div>
                  </div>

                  <div className="minErrorHeight mt-3 ms-3">
                    {errors.wholesalers_product_id &&
                    touched.wholesalers_product_id ? (
                      <div className="text-error text-start">
                        {errors.wholesalers_product_id}
                      </div>
                    ) : null}
                  </div>
                  <Modal
                    open={openProductsModal}
                    onClose={handleCloseProductsModal}
                    className="product-modal-in-add-offer-page"
                    modalBody={
                      <SelectOfferProduct
                        wholesaler_id={wholesalerId}
                        wholesalerUserName={wholesalerUserName}
                        onClose={handleCloseProductsModal}
                        handleAddProduct={(product) => {
                          setAddedProduct(product);
                          setFieldValue(
                            "wholesalers_product_id",
                            product.id
                          );
                          setFieldValue("discount_value", "");
                        }}
                      />
                    }
                  />
                </div>
              </div>
              <div
                className={`row ms-1 align-items-start offer-value-container  ${
                  id &&
                  offer &&
                  offerExpiredStatus.includes(offer?.status)
                    ? "min-height-50px"
                    : ""
                }`}
              >
                <RadioButtonsGroup
                  wrapperClassName="d-flex align-items-start flex-row col-8"
                  label={offers.addEditPage.offerValue}
                  labelClassName="text-gray-title me-4 col-4 pt-13px"
                  className="d-flex align-items-start flex-column justify-content-between flex-nowrap w-60 ms-4 position-relative firstChildsNoShrink"
                  required={
                    !id ||
                    (id !== undefined &&
                      offerPendingStatus.includes(offer?.status))
                  }
                  disabled={
                    id &&
                    offer &&
                    (offerExpiredStatus.includes(offer?.status) ||
                      offerActiveStatus.includes(offer?.status))
                  }
                  id="discount_type"
                  name="discount_type"
                  value={values["discount_type"]}
                  alignedColumn={true}
                  alignedColumnClasses="d-flex mb-4"
                  handleChange={(e) => {
                    setFieldTouched("discount_type");
                    setFieldValue("discount_type", e.target.value);
                    setFieldValue("discount_value", "");
                  }}
                  listOfRadios={listOfOfferTypes}
                  hasDisabledReplacementBody={
                    id !== undefined &&
                    offerExpiredStatus.includes(offer?.status)
                  }
                  disabledReplacementBody={
                    <div className="ms-4 d-flex justif-content-start align-items-center offer-value-disabled-replacement ">
                      <p className="fsize-16 text-dark-blue mb-0 pe-2">
                        {offer?.discount_type === "percentage"
                          ? offers.addEditPage.percentage
                          : offers.addEditPage.amount}
                      </p>
                      <div className="w-100 d-flex justify-content-center align-items-center offer-value-disabled-replacement-container">
                        <div className="w-90 d-flex justify-content-center align-items-center ms-2 me-1 fsize-16 value-replacement-content ">
                          {offer?.discount_value}
                        </div>
                        <span>
                          {offer?.discount_type === "percentage"
                            ? "%"
                            : offers.addEditPage.le}
                        </span>
                      </div>
                    </div>
                  }
                />
              </div>
              <div className="dividerLine my-1"></div>
              <div className="row ms-1 align-items-start flex-nowrap mt-4 mb-4 start-date-container">
                <div className="text-gray-title ps-0 col-3 pt-13px">
                  {offers.addEditPage.startDateAndTime}
                  {(!id ||
                    (id !== undefined &&
                      offerPendingStatus.includes(
                        offer?.status
                      ))) && <span className="err-asterisk">*</span>}
                </div>
                <div className="col-8 date-and-time-picker-container">
                  <DateAndTimePicker
                    dateLabel={offers.addEditPage.startDate}
                    timeLabel={offers.addEditPage.startTime}
                    isInlineLabel
                    inputWrapperClass="me-3 date offer-date-time-picker"
                    name="start_datetime"
                    value={values["start_datetime"]}
                    handleDateChange={(value) => {
                      handleStartDateChange(value);
                    }}
                    minDate={currentTimeWithAddedFiftenMinutes}
                    maxDate={values["end_datetime"]}
                    disablePast
                    disabled={
                      id &&
                      offer &&
                      (offerExpiredStatus.includes(offer?.status) ||
                        offerActiveStatus.includes(offer?.status))
                    }
                  />
                  {errors.start_datetime && touched.start_datetime ? (
                    <div className="text-error">
                      {errors.start_datetime}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row ms-1 align-items-start flex-nowrap mt-4 mb-3 end-date-container">
                <div className="text-gray-title ps-0 col-3 pt-13px">
                  {offers.addEditPage.endDateAndTime}
                  {(!id ||
                    (id !== undefined &&
                      !offerExpiredStatus.includes(
                        offer?.status
                      ))) && <span className="err-asterisk">*</span>}
                </div>
                <div className="col-8 date-and-time-picker-container">
                  <DateAndTimePicker
                    dateLabel={offers.addEditPage.endDate}
                    timeLabel={offers.addEditPage.endTime}
                    isInlineLabel
                    inputWrapperClass="me-3 date offer-date-time-picker"
                    name="end_datetime"
                    value={values["end_datetime"]}
                    handleDateChange={(value) => {
                      handleEndDateChange(value);
                    }}
                    minDate={values["start_datetime"]}
                    disablePast
                    disabled={
                      id &&
                      offer &&
                      offerExpiredStatus.includes(offer?.status)
                    }
                  />
                  {errors.end_datetime && touched.end_datetime ? (
                    <div className="text-error">
                      {errors.end_datetime}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="dividerLine my-4"></div>
              {offer?.expiration_date &&
                offer?.expiration_reason &&
                offer?.expiration_date !== offer?.end_datetime && (
                  <div className="expiration-date-reason-container">
                    <div className="row ms-1 align-items-start flex-nowrap align-items-center">
                      <div className="text-gray-title ps-0 col-3">
                        {offers.addEditPage.expirationDate}
                      </div>
                      <div className="text-gray-title ps-3 col-5 fsize-16">
                        <span className="me-2">
                          {formatDate(
                            offer?.expiration_date,
                            "DD/MM/YYYY"
                          )}
                        </span>
                        <span className="ms-2">
                          {formatDate(
                            offer?.expiration_date,
                            "hh:mm A"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="row ms-1 mb-3 mt-4 align-items-start flex-nowrap pt-3 align-items-center">
                      <div className="text-gray-title ps-0 col-3">
                        {offers.addEditPage.expirationReason}
                      </div>
                      <div className="text-gray-title ps-3 col-5 fsize-16">
                        {offer?.expiration_reason}
                      </div>
                    </div>
                    <div className="dividerLine my-4"></div>
                  </div>
                )}

              <div className="container d-flex justify-content-center align-items-center my-3">
                <Button
                  className={`w-40 mb-3 ${
                    id &&
                    offer &&
                    offerExpiredStatus.includes(offer?.status) &&
                    "d-none"
                  }`}
                  label={
                    <span className="d-flex align-items-center">
                      <span className="ps-1 fsize-14 ">
                        {id
                          ? offers.addEditPage.editOffer
                          : offers.addEditPage.addOffer}
                      </span>
                    </span>
                  }
                  type="submit"
                  disabled={
                    values["wholesalers_product_id"] === "" ||
                    !dirty ||
                    !isValid
                  }
                  labelClass="fsize-16 text-white"
                />
              </div>
              {id &&
                offer &&
                offerPendingStatus.includes(offer?.status) && (
                  <div className="container d-flex justify-content-center align-items-center my-3 edit-view-delete-action-container ">
                    <Button
                      outlined
                      className="w-40 mb-3 px-2 action-btn-delete"
                      label={
                        <p className="ps-2 m-0 fsize-16">
                          {offers.delete}
                        </p>
                      }
                      labelClass="fsize-16 pe-2 w-100"
                      onClick={handleActionClick}
                    />
                    <Modal
                      open={openActionConfirm}
                      modalWrapperClass="action-confirm-modal"
                      onClose={handleCloseActionConfirmModal}
                      modalBody={
                        <>
                          <DeleteIcon className="delete-icon mt-2 mb-4" />
                          <p>{offers.deleteConfirmationMsg}?</p>
                        </>
                      }
                      actionBtns={
                        <div className="container px-4">
                          <div className="row">
                            <div className="col-md-6">
                              <div className=" mx-2 d-flex justify-content-center align-items-center">
                                <Button
                                  label={offers.delete}
                                  type="button"
                                  labelClass="fsize-14 text-white"
                                  onClick={() => {
                                    deleteAction(offer?.id);
                                  }}
                                  block={true}
                                  className="py-2"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div
                                className="mx-2 d-flex justify-content-center align-items-center pointer"
                                onClick={
                                  handleCloseActionConfirmModal
                                }
                              >
                                <div>
                                  <p className="fsize-14 text-orange py-2">
                                    {offers.offersList.cancel}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </div>
                )}
            </div>
            <div className="col-4 d-flex pt-4 pb-3 justify-content-start flex-column align-items-center product-preview">
              {addedProduct && (
                <OfferProductCard
                  selectedProduct={addedProduct}
                  viewEditMode={
                    id !== undefined && offer !== undefined
                  }
                  discountType={values["discount_type"]}
                  discountValue={parseFloat(
                    values["discount_value"]
                  ).toFixed(2)}
                  handleDeleteProduct={() => {
                    setAddedProduct(null);
                    setFieldValue("discount_value", "");
                  }}
                />
              )}

              {addedProduct && previousProductOffers.length > 0 && (
                <div className="prev-product-offers mt-5 mb-4">
                  <p className="prev-product-offers-qoute text-product-card-blue fsize-14 mb-3">
                    {offers.addEditPage.prevProductOffersQoute}
                  </p>
                  {previousProductOffers?.map((offer, index) => (
                    <div key={`prev-offer-${index}`}>
                      <PreviousOffersCard
                        prevOffer={offer}
                        handleViewDetails={() => {
                          handleViewPreviousOrderClick(offer);
                        }}
                      />
                    </div>
                  ))}
                  <Modal
                    open={openPreviousOfferModal}
                    onClose={handleClosePreviousOfferModal}
                    className="previous-offfer-modal-in-add-offer-page"
                    modalBody={
                      <PreviousOffersModal
                        prevOffer={previousOffer}
                        closeModal={handleClosePreviousOfferModal}
                      />
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddEditOffer;
