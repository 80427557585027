import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { TableCell, TableRow } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";

import Filter from "../../../components/Filter";
import Table from "../../../components/Table";
import SearchInput from "../../../components/SearchInput";
import messages from "../../../assets/locale/messages";
import Button from "../../../components/Button";
import EmptyState from "../../../components/EmptyState";
import Pagination from "../../../components/Pagination";
import { getWholesalerProductsRequest } from "../../../store/Wholesalers/actions";
import { getProductCategoriesRequest } from "../../../store/ProductCategories/actions";
import "./SelectOfferProduct.scss";

const SelectOfferProduct = ({
  wholesaler_id,
  onClose,
  handleAddProduct,
  wholesalerUserName
}) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const { offers, general } = messages[lang];

  const [searchValue, setSearchValue] = useState("");
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [category, setCategory] = useState(null);

  const categoriesList = useSelector(
    (state) => state.productCategories.list
  );
  const productsList = useSelector(
    (state) => state.wholesalers.productsList
  );

  const meta = useSelector((state) => state.wholesalers.productsMeta);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(
      getProductCategoriesRequest({
        page: -1,
        items: 1
      })
    );
  }, []);

  const getWholesalerProducts = (
    pageNumber,
    query,
    product_category_id
  ) => {
    dispatch(
      getWholesalerProductsRequest({
        id: wholesaler_id,
        params: {
          page: pageNumber,
          items: 5,
          query,
          product_category_id,
          is_in_stock: true,
          wholesalers_product_status: "active"
        }
      })
    );
  };

  // useEffect(() => {
  //   getWholesalerProducts(page, searchValue, category?.id);
  // }, [page, wholesaler_id, category?.id]);

  // useEffect(() => {
  //   if (searchValue.length >= 3 || searchValue.length === 0) {
  //     getWholesalerProducts(1, searchValue, category?.id);
  //   }
  // }, [searchValue]);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      getWholesalerProducts(page, searchValue, category?.id);
    }
  }, [page, wholesaler_id, category?.id, searchValue]);

  useEffect(() => {
    const options = categoriesList.map((category) => ({
      id: category.id,
      label: category.name
    }));
    setCategoriesOptions(options);
  }, [categoriesList]);

  const renderModalBody = () => {
    if (productsList?.length == 0 && !searchValue && !category?.id) {
      return (
        <div className="my-5">
          <EmptyState
            subTitle={offers.addEditPage.emptyState}
            showActionButton={false}
          />
        </div>
      );
    } else if (
      productsList?.length == 0 &&
      (searchValue || category?.id)
    ) {
      return (
        <div className="my-5">
          <EmptyState
            subTitle={general.noSearch}
            showActionButton={false}
          />
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-column align-items-center w-100">
          <Table
            headlines={[
              "",
              offers.addEditPage.productName,
              offers.addEditPage.category,
              offers.addEditPage.price
            ]}
            rows={productsList?.map((row, index) => (
              <TableRow key={index}>
                <TableCell className="font-medium  text-dark-blue fsize-14   d-flex justify-content-center position-relative">
                  <div className="ms-4">
                    <Radio
                      color="secondary"
                      id={`product-${row.id}`}
                      name={`product-${row.id}`}
                      checked={
                        row.id === selectedProduct?.id || false
                      }
                      onChange={() => {
                        setSelectedProduct(row);
                      }}
                    />
                  </div>
                </TableCell>
                <TableCell className="font-medium  text-dark-blue fsize-14 ">
                  {row.product.concatenated_name}
                </TableCell>
                <TableCell className="font-medium  text-dark-blue fsize-14  d-flex position-relative">
                  {row.product.super_product.product_category.name}
                </TableCell>
                <TableCell className="font-medium  text-orange  fsize-14 quantity ">
                  {row.price}
                  <span className="mx-2">
                    {offers.addEditPage.le}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          />
          <div className="d-flex justify-content-center py-3">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={meta.currentPage || page}
                handleChange={(event, page) => {
                  setPage(page);
                }}
                defaultPage={1}
              />
            )}
          </div>
          <div className="btn-submit">
            <Button
              label={
                <div className="d-flex align-items-center">
                  {offers.addEditPage.confirm}
                </div>
              }
              labelClass="fsize-16 text-white"
              className="w-25"
              disabled={!selectedProduct}
              onClick={() => {
                handleAddProduct(selectedProduct);
                onClose(false);
              }}
            />
          </div>
        </div>
      );
    }
  };
  return (
    <div className="add-offer-product">
      <div className="top-text-container mb-4">
        <p className="fsize-18">
          {wholesalerUserName}
          <span className="mx-2">{offers.addEditPage.products}</span>
        </p>
        <p className="my-3 ms-5 text-start blue-quote">
          {offers.addEditPage.productsQuote}
        </p>
      </div>
      <div className="search-bar d-flex align-items-center ms-5 ">
        <SearchInput
          name="search"
          value={searchValue}
          onChange={(value) => {
            setPage(1);
            setSearchValue(value);
          }}
          placeholder={offers.addEditPage.searchPlaceholder}
          inputWrapperClass="w-50 px-0"
          inputClass="mt-2"
        />
        <Filter
          placeholder={offers.addEditPage.categories}
          options={categoriesOptions}
          name="Category"
          onChange={(value) => {
            setCategory(value);
            setPage(1);
          }}
          value={category}
          inputClassName="ms-4 "
          filterWrapperClass="mt-2 w-25"
        />
      </div>
      <div className="d-flex justify-content-center align-items-center">
        {renderModalBody()}
      </div>
    </div>
  );
};

export default SelectOfferProduct;
SelectOfferProduct.propTypes = {
  order_id: PropTypes.string,
  wholesaler_id: PropTypes.any,
  onClose: PropTypes.func,
  handleAddProduct: PropTypes.func,
  wholesalerUserName: PropTypes.string
};
