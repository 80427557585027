import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import messages from "../../../assets/locale/messages";
import { ARABIC_REGEX } from "../../../utils/Patterns";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import {
  addSuperProductRequest,
  editSuperProductRequest
} from "../../../store/SuperProducts/actions";

const CreateSuperProduct = ({
  closeModal,
  editMode,
  superProductName,
  product_category_id,
  page,
  searchValue,
  superProductId
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { superProducts } = messages[lang];
  const dispatch = useDispatch();
  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
    setValues
  } = useFormik({
    initialValues: {
      name: ""
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(superProducts.validations.superProductName)
        .matches(ARABIC_REGEX, superProducts.validations.arabic)
    }),
    onSubmit: (values) => {
      let superProductData = {};
      superProductData.name = values["name"].replace(/  +/g, " ");
      superProductData.product_category_id = product_category_id;
      if (editMode) {
        dispatch(
          editSuperProductRequest({
            data: superProductData,
            id: superProductId,
            product_category_id,
            page,
            searchValue
          })
        );
      } else {
        dispatch(
          addSuperProductRequest({
            data: superProductData,
            product_category_id,
            page
          })
        );
      }
      closeModal();
    }
  });

  useEffect(() => {
    if (editMode) {
      setValues({
        name: superProductName
      });
    }
  }, [editMode]);

  return (
    <div className="createFormContainer">
      <form noValidate onSubmit={handleSubmit}>
        <Input
          id="name"
          label={superProducts.superProductName}
          labelAdornment={
            <span className="text-bg-primary-mid fsize-14 font-reguler">
              {superProducts.arabicLang}
            </span>
          }
          name="name"
          type="text"
          placeholder={superProducts.placeholder}
          required
          onChange={(value) => {
            setFieldTouched("name");
            setFieldValue("name", value);
          }}
          value={values["name"]}
          inputClass="mb-1"
          labelClassName="font-medium mb-3 text-start"
          inputWrapperClass="my-4"
          isInputHasErr={!!(touched["name"] && errors["name"])}
          errMsg={errors["name"]}
        />
        <div className="d-flex justify-content-center mt-5 btn-container">
          <Button
            label={
              editMode ? superProducts.save : superProducts.create
            }
            type="submit"
            labelClass="fsize-14 text-white py-1 px-5"
            className="createBtn"
            disabled={!dirty || !isValid}
          />
        </div>
      </form>
    </div>
  );
};

CreateSuperProduct.propTypes = {
  closeModal: PropTypes.func,
  editMode: PropTypes.bool,
  superProductName: PropTypes.string,
  product_category_id: PropTypes.string,
  page: PropTypes.number,
  searchValue: PropTypes.string,
  superProductId: PropTypes.number
};

export default CreateSuperProduct;
