export const GET_SUPERPRODUCTS_REQUEST = "GET_SUPERPRODUCTS_REQUEST";
export const GET_SUPERPRODUCTS_RESPONSE =
  "GET_SUPERPRODUCTS_RESPONSE";

export const GET_SUPERPRODUCT_REQUEST = "GET_SUPERPRODUCT_REQUEST";
export const GET_SUPERPRODUCT_RESPONSE = "GET_SUPERPRODUCT_RESPONSE";

export const ADD_SUPERPRODUCT_REQUEST = "ADD_SUPERPRODUCT_REQUEST";

export const EDIT_SUPERPRODUCT_REQUEST = "EDIT_SUPERPRODUCT_REQUEST";
