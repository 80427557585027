import * as Yup from "yup";

import messages from "../../../assets/locale/messages";
import {
  EGYPT_MOBILEPHONE_REGEX,
  IBAN_REGEX,
  ARABIC_REGEX
} from "../../../utils/Patterns";

const lang = localStorage.getItem("lang") || "en";
const {
  addEdit: { validations }
} = messages[lang].Wholesalers;

export const ADD_EDIT_WHOLESALER_SCHEMA = Yup.object({
  wholesalerName: Yup.string()
    .required(validations.name)
    .matches(ARABIC_REGEX, validations.arabic),
  wholesalerEmail: Yup.string()
    .email(validations.validEmail)
    .required(validations.email),
  wholesalerPhone: Yup.string()
    .required(validations.phone)
    .matches(EGYPT_MOBILEPHONE_REGEX, validations.validPhone),
  wholesalerAddress: Yup.string()
    .required(validations.address)
    .matches(ARABIC_REGEX, validations.arabic),
  bankName: Yup.string().required(validations.bankName),
  bankBranch: Yup.string().required(validations.bankBranch),
  accountNumber: Yup.string().required(validations.accountNumber),
  ibanNumber: Yup.string().matches(
    IBAN_REGEX,
    validations.validIbanNumber
  ),
  incontactName: Yup.string()
    .required(validations.name)
    .matches(ARABIC_REGEX, validations.arabic),
  incontactEmail: Yup.string()
    .email(validations.validEmail)
    .required(validations.email),
  incontactPhone: Yup.string()
    .required(validations.phone)
    .matches(EGYPT_MOBILEPHONE_REGEX, validations.validPhone),
  delivery: Yup.array().test(
    "at-least-one-is-true",
    validations.delivery,
    (methods) => {
      return methods.some((method) => method.isChosen);
    }
  ),
  payment: Yup.array().test(
    "at-least-one-is-true",
    validations.payment,
    (methods) => {
      return methods.some((method) => method.isChosen);
    }
  )
});

export const getInitialValues = (wholesaler, deliveryMethodsList) => {
  return {
    wholesalerName: wholesaler?.username || "",
    wholesalerEmail: wholesaler?.email || "",
    wholesalerPhone: wholesaler?.phone_number || "",
    wholesalerAddress: wholesaler?.address || "",
    wholesalerLogo: wholesaler?.logo || "",
    bankName: wholesaler?.bank_name || "",
    bankBranch: wholesaler?.bank_branch || "",
    accountNumber: wholesaler?.bank_account_number || "",
    ibanNumber: wholesaler?.bank_iban || "",
    incontactName: wholesaler?.incontact_name || "",
    incontactEmail: wholesaler?.incontact_email || "",
    incontactPhone: wholesaler?.incontact_phone_number || "",
    delivery: deliveryMethodsList?.map((method) => {
      return {
        id: method.id,
        isChosen: wholesaler
          ? isMethodChosen(wholesaler.delivery_methods, method.id)
          : method.name === "direct",
        name:
          method.name === "direct"
            ? "direct"
            : method.name === "pickup"
            ? "pickup"
            : "serviceProvider"
      };
    }),
    payment: [
      {
        name: "cash",
        id: 1,
        isChosen: wholesaler
          ? isMethodChosen(wholesaler.payment_methods, 1)
          : false
      },
      {
        name: "online",
        id: 2,
        isChosen: wholesaler
          ? isMethodChosen(wholesaler.payment_methods, 2)
          : false
      }
    ]
  };
};

const isMethodChosen = (methodsArr, id) =>
  methodsArr?.findIndex((method) => method.id === id) !== -1;

export const formWholesalerData = (values) => {
  let wholesalerData = {};
  wholesalerData.username = values.wholesalerName;
  wholesalerData.email = values.wholesalerEmail;
  wholesalerData.phone_number =
    values.wholesalerPhone.length == 11
      ? values.wholesalerPhone.substring(1)
      : values.wholesalerPhone;
  wholesalerData.address = values.wholesalerAddress;
  wholesalerData.bank_name = values.bankName;
  wholesalerData.bank_branch = values.bankBranch;
  wholesalerData.bank_account_number = values.accountNumber;
  wholesalerData.bank_iban = values.ibanNumber;
  wholesalerData.incontact_name = values.incontactName;
  wholesalerData.incontact_email = values.incontactEmail;
  wholesalerData.incontact_phone_number =
    values.incontactPhone.length == 11
      ? values.incontactPhone.substring(1)
      : values.incontactPhone;
  wholesalerData.delivery_method_ids = values.delivery
    .filter((method) => method.isChosen)
    .map((method) => method.id);
  wholesalerData.payment_method_ids = values.payment
    .filter((method) => method.isChosen)
    .map((method) => method.id);

  return wholesalerData;
};
