import { put, call, takeEvery } from "redux-saga/effects";

import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import * as types from "./types";
import * as apis from "../../network/apis/banners";
import {
  getBannersResponse,
  getBannerResponse,
  getBannersRequest
} from "./actions";
import { setBannersRoute } from "../Routing/actions";
import { showHideSnackbar } from "../Snackbar/actions";
import messages from "../../assets/locale/messages";
import history from "../../routes/History";

export function* getBannersSaga({ payload }) {
  try {
    const response = yield call(apis.getBanners, payload);
    yield put(
      getBannersResponse({
        list: response.data.banners,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
    if (
      +response.headers["current-page"] >
      +response.headers["total-pages"]
    ) {
      yield put(
        getBannersRequest({
          ...payload,
          page: +response.headers["total-pages"]
        })
      );
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getBannerSaga({ payload }) {
  try {
    const response = yield call(apis.getBanner, payload);
    yield put(getBannerResponse(response.data.banner));
  } catch (err) {
    console.log(err);
  }
}

export function* addBannerSaga({ payload }) {
  try {
    yield call(apis.addBanner, payload.data);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].banners.createSuccess
      })
    );
    history.push(ROUTE_PATHS["banners"]);
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}

export function* editBannerSaga({ payload }) {
  try {
    yield call(apis.editBanner, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].banners.editSuccess
      })
    );
    const { page, filterStatus } = payload;
    yield put(
      getBannersRequest({
        items: 9,
        page,
        is_hidden: filterStatus?.value
      })
    );
    yield put(setBannersRoute(null));
    history.push(ROUTE_PATHS["banners"]);
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export function* deleteBannerSaga({ payload }) {
  try {
    yield call(apis.deleteBanner, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].banners.deleteSuccess
      })
    );
    const { page, filterStatus } = payload;
    yield put(
      getBannersRequest({
        items: 9,
        page,
        is_hidden: filterStatus?.value
      })
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}

export default function* BannersSagas() {
  yield takeEvery(types.GET_BANNERS_REQUEST, getBannersSaga);
  yield takeEvery(types.GET_BANNER_REQUEST, getBannerSaga);
  yield takeEvery(types.ADD_BANNER_REQUEST, addBannerSaga);
  yield takeEvery(types.EDIT_BANNER_REQUEST, editBannerSaga);
  yield takeEvery(types.DELETE_BANNER_REQUEST, deleteBannerSaga);
}
