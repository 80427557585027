import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Block } from "@material-ui/icons";

import messages from "../../../assets/locale/messages";
import Navbar from "../../../components/Navbar";
import Breadcrumb from "../../../components/Breadcrumb";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import {
  getRetailerRequest,
  getRetailerResponse,
  updateRetailerRequest
} from "../../../store/Retailers/actions";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { Exclamation } from "../../../utils/Icons/Exclamation";
import { formatDate } from "../../../utils/Helpers";
import "./ViewRetailer.scss";

const ViewRetailer = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { id } = useParams();
  const { viewRetailers } = messages[lang];
  const isLoading = useSelector((state) => state.loader);
  const retailer = useSelector((state) => state.retailers.retailer);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRetailerRequest(id));

    return () => {
      dispatch(getRetailerResponse(null));
    };
  }, [id]);

  const handleOpenModal = () => {
    setOpenBlockModal(true);
  };
  const handleCloseBlockModal = () => {
    setOpenBlockModal(false);
  };

  const renderBlockContent = () => {
    return (
      <div className="fsize-14 text-center">
        <p className="font-semibold text-dark">
          {retailer?.is_activated && retailer.has_ongoing_orders && (
            <>
              <p className="mb-0">
                {viewRetailers.modalBlockOngoingOrder1}
              </p>
              <p>{viewRetailers.modalBlockOngoingOrder2}</p>
            </>
          )}
          {retailer?.is_activated &&
            !retailer.has_ongoing_orders &&
            viewRetailers.modalBlock}
          {!retailer?.is_activated && viewRetailers.unBlockModal}
        </p>
      </div>
    );
  };

  const handlBlockRetailer = (isBlocked) => {
    dispatch(
      updateRetailerRequest({
        id: +id,
        data: { is_activated: !isBlocked }
      })
    );
    setOpenBlockModal(false);
  };

  return (
    <>
      <Navbar NavbarTitle={viewRetailers.RetailerDetails} />
      {!isLoading && retailer && (
        <div className="content-wrapper viewRetailer-page ">
          <div className="d-flex justify-content-between align-items-center ">
            <Breadcrumb
              linksArray={[
                {
                  link: ROUTE_PATHS["retailers"].replace(":id", id),
                  name: viewRetailers.Retailers
                },
                {
                  name: retailer.name || ""
                }
              ]}
              className="mb-4"
            />
            <Button
              outlined
              className="mb-4 block-btn"
              label={
                <span className="d-flex  align-items-center">
                  <Block />
                  <span className="ps-1 fsize-16">
                    {retailer?.is_activated
                      ? viewRetailers.blockRetailer
                      : viewRetailers.unBloclRetailer}
                  </span>
                </span>
              }
              onClick={handleOpenModal}
              labelClass="fsize-12   text-center bg-transparent"
            />
          </div>

          <div className="row">
            <div className="col-4 mb-4">
              <p className="mb-0">{viewRetailers.StoreName}</p>
              <p className="Retailer_storname fsize-16 font-semibold ">
                {retailer.storename}
              </p>
            </div>
            <div className="col-4 mb-4">
              <p className="mb-0">{viewRetailers.UserName}</p>
              <p className="Retailer_username fsize-16 font-semibold ">
                {retailer.username}
              </p>
            </div>
            <div className="col-4 mb-4">
              <p className="mb-0">{viewRetailers.JoiningDate}</p>
              <p className="Retailer_username fsize-16 font-semibold ">
                {formatDate(
                  retailer.created_at,
                  "(DD/MM/yyyy)  h:mm a"
                )}
              </p>
            </div>

            <div className="row">
              <div className="col-4 mb-4">
                <p className="mb-0">{viewRetailers.Phone}</p>
                <p className="Retailer_phone fsize-16 font-semibold ">
                  {retailer.country_code}
                  {retailer.phone_number}
                </p>
              </div>
              <div className="col-4 mb-4">
                <p className="mb-0">{viewRetailers.EmailAddress}</p>
                <p className="Retailer_email fsize-16 font-semibold ">
                  {retailer.email}
                </p>
              </div>
              {/* <div className="col-4">
                <p className="mb-0">{viewRetailers.address} </p>
                <p className="Retailer_address fsize-16 font-semibold ">
                  {retailer?.default_address?.building_number}
                  {retailer?.default_address?.street_name} ,
                  {retailer?.default_address?.area} ,
                  {retailer?.default_address?.additional_info}
                </p>
              </div> */}
            </div>
            <div className="row">
              <p>{viewRetailers.address} </p>
              <div className="col-6 d-flex align-items-center justify-content-between">
                <div>
                  <p>{viewRetailers.area}</p>
                  <p className="Retailer_address fsize-16 font-semibold ">
                    {retailer?.default_address?.area}
                  </p>
                </div>
                <div>
                  <p>{viewRetailers.street}</p>
                  <p className="Retailer_address fsize-16 font-semibold ">
                    {retailer?.default_address?.street_name}
                  </p>
                </div>

                <div>
                  <p>{viewRetailers.buildingNumber}</p>
                  <p className="Retailer_address fsize-16 font-semibold">
                    {retailer?.default_address?.building_number}
                  </p>
                </div>
                <div>
                  <p>{viewRetailers.sign}</p>
                  <p className="Retailer_address fsize-16 font-semibold ">
                    {retailer?.default_address?.additional_info}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        open={openBlockModal}
        onClose={handleCloseBlockModal}
        modalWrapperClass="block-modal"
        modalBody={
          <div className="">
            <div className="d-flex justify-content-center mb-2">
              {retailer?.has_ongoing_orders ? (
                <Exclamation />
              ) : (
                <Block fontSize="large" />
              )}
            </div>
            {renderBlockContent()}
          </div>
        }
        actionBtns={
          <div className="container px-4 d-flex align-items-center justify-content-center">
            <Button
              className="block mx-2 w-50 py-2"
              label={
                <span className="blockLabel">
                  {retailer?.is_activated
                    ? viewRetailers.block
                    : viewRetailers.unBlock}
                </span>
              }
              onClick={() => {
                handlBlockRetailer(retailer?.is_activated);
              }}
              labelClass="fsize-12   text-center "
            />
            <Button
              outlined
              className="mx-2 cancel w-50 py-2"
              label={
                <span className="cancelLabel">
                  {viewRetailers.cancel}
                </span>
              }
              onClick={() => {
                setOpenBlockModal(false);
              }}
              labelClass="fsize-12   text-center "
            />
          </div>
        }
      />
    </>
  );
};

export default ViewRetailer;
