import React from "react";
import PropTypes from "prop-types";

import "./BannerCard.scss";

const BannerCard = ({
  bannerImage,
  bannerAlt,
  actions,
  actionsElement,
  hideBanner
}) => {
  return (
    <div className="banner-card">
      {hideBanner && <div className="hide-banner">{hideBanner}</div>}
      <div className="d-flex justify-content-center mega-images-container">
        <div
          className="banner-image-container"
          style={{
            backgroundImage: `url(${bannerImage})`
          }}
        ></div>
        <img className="banner" src={bannerImage} alt={bannerAlt} />
      </div>
      {actions && (
        <div className="pt-4 pb-2 actions-container">
          {actionsElement}
        </div>
      )}
    </div>
  );
};

BannerCard.propTypes = {
  bannerImage: PropTypes.any,
  bannerAlt: PropTypes.string,
  actions: PropTypes.bool,
  actionsElement: PropTypes.element,
  hideBanner: PropTypes.element
};

export default BannerCard;
