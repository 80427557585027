import { put, call, takeEvery } from "redux-saga/effects";
import amplitude from "amplitude-js";

import * as apis from "../../network/apis/subProducts";
import {
  getSubProductsResponse,
  getSubProductResponse
} from "./actions";
import * as SubProductsTypes from "./types";
import messages from "../../assets/locale/messages";
import { showHideSnackbar } from "../Snackbar/actions";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import history from "../../routes/History";

export function* getSubProductsSaga({ payload }) {
  try {
    const response = yield call(apis.getSubProducts, payload);
    yield put(
      getSubProductsResponse({
        list: response.data.products,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export function* getSubProductSaga({ payload }) {
  try {
    const response = yield call(apis.getSubProduct, payload);
    yield put(getSubProductResponse(response.data.product));
    const eventProperties = {
      "product id": response.data.product.id,
      "product name": response.data.product.concatenated_name
    };

    amplitude
      .getInstance()
      .logEvent(
        "admin browse: view product details",
        eventProperties
      );
  } catch (err) {
    console.log(err);
  }
}

export function* addSubProductSaga({ payload }) {
  try {
    const { data } = payload;
    const response = yield call(apis.addSubProduct, data);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].subProducts.addEdit.addSuccess
      })
    );
    const eventProperties = {
      "product id": response.data.product.id,
      "product name": response.data.product.concatenated_name
    };

    amplitude
      .getInstance()
      .logEvent("admin update: product added", eventProperties);
    const { id, superId } = payload;
    history.push(
      ROUTE_PATHS["subProducts"]
        .replace(":id", id)
        .replace(":superId", superId)
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}

export function* editSubProductSaga({ payload }) {
  try {
    const response = yield call(apis.editSubProduct, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: `${messages["en"].subProducts.addEdit.editSuccess} ${
          response.data?.message ? `, ${response.data.message}` : ""
        }`
      })
    );
    const {
      id,
      superId,
      routerFlag,
      indicateThatProductWillBeDeactivated
    } = payload;

    const eventProperties = {
      "product id": response?.data?.product.id,
      "product name": response?.data?.product.concatenated_name
    };

    if (routerFlag) {
      history.push(ROUTE_PATHS.allProducts);
      if (indicateThatProductWillBeDeactivated) {
        amplitude
          .getInstance()
          .logEvent(
            "admin update: product deactivated",
            eventProperties
          );
      }
    } else {
      history.push(
        ROUTE_PATHS["subProducts"]
          .replace(":id", id)
          .replace(":superId", superId)
      );
      if (indicateThatProductWillBeDeactivated) {
        amplitude
          .getInstance()
          .logEvent(
            "admin update: product deactivated",
            eventProperties
          );
      }
    }
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data.message
      })
    );
  }
}

export default function* SubProductsSagas() {
  yield takeEvery(
    SubProductsTypes.GET_SUBPRODUCTS_REQUEST,
    getSubProductsSaga
  );
  yield takeEvery(
    SubProductsTypes.GET_SUBPRODUCT_REQUEST,
    getSubProductSaga
  );
  yield takeEvery(
    SubProductsTypes.ADD_SUBPRODUCT_REQUEST,
    addSubProductSaga
  );
  yield takeEvery(
    SubProductsTypes.EDIT_SUBPRODUCT_REQUEST,
    editSubProductSaga
  );
}
