import React from "react";
import PropTypes from "prop-types";

export const Users = ({ selectedIcon }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="16"
      fill="none"
      viewBox="0 0 22 16"
    >
      <path
        fill="#fff"
        fillOpacity={selectedIcon ? "1" : "0.5"}
        d="M10.949 10.54c-3.45 0-6.36.563-6.36 2.739s2.929 2.72 6.36 2.72c3.45 0 6.361-.564 6.361-2.74s-2.93-2.72-6.361-2.72zM10.949 8.467a4.207 4.207 0 004.21-4.233A4.206 4.206 0 0010.948 0a4.206 4.206 0 00-4.21 4.234 4.207 4.207 0 004.21 4.233zM20.088 5.22C20.692 2.842 18.92.707 16.664.707c-.245 0-.48.027-.71.073-.03.007-.064.022-.082.05-.02.034-.005.08.017.11a5.807 5.807 0 011.068 3.37 5.762 5.762 0 01-.984 3.241.158.158 0 00.106.244c.158.028.319.043.484.047 1.643.043 3.117-1.02 3.525-2.622zM21.81 10.816c-.302-.644-1.028-1.087-2.132-1.304-.52-.127-1.931-.308-3.243-.283-.02.002-.03.016-.032.025-.003.013.003.034.029.048.606.301 2.949 1.614 2.654 4.38-.012.12.084.224.203.206.576-.083 2.06-.403 2.52-1.402a1.922 1.922 0 000-1.67zM6.045.78a3.514 3.514 0 00-.71-.073C3.08.707 1.308 2.842 1.914 5.22c.406 1.602 1.88 2.665 3.524 2.622.165-.004.327-.02.483-.047a.158.158 0 00.107-.244 5.764 5.764 0 01-.984-3.24c0-1.252.39-2.416 1.068-3.372.022-.03.038-.075.016-.11C6.11.803 6.077.788 6.045.78zM2.322 9.512c-1.104.217-1.83.659-2.13 1.304a1.918 1.918 0 000 1.67c.46.998 1.943 1.32 2.52 1.401.119.018.214-.084.201-.205-.294-2.766 2.05-4.078 2.656-4.38.025-.015.03-.035.028-.049-.002-.009-.012-.022-.031-.024-1.313-.025-2.722.155-3.244.283z"
      ></path>
    </svg>
  );
};

Users.propTypes = {
  selectedIcon: PropTypes.bool
};
