import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Add, EditOutlined } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";

import messages from "../../assets/locale/messages";
import Navbar from "../../components/Navbar";
import EmptyState from "../../components/EmptyState";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import Breadcrumb from "../../components/Breadcrumb";
import Button from "../../components/Button";
import SearchInput from "../../components/SearchInput";
import Pagination from "../../components/Pagination";
import CategoryCard from "../../components/CategoryCard";
import Modal from "../../components/Modal";
import CreateSuperProduct from "./CreateSuperProduct";
import {
  getSuperProductsRequest,
  getSuperProductsResponse
} from "../../store/SuperProducts/actions";
import {
  getProductCategoryRequest,
  getProductCategoryResponse
} from "../../store/ProductCategories/actions";
import { setSuperProductsRoute } from "../../store/Routing/actions";
import "./SuperProducts.scss";

const superProducts = () => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const { superProducts, general } = messages[lang];
  const list = useSelector((state) => state.superProducts.list);
  const productCategory = useSelector(
    (state) => state.productCategories.productCategories
  );
  const meta = useSelector((state) => state.superProducts.meta);
  const isLoading = useSelector((state) => state.loader);
  const superProductsRoutingData = useSelector(
    (state) => state.routing.superProducts
  );

  const [searchValue, setSearchValue] = useState(
    superProductsRoutingData?.search || ""
  );
  const [openModal, setOpenModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editSuperProductName, setEditSuperProductName] =
    useState("");
  const [editSuperProductId, setEditSuperProductId] = useState(null);
  const [page, setPage] = useState(
    superProductsRoutingData?.page || 1
  );

  const getSuperProducts = (pageNumber, search) => {
    dispatch(
      getSuperProductsRequest({
        page: pageNumber,
        items: 10,
        product_category_id: id,
        name: search
      })
    );
  };

  useEffect(() => {
    if (superProductsRoutingData) {
      dispatch(setSuperProductsRoute(null));
    }
  }, [superProductsRoutingData]);

  useEffect(() => {
    dispatch(getProductCategoryRequest(id));
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(
        getSuperProductsResponse({
          list: [],
          meta: {}
        })
      );
      dispatch(getProductCategoryResponse(null));
    };
  }, []);

  useEffect(() => {
    getSuperProducts(page, searchValue);
  }, [page]);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      setPage(superProductsRoutingData?.page || 1);
      getSuperProducts(
        superProductsRoutingData?.page || 1,
        searchValue
      );
    }
  }, [searchValue]);

  const handleCreate = () => {
    setOpenModal(true);
  };

  const handleCloseCreateModal = () => {
    setIsEditMode(false);
    setOpenModal(false);
  };

  const handleEditClick = (superProductName, superProductId) => {
    setEditSuperProductName(superProductName);
    setEditSuperProductId(superProductId);
    setIsEditMode(true);
    setOpenModal(true);
  };

  const handleViewProducts = (superId) => {
    dispatch(
      setSuperProductsRoute({
        page,
        search: searchValue
      })
    );
    history.push(
      ROUTE_PATHS.subProducts
        .replace(":id", id)
        .replace(":superId", superId)
    );
  };

  const breadcrumb = () => (
    <Breadcrumb
      linksArray={[
        {
          link: ROUTE_PATHS["productCategories"],
          name: superProducts.categories
        },
        {
          name: productCategory?.name || "",
          isArabic: true
        }
      ]}
    />
  );

  const superProductsList = () => {
    if (!isLoading && list.length == 0 && !searchValue) {
      return (
        <>
          <Navbar NavbarTitle={superProducts.title} />
          <div className="bg-white m-4 py-4 px-5">
            {breadcrumb()}
            <div className="warehouses-page d-flex justify-content-center align-items-center flex-column">
              <EmptyState
                subTitle={
                  <span>
                    {superProducts.emptyState.subTitleOne}
                    <span className="font-cairo-regular text-dark mx-2">
                      {/* here */}
                      &quot;{productCategory?.name}&quot;
                    </span>
                    {superProducts.emptyState.subTitleTwo}
                  </span>
                }
                description={superProducts.emptyState.description}
                ButtonLabel={superProducts.emptyState.buttonLabel}
                handelCreate={handleCreate}
              />
            </div>
          </div>
        </>
      );
    } else if (!isLoading && searchValue && list.length == 0) {
      return (
        <>
          <Navbar NavbarTitle={superProducts.title}>
            <Button
              label={
                <span className="d-flex align-items-center">
                  <Add fontSize="large" />
                  <span className="ps-1">
                    {superProducts.addSuperProduct}
                  </span>
                </span>
              }
              labelClass="fsize-16 text-white pe-2"
              onClick={handleCreate}
            />
          </Navbar>
          <div className="d-flex justify-content-end pe-4 mt-3">
            <SearchInput
              name="search"
              value={searchValue}
              onChange={(value) => {
                setSearchValue(value);
              }}
              placeholder={superProducts.search}
            />
          </div>
          <div className="bg-white m-4 py-4 px-5">
            {breadcrumb()}
            <div className="warehouses-page d-flex justify-content-center align-items-center flex-column">
              <EmptyState
                subTitle={general.noSearch}
                showActionButton={false}
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div>
          <Navbar NavbarTitle={superProducts.title}>
            <Button
              label={
                <span className="d-flex align-items-center">
                  <Add fontSize="large" />
                  <span className="ps-1">
                    {superProducts.addSuperProduct}
                  </span>
                </span>
              }
              labelClass="fsize-16 text-white pe-2"
              onClick={handleCreate}
            />
          </Navbar>
          <div className="d-flex justify-content-end pe-4 mt-3">
            <SearchInput
              name="search"
              value={searchValue}
              onChange={(value) => {
                setSearchValue(value);
              }}
              placeholder={superProducts.search}
            />
          </div>
          <div className="bg-white mx-4 my-3 py-4 px-5 table-list">
            {breadcrumb()}
            <div className="super-products-list-container d-flex flex-wrap justify-content-start">
              {list.map((superProduct) => (
                <div
                  key={superProduct.id}
                  className="p-3 super-product-card"
                >
                  <CategoryCard
                    categoryName={superProduct.name}
                    handleEditClick={() => {
                      handleEditClick(
                        superProduct.name,
                        superProduct.id
                      );
                    }}
                    editText={superProducts.rename}
                    handleView={() => {
                      handleViewProducts(superProduct.id);
                    }}
                    viewBtnText={superProducts.viewSubProducts}
                    EditIcon={
                      <EditOutlined
                        color="primary"
                        fontSize="medium"
                        className="opacity_7"
                      />
                    }
                  />
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-center py-3">
              {meta.pages > 1 && (
                <Pagination
                  count={meta.pages}
                  page={meta.currentPage || page}
                  handleChange={(event, page) => {
                    setPage(page);
                  }}
                  defaultPage={1}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {superProductsList()}
      <Modal
        open={openModal}
        onClose={handleCloseCreateModal}
        modalBody={
          <CreateSuperProduct
            closeModal={handleCloseCreateModal}
            editMode={isEditMode}
            superProductName={editSuperProductName}
            product_category_id={id}
            page={page}
            searchValue={searchValue}
            superProductId={editSuperProductId}
          />
        }
      />
    </>
  );
};

export default superProducts;
