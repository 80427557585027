import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Snackbar as CustomizedSnackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import { showHideToast } from "../../store/ToastMessage/actions";
import {
  handleNotificationsClickActions,
  setNotificationSeen
} from "../../utils/Helpers";
import AlertIcon from "../../utils/Icons/Alert";
import "./ToastMessage.scss";

const ToastMessage = () => {
  const dispatch = useDispatch();

  const [msgsArr, setMsgsArr] = useState([]);
  const notificationsList = useSelector(
    (state) => state.notifications.fullList
  );
  const toastMessages = useSelector((state) => state.ToastMessage);

  useEffect(() => {
    if (toastMessages?.title || toastMessages?.body) {
      setMsgsArr([
        ...msgsArr,
        {
          title: toastMessages?.title,
          body: toastMessages?.body,
          id: notificationsList?.length + 1,
          action_id: toastMessages?.action_id,
          click_action: toastMessages?.click_action,
          seen: toastMessages?.seen
        }
      ]);
    }
  }, [toastMessages?.title, toastMessages?.body]);

  useEffect(() => {
    if (msgsArr.length > 4) {
      msgsArr.shift();
      setMsgsArr(msgsArr);
    }
  }, [msgsArr]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      showHideToast({
        isOpen: false,
        title: toastMessages?.title,
        body: toastMessages?.body
      })
    );
  };

  const handleClickNotification = (message, index) => {
    handleNotificationsClickActions({
      id: message.id,
      click_action: message.click_action,
      action_id: message.action_id,
      seen: message.seen
    }),
      setNotificationSeen(message.id);
    msgsArr.splice(index, 1);
    setMsgsArr([...msgsArr]);
  };

  return (
    <div className="toast-msg">
      {msgsArr?.map((msg, index) => (
        <CustomizedSnackbar
          open={toastMessages.showToast}
          autoHideDuration={10000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          key={index}
          id={msg.id}
          className={`alert_no_${index + 1}`}
          onClick={() => {
            handleClickNotification(msg, index);
          }}
        >
          <MuiAlert elevation={6} onClose={handleClose} action={""}>
            <p className="title ">
              <AlertIcon />
              <span className="fsize-16 ps-3 font-semibold">
                {msg.title}
              </span>
            </p>
            <p className="ms-5 fsize-16">{msg.body}</p>
          </MuiAlert>
        </CustomizedSnackbar>
      ))}
    </div>
  );
};

export default ToastMessage;
