import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Swiper } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import { Block } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

import messages from "../../assets/locale/messages";
import "./ProductCard.scss";

SwiperCore.use([Navigation]);

const ProductCardTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.tooltip.backgroundGrey,
    color: theme.tooltip.backgroundBlue,
    fontSize: "1rem"
  },
  arrow: {
    color: theme.tooltip.backgroundGrey,
    fontSize: "1rem"
  }
}))(Tooltip);

const ProductCard = ({
  prdImgs,
  prdNameParent,
  prdNameChild,
  prdCategory,
  prdDescription,
  prdSpecifications,
  prdPrice,
  prdQuent,
  actions,
  actionsElement,
  deactivated,
  initialSlide,
  showDesc,
  isOutOfStock,
  viewProduct,
  hasOffer,
  handleViewProduct,
  insideWholesalerProducts,
  orderProductHasOffer,
  product
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    productCard: {
      description,
      category,
      price,
      quantity,
      noProducts,
      outOfStock
    }
  } = messages[lang].subProducts;
  const { general } = messages[lang];

  const renderSpecs = (specsArray) =>
    specsArray?.map((spec, index) =>
      spec?.length ? (
        <span key={index} className="spec-label mx-1 mb-1 px-2 py-1">
          {spec}
        </span>
      ) : null
    );

  return (
    <div className="product-card py-4">
      <div>
        {viewProduct && (
          <div className="view-product">{viewProduct}</div>
        )}
        <div className="px-4">
          <Swiper
            className="mb-2 item-swiper "
            navigation={prdImgs?.length > 1}
            slidesPerView={1}
            initialSlide={initialSlide}
          >
            {prdImgs}
            {hasOffer && (
              <div className="offer-flag">{general.offers}</div>
            )}
            {orderProductHasOffer && (
              <div className="offer-flag offer-flag-order">
                {general.offer}
              </div>
            )}
          </Swiper>
        </div>
        {prdNameParent?.length > 20 ? (
          <ProductCardTooltip
            title={<div>{prdNameParent}</div>}
            placement="left"
            arrow
          >
            <div
              className={`text-dark-blue font-cairo-semibold fsize-14 item-parent-name px-4 ${
                !viewProduct &&
                insideWholesalerProducts &&
                "product-title-style"
              }`}
            >
              <span
                className="product-name"
                onClick={() => {
                  !viewProduct &&
                    insideWholesalerProducts &&
                    handleViewProduct();
                }}
              >
                {prdNameParent?.substring(0, 20) + "..."}
              </span>
            </div>
          </ProductCardTooltip>
        ) : (
          <div
            className={`text-dark-blue font-cairo-semibold fsize-14 item-parent-name px-4 ${
              !viewProduct &&
              insideWholesalerProducts &&
              "product-title-style"
            }`}
          >
            <span
              className="product-name"
              onClick={() => {
                !viewProduct &&
                  insideWholesalerProducts &&
                  handleViewProduct();
              }}
            >
              {prdNameParent}
            </span>
          </div>
        )}

        <div className="text-dark-blue font-cairo-medium fsize-14 my-1 item-child-name row px-4">
          {prdNameChild}
        </div>
      </div>
      <div className="details-container my-auto px-4 pb-2">
        {showDesc && (
          <div className="mt-2 font-cairo-regular row">
            <div className=" fsize-10 col-3 pt-02 text-product-card-dark ps-0">
              {prdDescription && prdDescription?.length > 40 ? (
                <ProductCardTooltip
                  title={
                    <div className="Tooltip-desc">
                      {prdDescription}
                    </div>
                  }
                  placement="left"
                  arrow
                >
                  <span>{description}</span>
                </ProductCardTooltip>
              ) : (
                <span>{description}</span>
              )}
            </div>
            <div className=" fsize-12 col-9 pe-0 text-product-card-dark  ">
              {!prdDescription || prdDescription?.length == 0
                ? noProducts
                : prdDescription?.length < 40
                ? prdDescription
                : prdDescription.substring(0, 40) + "..."}
            </div>
          </div>
        )}

        {prdCategory && (
          <div className="mt-2 font-cairo-regular row">
            <div className="row align-items-center">
              <div className="col-3 fsize-10  ps-0 text-product-card-text">
                {prdCategory && prdCategory?.length > 40 ? (
                  <ProductCardTooltip
                    title={
                      <div className="Tooltip-desc">
                        {prdCategory}
                      </div>
                    }
                    placement="left"
                    arrow
                  >
                    <span>{category}</span>
                  </ProductCardTooltip>
                ) : (
                  <span>{category}</span>
                )}
              </div>
              <div className="text-product-card-blue col-9 fsize-10">
                {prdCategory?.length < 40
                  ? prdCategory
                  : prdCategory.substring(0, 40) + "..."}
              </div>
            </div>
          </div>
        )}
        {prdPrice && (
          <div className="mt-2 font-cairo-regular d-flex justify-content-between align-items-center   product-card-price">
            <div className=" fsize-14  ps-0 text-product-card-text font-light">
              {price}
            </div>
            <div className="d-flex direction-ltr pe-0 text-orange fsize-14 font-cairo-semibold">
              {orderProductHasOffer && (
                <div className="direction-ltr">
                  <span>{product.new_price_per_product}</span>
                  <span className="me-3"> {general.le}</span>
                </div>
              )}
              <div
                className={`${
                  orderProductHasOffer &&
                  "font-cairo-regular  old-price-style"
                }`}
              >
                <span>{prdPrice}</span>
                <span> {general.le}</span>
              </div>
            </div>
          </div>
        )}
        {prdQuent && (
          <div className="mt-2 font-cairo-regular d-flex justify-content-between align-items-center  product-card-price">
            <div className=" fsize-14  ps-0 text-product-card-text font-light">
              {quantity}
            </div>
            <div className=" pe-0 text-product-card-dark fsize-14 font-cairo-semibold">
              {prdQuent}
            </div>
          </div>
        )}
        {prdSpecifications && (
          <div className="mt-2 fsize-10 text-product-card-text font-cairo-regular row">
            <div className="col d-flex flex-wrap">
              {renderSpecs(prdSpecifications)}
            </div>
          </div>
        )}
      </div>
      {actions && (
        <div className="mt-2  actions-wrapper px-3">
          {actionsElement}
        </div>
      )}
      {deactivated && (
        <div className="deactivated-icon">
          <Block color="primary" fontSize="large" />
        </div>
      )}
      {isOutOfStock && (
        <div className="out-of-stock">{outOfStock}</div>
      )}
    </div>
  );
};

ProductCard.propTypes = {
  prdImgs: PropTypes.array,
  prdNameParent: PropTypes.string,
  prdNameChild: PropTypes.string,
  prdCategory: PropTypes.string,
  prdDescription: PropTypes.string,
  prdSpecifications: PropTypes.array,
  prdPrice: PropTypes.string,
  prdQuent: PropTypes.string,
  actions: PropTypes.bool,
  showDesc: PropTypes.bool,
  deactivated: PropTypes.bool,
  actionsElement: PropTypes.element,
  initialSlide: PropTypes.number,
  isOutOfStock: PropTypes.bool,
  viewProduct: PropTypes.element,
  hasOffer: PropTypes.bool,
  handleViewProduct: PropTypes.func,
  insideWholesalerProducts: PropTypes.bool,
  orderProductHasOffer: PropTypes.bool,
  product: PropTypes.object
};

ProductCard.defaultProps = {
  showDesc: true
};
export default ProductCard;
