import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Add } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { Edit, Delete } from "@material-ui/icons";
import debounce from "lodash.debounce";

import messages from "../../assets/locale/messages";
import {
  getProductCategoriesRequest,
  deleteProductCategoryRequest
} from "../../store/ProductCategories/actions";
import { setCategoriesRoute } from "../../store/Routing/actions";
import Navbar from "../../components/Navbar";
import EmptyState from "../../components/EmptyState";
import Button from "../../components/Button";
import SearchInput from "../../components/SearchInput";
import Pagination from "../../components/Pagination";
import CategoryCard from "../../components/CategoryCard";
import Modal from "../../components/Modal";
import CreateCategory from "./CreateCategory";
import DeleteConfirmation from "./DeleteConfirmation";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import "./productCategories.scss";

const ProductCategories = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const lang = useSelector((state) => state.locale.lang);
  const { productCategories, general } = messages[lang];
  const list = useSelector((state) => state.productCategories.list);
  const meta = useSelector((state) => state.productCategories.meta);
  const isLoading = useSelector((state) => state.loader);
  const categoriesRoutingData = useSelector(
    (state) => state.routing.categories
  );

  const [page, setPage] = useState(categoriesRoutingData?.page || 1);
  const [openCreate, setOpenCreate] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [editCategoryName, setEditCategoryName] = useState("");
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);
  const [searchValue, setSearchValue] = useState(
    categoriesRoutingData?.search || ""
  );
  const [searchQuery, setSearchQuery] = useState(
    categoriesRoutingData?.search || ""
  );

  const getProductCategories = (pageNumber, name) => {
    dispatch(
      getProductCategoriesRequest({
        page: pageNumber,
        items: 12,
        name
      })
    );
  };

  const updateSearchQuery = () => {
    setSearchValue(searchQuery);
  };
  const delayedSearchQuery = useCallback(
    debounce(updateSearchQuery, 500),
    [searchQuery]
  );
  useEffect(() => {
    delayedSearchQuery();
    // Cancel the debounce on useEffect cleanup.
    return delayedSearchQuery.cancel;
  }, [searchQuery, delayedSearchQuery]);

  useEffect(() => {
    if (categoriesRoutingData) {
      dispatch(setCategoriesRoute(null));
    }
  }, [categoriesRoutingData]);

  useEffect(() => {
    getProductCategories(page, searchValue);
  }, [page]);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      setPage(categoriesRoutingData?.page || 1);
      getProductCategories(
        categoriesRoutingData?.page || 1,
        searchValue
      );
    }
  }, [searchValue]);

  const handleCreate = () => {
    setOpenCreate(true);
  };

  const handleEditClick = (categoryName, categoryId) => {
    setEditCategoryName(categoryName);
    setEditCategoryId(categoryId);
    setEditCategory(true);
    setOpenCreate(true);
  };

  const handleDeleteClick = (categoryIdToDelete) => {
    setDeleteCategoryId(categoryIdToDelete);
    setOpenDeleteConfirm(true);
  };

  const handleViewProducts = (categoryId) => {
    dispatch(
      setCategoriesRoute({
        page,
        search: searchValue
      })
    );
    history.push(
      ROUTE_PATHS.superProducts.replace(":id", categoryId)
    );
  };

  const handleCloseCreateModal = () => {
    setEditCategory(false);
    setOpenCreate(false);
  };

  const handleCloseDeleteConfirmModal = () => {
    setOpenDeleteConfirm(false);
  };

  const confirmDeleteBtn = () => {
    dispatch(
      deleteProductCategoryRequest({
        id: deleteCategoryId,
        page
      })
    );
    setOpenDeleteConfirm(false);
  };

  const productCategoriesList = () => {
    if (!isLoading && list.length == 0 && !searchValue) {
      return (
        <>
          <Navbar NavbarTitle={productCategories.title} />

          <div className=" bg-white warehouses-page d-flex justify-content-center align-items-center flex-column m-4">
            <EmptyState
              subTitle={productCategories.emptyState.subTitle}
              description={productCategories.emptyState.description}
              ButtonLabel={productCategories.emptyState.buttonLabel}
              handelCreate={handleCreate}
            />
          </div>
        </>
      );
    } else if (!isLoading && searchValue && list.length == 0) {
      return (
        <>
          <Navbar NavbarTitle={productCategories.title}>
            <Button
              label={
                <span className="d-flex align-items-center">
                  <Add fontSize="large" />
                  <span className="ps-1">
                    {productCategories.addCategory}
                  </span>
                </span>
              }
              labelClass="fsize-16 text-white pe-2"
              onClick={handleCreate}
            />
          </Navbar>
          <div className="d-flex justify-content-end pe-4 mt-3">
            <SearchInput
              name="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder={productCategories.search}
            />
          </div>
          <div className=" bg-white warehouses-page d-flex justify-content-center align-items-center flex-column m-4">
            <EmptyState
              subTitle={general.noSearch}
              showActionButton={false}
            />
          </div>
        </>
      );
    } else {
      return (
        <div>
          <Navbar NavbarTitle={productCategories.title}>
            <Button
              label={
                <span className="d-flex align-items-center">
                  <Add fontSize="large" />
                  <span className="ps-1">
                    {productCategories.addCategory}
                  </span>
                </span>
              }
              labelClass="fsize-16 text-white pe-2"
              onClick={handleCreate}
            />
          </Navbar>
          <div className="d-flex justify-content-end pe-4 mt-3">
            <SearchInput
              name="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder={productCategories.search}
            />
          </div>
          <div className="bg-white mx-4 my-3 table-list">
            <div className="product-categories-list-container">
              <div className="container">
                <div className="row">
                  {list.map((category) => (
                    <div
                      className="col-sm-6 col-md-4 col-lg-3 my-3"
                      key={category.id}
                    >
                      <CategoryCard
                        categoryName={category.name}
                        handleEditClick={() => {
                          handleEditClick(category.name, category.id);
                        }}
                        editText={productCategories.rename}
                        handleDeleteClick={() => {
                          handleDeleteClick(category.id);
                        }}
                        handleView={() => {
                          handleViewProducts(category.id);
                        }}
                        isEditable={
                          category.name !== productCategories.offers
                        }
                        viewBtnText={productCategories.viewProducts}
                        EditIcon={
                          <Edit
                            color="secondary"
                            fontSize="medium"
                            className="opacity_7"
                          />
                        }
                        DeleteIcon={
                          <Delete
                            fontSize="medium"
                            className="pointer text-red"
                          />
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center py-3">
              {meta.pages > 1 && (
                <Pagination
                  count={meta.pages}
                  page={meta.currentPage || page}
                  handleChange={(event, page) => {
                    setPage(page);
                  }}
                  defaultPage={1}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {productCategoriesList()}
      <Modal
        open={openCreate}
        onClose={handleCloseCreateModal}
        modalBody={
          <CreateCategory
            handleClose={handleCloseCreateModal}
            editMode={editCategory}
            categoryName={editCategoryName}
            categoryId={editCategoryId}
            page={page}
            searchValue={searchValue}
          />
        }
      />

      <Modal
        open={openDeleteConfirm}
        onClose={handleCloseDeleteConfirmModal}
        modalBody={<DeleteConfirmation />}
        actionBtns={
          <div className="container px-4">
            <div className="row">
              <div className="col-md-6">
                <div className=" mx-2 d-flex justify-content-center align-items-center">
                  <Button
                    label={
                      productCategories.deleteConfirmation.button
                    }
                    type="button"
                    labelClass="fsize-14 text-white"
                    onClick={confirmDeleteBtn}
                    block={true}
                    className="py-2"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="mx-2 d-flex justify-content-center align-items-center pointer"
                  onClick={handleCloseDeleteConfirmModal}
                >
                  <div>
                    <p className="fsize-14 text-orange py-2">
                      {productCategories.deleteConfirmation.cancel}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default ProductCategories;
