import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Add } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { setOffersRoute } from "../../store/Routing/actions";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import messages from "../../assets/locale/messages";
import Navbar from "../../components/Navbar";
import Button from "../../components/Button";
import OffersList from "./OffersList";

const Offers = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const { offers } = messages[lang];

  useEffect(() => {
    return dispatch(setOffersRoute(null));
  }, []);

  return (
    <div className="offers-page-container">
      <Navbar NavbarTitle={offers.list.topTitle}>
        <Button
          label={
            <span className="d-flex align-items-center">
              <Add fontSize="large" />
              <span className="ps-1">{offers.list.add}</span>
            </span>
          }
          labelClass="fsize-16 text-white pe-2"
          onClick={() => {
            history.push(ROUTE_PATHS.addOffer);
          }}
        />
      </Navbar>
      <OffersList />
    </div>
  );
};

export default Offers;
