import messages from "../assets/locale/messages";
import { ROUTE_PATHS } from "./RoutesPaths";

export const dayTime = {
  am: "AM",
  pm: "PM"
};

export const orderStatus = {
  pending: "pending",
  inProgress: "in_progress",
  outForDelivery: "out_for_delivery",
  delivered: "delivered",
  cancelled: "cancelled",
  rejected: "rejected"
};

export const orderStatusOptions = [
  {
    id: 1,
    label: messages["en"].orders.filterOrder.pending,
    value: orderStatus.pending
  },
  {
    id: 2,
    label: messages["en"].orders.filterOrder.in_progress,
    value: orderStatus.inProgress
  },
  {
    id: 3,
    label: messages["en"].orders.filterOrder.out_for_delivery,
    value: orderStatus.outForDelivery
  },
  {
    id: 4,
    label: messages["en"].orders.filterOrder.delivered,
    value: orderStatus.delivered
  },
  {
    id: 5,
    label: messages["en"].orders.filterOrder.cancelled,
    value: orderStatus.cancelled
  },
  {
    id: 6,
    label: messages["en"].orders.filterOrder.rejected,
    value: orderStatus.rejected
  }
];

export const acceptedImagesFilesTypes = [
  "image/jpeg",
  "image/png",
  "image/bmp"
];

export const acceptedImagesFilesMaxSize = 2097152;

export const notificationsActionsList = [
  {
    action: "open_order",
    route: ROUTE_PATHS.viewOrder
  }
];

export const promoCodeStatus = [
  {
    id: 1,
    value: true,
    label: messages["en"].promocodes.active
  },
  {
    id: 2,
    value: false,
    label: messages["en"].promocodes.inActive
  }
];

export const promoCodeOfferdBy = [
  {
    id: 1,
    value: process.env.REACT_APP_PROMO_CODE_SERVICE_PROVIDER_OWNER,
    label: messages["en"].promocodes.serviceProvider
  },
  {
    id: 2,
    value: "wholesaler",
    label: messages["en"].promocodes.wholesaler
  }
];

export const bannersStatus = [
  {
    id: 1,
    value: true,
    label: messages["en"].banners.lables.hidden
  },
  {
    id: 2,
    value: false,
    label: messages["en"].banners.lables.displayed
  }
];

export const offersStatus = [
  {
    id: 1,
    value: "active",
    label: messages["en"].offers.offersList.filterStatus.active
  },
  {
    id: 2,
    value: "pending",
    label: messages["en"].offers.offersList.filterStatus.pending
  },
  {
    id: 3,
    value: "expired",
    label: messages["en"].offers.offersList.filterStatus.expired
  }
];

export const offersSubStatus = [
  {
    id: 1,
    value: true,
    label: messages["en"].offers.offersList.filterSubStatus.enabled
  },
  {
    id: 2,
    value: false,
    label: messages["en"].offers.offersList.filterSubStatus.disabled
  }
];

export const offerActiveStatus = ["active"];

export const offerPendingStatus = ["pending"];

export const offerExpiredStatus = ["expired"];
