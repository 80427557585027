import { all } from "redux-saga/effects";

import AuthSagas from "./Auth/sagas";
import WholesalersSagas from "./Wholesalers/sagas";
import WarehousesSags from "./Warehouses/sagas";
import RetailersSagas from "./Retailers/sagas";
import ProductCategoriesSagas from "./ProductCategories/sagas";
import SuperProductsSagas from "./SuperProducts/sagas";
import SubProductsSagas from "./SubProducts/sagas";
import OrdersSagas from "./Orders/sagas";
// import NotificationsSagas from "./Notifications/sagas";
import PromoCodesSagas from "./PromoCodes/sagas";
import BannersSagas from "./Banners/sagas";
import OffersSagas from "./Offers/sagas";

export default function* rootSaga() {
  yield all([
    AuthSagas(),
    WholesalersSagas(),
    WarehousesSags(),
    RetailersSagas(),
    ProductCategoriesSagas(),
    SuperProductsSagas(),
    SubProductsSagas(),
    OrdersSagas(),
    // NotificationsSagas(),
    PromoCodesSagas(),
    BannersSagas(),
    OffersSagas()
  ]);
}
