import React, {
  useEffect
  // , useState
} from "react";
import { Router } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import amplitude from "amplitude-js";

import history from "../routes/History";
import Routes from "../routes/Routes";
import { setCurrentLang } from "../store/Lang/actions";
import messages from "../assets/locale/messages";
import Theme from "../utils/Theme";
import Snackbar from "./../components/Snackbar";
import Loader from "./../components/Loader";
import Layout from "../components/Layout";
import ToastMessage from "../components/ToastMessage";
import {
  clearFirebaseToken
  // generateFirebaseToken
  // ,subscribeOnPushNotifications
} from "../firebase/helpers";
// import { onMessageListener } from "../firebase/firebase";
// import { getNotificationsRequest } from "../store/Notifications/actions";
import "./App.scss";

const App = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const isLoading = useSelector((state) => state.loader);
  const isAuth = useSelector((state) => state.auth.login?.auth_token);
  // const notificationsList = useSelector(
  //   (state) => state.notifications.list
  // );
  // const [notificationsSubscribtion, setNotificationsSubscribtion] =
  //   useState(null);

  useEffect(() => {
    dispatch(setCurrentLang(lang));

    // generateFirebaseToken();
    // onMessageListener();

    // const subscribtion = subscribeOnPushNotifications(
    //   notificationsList[0]?.id
    // );
    // setNotificationsSubscribtion(subscribtion);
    // dispatch(
    //   getNotificationsRequest({
    //     items: 10,
    //     page: 1
    //   })
    // );
    return () => {
      clearFirebaseToken();
      // notificationsSubscribtion.unsubscribe();
    };
  }, []);

  // initialize Amplitude to the client

  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY);

  return (
    <IntlProvider
      locale={lang}
      messages={messages[lang]}
      defaultLocale="ar"
    >
      <ThemeProvider theme={Theme}>
        <Router history={history}>
          <Layout isAuth={!!isAuth}>{Routes}</Layout>
        </Router>
        <Snackbar />
        <ToastMessage />
      </ThemeProvider>
      {isLoading && <Loader />}
    </IntlProvider>
  );
};

export default App;
