import React from "react";
import PropTypes from "prop-types";
import MaterialCheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const Checkbox = ({
  label,
  checked,
  onChange,
  color,
  labelClass,
  id,
  disabled
}) => {
  return (
    <FormControlLabel
      control={
        <MaterialCheckbox
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          color={color}
          id={id}
          disabled={disabled}
        />
      }
      label={label}
      className={labelClass}
    />
  );
};

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  color: PropTypes.string,
  labelClass: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool
};

Checkbox.defaultProps = {
  color: "primary"
};

export default Checkbox;
