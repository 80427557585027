import { put, call, takeEvery } from "redux-saga/effects";

import * as apis from "../../network/apis/superProducts";
import {
  getSuperProductsResponse,
  getSuperProductResponse,
  getSuperProductsRequest
} from "./actions";
import * as SuperProductsTypes from "./types";
import messages from "../../assets/locale/messages";
import { showHideSnackbar } from "../Snackbar/actions";

export function* getSuperProductsSaga({ payload }) {
  try {
    const response = yield call(apis.getSuperProducts, payload);
    yield put(
      getSuperProductsResponse({
        list: response.data.super_products,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export function* getSuperProductSaga({ payload }) {
  try {
    const response = yield call(apis.getSuperProduct, payload);
    yield put(getSuperProductResponse(response.data.super_product));
  } catch (err) {
    console.log(err);
  }
}

export function* addSuperProductSaga({ payload }) {
  try {
    const { data } = payload;
    yield call(apis.addSuperProduct, data);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].superProducts.addSuccess
      })
    );
    const { page, product_category_id } = payload;
    yield put(
      getSuperProductsRequest({
        page,
        items: 10,
        product_category_id
      })
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}

export function* editSuperProductSaga({ payload }) {
  try {
    yield call(apis.editSuperProduct, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].superProducts.editSuccess
      })
    );
    const { page, product_category_id, searchValue } = payload;
    yield put(
      getSuperProductsRequest({
        page,
        name: searchValue,
        items: 10,
        product_category_id
      })
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}

export default function* SuperProductsSagas() {
  yield takeEvery(
    SuperProductsTypes.GET_SUPERPRODUCTS_REQUEST,
    getSuperProductsSaga
  );
  yield takeEvery(
    SuperProductsTypes.GET_SUPERPRODUCT_REQUEST,
    getSuperProductSaga
  );
  yield takeEvery(
    SuperProductsTypes.ADD_SUPERPRODUCT_REQUEST,
    addSuperProductSaga
  );
  yield takeEvery(
    SuperProductsTypes.EDIT_SUPERPRODUCT_REQUEST,
    editSuperProductSaga
  );
}
