import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import Tooltip from "@material-ui/core/Tooltip";

import messages from "../../../assets/locale/messages";
import EgyptFlag from "../../../assets/svgs/flag-egypt.svg";
import Button from "../../../components/Button";
import Navbar from "../../../components/Navbar";
import Input from "../../../components/Input";
import Checkbox from "../../../components/Checkbox";
import Upload from "../../../components/Upload";
import {
  ADD_EDIT_WHOLESALER_SCHEMA,
  getInitialValues,
  formWholesalerData
} from "./validations";
import {
  addWholesalerRequest,
  editWholesalerRequest,
  getWholesalerRequest,
  getWholesalerResponse,
  getDeliveryMethodsRequest
} from "../../../store/Wholesalers/actions";
import { uploadToS3, directories } from "../../../utils/S3";
import "./AddEditWholesaler.scss";

const AddEditWholesaler = () => {
  const lang = useSelector((state) => state.locale.lang);
  const wholesaler = useSelector(
    (state) => state.wholesalers.wholesaler
  );
  const deliveryMethodsList = useSelector(
    (state) => state.wholesalers.deliveryMethodsList
  );
  const {
    Wholesalers: {
      addEdit,
      addEdit: {
        pageTitle,
        details,
        name,
        email,
        phone,
        address,
        bank,
        bankName,
        bankBranch,
        account,
        iban,
        incontact,
        delivery,
        payment,
        allRequired,
        arabicLang,
        create,
        placeholders,
        logo,
        uploadLabel,
        profile,
        save
      }
    }
  } = messages[lang];
  const { conditions, filesAccepted } = messages[lang].general;

  const [img, setImg] = useState();
  // flag to upload image in edit mode if image changed only
  const [imgChanged, setImgChanged] = useState(false);

  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDeliveryMethodsRequest({
        page: -1
      })
    );
    if (id) {
      dispatch(getWholesalerRequest(id));
    }
    return () => {
      // remove wholesaler data to clean page
      dispatch(getWholesalerResponse(null));
    };
  }, []);

  useEffect(() => {
    if (wholesaler && id) {
      // set fields to be touched to show error messages directly
      setTouched({
        wholesalerName: true,
        wholesalerEmail: true,
        wholesalerPhone: true,
        wholesalerAddress: true,
        minOrderPrice: true,
        bankName: true,
        bankBranch: true,
        accountNumber: true,
        ibanNumber: true,
        incontactName: true,
        incontactPhone: true,
        incontactEmail: true,
        delivery: true,
        payment: true
      });
      setValues(getInitialValues(wholesaler, deliveryMethodsList));
    } else {
      setValues(getInitialValues(null, deliveryMethodsList));
    }
  }, [wholesaler, deliveryMethodsList]);

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
    setValues,
    setTouched
  } = useFormik({
    initialValues: getInitialValues(null, deliveryMethodsList),
    validationSchema: ADD_EDIT_WHOLESALER_SCHEMA,
    onSubmit: async (values) => {
      let wholesalerData = formWholesalerData(values);
      if (values.wholesalerLogo) {
        if (imgChanged) {
          const data = await uploadToS3(
            values.wholesalerLogo,
            // unique file name to avoid conflicts and without spaces as it makes errors
            `${new Date().getTime()}_${values.wholesalerLogo.name
              .replace(/\s/g, "")
              .replace(/(\(|\))/g, "")}`,
            directories.wholesalers_logos
          );
          wholesalerData.logo = data?.link;
        } else {
          wholesalerData.logo = values.wholesalerLogo;
        }
      }
      if (id) {
        dispatch(editWholesalerRequest({ data: wholesalerData, id }));
      } else {
        dispatch(addWholesalerRequest(wholesalerData));
      }
    }
  });

  return (
    <div className="add-edit-wholesaler-page">
      <Navbar NavbarTitle={id ? wholesaler?.username : pageTitle} />
      <form noValidate onSubmit={handleSubmit}>
        <div className="px-5 bg-white mx-4 my-4">
          <div className="py-4">
            <div className="font-medium text-dark fsize-18">
              {id ? profile : details}
              {!id && (
                <span className="font-reguler ms-3 text-bg-primary-mid fsize-14">
                  {allRequired}
                </span>
              )}
            </div>
            <div className="row mt-4">
              <div className="col-4">
                <Input
                  id="name"
                  label={name}
                  labelAdornment={
                    <span className="text-bg-primary-mid fsize-14 font-reguler">
                      {arabicLang}
                    </span>
                  }
                  name="wholesalerName"
                  type="text"
                  placeholder={placeholders.name}
                  required
                  onChange={(value) => {
                    setFieldTouched("wholesalerName");
                    setFieldValue("wholesalerName", value);
                  }}
                  value={values["wholesalerName"]}
                  inputWrapperClass="mb-4"
                  labelClassName="text-dark font-medium mb-2"
                  isInputHasErr={
                    !!(
                      touched["wholesalerName"] &&
                      errors["wholesalerName"]
                    )
                  }
                  errMsg={errors["wholesalerName"]}
                />
              </div>
              <div className="col-4">
                <Input
                  id="email"
                  label={email}
                  name="wholesalerEmail"
                  type="email"
                  placeholder={placeholders.email}
                  required
                  onChange={(value) => {
                    setFieldTouched("wholesalerEmail");
                    setFieldValue("wholesalerEmail", value);
                  }}
                  value={values["wholesalerEmail"]}
                  inputWrapperClass="mb-4"
                  labelClassName="text-dark font-medium mb-2"
                  isInputHasErr={
                    !!(
                      touched["wholesalerEmail"] &&
                      errors["wholesalerEmail"]
                    )
                  }
                  errMsg={errors["wholesalerEmail"]}
                />
              </div>
              <div className="col-4">
                <Input
                  id="phone"
                  label={phone}
                  name="wholesalerPhone"
                  type="tel"
                  placeholder={placeholders.phone}
                  required
                  onChange={(value) => {
                    setFieldTouched("wholesalerPhone");
                    if (value.split("").every((num) => !isNaN(num))) {
                      setFieldValue("wholesalerPhone", value);
                    }
                  }}
                  value={values["wholesalerPhone"]}
                  inputWrapperClass="mb-4"
                  labelClassName="text-dark font-medium mb-2"
                  startAdornment={
                    <div className="d-flex align-items-center ms-1">
                      <div className="flag-container-addEdit bg-gray-flag px-2 py-1">
                        <img
                          src={EgyptFlag}
                          alt="eg"
                          className="img-fluid"
                        />
                      </div>
                      <span className="ms-2 fsize-16 text-gray">
                        +20
                      </span>
                    </div>
                  }
                  isInputHasErr={
                    !!(
                      touched["wholesalerPhone"] &&
                      errors["wholesalerPhone"]
                    )
                  }
                  errMsg={errors["wholesalerPhone"]}
                />
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-4">
                <Input
                  id="address"
                  label={address}
                  labelAdornment={
                    <span className="text-bg-primary-mid fsize-14 font-reguler">
                      {arabicLang}
                    </span>
                  }
                  name="wholesalerAddress"
                  type="text"
                  placeholder={placeholders.address}
                  required
                  onChange={(value) => {
                    setFieldTouched("wholesalerAddress");
                    setFieldValue("wholesalerAddress", value);
                  }}
                  value={values["wholesalerAddress"]}
                  inputWrapperClass="mb-4"
                  labelClassName="text-dark font-medium mb-2"
                  isInputHasErr={
                    !!(
                      touched["wholesalerAddress"] &&
                      errors["wholesalerAddress"]
                    )
                  }
                  errMsg={errors["wholesalerAddress"]}
                />
              </div>

              <div className="col-4">
                <span className="text-dark font-medium">{logo}</span>
                <Tooltip
                  title={
                    <span className="fsize-12">{filesAccepted}</span>
                  }
                >
                  <span className="text-bg-primary-mid fsize-14 font-reguler ms-2">
                    {conditions}
                  </span>
                </Tooltip>

                <Upload
                  label={
                    <div className="pt-1 d-flex flex-column align-items-center">
                      <ImageOutlinedIcon fontSize="large" />
                      <span>{uploadLabel}</span>
                    </div>
                  }
                  onChange={(img, value) => {
                    setImg(img);
                    setImgChanged(true);
                    setFieldValue("wholesalerLogo", value);
                  }}
                  img={img || values["wholesalerLogo"]}
                  name="wholesalerLogo"
                />
              </div>
            </div>
          </div>
          <div className="pt-1">
            <div className="font-medium text-dark fsize-18">
              {bank}
            </div>
            <div className="row mt-4">
              <div className="col-4">
                <Input
                  id="bankName"
                  label={bankName}
                  name="bankName"
                  type="text"
                  placeholder={placeholders.bankName}
                  required
                  onChange={(value) => {
                    setFieldTouched("bankName");
                    setFieldValue("bankName", value);
                  }}
                  value={values["bankName"]}
                  inputWrapperClass="mb-4"
                  labelClassName="text-dark font-medium mb-2"
                  isInputHasErr={
                    !!(touched["bankName"] && errors["bankName"])
                  }
                  errMsg={errors["bankName"]}
                />
              </div>
              <div className="col-4">
                <Input
                  id="bankBranch"
                  label={bankBranch}
                  name="bankBranch"
                  type="text"
                  placeholder={placeholders.bankBranch}
                  required
                  onChange={(value) => {
                    setFieldTouched("bankBranch");
                    setFieldValue("bankBranch", value);
                  }}
                  value={values["bankBranch"]}
                  inputWrapperClass="mb-4"
                  labelClassName="text-dark font-medium mb-2"
                  isInputHasErr={
                    !!(touched["bankBranch"] && errors["bankBranch"])
                  }
                  errMsg={errors["bankBranch"]}
                />
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-8">
                <Input
                  id="account"
                  label={account}
                  name="accountNumber"
                  type="text"
                  placeholder={placeholders.account}
                  required
                  onChange={(value) => {
                    setFieldTouched("accountNumber");
                    setFieldValue("accountNumber", value);
                  }}
                  value={values["accountNumber"]}
                  inputWrapperClass="mb-4"
                  labelClassName="text-dark font-medium mb-2"
                  isInputHasErr={
                    !!(
                      touched["accountNumber"] &&
                      errors["accountNumber"]
                    )
                  }
                  errMsg={errors["accountNumber"]}
                />
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-8">
                <Input
                  id="iban"
                  label={iban}
                  name="ibanNumber"
                  type="text"
                  placeholder={placeholders.iban}
                  onChange={(value) => {
                    setFieldTouched("ibanNumber");
                    setFieldValue("ibanNumber", value);
                  }}
                  value={values["ibanNumber"]}
                  inputWrapperClass="mb-4"
                  labelClassName="text-dark font-medium mb-2"
                  isInputHasErr={
                    !!(touched["ibanNumber"] && errors["ibanNumber"])
                  }
                  errMsg={errors["ibanNumber"]}
                />
              </div>
            </div>
          </div>
          <div className="pt-4">
            <div className="font-medium text-dark fsize-18">
              {incontact}
            </div>
            <div className="row mt-4">
              <div className="col-4">
                <Input
                  id="name"
                  label={name}
                  labelAdornment={
                    <span className="text-bg-primary-mid fsize-14 font-reguler">
                      {arabicLang}
                    </span>
                  }
                  name="incontactName"
                  type="text"
                  placeholder={placeholders.name}
                  required
                  onChange={(value) => {
                    setFieldTouched("incontactName");
                    setFieldValue("incontactName", value);
                  }}
                  value={values["incontactName"]}
                  inputWrapperClass="mb-4"
                  labelClassName="text-dark font-medium mb-2"
                  isInputHasErr={
                    !!(
                      touched["incontactName"] &&
                      errors["incontactName"]
                    )
                  }
                  errMsg={errors["incontactName"]}
                />
              </div>
              <div className="col-4">
                <Input
                  id="email"
                  label={email}
                  name="incontactEmail"
                  type="email"
                  placeholder={placeholders.email}
                  required
                  onChange={(value) => {
                    setFieldTouched("incontactEmail");
                    setFieldValue("incontactEmail", value);
                  }}
                  value={values["incontactEmail"]}
                  inputWrapperClass="mb-4"
                  labelClassName="text-dark font-medium mb-2"
                  isInputHasErr={
                    !!(
                      touched["incontactEmail"] &&
                      errors["incontactEmail"]
                    )
                  }
                  errMsg={errors["incontactEmail"]}
                />
              </div>
              <div className="col-4">
                <Input
                  id="phone"
                  label={phone}
                  name="incontactPhone"
                  type="tel"
                  placeholder={placeholders.phone}
                  required
                  onChange={(value) => {
                    setFieldTouched("incontactPhone");
                    if (value.split("").every((num) => !isNaN(num))) {
                      setFieldValue("incontactPhone", value);
                    }
                  }}
                  value={values["incontactPhone"]}
                  inputWrapperClass="mb-4"
                  labelClassName="text-dark font-medium mb-2"
                  startAdornment={
                    <div className="d-flex align-items-center ms-1">
                      <div className="flag-container-addEdit bg-gray-flag px-2 py-1">
                        <img
                          src={EgyptFlag}
                          alt="eg"
                          className="img-fluid"
                        />
                      </div>
                      <span className="ms-2 fsize-16 text-gray">
                        +20
                      </span>
                    </div>
                  }
                  isInputHasErr={
                    !!(
                      touched["incontactPhone"] &&
                      errors["incontactPhone"]
                    )
                  }
                  errMsg={errors["incontactPhone"]}
                />
              </div>
            </div>
          </div>
          <div className="pt-4 d-flex">
            <div className="col-6">
              <div className="font-medium text-dark fsize-18">
                {delivery}
                <span className="err-asterisk">*</span>
              </div>
              <span className="fsize-12 text-error font-medium">
                {touched["delivery"] && errors["delivery"]}
              </span>
              <div className="row mt-4">
                {values.delivery?.map((method, index) => (
                  <div className="col" key={index}>
                    <Checkbox
                      id={method.name}
                      color="secondary"
                      label={addEdit[method.name]}
                      labelClass="font-reguler text-dark fsize-16 checkbox-addwholesaler"
                      name={`delivery[${index}]`}
                      checked={values.delivery[index].isChosen}
                      onChange={(checked) => {
                        setFieldTouched("delivery");
                        setFieldValue(
                          `delivery[${index}].isChosen`,
                          checked
                        );
                      }}
                      disabled={index === 0}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="pt-4 d-flex mt-4">
            <div className="col-6">
              <div className="font-medium text-dark fsize-18">
                {payment}
                <span className="err-asterisk">*</span>
              </div>
              <span className="fsize-12 text-error font-medium">
                {touched["payment"] && errors["payment"]}
              </span>
              <div className="row mt-4">
                {values.payment.map((method, index) => (
                  <div className="col-4" key={index}>
                    <Checkbox
                      id={method.name}
                      color="secondary"
                      label={addEdit[method.name]}
                      labelClass="font-reguler text-dark fsize-16 checkbox-addwholesaler"
                      name={`payment[${index}]`}
                      checked={values.payment[index].isChosen}
                      onChange={(checked) => {
                        setFieldTouched("payment");
                        setFieldValue(
                          `payment[${index}].isChosen`,
                          checked
                        );
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row mt-4 py-4 d-flex justify-content-center">
            <div className="col-4">
              <Button
                onClick={() => {}}
                type="submit"
                block
                label={id ? save : create}
                labelClass="px-4 fsize-16 font-medium text-white"
                disabled={!dirty || !isValid}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddEditWholesaler;
