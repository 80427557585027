import moment from "moment";
import { v4 as uuid } from "uuid";

import { notificationsActionsList } from "./Constants";
import history from "../routes/History";
import store from "../store";

import {
  getNotificationsResponse,
  setNotificationSeenRequest,
  getAllNotificationsResponse
} from "../store/Notifications/actions";

export const getTwentyFourHrFormat = (time, isAm) => {
  const newTime = moment(`${time} ${isAm ? "AM" : "PM"}`, [
    "h:m A"
  ]).format("HH:mm");
  return newTime;
};

export const getTwelveHrFormat = (time) => {
  const newTime = moment(time, "HH:mm").format("hh:mm A");
  return newTime;
};

export const checkTimeOverlap = (timeSegments) => {
  if (timeSegments.length === 1) return false;

  timeSegments.sort((timeSegment1, timeSegment2) =>
    timeSegment1[0].localeCompare(timeSegment2[0])
  );

  for (let i = 0; i < timeSegments.length - 1; i++) {
    const currentEndTime = timeSegments[i][1];
    const nextStartTime = timeSegments[i + 1][0];

    if (currentEndTime > nextStartTime) {
      return true;
    }
  }

  return false;
};

export const formatDate = (date, format = "DD-MM-YYYY") =>
  moment(date).format(format);

export const formatDateWithTime = (date) => {
  return `${date.getFullYear().toString().padStart(4, "0")}-${(
    date.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${date
    .getDate()
    .toString()
    .padStart(2, "0")} ${date
    .getHours()
    .toString()
    .padStart(2, "0")}:${date
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;
};

export const generateDeviceId = () => {
  return uuid();
};

export const getDeviceId = () => {
  return localStorage.getItem("device_id");
};

export const handleNotificationsClickActions = ({
  id,
  click_action,
  action_id,
  seen
}) => {
  const notificationAction = notificationsActionsList.find(
    (elt) => elt.action === click_action
  );
  !seen && setNotificationSeen(id);

  click_action &&
    notificationAction &&
    history.push(notificationAction.route?.replace(":id", action_id));
};

export const setNotificationSeen = (id) => {
  store.dispatch(setNotificationSeenRequest(id));
};

export const replaceSeenNotificationInList = (notification) => {
  const notificationsList = store.getState().notifications.list;
  const fullNotificationsList =
    store.getState().notifications.fullList;
  const notificationsMeta = store.getState().notifications.meta;
  const notificationIndex = notificationsList.findIndex(
    (elt) => elt.id === notification.id
  );
  const fullNotificationIndex = fullNotificationsList.findIndex(
    (elt) => elt.id === notification.id
  );

  notificationsList.splice(notificationIndex, 1, notification);
  fullNotificationsList.splice(
    fullNotificationIndex,
    1,
    notification
  );

  store.dispatch(
    getNotificationsResponse({
      list: notificationsList,
      meta: notificationsMeta
    })
  );
  store.dispatch(
    getAllNotificationsResponse({
      list: fullNotificationsList
    })
  );
};
