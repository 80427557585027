import {
  SET_CATEGORIES_ROUTE,
  SET_ORDERS_ROUTE,
  SET_PRODUCTS_ROUTE,
  SET_RETAILERS_ROUTE,
  SET_SUPER_PRODUCTS_ROUTE,
  SET_WAREHOUSES_ROUTE,
  SET_WHOLESALERS_ROUTE,
  SET_WHOLESALER_PRODUCTS_ROUTE,
  SET_PROMOCODES_ROUTE,
  SET_BANNERS_ROUTE,
  SET_OFFERS_ROUTE
} from "./types";

export const setWholesalersRoute = (payload) => ({
  type: SET_WHOLESALERS_ROUTE,
  payload
});

export const setWarehousesRoute = (payload) => ({
  type: SET_WAREHOUSES_ROUTE,
  payload
});

export const setRetailersRoute = (payload) => ({
  type: SET_RETAILERS_ROUTE,
  payload
});

export const setOrdersRoute = (payload) => ({
  type: SET_ORDERS_ROUTE,
  payload
});

export const setCategoriesRoute = (payload) => ({
  type: SET_CATEGORIES_ROUTE,
  payload
});

export const setSuperProductsRoute = (payload) => ({
  type: SET_SUPER_PRODUCTS_ROUTE,
  payload
});

export const setProductsRoute = (payload) => ({
  type: SET_PRODUCTS_ROUTE,
  payload
});

export const setWholesalerProductsRoute = (payload) => ({
  type: SET_WHOLESALER_PRODUCTS_ROUTE,
  payload
});

export const setPromoCodesRoute = (payload) => ({
  type: SET_PROMOCODES_ROUTE,
  payload
});

export const setBannersRoute = (payload) => ({
  type: SET_BANNERS_ROUTE,
  payload
});

export const setOffersRoute = (payload) => ({
  type: SET_OFFERS_ROUTE,
  payload
});
