import React from "react";
import PropTypes from "prop-types";

export const RetailersIcon = ({ selectedIcon }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        fillOpacity={selectedIcon ? "1" : "0.5"}
        d="M16.884 5.115h-2.943a2.43 2.43 0 01-1.894-.887l-.969-1.34A2.367 2.367 0 009.193 2h-2.08C3.378 2 2 4.192 2 7.92v4.028c-.005.443 19.996.443 19.997 0v-1.17c.018-3.728-1.325-5.662-5.113-5.662z"
        opacity="0.4"
      ></path>
      <path
        fill="#fff"
        fillOpacity={selectedIcon ? "1" : "0.5"}
        fillRule="evenodd"
        d="M20.832 6.544c.32.374.567.804.73 1.269.318.954.465 1.958.435 2.963v5.253a9.346 9.346 0 01-.098 1.323 5.991 5.991 0 01-.8 2.182 4.436 4.436 0 01-.676.888 6 6 0 01-4.366 1.57H7.931a6.027 6.027 0 01-4.375-1.57 4.437 4.437 0 01-.667-.888 5.83 5.83 0 01-.782-2.182A8.202 8.202 0 012 16.029v-5.253c0-.438.024-.877.071-1.313.01-.076.025-.152.04-.226.025-.124.049-.246.049-.368.09-.527.255-1.038.49-1.518.693-1.482 2.115-2.236 4.445-2.236h9.78c1.305-.1 2.6.292 3.628 1.1.119.1.229.21.33.329zM6.97 15.54h10.083a.828.828 0 00.863-.798.744.744 0 00-.178-.533.783.783 0 00-.622-.32H6.97a.826.826 0 100 1.651z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

RetailersIcon.propTypes = {
  selectedIcon: PropTypes.bool
};
