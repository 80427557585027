import { axiosInstance } from "./";

const getRetailers = async (params) => {
  return await axiosInstance.get(`retailers`, { params });
};
const getRetailer = async (id) =>
  await axiosInstance.get(`retailers/${id}`);

const updateRetailer = async ({ data, id }) =>
  await axiosInstance.patch(`retailers/${id}`, data);

export { getRetailers, getRetailer, updateRetailer };
