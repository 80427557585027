import { put, call, takeEvery } from "redux-saga/effects";
import amplitude from "amplitude-js";

import * as types from "./types";
import * as apis from "../../network/apis/productCategories";
import {
  getProductCategoriesResponse,
  getProductCategoryResponse,
  getProductCategoriesRequest
} from "./actions";
import messages from "../../assets/locale/messages";
import { showHideSnackbar } from "../Snackbar/actions";

export function* getProductCategoriesSaga({ payload }) {
  try {
    const response = yield call(apis.getProductCategories, payload);
    yield put(
      getProductCategoriesResponse({
        list: response.data.product_categories,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
    if (
      +response.headers["current-page"] >
      +response.headers["total-pages"]
    ) {
      yield put(
        getProductCategoriesRequest({
          ...payload,
          page: +response.headers["total-pages"]
        })
      );
    }
  } catch (err) {
    console.log(err);
  }
}

export function* addProductCategorySaga({ payload }) {
  try {
    const response = yield call(
      apis.addProductCategory,
      payload.data
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].productCategories.createSuccess
      })
    );
    const { page } = payload;
    yield put(
      getProductCategoriesRequest({
        page,
        items: 12
      })
    );

    const eventProperties = {
      "category id": response.data.product_category.id,
      "category name": response.data.product_category.name
    };
    amplitude
      .getInstance()
      .logEvent("admin update: category added", eventProperties);
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}

export function* editProductCategorySaga({ payload }) {
  try {
    yield call(apis.editProductCategory, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].productCategories.editSuccess
      })
    );
    const { page, searchValue } = payload;
    yield put(
      getProductCategoriesRequest({
        page,
        items: 12,
        name: searchValue
      })
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}

export function* deleteProductCategorySaga({ payload }) {
  try {
    yield call(apis.deleteProductCategory, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].productCategories.deleteSuccess
      })
    );
    const { page } = payload;
    yield put(
      getProductCategoriesRequest({
        items: 12,
        page
      })
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}

export function* getProductCategorySaga({ payload }) {
  try {
    const response = yield call(apis.getProductCategory, payload);
    yield put(
      getProductCategoryResponse(response.data.product_category)
    );
  } catch (err) {
    console.log(err);
  }
}

export default function* ProductCategoriesSagas() {
  yield takeEvery(
    types.GET_PRODUCTCATEGORIES_REQUEST,
    getProductCategoriesSaga
  );
  yield takeEvery(
    types.ADD_PRODUCTCATEGORY_REQUEST,
    addProductCategorySaga
  );

  yield takeEvery(
    types.EDIT_PRODUCTCATEGORY_REQUEST,
    editProductCategorySaga
  );

  yield takeEvery(
    types.GET_PRODUCTCATEGORY_REQUEST,
    getProductCategorySaga
  );

  yield takeEvery(
    types.DELETE_PRODUCTCATEGORY_REQUEST,
    deleteProductCategorySaga
  );
}
