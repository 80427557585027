import * as types from "./types";

export const getProductCategoriesRequest = (data) => ({
  type: types.GET_PRODUCTCATEGORIES_REQUEST,
  payload: data
});

export const getProductCategoriesResponse = (data) => ({
  type: types.GET_PRODUCTCATEGORIES_RESPONSE,
  payload: data
});

export const addProductCategoryRequest = (data) => ({
  type: types.ADD_PRODUCTCATEGORY_REQUEST,
  payload: data
});

export const getProductCategoryRequest = (data) => ({
  type: types.GET_PRODUCTCATEGORY_REQUEST,
  payload: data
});

export const getProductCategoryResponse = (data) => ({
  type: types.GET_PRODUCTCATEGORY_RESPONSE,
  payload: data
});

export const editProductCategoryRequest = (data) => ({
  type: types.EDIT_PRODUCTCATEGORY_REQUEST,
  payload: data
});

export const deleteProductCategoryRequest = (data) => ({
  type: types.DELETE_PRODUCTCATEGORY_REQUEST,
  payload: data
});
