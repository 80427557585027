import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
// import CloseIcon from "@material-ui/icons/Close";

import messages from "../../assets/locale/messages";
import {
  addPromoCodeRequest,
  editPromoCodeRequest,
  getOnlyCodesRequest
} from "../../store/PromoCodes/actions";
import Input from "../Input";
import RadioButtonsGroup from "../RadioButton";
import Select from "../Select";
import Button from "../Button";
import { getWholesalersRequest } from "../../store/Wholesalers/actions";
// import ExclamationInfo from "../../utils/Icons/ExclamationInfo";
// import DatePicker from "../DatePicker";
import DateAndTimePicker from "../DateAndTimePicker";
import { formatDateWithTime } from "../../utils/Helpers";
import {
  NO_SPACE,
  ONLY_POSITIVE_NUMBERS,
  FROM_ONE_TO_HUNDRED_ONLY
} from "../../utils/Patterns";
import "./AddEditViewPromocode.scss";

const AddEditViewPromocode = ({
  closeOnSubmit,
  viewEditMode,
  promoCode,
  page,
  searchValue,
  filterStatus,
  filterOffer
}) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const { promocodes } = messages[lang];
  const [wholsalersOptions, setWholsalersOption] = useState([]);
  const [existingCodes, setExistingCodes] = useState([]);
  const [
    currentTimeWithAddedFiftenMinutes,
    setCurrentTimeWithAddedFiftenMinutes
  ] = useState(new Date());

  const [
    currentTimeWithAddedThirtyMinutes,
    setCurrentTimeWithAddedThirtyMinutes
  ] = useState(new Date());
  const wholesalers = useSelector((state) => state.wholesalers.list);
  const existingPromoCodes = useSelector(
    (state) => state.promoCodes.codes.codes
  );
  // const addResponse = useSelector(
  //   (state) => state.promoCodes.addResponse
  // );
  // const [areas, setAreas] = useState([]);

  useEffect(() => {
    const updateTimeForStart = setInterval(() => {
      let minutesToAdd = 15;
      let currentDate = new Date();
      let futureDate = new Date(
        currentDate.getTime() + minutesToAdd * 60000
      );
      setCurrentTimeWithAddedFiftenMinutes(futureDate);
    }, 1000);

    const updateTimeForEnd = setInterval(() => {
      let minutesToAdd = 30;
      let currentDate = new Date();
      let futureDate = new Date(
        currentDate.getTime() + minutesToAdd * 60000
      );
      setCurrentTimeWithAddedThirtyMinutes(futureDate);
    }, 1000);

    dispatch(
      getWholesalersRequest({
        lookup: "options",
        page: -1,
        items: 1,
        visible_wholesalers: true
      })
    );

    return () => {
      clearInterval(updateTimeForStart);
      clearInterval(updateTimeForEnd);
    };
  }, []);

  useEffect(() => {
    dispatch(
      getOnlyCodesRequest({
        page: -1,
        items: 1
      })
    );
  }, []);

  useEffect(() => {
    const options = wholesalers.map((wholesaler) => ({
      label: wholesaler.username,
      id: wholesaler.id
    }));
    setWholsalersOption(options);
  }, [wholesalers]);

  useEffect(() => {
    const codes = existingPromoCodes?.map((singlePromoCode) =>
      singlePromoCode.code.toLowerCase()
    );
    setExistingCodes(codes);
  }, [existingPromoCodes]);

  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    touched,
    setFieldTouched,
    dirty,
    isValid,
    setValues,
    setFieldError
  } = useFormik({
    initialValues: {
      title: "",
      code: "",
      start_datetime: "",
      end_datetime: "",
      description: "",
      offered_by: "",
      wholesaler_id: "",
      type_of_usage: "per_retailer",
      no_of_usages: 1,
      discount_type: "",
      discount_value: ""
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required(promocodes.validations.title)
        .max(30, promocodes.validations.maxTitle),
      code: Yup.string()
        .matches(NO_SPACE, promocodes.validations.noSpace)
        .required(promocodes.validations.code)
        .max(15, promocodes.validations.maxCode),
      description: Yup.string().max(
        60,
        promocodes.validations.maxDescription
      ),
      offered_by: Yup.string().required(promocodes.validations.owner),
      wholesaler_id: Yup.number().when("offered_by", {
        is: (offered_by) => {
          return offered_by === "wholesaler";
        },
        then: Yup.number().required(
          promocodes.validations.wholesaler
        ),
        otherwise: Yup.number()
      }),
      discount_type: Yup.string().required(
        promocodes.validations.discountType
      ),
      discount_value: Yup.string().when("discount_type", {
        is: (discount_type) => {
          return discount_type === "amount";
        },
        then: Yup.string()
          .matches(
            ONLY_POSITIVE_NUMBERS,
            promocodes.validations.amount
          )
          .required(""),
        otherwise: Yup.string()
          .matches(
            FROM_ONE_TO_HUNDRED_ONLY,
            promocodes.validations.percentage
          )
          .required("")
      }),
      no_of_usages: Yup.number(
        promocodes.validations.numberOnly
      ).required(promocodes.validations.numberUsage),
      start_datetime: Yup.string(promocodes.validations.startDate)
        .typeError(promocodes.validations.startDate)
        .required(promocodes.validations.startDate),
      end_datetime: Yup.string(promocodes.validations.endDate)
        .typeError(promocodes.validations.endDate)
        .required(promocodes.validations.endDate)
    }),
    onSubmit: (values) => {
      let nowDate = new Date();
      let nowYear = nowDate.getFullYear();
      let nowMonth = nowDate.getMonth() + 1;
      let nowDay = nowDate.getDate();
      let nowHour = nowDate.getHours();
      let nowMinute = nowDate.getMinutes();

      let startHour = parseInt(
        values.start_datetime.split(" ")[1].split(":")[0]
      );
      let startMinute = parseInt(
        values.start_datetime.split(" ")[1].split(":")[1]
      );

      let startYear = parseInt(values.start_datetime.split("-")[0]);
      let startMonth = parseInt(values.start_datetime.split("-")[1]);
      let startDay = parseInt(
        values.start_datetime.split("-")[2].split(" ")[0]
      );

      let endHour = parseInt(
        values.end_datetime.split(" ")[1].split(":")[0]
      );
      let endMinute = parseInt(
        values.end_datetime.split(" ")[1].split(":")[1]
      );

      let endYear = parseInt(values.end_datetime.split("-")[0]);
      let endMonth = parseInt(values.end_datetime.split("-")[1]);
      let endDay = parseInt(
        values.end_datetime.split("-")[2].split(" ")[0]
      );

      let promoCodeValue = values.code.toLowerCase();

      if (
        (Object.values(existingCodes).includes(promoCodeValue) &&
          !viewEditMode &&
          !promoCode?.is_activated) ||
        (viewEditMode &&
          !promoCode?.is_activated &&
          Object.values(existingCodes).includes(promoCodeValue) &&
          promoCodeValue !== promoCode?.code?.toLowerCase())
      ) {
        setFieldError("code", promocodes.validations.codeExist);
      } else if (
        startYear === nowYear &&
        startMonth === nowMonth &&
        startDay === nowDay &&
        startHour < nowHour &&
        !viewEditMode
      ) {
        setFieldError(
          "start_datetime",
          promocodes.validations.futureDate
        );
      } else if (
        startYear === nowYear &&
        startMonth === nowMonth &&
        startDay === nowDay &&
        startHour === nowHour &&
        startMinute <= nowMinute &&
        !viewEditMode
      ) {
        setFieldError(
          "start_datetime",
          promocodes.validations.futureDate
        );
      } else if (
        endYear === startYear &&
        endMonth === startMonth &&
        endDay === startDay &&
        endHour < startHour
      ) {
        setFieldError(
          "end_datetime",
          promocodes.validations.beforeStart
        );
      } else if (
        endYear === startYear &&
        endMonth === startMonth &&
        endDay === startDay &&
        endHour === startHour &&
        endMinute <= startMinute
      ) {
        setFieldError(
          "end_datetime",
          promocodes.validations.beforeStart
        );
      } else {
        if (viewEditMode) {
          dispatch(
            editPromoCodeRequest({
              data: values,
              id: promoCode.id,
              page,
              searchValue,
              filterStatus,
              filterOffer
            })
          );
        } else {
          dispatch(
            addPromoCodeRequest({
              data: values,
              page,
              searchValue,
              filterStatus,
              filterOffer
            })
          );
        }
        closeOnSubmit();
      }
    }
  });

  useEffect(() => {
    if (viewEditMode && promoCode) {
      setValues({
        title: promoCode?.title,
        code: promoCode?.code,
        start_datetime: promoCode?.start_datetime,
        end_datetime: promoCode?.end_datetime,
        description: promoCode?.description,
        offered_by: promoCode?.offered_by,
        wholesaler_id: promoCode?.wholesaler?.id,
        type_of_usage: promoCode?.type_of_usage,
        no_of_usages: promoCode?.no_of_usages,
        discount_type: promoCode?.discount_type,
        discount_value: promoCode?.discount_value
      });
    }
  }, [viewEditMode, promoCode]);

  const listofowners = [
    {
      value: process.env.REACT_APP_PROMO_CODE_SERVICE_PROVIDER_OWNER,
      label: promocodes.serviceProvider
    },
    {
      value: "wholesaler",
      label: promocodes.wholesaler
    }
  ];
  const listOfDiscountTypes = [
    {
      value: "amount",
      label: "Money",
      children: (
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center justify-content-center">
            <Input
              id="money"
              name="discount_value"
              labelClassName=" mb-3 text-gray-title fsize-14"
              inputWrapperClass=""
              disabled={
                (viewEditMode && promoCode?.is_activated) ||
                promoCode?.is_expired
              }
              onChange={(value) => {
                setFieldTouched("discount_value");
                setFieldValue("discount_value", value);
              }}
              value={values["discount_value"]}
            />
            <span>LE</span>
          </div>
          {errors.discount_value && touched.discount_value ? (
            <div className="text-error text-center">
              {errors.discount_value}
            </div>
          ) : null}
        </div>
      )
    },
    {
      value: "percentage",
      label: "Percentage",
      children: (
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center justify-content-center">
            <Input
              id="percentage"
              name="discount_value"
              labelClassName=" mb-3 text-gray-title fsize-14"
              inputWrapperClass=""
              disabled={
                (viewEditMode && promoCode?.is_activated) ||
                promoCode?.is_expired
              }
              onChange={(value) => {
                setFieldTouched("discount_value");
                setFieldValue("discount_value", value);
              }}
              value={values["discount_value"]}
            />
            <span>%</span>
          </div>
          {errors.discount_value && touched.discount_value ? (
            <div className="text-error text-center">
              {errors.discount_value}
            </div>
          ) : null}
        </div>
      )
    }
  ];

  const handleStartDateChange = (value) => {
    setFieldTouched("start_datetime");
    if (value) {
      const formatedValue = formatDateWithTime(value);
      let nowDate = new Date();
      let nowYear = nowDate.getFullYear();
      let nowMonth = nowDate.getMonth() + 1;
      let nowDay = nowDate.getDate();
      let nowHour = nowDate.getHours();
      let nowMinute = nowDate.getMinutes();

      let startHour = parseInt(
        formatedValue.split(" ")[1].split(":")[0]
      );
      let startMinute = parseInt(
        formatedValue.split(" ")[1].split(":")[1]
      );

      let startYear = parseInt(formatedValue.split("-")[0]);
      let startMonth = parseInt(formatedValue.split("-")[1]);
      let startDay = parseInt(
        formatedValue.split("-")[2].split(" ")[0]
      );

      if (
        startYear === nowYear &&
        startMonth === nowMonth &&
        startDay === nowDay &&
        startHour === nowHour &&
        startMinute <= nowMinute
      ) {
        setFieldValue(
          "start_datetime",
          formatDateWithTime(currentTimeWithAddedFiftenMinutes)
        );
      } else {
        setFieldValue(
          "start_datetime",
          value === null ? value : formatDateWithTime(value)
        );
      }
    }

    if (value === null) {
      setFieldValue("start_datetime", null);
    }
  };

  const handleEndDateChange = (value) => {
    setFieldTouched("end_datetime");
    if (value) {
      const formatedValue = formatDateWithTime(value);
      let nowDate = new Date();
      let nowYear = nowDate.getFullYear();
      let nowMonth = nowDate.getMonth() + 1;
      let nowDay = nowDate.getDate();
      let nowHour = nowDate.getHours();
      let nowMinute = nowDate.getMinutes();

      let endHour = parseInt(
        formatedValue.split(" ")[1].split(":")[0]
      );
      let endMinute = parseInt(
        formatedValue.split(" ")[1].split(":")[1]
      );

      let endYear = parseInt(formatedValue.split("-")[0]);
      let endMonth = parseInt(formatedValue.split("-")[1]);
      let endDay = parseInt(
        formatedValue.split("-")[2].split(" ")[0]
      );

      if (
        endYear === nowYear &&
        endMonth === nowMonth &&
        endDay === nowDay &&
        endHour === nowHour &&
        endMinute <= nowMinute
      ) {
        setFieldValue(
          "end_datetime",
          formatDateWithTime(currentTimeWithAddedThirtyMinutes)
        );
      } else {
        setFieldValue(
          "end_datetime",
          value === null ? value : formatDateWithTime(value)
        );
      }
    }

    if (value === null) {
      setFieldValue("end_datetime", null);
    }
  };

  // Remove Later if not used
  // const listOfNumberOfUsage = [
  //   {
  //     value: "overall",
  //     label: "Overall",
  //     children: (
  //       <div>
  //         <Input
  //           id="overall"
  //           name="no_of_usages"
  //           labelClassName=" mb-3 text-gray-title fsize-14"
  //           onChange={(value) => {
  //             setFieldTouched("no_of_usages");
  //             setFieldValue("no_of_usages", value);
  //           }}
  //           value={values["no_of_usages"]}
  //         />
  //       </div>
  //     )
  //   },
  //   {
  //     value: "per_retailer",
  //     label: "Per retailer",
  //     children: (
  //       <div>
  //         <Input
  //           id="perRetailer"
  //           name="no_of_usages"
  //           labelClassName=" mb-3 text-gray-title fsize-14"
  //           inputWrapperClass=""
  //           onChange={(value) => {
  //             setFieldTouched("no_of_usages");
  //             setFieldValue("no_of_usages", value);
  //           }}
  //           value={values["no_of_usages"]}
  //         />
  //       </div>
  //     )
  //   }
  // ];

  ////////////////////////////////////////////////////////////////////
  //This will be used later in advanced Version */}
  // /////////////////////////////////////////////////////////////// */
  // const listofCritrias = [
  //   {
  //     value: "First order",
  //     label: "First order",
  //     endAddornment: <ExclamationInfo />,
  //     info: "First order"
  //   },
  //   {
  //     value: "Minimum order amount paid in a specific period ",
  //     label: "Minimum order amount paid in a specific period ",
  //     endAddornment: <ExclamationInfo />,
  //     info: "Minimum order amount paid in a specific period ",
  //     children: (
  //       <>
  //         <div className="d-flex justify-content-center align-items-center">
  //           <Input
  //             id="minOrderAmount"
  //             name="minOrderAmount"
  //             value={values["minOrderAmount"]}
  //             inputWrapperClass="w-25 me-1 value"
  //             className=" text-orange "
  //             onChange={(value) => {
  //               setFieldTouched("minOrderAmount");
  //               setFieldValue("minOrderAmount", value);
  //             }}
  //           />
  //           <span className="me-3 text-dark-blue">LE</span>
  //           <DatePicker
  //             label={" Period Start Date"}
  //             name={"startDate"}
  //             value={values["startDate"]}
  //             isInlineLabel
  //             handleDateChange={(value) => {
  //               setFieldTouched("startDate");
  //               setFieldValue("startDate", value);
  //             }}
  //             maxDate={values["endDate"]}
  //             placeholder={formatDate(new Date(), "DD/MM/yyyy")}
  //             inputWrapperClass="me-3 date"
  //             // isInputHasErr={!!endDateErr}
  //             // errMsg={endDateErr}
  //           />
  //           <DatePicker
  //             label={" Period End Date"}
  //             name={"endDate"}
  //             value={values["endDate"]}
  //             isInlineLabel
  //             handleDateChange={(value) => {
  //               setFieldTouched("endDate");
  //               setFieldValue("endDate", value);
  //             }}
  //             minDate={values["startDate"]}
  //             placeholder={formatDate(new Date(), "DD/MM/yyyy")}
  //             inputWrapperClass="me-3 date"
  //             // isInputHasErr={!!endDateErr}
  //             // errMsg={endDateErr}
  //           />
  //         </div>
  //       </>
  //     )
  //   },
  //   {
  //     value: "Minimum order amount with no specific condition",
  //     label: "Minimum order amount with no specific condition",
  //     endAddornment: <ExclamationInfo />,
  //     info: "Minimum order amount with no specific condition",
  //     children: (
  //       <div className="d-flex align-items-center justify-content-start ">
  //         <Input
  //           id="minOrderAmount"
  //           name="minOrderAmount"
  //           value={values["minOrderAmount"]}
  //           inputWrapperClass="w-25 ms-5 value"
  //           className=" text-orange "
  //           onChange={(value) => {
  //             setFieldTouched("minOrderAmount");
  //             setFieldValue("minOrderAmount", value);
  //           }}
  //         />
  //         <span className="me-3 text-dark-blue">LE</span>
  //       </div>
  //     )
  //   },
  //   {
  //     value: "Minimum number of order’s  products",
  //     label: "Minimum number of order’s  products",
  //     endAddornment: <ExclamationInfo />,
  //     info: "Minimum number of order’s  products",
  //     children: (
  //       <div className="d-flex align-items-center justify-content-start ">
  //         <Input
  //           id="minOrderAmount"
  //           name="minOrderAmount"
  //           value={values["minOrderAmount"]}
  //           inputWrapperClass="w-25 ms-5 value"
  //           className=" text-orange "
  //           onChange={(value) => {
  //             setFieldTouched("minOrderAmount");
  //             setFieldValue("minOrderAmount", value);
  //           }}
  //         />
  //         <span className="me-3 text-dark-blue">Products</span>
  //       </div>
  //     )
  //   },
  //   {
  //     value: "Specific zones",
  //     label: "Specific zones",
  //     endAddornment: <ExclamationInfo />,
  //     info: "Specific zones",
  //     children: (
  //       <>
  //         <div className="ms-4 mt-2">
  //           <Select
  //             name="wholesalerId"
  //             options={wholsalersOptions}
  //             variant
  //             block
  //             labelClassName="text-gray-title  pb-2"
  //             placeholder={"Choose the zones"}
  //             value={
  //               wholsalersOptions.find(
  //                 (option) => option.id == values["wholesalerId"]
  //               ) || null
  //             }
  //             onChange={(value) => {
  //               setFieldTouched("wholesalerId");
  //               setFieldValue("wholesalerId", value ? value.id : "");
  //               areas.push(value);
  //               setAreas(areas);
  //             }}
  //             hasError={
  //               !!(touched["wholesalerId"] && errors["wholesalerId"])
  //             }
  //             errMsg={errors["wholesalerId"]}
  //           />
  //         </div>
  //       </>
  //     )
  //   }
  // ];
  // /////////////////////////////////////////////////////////////// */
  return (
    <div className="text-left add-edit-promocode">
      <p className="fsize-18 font-medium mb-4 title  ">
        {viewEditMode ? promoCode?.title : promocodes.newPromocode}
      </p>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row mb-4">
          <div className="col-6 flex-column">
            <Input
              label={promocodes.promocodeTitle}
              id="title"
              name="title"
              placeholder={promocodes.titlePlaceholder}
              required
              disabled={promoCode?.is_expired}
              value={values["title"]}
              labelClassName=" mb-3 text-gray-title fsize-14"
              inputWrapperClass=""
              onChange={(value) => {
                setFieldTouched("title");
                setFieldValue("title", value);
              }}
            />
            <div className="minErrorHeight">
              {errors.title && touched.title ? (
                <div className="text-error text-start">
                  {errors.title}
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-6">
            <Input
              label={promocodes.promocode}
              id="code"
              name="code"
              placeholder={promocodes.promocodePlaceholder}
              required
              labelClassName=" mb-3 text-gray-title fsize-14"
              inputWrapperClass=""
              value={values["code"]}
              disabled={
                (viewEditMode && promoCode?.is_activated) ||
                promoCode?.is_expired
              }
              onChange={(value) => {
                setFieldTouched("code");
                setFieldValue("code", value);
              }}
            />
            {errors.code && touched.code ? (
              <div className="text-error">{errors.code}</div>
            ) : null}
          </div>
        </div>
        <div className="d-flex flex-column mb-3">
          <Input
            id="description"
            label={promocodes.description}
            labelAdornment={
              <span className="description fsize-14 font-reguler">
                “ Optional, will display for the admin only “
              </span>
            }
            name="description"
            type="text"
            multiline={true}
            value={values["description"]}
            rows={6}
            placeholder={"Enter the description here"}
            disabled={promoCode?.is_expired}
            onChange={(value) => {
              setFieldTouched("description");
              setFieldValue("description", value);
            }}
            inputWrapperClass="mb-4"
            labelClassName="text-gray-title font-medium mb-2"
          />
          <div className="minErrorHeight">
            {errors.description && touched.description ? (
              <div className="text-error text-start">
                {errors.description}
              </div>
            ) : null}
          </div>
        </div>

        <div className="row ms-1 align-items-start promo-codes-owners-container ">
          <RadioButtonsGroup
            wrapperClassName="d-flex align-items-center flex-row col-8 "
            label={"Promocode’s owner"}
            labelClassName="text-gray-title me-4 w-40"
            className="d-flex align-items-start flex-row justify-content-between flex-nowrap w-60 firstChildsNoShrink"
            required
            disabled={
              (viewEditMode && promoCode?.is_activated) ||
              promoCode?.is_expired
            }
            id="offered_by"
            name="offered_by"
            value={values["offered_by"]}
            handleChange={(e) => {
              setFieldTouched("offered_by");
              setFieldValue("offered_by", e.target.value);
            }}
            listOfRadios={listofowners}
          />
          <div className="col-4 mt-2">
            {values["offered_by"] == "wholesaler" && (
              <Select
                name="wholesaler_id"
                options={wholsalersOptions}
                label={"Choose wholesaler’s name"}
                variant
                block
                disabled={
                  (viewEditMode && promoCode?.is_activated) ||
                  promoCode?.is_expired
                }
                labelClassName="text-gray-title  pb-2"
                placeholder={"Wholesaler Name"}
                required
                value={
                  wholsalersOptions.find(
                    (option) => option.id == values["wholesaler_id"]
                  ) || null
                }
                onChange={(value) => {
                  setFieldTouched("wholesaler_id");
                  setFieldValue(
                    "wholesaler_id",
                    value ? value.id : ""
                  );
                }}
                hasError={
                  !!(
                    touched["wholesaler_id"] &&
                    errors["wholesaler_id"]
                  )
                }
                errMsg={errors["wholesaler_id"]}
              />
            )}
          </div>
        </div>
        {/* This will be used later in advanced Version */}
        {/* /////////////////////////////////////////////////////////////// */}
        {/*       <div className="dividerLine my-4"></div>
 
         <RadioButtonsGroup
          wrapperClassName="d-flex align-items-center critria "
          label={"Criteria"}
          labelClassName="text-gray-title mx-4 "
          className=""
          required
          id="criteria"
          endAdornment={
            <span className="description ms-1">
              “ This promocode will be applied if this condition is
              met “
            </span>
          }
          name="criteria"
          value={values["criteria"]}
          handleChange={(e) => {
            setFieldTouched("criteria");
            setFieldValue("criteria", e.target.value);
          }}
          listOfRadios={listofCritrias}
        /> */}
        {/* <div className="ms-5 ps-5 mt-4">
          {areas.map((area, index) => (
            <span
              key={index}
              className="area px-4 font-cairo-medium fsize-15 py-1 mx-2 text-dark-blue position-relative mb-5"
            >
              <CloseIcon
                className="closeIcon text-gray-title"
                fontSize="small"
                onClick={() => {
                  console.log();
                }}
              />
              {area?.label}
            </span>
          ))}
        </div> */}
        {/* /////////////////////////////////////////////////////////////// */}
        <div className="dividerLine my-4"></div>
        <div className="row ms-1 align-items-start discount-type-container">
          <RadioButtonsGroup
            wrapperClassName="d-flex align-items-start flex-row col-8"
            label={"Discount type"}
            labelClassName="text-gray-title me-4 w-40"
            className="d-flex align-items-start flex-row justify-content-between flex-nowrap w-60 firstChildsNoShrink"
            required
            disabled={
              (viewEditMode && promoCode?.is_activated) ||
              promoCode?.is_expired
            }
            id="discount_type"
            name="discount_type"
            value={values["discount_type"]}
            handleChange={(e) => {
              setFieldTouched("discount_type");
              setFieldValue("discount_type", e.target.value);
              setFieldValue("discount_value", "");
            }}
            listOfRadios={listOfDiscountTypes}
          />
        </div>
        {/* <div className="dividerLine my-4"></div>
        <div className="row ms-1 align-items-start number-of-usage-container">
          <RadioButtonsGroup
            wrapperClassName="d-flex align-items-start flex-row col-8"
            label={"Number of usage"}
            labelClassName="text-gray-title me-4 w-40"
            className="d-flex align-items-start flex-row justify-content-between flex-nowrap w-60 firstChildsNoShrink"
            required
            id="type_of_usage"
            name="type_of_usage"
            value={values["type_of_usage"]}
            handleChange={(e) => {
              setFieldTouched("type_of_usage");
              setFieldValue("type_of_usage", e.target.value);
            }}
            listOfRadios={listOfNumberOfUsage}
          />
        </div> */}
        <div className="dividerLine my-4"></div>
        <div className="row ms-1 align-items-start flex-nowrap mb-4 start-date-container">
          <div className="text-gray-title ps-0 col-4">
            {promocodes.promocodeStartDate}
            <span className="err-asterisk">*</span>
          </div>
          <div className="col-8 date-and-time-picker-container">
            <DateAndTimePicker
              dateLabel={promocodes.startDate}
              timeLabel={promocodes.startTime}
              isInlineLabel
              inputWrapperClass="me-3 date"
              name="start_datetime"
              value={values["start_datetime"]}
              handleDateChange={(value) => {
                handleStartDateChange(value);
              }}
              minDate={currentTimeWithAddedFiftenMinutes}
              maxDate={values["end_datetime"]}
              disablePast
              disabled={
                (viewEditMode && promoCode?.is_activated) ||
                promoCode?.is_expired
              }
            />
            {errors.start_datetime && touched.start_datetime ? (
              <div className="text-error">
                {errors.start_datetime}
              </div>
            ) : null}
          </div>
        </div>
        <div className="row ms-1 align-items-start flex-nowrap mt-4 end-date-container">
          <div className="text-gray-title ps-0 col-4">
            {promocodes.promocodeEndDate}
            <span className="err-asterisk">*</span>
          </div>
          <div className="col-8 date-and-time-picker-container">
            <DateAndTimePicker
              dateLabel={promocodes.endDate}
              timeLabel={promocodes.endTime}
              isInlineLabel
              inputWrapperClass="me-3 date"
              name="end_datetime"
              value={values["end_datetime"]}
              handleDateChange={(value) => {
                handleEndDateChange(value);
              }}
              minDate={values["start_datetime"]}
              disablePast
              disabled={promoCode?.is_expired}
            />
            {errors.end_datetime && touched.end_datetime ? (
              <div className="text-error">{errors.end_datetime}</div>
            ) : null}
          </div>
        </div>
        <div className="container d-flex justify-content-center align-items-center mt-5 mb-3">
          {!promoCode?.is_expired && (
            <Button
              className="w-40 mb-3"
              label={
                <span className="d-flex align-items-center">
                  <span className="ps-1 fsize-14 ">
                    {viewEditMode
                      ? promocodes.editPromoCode
                      : promocodes.addPromoCode}
                  </span>
                </span>
              }
              type="submit"
              disabled={!dirty || !isValid}
              labelClass="fsize-16 text-white"
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default AddEditViewPromocode;

AddEditViewPromocode.propTypes = {
  closeOnSubmit: PropTypes.func,
  viewEditMode: PropTypes.bool,
  promoCode: PropTypes.object,
  page: PropTypes.number,
  searchValue: PropTypes.any,
  filterStatus: PropTypes.any,
  filterOffer: PropTypes.any
};
