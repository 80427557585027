import React from "react";
import PropTypes from "prop-types";

import SideMenu from "../../components/SideMenu";
import "./Layout.scss";

const Layout = ({ isAuth, children }) => {
  return isAuth ? (
    <div className="row layout">
      <div className="col-2 sideMenuBar">
        <SideMenu />
      </div>
      <div className="col-10 pages-container">
        <div className="children-container ">{children}</div>
      </div>
    </div>
  ) : (
    children
  );
};

Layout.propTypes = {
  isAuth: PropTypes.bool,
  children: PropTypes.node
};

export default Layout;
