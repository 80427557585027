import { axiosInstance } from "./";

const getSubProducts = async (params) =>
  await axiosInstance.get(`products`, { params });

const getSubProduct = async (id) =>
  await axiosInstance.get(`products/${id}`);

const addSubProduct = async (payload) =>
  await axiosInstance.post(`products`, payload);

const editSubProduct = async ({ data, prdId }) =>
  await axiosInstance.patch(`products/${prdId}`, data);

export {
  getSubProducts,
  getSubProduct,
  addSubProduct,
  editSubProduct
};
