import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Visibility, Edit, Publish, Add } from "@material-ui/icons";
import { TableCell, TableRow } from "@material-ui/core";
import debounce from "lodash.debounce";

import Switch from "../../components/Switch";
import messages from "../../assets/locale/messages";
import Table from "../../components/Table";
import Navbar from "../../components/Navbar";
import Button from "../../components/Button";
import SearchInput from "../../components/SearchInput";
import EmptyState from "../../components/EmptyState";
import Pagination from "../../components/Pagination";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import {
  getWholesalersRequest,
  editWholesalerRequest
} from "../../store/Wholesalers/actions";
import { setWholesalersRoute } from "../../store/Routing/actions";
import "./Wholesalers.scss";

const Wholesalers = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const lang = useSelector((state) => state.locale.lang);
  const list = useSelector((state) => state.wholesalers.list);
  const meta = useSelector((state) => state.wholesalers.meta);
  const isLoading = useSelector((state) => state.loader);
  const wholesalersRoutingData = useSelector(
    (state) => state.routing.wholesalers
  );

  const [page, setPage] = useState(wholesalersRoutingData?.page || 1);
  const [searchValue, setSearchValue] = useState(
    wholesalersRoutingData?.search || ""
  );
  const [searchQuery, setSearchQuery] = useState(
    wholesalersRoutingData?.search || ""
  );

  const getWholesalers = (pageNumber, search) => {
    dispatch(
      getWholesalersRequest({
        page: pageNumber,
        items: 10,
        wholesaler_data: search
      })
    );
  };

  const updateSearchQuery = () => {
    setSearchValue(searchQuery);
  };
  const delayedSearchQuery = useCallback(
    debounce(updateSearchQuery, 500),
    [searchQuery]
  );
  useEffect(() => {
    delayedSearchQuery();
    // Cancel the debounce on useEffect cleanup.
    return delayedSearchQuery.cancel;
  }, [searchQuery, delayedSearchQuery]);

  useEffect(() => {
    getWholesalers(page, searchValue);
  }, [page]);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      setPage(wholesalersRoutingData?.page || 1);
      getWholesalers(wholesalersRoutingData?.page || 1, searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (wholesalersRoutingData) {
      dispatch(setWholesalersRoute(null));
    }
  }, [wholesalersRoutingData]);

  const {
    list: {
      pageTitle,
      name,
      mail,
      phone,
      view,
      edit,
      publish,
      published,
      activated,
      deactivated,
      addNew,
      search,
      subTitle,
      description,
      CreateWholesalerButton
    }
  } = messages[lang].Wholesalers;
  const { general } = messages[lang];

  const toggleActivateWholesaler = (isActivated, id) => {
    dispatch(
      editWholesalerRequest({
        data: {
          is_activated: !isActivated
        },
        id,
        page,
        searchValue
      })
    );
  };

  const publishWholesaler = (id) => {
    dispatch(
      editWholesalerRequest({
        data: {
          is_published: true
        },
        id,
        page,
        searchValue
      })
    );
  };

  const handleViewWholesaler = (id) => {
    dispatch(
      setWholesalersRoute({
        page,
        search: searchValue
      })
    );
    history.push(ROUTE_PATHS["wholesalerDetails"].replace(":id", id));
  };
  const handleEditWholesaler = (id) => {
    dispatch(
      setWholesalersRoute({
        page,
        search: searchValue
      })
    );
    history.push(ROUTE_PATHS["editWholesaler"].replace(":id", id));
  };

  const wholesalersList = () => {
    if (!isLoading && list.length == 0 && !searchValue) {
      return (
        <>
          <Navbar NavbarTitle={pageTitle} />
          <div className=" bg-white warehouses-page d-flex justify-content-center align-items-center flex-column m-4">
            <EmptyState
              subTitle={subTitle}
              description={description}
              ButtonLabel={CreateWholesalerButton}
              handelCreate={() => {
                history.push(ROUTE_PATHS.addWholesaler);
              }}
            />
          </div>
        </>
      );
    } else if (!isLoading && searchValue && list.length == 0) {
      return (
        <>
          <Navbar NavbarTitle={pageTitle}>
            <Button
              label={
                <span className="d-flex align-items-center">
                  <Add fontSize="large" />
                  <span className="ps-1">{addNew}</span>
                </span>
              }
              labelClass="fsize-16 text-white pe-2"
              onClick={() => {
                history.push(ROUTE_PATHS.addWholesaler);
              }}
            />
          </Navbar>
          <div className="d-flex justify-content-end pe-4 mt-3">
            <SearchInput
              name="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder={search}
            />
          </div>
          <div className=" bg-white warehouses-page d-flex justify-content-center align-items-center flex-column m-4">
            <EmptyState
              subTitle={general.noSearch}
              showActionButton={false}
            />
          </div>
        </>
      );
    } else {
      return (
        <div>
          <Navbar NavbarTitle={pageTitle}>
            <Button
              label={
                <span className="d-flex align-items-center">
                  <Add fontSize="large" />
                  <span className="ps-1">{addNew}</span>
                </span>
              }
              labelClass="fsize-16 text-white pe-2"
              onClick={() => {
                history.push(ROUTE_PATHS.addWholesaler);
              }}
            />
          </Navbar>
          <div className="d-flex justify-content-end pe-4 mt-3">
            <SearchInput
              name="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder={search}
            />
          </div>
          <div className="bg-white mx-4 my-3 wholesalers-table-list">
            <Table
              headlines={[name, mail, phone]}
              notHasSizeLimitaions={true}
              tableContainerClasses="d-flex justify-content-center"
              tableClasses="w-90"
              hasActions={true}
              rows={list.map((row, i) => (
                <TableRow key={i}>
                  <TableCell className="font-reguler text-dark-blue fsize-14 w-10">
                    {row.username}
                  </TableCell>
                  <TableCell className="font-reguler text-dark-blue fsize-14 w-15">
                    {row.email}
                  </TableCell>
                  <TableCell className="font-reguler text-dark-blue fsize-14 w-15">
                    +20 {row.phone_number}
                  </TableCell>
                  <TableCell className="font-reguler text-dark-blue fsize-14 table-icons-container">
                    <div className="pt-2 d-flex flex-column align-items-center justify-content-between">
                      <Visibility
                        color="secondary"
                        fontSize="large"
                        className="opacity_7 pointer"
                        onClick={() => handleViewWholesaler(row.id)}
                      />
                      <span className="text-gray">{view}</span>
                    </div>
                    <div className="pt-2 d-flex ps-4 pe-3 flex-column align-items-center justify-content-between">
                      <Edit
                        color="secondary"
                        fontSize="large"
                        className="opacity_7 pointer"
                        onClick={() => handleEditWholesaler(row.id)}
                      />
                      <span className="text-gray">{edit}</span>
                    </div>
                    {row.is_email_verified && row.is_published && (
                      <div className="pt-2 d-flex flex-column align-items-center justify-content-between">
                        <Switch
                          checked={row.is_activated}
                          name="published"
                          onChange={() => {
                            toggleActivateWholesaler(
                              row.is_activated,
                              row.id
                            );
                          }}
                        />
                        <span className="text-gray">
                          {row.is_activated ? activated : deactivated}
                        </span>
                      </div>
                    )}
                    {row.is_email_verified && (
                      <div
                        className={`pt-2 d-flex flex-column align-items-center publish-item ${
                          row.is_published
                            ? "justify-content-end"
                            : "justify-content-between"
                        }`}
                      >
                        {!row.is_published && (
                          <Publish
                            color="secondary"
                            fontSize="large"
                            className="opacity_7 pointer"
                            onClick={() => {
                              publishWholesaler(row.id);
                            }}
                          />
                        )}
                        <span className="text-gray">
                          {row.is_published ? published : publish}
                        </span>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            />

            <div className="d-flex justify-content-center py-3">
              {meta.pages > 1 && (
                <Pagination
                  count={meta.pages}
                  page={meta.currentPage || page}
                  handleChange={(event, page) => {
                    setPage(page);
                  }}
                  defaultPage={1}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  return <>{wholesalersList()}</>;
};

export default Wholesalers;
