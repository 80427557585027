import React from "react";
import PropTypes from "prop-types";
import MaterialModal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";

import GoogleMaps from "../GoogleMaps";
import "./MapModal.scss";

const MapModal = ({
  open,
  handleClose,
  savedCoords,
  savedPolygon,
  modalHeaderLabel
}) => {
  return (
    <div>
      <MaterialModal open={open} onClose={handleClose}>
        <div className="pt-3 ">
          <div className="mapLocation mt-5 me-5 ">
            <div className="d-flex justify-content-between">
              <p className="mb-0 fsize-16 font-medium">
                {modalHeaderLabel}
              </p>
              <CloseIcon
                className="me-2 closeIcon"
                onClick={handleClose}
              />
            </div>
            <GoogleMaps
              hasSearch={false}
              savedCoords={savedCoords}
              savedPolygon={savedPolygon}
              className="w-75 h-75 googleMap"
              viewOnly={true}
              isMarker={!!savedCoords.coords}
              isPolygon={!!savedPolygon.length}
            />
          </div>
        </div>
      </MaterialModal>
    </div>
  );
};

export default MapModal;

MapModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  modalBody: PropTypes.element,
  savedCoords: PropTypes.object,
  savedPolygon: PropTypes.array,
  modalHeaderLabel: PropTypes.string
};
