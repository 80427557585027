import { put, call, takeEvery } from "redux-saga/effects";

import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import * as types from "./types";
import * as apis from "../../network/apis/offers";
import {
  getOffersResponse,
  getOffersRequest,
  getPreviousProductOffersResponse,
  getOfferResponse,
  getOfferRequest,
  getPreviousProductOffersRequest
} from "./actions";
import { showHideSnackbar } from "../Snackbar/actions";
import messages from "../../assets/locale/messages";
import history from "../../routes/History";

export function* getOffersSaga({ payload }) {
  try {
    const response = yield call(apis.getOffers, payload);
    yield put(
      getOffersResponse({
        list: response.data.offers,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
    if (
      +response.headers["current-page"] >
      +response.headers["total-pages"]
    ) {
      yield put(
        getOffersRequest({
          ...payload,
          page: +response.headers["total-pages"]
        })
      );
    }
  } catch (err) {
    console.log(err);
  }
}

export function* addOfferSaga({ payload }) {
  try {
    yield call(apis.addOffer, payload.data);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].offers.addEditPage.createSuccess
      })
    );
    history.push(ROUTE_PATHS["offers"]);
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}

export function* getPreviousProductOffersSaga({ payload }) {
  try {
    const response = yield call(apis.getOffers, payload);
    yield put(getPreviousProductOffersResponse(response.data.offers));
  } catch (err) {
    console.log(err);
  }
}

export function* getOfferSaga({ payload }) {
  try {
    const response = yield call(apis.getOffer, payload);
    yield put(getOfferResponse(response.data.offer));
  } catch (err) {
    console.log(err);
  }
}

export function* editOfferSaga({ payload }) {
  try {
    yield call(apis.editOffer, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].offers.editSuccess
      })
    );
    const {
      page,
      filterStatus,
      filterSubStatus,
      filterWholsaler,
      searchValue,
      insideSaveOfferChanges,
      id,
      wholesalerProductId
    } = payload;

    yield put(getOfferRequest(id));

    if (insideSaveOfferChanges) {
      history.push(ROUTE_PATHS["offers"]);
    } else if (wholesalerProductId) {
      yield put(
        getPreviousProductOffersRequest({
          wholesalers_product_id: wholesalerProductId,
          applied_offers: true
        })
      );
    } else {
      yield put(
        getOffersRequest({
          items: 8,
          page,
          status: filterStatus?.value,
          is_enabled: filterSubStatus?.value,
          wholesaler_id: filterWholsaler?.id,
          product_name: searchValue
        })
      );
    }
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export function* deleteOfferSaga({ payload }) {
  try {
    yield call(apis.deleteOffer, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].offers.deleteSuccess
      })
    );
    const {
      page,
      filterStatus,
      filterSubStatus,
      filterWholsaler,
      searchValue,
      insideViewEditOfferTopContainer,
      wholesalerProductId
    } = payload;

    if (insideViewEditOfferTopContainer) {
      history.push(ROUTE_PATHS["offers"]);
    } else if (wholesalerProductId) {
      yield put(
        getPreviousProductOffersRequest({
          wholesalers_product_id: wholesalerProductId,
          applied_offers: true
        })
      );
    }

    yield put(
      getOffersRequest({
        items: 8,
        page,
        status: filterStatus?.value,
        is_enabled: filterSubStatus?.value,
        wholesaler_id: filterWholsaler?.id,
        product_name: searchValue
      })
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}

export default function* OffersSagas() {
  yield takeEvery(types.GET_OFFERS_REQUEST, getOffersSaga);
  yield takeEvery(types.ADD_OFFER_REQUEST, addOfferSaga);
  yield takeEvery(
    types.GET_PREVIOUS_PRODUCT_OFFERS_REQUEST,
    getPreviousProductOffersSaga
  );
  yield takeEvery(types.GET_OFFER_REQUEST, getOfferSaga);
  yield takeEvery(types.EDIT_OFFER_REQUEST, editOfferSaga);
  yield takeEvery(types.DELETE_OFFER_REQUEST, deleteOfferSaga);
}
