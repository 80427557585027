import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import messages from "../../../../assets/locale/messages";
import { formatDate } from "../../../../utils/Helpers";
import "./PreviousOffersCard.scss";

const PreviousOffersCard = ({ prevOffer, handleViewDetails }) => {
  const lang = useSelector((state) => state.locale.lang);
  const { offers } = messages[lang];

  return (
    <div className="d-flex justify-content-center align-items-center my-3 previous-offers-card-container ">
      <div className="previous-offers-card px-3 w-100">
        <div className="d-flex justify-content-between">
          <div className="text-uppercase prev-offer-value">
            {prevOffer.discount_value}{" "}
            {`${
              prevOffer.discount_type === "amount"
                ? offers.addEditPage.le
                : "%"
            }`}
          </div>
          <div
            className="view-details-btn"
            onClick={() => {
              handleViewDetails();
            }}
          >
            {offers.addEditPage.viewDetails}
          </div>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <div>
            <p className="my-0 date-title">
              {offers.addEditPage.start}
            </p>
            <p className="my-0 date-text-color fsize-12">
              {formatDate(prevOffer.start_datetime, "DD-MM-YYYY")}
            </p>
          </div>
          <div>
            <p className="my-0 date-title">
              {offers.addEditPage.end}
            </p>
            <p className="my-0 date-text-color fsize-12">
              {formatDate(prevOffer.end_datetime, "DD-MM-YYYY")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviousOffersCard;

PreviousOffersCard.propTypes = {
  prevOffer: PropTypes.object,
  handleViewDetails: PropTypes.func
};
