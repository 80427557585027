import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";

import {
  getBannerRequest,
  getBannerResponse,
  addBannerRequest,
  editBannerRequest
} from "../../../store/Banners/actions";
import messages from "../../../assets/locale/messages";
import Navbar from "../../../components/Navbar";
import Input from "../../../components/Input";
import Upload from "../../../components/Upload";
import Button from "../../../components/Button";
import Switch from "../../../components/Switch";
import {
  ONLY_POSITIVE_NUMBERS,
  ONLY_URL
} from "../../../utils/Patterns";
import { uploadToS3, directories } from "../../../utils/S3";
import "./AddEditBanner.scss";

const AddEditBanner = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { banners } = messages[lang];

  const [img, setImg] = useState();
  // flag to upload image in edit mode if image changed only
  const [imgChanged, setImgChanged] = useState(false);

  const banner = useSelector((state) => state.banners.banner);

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getBannerRequest(id));
    }
    return () => {
      // remove Banner data to clean page
      dispatch(getBannerResponse(null));
    };
  }, []);

  useEffect(() => {
    if (banner && id) {
      // set fields to be touched to show error messages directly
      setTouched({
        title: true,
        subtitle: true,
        image: true,
        duration: true,
        url: true
      });
      setValues({
        title: banner?.title || "",
        subtitle: banner?.subtitle || "",
        image: banner?.image || "",
        duration: Number(banner?.duration) || 1,
        url: banner?.url || "",
        is_hidden: banner?.is_hidden || false
      });
    }
  }, [banner]);

  const formBannerData = (values) => {
    let bannerData = {};
    bannerData.title = values.title;
    bannerData.subtitle = values.subtitle;
    bannerData.duration = Number(values.duration);
    bannerData.url = values.url;
    bannerData.is_hidden = values.is_hidden;

    return bannerData;
  };

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
    setValues,
    setTouched
  } = useFormik({
    initialValues: {
      title: banner?.title || "",
      subtitle: banner?.subtitle || "",
      image: banner?.image || "",
      duration: Number(banner?.duration) || 1,
      url: banner?.url || "",
      is_hidden: banner?.is_hidden || true
    },
    validationSchema: Yup.object({
      title: Yup.string(),
      subtitle: Yup.string(),
      image: Yup.mixed().required(banners.validations.image),
      duration: Yup.string()
        .required(banners.validations.durationRequired)
        .matches(
          ONLY_POSITIVE_NUMBERS,
          banners.validations.durationPositveNumbers
        ),
      url: Yup.string().matches(ONLY_URL, banners.validations.url)
    }),
    onSubmit: async (values) => {
      let bannerData = formBannerData(values);
      if (values.image) {
        if (imgChanged) {
          const data = await uploadToS3(
            values.image,
            // unique file name to avoid conflicts and without spaces as it makes errors
            `${new Date().getTime()}_${values.image.name
              .replace(/\s/g, "")
              .replace(/(\(|\))/g, "")}`,
            directories.banner
          );
          bannerData.image = data?.link;
        } else {
          bannerData.image = values.image;
        }
      }
      if (id) {
        dispatch(editBannerRequest({ data: bannerData, id }));
      } else {
        dispatch(addBannerRequest({ data: bannerData }));
      }
    }
  });

  return (
    <div className="add-edit-banner-page">
      <Navbar
        NavbarTitle={
          id ? banners.editPage.pageTitle : banners.addPage.pageTitle
        }
      />
      <form noValidate onSubmit={handleSubmit}>
        <div className="px-5 bg-white mx-4 my-4 form-container">
          <div className="py-4">
            <div className="row mt-4">
              <div className="col-6">
                <Input
                  id="title"
                  label={banners.lables.title}
                  name="title"
                  type="text"
                  placeholder={banners.placeholders.title}
                  onChange={(value) => {
                    setFieldTouched("title");
                    setFieldValue("title", value);
                  }}
                  value={values["title"]}
                  inputWrapperClass="mb-4"
                  labelClassName="text-dark font-medium mb-2"
                  isInputHasErr={
                    !!(touched["title"] && errors["title"])
                  }
                  errMsg={errors["title"]}
                />
              </div>
              <div className="col-6">
                <Input
                  id="subtitle"
                  label={banners.lables.subTitle}
                  name="subtitle"
                  type="text"
                  placeholder={banners.placeholders.subTitle}
                  onChange={(value) => {
                    setFieldTouched("subtitle");
                    setFieldValue("subtitle", value);
                  }}
                  value={values["subtitle"]}
                  inputWrapperClass="mb-4"
                  labelClassName="text-dark font-medium mb-2"
                  isInputHasErr={
                    !!(touched["subtitle"] && errors["subtitle"])
                  }
                  errMsg={errors["subtitle"]}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-8">
                <div className="mb-4">
                  <span className="text-dark font-medium">
                    {banners.lables.image}
                  </span>
                  <span className="text-bg-primary-mid ms-2">*</span>
                  <span className="text-bg-primary-mid fsize-14 font-reguler ms-2">
                    {banners.lables.ratio}
                  </span>
                </div>

                <Upload
                  label={
                    <div className="pt-1 d-flex flex-column align-items-center justify-content-center banner-image-upload-initial-container">
                      <ImageOutlinedIcon fontSize="large" />
                      <span>{banners.lables.uploadLabel}</span>
                    </div>
                  }
                  onChange={(img, value) => {
                    setImg(img);
                    setImgChanged(true);
                    setFieldValue("image", value);
                  }}
                  img={img || values["image"]}
                  name="image"
                  isBanner={true}
                  className="mt-3 min-height-image"
                  bannerClassName="banner-image-upload-container"
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-3 pe-4">
                <Input
                  id="duration"
                  label={banners.lables.duration}
                  labelAdornment={
                    <>
                      <span className="text-bg-primary-mid ms-2">
                        *
                      </span>
                      <span className="text-bg-primary-mid fsize-14 font-reguler ms-2">
                        {banners.lables.seconds}
                      </span>
                    </>
                  }
                  name="duration"
                  type="number"
                  onKeyDown={(e) =>
                    (e.key === "e" || e.key === ".") &&
                    e.preventDefault()
                  }
                  onChange={(value) => {
                    if (value >= 1 || !value) {
                      setFieldTouched("duration");
                      setFieldValue("duration", value);
                    }
                  }}
                  value={values["duration"]}
                  inputWrapperClass="mb-4"
                  labelClassName="text-dark font-medium mb-2"
                  isInputHasErr={
                    !!(touched["duration"] && errors["duration"])
                  }
                  min={1}
                  errMsg={errors["duration"]}
                />
              </div>
              <div className="col-4  mt-3 pt-1 ps-5">
                <div className="d-flex flex-column justify-content-between">
                  <Switch
                    checked={!values["is_hidden"]}
                    name="is_hidden"
                    onChange={() => {
                      setFieldTouched("is_hidden");
                      setFieldValue(
                        "is_hidden",
                        !values["is_hidden"]
                      );
                    }}
                  />
                  <span className="text-gray mt-2">
                    {banners.lables.displayed}
                  </span>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              {/* Hide URL Field Temporary */}
              {/* <div className="col-8 pe-5">
                <Input
                  id="url"
                  label={banners.lables.url}
                  name="url"
                  type="text"
                  placeholder={banners.placeholders.url}
                  onChange={(value) => {
                    setFieldTouched("url");
                    setFieldValue("url", value);
                  }}
                  value={values["url"]}
                  inputWrapperClass="mb-4"
                  labelClassName="text-dark font-medium mb-2"
                  isInputHasErr={!!(touched["url"] && errors["url"])}
                  errMsg={errors["url"]}
                />
              </div> */}
            </div>

            <div className="row mt-4 py-4 d-flex justify-content-center">
              <div className="col-4">
                <Button
                  onClick={() => {}}
                  type="submit"
                  block
                  label={id ? banners.save : banners.create}
                  labelClass="px-4 fsize-16 font-medium text-white"
                  disabled={!dirty || !isValid}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddEditBanner;
