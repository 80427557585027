import React from "react";

export const Exclamation = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      fill="none"
      viewBox="0 0 42 42"
    >
      <path
        fill="#393939"
        d="M20.979 41.667C9.475 41.654.159 32.323.167 20.819.175 9.317 9.504-.004 21.007 0 32.51.004 41.832 9.33 41.832 20.833c-.006 11.51-9.344 20.838-20.854 20.834zM4.333 21.192a16.667 16.667 0 100-.359v.359zm18.75 10.058h-4.166v-4.167h4.166v4.167zm0-8.333h-4.166v-12.5h4.166v12.5z"
      ></path>
    </svg>
  );
};
