import * as Yup from "yup";

import messages from "../../assets/locale/messages";
import { ONLY_POSITIVE_NUMBERS_WITH_MAX_TWO_DECIMAL_PLACES } from "../../utils/Patterns";

const lang = localStorage.getItem("lang") || "en";

const {
  addEditPage: { validations }
} = messages[lang].offers;

export const addEditOfferSchema = (originalProductPrice) => {
  const schema = Yup.object({
    // offered_by: Yup.string().required(),
    // Will Be Used Later for Multi offers Providers
    wholesaler_id: Yup.number().required(
      validations.wholesalerRequired
    ),
    wholesalers_product_id: Yup.number().required(
      validations.productRequired
    ),
    discount_type: Yup.string().required(validations.offerType),
    discount_value: Yup.string().when("discount_type", {
      is: (discount_type) => {
        return discount_type === "amount";
      },
      then: Yup.string()
        .matches(
          ONLY_POSITIVE_NUMBERS_WITH_MAX_TWO_DECIMAL_PLACES,
          validations.amount
        )
        .test(
          "less than price",
          validations.discountMoreThanPrice,
          (value) => {
            return !(+value >= originalProductPrice);
          }
        )
        .required(""),
      otherwise: Yup.string()
        .matches(
          ONLY_POSITIVE_NUMBERS_WITH_MAX_TWO_DECIMAL_PLACES,
          validations.percentage
        )
        .test(
          "less than pone hundred",
          validations.percentage,
          (value) => {
            return +value < 100;
          }
        )
        .required("")
    }),
    start_datetime: Yup.string(validations.startDate)
      .typeError(validations.startDate)
      .required(validations.startDate),
    end_datetime: Yup.string(validations.endDate)
      .typeError(validations.endDate)
      .required(validations.endDate)
  });

  return schema;
};

export const getInitialValues = (offer) => {
  return {
    // offered_by: "wholesaler",
    // Will Be Used Later for Multi offers Providers
    wholesaler_id: offer?.wholesalers_product?.wholesaler?.id || "",
    wholesalers_product_id: offer?.wholesalers_product?.id || "",
    discount_type: offer?.discount_type || "",
    discount_value: offer?.discount_value || "",
    start_datetime: offer?.start_datetime || "",
    end_datetime: offer?.end_datetime || ""
  };
};
