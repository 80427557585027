import React from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { FormHelperText } from "@material-ui/core";

import "./Filter.scss";

const Filter = ({
  value,
  inputClassName,
  disabled,
  options,
  placeholder,
  id,
  name,
  onChange,
  helperText,
  helperTextClass,
  filterWrapperClass
}) => {
  return (
    <div
      className={`d-flex justify-content-center flex-column filterWrapper ${filterWrapperClass}`}
    >
      <Autocomplete
        name={name}
        id={id || name}
        options={options}
        disabled={disabled}
        getOptionLabel={(option) => option.label}
        onChange={(e, value) => {
          onChange(value);
        }}
        className={`${inputClassName}`}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            variant="outlined"
            color="primary"
          />
        )}
      />
      {helperText && (
        <FormHelperText
          className={helperTextClass || "font-medium fsize-12"}
        >
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
};

export default Filter;

Filter.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  inputClassName: PropTypes.string,
  filterWrapperClass: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string
    })
  ),
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  helperTextClass: PropTypes.string,
  value: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    label: PropTypes.string.isRequired
  })
};
