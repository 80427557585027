import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TableCell, TableRow } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Visibility, Block } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import debounce from "lodash.debounce";

import Navbar from "../../components/Navbar";
import EmptyState from "../../components/EmptyState";
import SearchInput from "../../components/SearchInput";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import Filter from "../../components/Filter";
import DatePicker from "../../components/DatePicker";
import messages from "../../assets/locale/messages";
import {
  getRetailersRequest,
  getRetailersLookupRequest
} from "../../store/Retailers/actions";
import { setRetailersRoute } from "../../store/Routing/actions";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { formatDate } from "../../utils/Helpers";
import "./Retailers.scss";

const RetailerBlockedTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.tooltip.backgroundGrey,
    color: theme.tooltip.backgroundBlue,
    fontSize: "1rem"
  },
  arrow: {
    color: theme.tooltip.backgroundGrey,
    fontSize: "1rem"
  }
}))(Tooltip);

const Retailers = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const meta = useSelector((state) => state.retailers.meta);
  const isLoading = useSelector((state) => state.loader);
  const list = useSelector((state) => state.retailers.list);
  const retailersOptions = useSelector(
    (state) => state.retailers.retailersNames
  );
  const retailersRoutingData = useSelector(
    (state) => state.routing.retailers
  );

  const { retailers, general } = messages[lang];
  const [searchValue, setSearchValue] = useState(
    retailersRoutingData?.search || ""
  );
  const [searchQuery, setSearchQuery] = useState(
    retailersRoutingData?.search || ""
  );

  const [filterRetailer, setFilterRetailer] = useState(
    retailersRoutingData?.retailer || null
  );
  const [startDate, setStartDate] = useState(
    retailersRoutingData?.startDate || null
  );
  const [endDate, setEndDate] = useState(
    retailersRoutingData?.endDate || null
  );
  const [startDateErr, setStartDateErr] = useState(null);
  const [endDateErr, setEndDateErr] = useState(null);
  const [page, setPage] = useState(retailersRoutingData?.page || 1);
  const [retailersLookUp, setRetailersLookUp] = useState([]);

  const updateSearchQuery = () => {
    setSearchValue(searchQuery);
  };
  const delayedSearchQuery = useCallback(
    debounce(updateSearchQuery, 500),
    [searchQuery]
  );
  useEffect(() => {
    delayedSearchQuery();
    // Cancel the debounce on useEffect cleanup.
    return delayedSearchQuery.cancel;
  }, [searchQuery, delayedSearchQuery]);

  useEffect(() => {
    if (retailersRoutingData) {
      dispatch(setRetailersRoute(null));
    }
  }, [retailersRoutingData]);

  useEffect(() => {
    setPage(retailersRoutingData?.page || 1);
    getRetailers(
      retailersRoutingData?.page || 1,
      searchValue,
      filterRetailer?.label,
      startDate,
      endDate
    );
  }, [filterRetailer]);

  useEffect(() => {
    getRetailers(
      page,
      searchValue,
      filterRetailer?.label,
      startDate,
      endDate
    );
  }, [page]);

  useEffect(() => {
    const options = retailersOptions.map((retailer, index) => ({
      id: index,
      label: retailer
    }));
    setRetailersLookUp(options);
  }, [retailersOptions]);

  useEffect(() => {
    dispatch(
      getRetailersLookupRequest({
        page: -1,
        items: 1,
        lookup_attribute: "name"
      })
    );
  }, []);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      setPage(retailersRoutingData?.page || 1);
      getRetailers(
        retailersRoutingData?.page || 1,
        searchValue,
        filterRetailer?.label,
        startDate,
        endDate
      );
    }
  }, [searchValue]);

  const getRetailers = (
    pageNumber,
    search,
    name,
    startDate,
    endDate
  ) => {
    dispatch(
      getRetailersRequest({
        page: pageNumber,
        items: 10,
        retailer_data: search,
        name,
        from_date: startDate?.toString(),
        to_date: endDate?.toString()
      })
    );
  };

  const viewRetailer = (id) => {
    dispatch(
      setRetailersRoute({
        page,
        search: searchValue,
        retailer: filterRetailer,
        startDate,
        endDate
      })
    );
    history.push(ROUTE_PATHS.viewRetailer.replace(":id", id));
  };
  const checkEndAfterStart = () => {
    if (
      startDate &&
      endDate &&
      startDate.getTime() > endDate.getTime()
    ) {
      setStartDateErr(retailers.dateError);
      setEndDateErr(retailers.dateError);
    } else {
      setStartDateErr(null);
      setEndDateErr(null);
    }
  };

  useEffect(() => {
    checkEndAfterStart();
  }, [startDate, endDate]);

  useEffect(() => {
    getRetailers(
      page,
      searchValue,
      filterRetailer?.label,
      startDate,
      endDate
    );
  }, [startDate, endDate]);

  const actionsRendering = () => {
    return (
      <div className="d-flex justify-content-between search-bar pe-4 mt-3  align-items-center">
        <div className="d-flex align-items-center ms-5 w-50">
          <p className="me-3 joining-date">{retailers.JoiningDate}</p>
          <DatePicker
            label={retailers.from}
            inputWrapperClass="me-2 w-30"
            name="start Date"
            value={startDate}
            isInlineLabel
            handleDateChange={(value) => {
              setStartDate(value);
            }}
            maxDate={endDate}
            placeholder={formatDate(new Date(), "DD/MM/yyyy")}
            isInputHasErr={!!startDateErr}
            errMsg={startDateErr}
          />
          <DatePicker
            label={retailers.to}
            name={"end Date"}
            value={endDate}
            inputWrapperClass="me-2 w-30"
            isInlineLabel
            handleDateChange={(value) => {
              setEndDate(value);
            }}
            minDate={startDate}
            placeholder={formatDate(new Date(), "DD/MM/yyyy")}
            isInputHasErr={!!endDateErr}
            errMsg={endDateErr}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center w-50">
          {retailersOptions && (
            <Filter
              placeholder={retailers.RetailerName}
              options={retailersLookUp}
              value={filterRetailer}
              name="retailer"
              onChange={(value) => {
                setFilterRetailer(value);
              }}
              inputClassName="me-4 mt-1"
              filterWrapperClass="filter-retailer"
            />
          )}
          <SearchInput
            name="search"
            value={searchQuery}
            onChange={(value) => {
              setSearchQuery(value);
            }}
            placeholder={retailers.search}
            inputWrapperClass="me-2"
          />
        </div>
      </div>
    );
  };

  const List = () => {
    if (
      !isLoading &&
      list.length == 0 &&
      !searchValue &&
      !startDate &&
      !endDate
    ) {
      return (
        <>
          <div className="content-wrapper retailers-page d-flex justify-content-center align-items-center flex-column">
            <EmptyState
              subTitle={retailers.RetailerEmptyState}
              showActionButton={false}
            />
          </div>
        </>
      );
    } else if (
      !isLoading &&
      list.length == 0 &&
      (searchValue || filterRetailer || startDate || endDate)
    ) {
      return (
        <>
          {actionsRendering()}
          <div className="content-wrapper retailers-page d-flex justify-content-center align-items-center flex-column">
            <EmptyState
              subTitle={general.noSearch}
              showActionButton={false}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          {actionsRendering()}
          <div className="content-wrapper retailers-page d-flex  align-items-center flex-column">
            <Table
              headlines={[
                retailers.Name,
                retailers.StoreName,
                retailers.JoiningDate,
                retailers.Phone
              ]}
              hasActions={true}
              rows={list.map((row, i) => (
                <TableRow key={i}>
                  <TableCell className="font-reguler text-dark-blue fsize-14 ">
                    {row.name ? (
                      <div className="d-flex align-items-center">
                        {row.name}
                        {!row.is_activated && (
                          <div className="ms-2 d-flex blocked-retailer align-items-center ">
                            <RetailerBlockedTooltip
                              title={
                                <span className="fsize-10">
                                  {retailers.blocked}
                                </span>
                              }
                              placement="right-start"
                              arrow
                            >
                              <Block fontSize="large" />
                            </RetailerBlockedTooltip>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="incomplete text-center">
                        <p className="px-3 py-2 mb-0">
                          {retailers.incompleteProfile}
                        </p>
                      </div>
                    )}
                  </TableCell>
                  <TableCell className="font-reguler text-dark-blue fsize-14 ">
                    {row.storename == null ? "---" : row.storename}
                  </TableCell>
                  <TableCell className="font-reguler text-dark-blue fsize-14 ">
                    {formatDate(row.created_at, "DD/MM/YYYY")}
                  </TableCell>
                  <TableCell className="font-reguler text-dark-blue fsize-14 ">
                    {row.country_code} {row.phone_number}
                  </TableCell>

                  <TableCell className="font-reguler text-dark-blue d-flex  px-0">
                    {row.name && row.storename ? (
                      <div className="pt-2 d-flex flex-column align-items-center   ">
                        <Visibility
                          color="secondary"
                          fontSize="large"
                          className="opacity_7 pointer "
                          onClick={() => {
                            viewRetailer(row.id);
                          }}
                        />
                        <span className="text-gray">
                          {retailers.view}
                        </span>
                      </div>
                    ) : (
                      <div className="pt-2 d-flex flex-column align-items-center  visibilty-disabled ">
                        <Visibility
                          fontSize="large"
                          className="opacity_7   "
                        />
                        <span>{retailers.view}</span>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            />
            <div className="d-flex justify-content-center align-items-center pt-4 pb-3">
              {meta.pages > 1 && (
                <Pagination
                  count={meta.pages}
                  page={meta.currentPage || page}
                  handleChange={(event, page) => {
                    setPage(page);
                  }}
                  defaultPage={1}
                />
              )}
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <div className="retailers-page">
      <Navbar NavbarTitle={retailers.RetailersList} />
      {List()}
    </div>
  );
};

export default Retailers;
