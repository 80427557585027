import * as types from "./types";

export const loginRequest = (data) => ({
  type: types.LOGIN_REQUEST,
  payload: data
});

export const loginResponse = (data) => ({
  type: types.LOGIN_RESPONSE,
  payload: data
});

export const logoutRequest = (data) => ({
  type: types.LOGOUT_REQUEST,
  payload: data
});
