import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SwiperSlide } from "swiper/react";
import { useParams, useHistory } from "react-router-dom";

import messages from "../../../assets/locale/messages";
import AlertNote from "../../../components/AlertNote";
import Navbar from "../../../components/Navbar";
import SearchInput from "../../../components/SearchInput";
import ProductCard from "../../../components/ProductCard";
import Filter from "../../../components/Filter";
import Switch from "../../../components/Switch";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import Breadcrumb from "../../../components/Breadcrumb";
import Pagination from "../../../components/Pagination";
import EmptyState from "../../../components/EmptyState";
import {
  getWholesalerProductsRequest,
  getWholesalerRequest,
  getWholesalerProductsResponse,
  deleteWholesalerProductRequest,
  getWholsalerProductRequest,
  editWholesalerProductRequest
} from "../../../store/Wholesalers/actions";
import { getProductCategoriesRequest } from "../../../store/ProductCategories/actions";
import { setWholesalerProductsRoute } from "../../../store/Routing/actions";
import DeleteIcon from "../../../utils/Icons/Delete";
import { EditIcon } from "../../../utils/Icons/EditIcon";
import Modal from "../../../components/Modal";
import WarhouseProductModal from "../../../components/WarehouseProductModal";

import "./WholesalerProducts.scss";

const WholeSalerProducts = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const lang = useSelector((state) => state.locale.lang);
  const {
    products: {
      search,
      wholesalerTitle,
      products,
      categories,
      pound,
      edit,
      inStock,
      runOut,
      deleteBtn
    }
  } = messages[lang].Wholesalers;

  const { general } = messages[lang];

  const productsList = useSelector(
    (state) => state.wholesalers.productsList
  );

  const product = useSelector(
    (state) => state.wholesalers.wholesalerProduct
  );

  const meta = useSelector((state) => state.wholesalers.productsMeta);
  const categoriesList = useSelector(
    (state) => state.productCategories.list
  );
  const wholesaler = useSelector(
    (state) => state.wholesalers.wholesaler
  );
  const isLoading = useSelector((state) => state.loader);
  const productsRoutingData = useSelector(
    (state) => state.routing.wholesalerProducts
  );

  const [searchValue, setSearchValue] = useState(
    productsRoutingData?.search || ""
  );
  const [category, setCategory] = useState(
    productsRoutingData?.category || null
  );
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [page, setPage] = useState(productsRoutingData?.page || 1);
  const [openEdit, setOpenEdit] = useState(false);

  const getWholesalerProducts = (
    pageNumber,
    query,
    product_category_id
  ) => {
    dispatch(
      getWholesalerProductsRequest({
        id,
        params: {
          page: pageNumber,
          items: 16,
          query,
          product_category_id
        }
      })
    );
  };

  useEffect(() => {
    dispatch(
      getProductCategoriesRequest({
        page: -1,
        items: 1
      })
    );
  }, []);

  useEffect(() => {
    const options = categoriesList.map((category) => ({
      id: category.id,
      label: category.name
    }));
    setCategoriesOptions(options);
  }, [categoriesList]);

  useEffect(() => {
    getWholesalerProducts(page, searchValue, category?.id);
  }, [page]);

  useEffect(() => {
    setPage(productsRoutingData?.page || 1);
    getWholesalerProducts(
      productsRoutingData?.page || 1,
      searchValue,
      category?.id
    );
  }, [category]);

  useEffect(() => {
    return () => {
      dispatch(
        getWholesalerProductsResponse({
          wholesalerProducts: [],
          meta: {}
        })
      );
    };
  }, []);

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      setPage(productsRoutingData?.page || 1);
      getWholesalerProducts(
        productsRoutingData?.page || 1,
        searchValue,
        category?.id
      );
    }
  }, [searchValue]);

  useEffect(() => {
    if (id) {
      dispatch(getWholesalerRequest(id));
    }
  }, [id]);

  useEffect(() => {
    if (productsRoutingData) {
      dispatch(setWholesalerProductsRoute(null));
    }
  }, [productsRoutingData]);

  const handleView = (proId) => {
    dispatch(
      setWholesalerProductsRoute({
        page,
        search: searchValue,
        category
      })
    );
    history.push(
      ROUTE_PATHS["viewWholesalerProduct"]
        .replace(":id", id)
        .replace(":productId", proId)
    );
  };

  const productImagesSlides = (imagesArray) => {
    let slides = [];
    if (imagesArray.length) {
      slides = imagesArray.map((image) => (
        <SwiperSlide key={image.id}>
          <div
            className="product-card-image-container"
            style={{ backgroundImage: `url(${image.url})` }}
          ></div>
        </SwiperSlide>
      ));
    } else {
      slides.push(
        <SwiperSlide key={1}>
          <div
            className="product-card-image-container product-card-image-container-empty"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_PRODUCTS_PLACEHOLDER})`
            }}
          ></div>
        </SwiperSlide>
      );
    }
    return slides;
  };

  const breadcrumb = () => (
    <Breadcrumb
      linksArray={[
        {
          link: ROUTE_PATHS["wholesalerDetails"].replace(":id", id),
          name: wholesalerTitle
        },
        {
          name: products
        }
      ]}
    />
  );

  const deleteProduct = (productId) => {
    dispatch(
      deleteWholesalerProductRequest({
        page,
        query: searchValue,
        product_category_id: category?.id,
        wholsalerId: id,
        productId
      })
    );
  };

  const handleEdit = (productId) => {
    setOpenEdit(true);
    dispatch(
      getWholsalerProductRequest({ wholsalerId: id, productId })
    );
  };

  const handleCloseEditModal = () => {
    setOpenEdit(false);
  };

  const toggleStock = (inStock, productId) => {
    dispatch(
      editWholesalerProductRequest({
        wholsalerId: id,
        productId,
        page,
        query: searchValue,
        product_category_id: category?.id,
        data: {
          is_in_stock: !inStock
        }
      })
    );
  };

  const wholesalerProductsList = () => {
    if (
      !isLoading &&
      productsList.length == 0 &&
      !searchValue &&
      !category
    ) {
      return (
        <div className="wholesaler-details-container">
          <Navbar
            NavbarTitle={
              <span
                className={`d-flex align-items-center ${
                  !wholesaler?.logo && "py-3"
                }`}
              >
                {wholesaler?.username || ""}
                {wholesaler?.logo ? (
                  <div
                    style={{
                      backgroundImage: `url(${wholesaler?.logo})`
                    }}
                    className="navbar-logo ms-4"
                  ></div>
                ) : (
                  ""
                )}
              </span>
            }
          />
          <div className="bg-white m-4 py-4 px-5">
            {breadcrumb()}
            <div className="warehouses-page d-flex justify-content-center align-items-center flex-column">
              <EmptyState
                subTitle={"There are no products to display"}
                showActionButton={false}
              />
            </div>
          </div>
        </div>
      );
    } else if (
      !isLoading &&
      productsList.length == 0 &&
      (searchValue || category)
    ) {
      return (
        <>
          <Navbar
            NavbarTitle={
              <span
                className={`d-flex align-items-center ${
                  !wholesaler?.logo && "py-3"
                }`}
              >
                {wholesaler?.username || ""}
                {wholesaler?.logo ? (
                  <div
                    style={{
                      backgroundImage: `url(${wholesaler?.logo})`
                    }}
                    className="navbar-logo ms-4"
                  ></div>
                ) : (
                  ""
                )}
              </span>
            }
          />
          <div className="d-flex justify-content-end align-items-center pe-4 mt-3 wholesaler-products-wrapper">
            <Filter
              placeholder={categories}
              options={categoriesOptions}
              name="Category"
              onChange={(value) => {
                setCategory(value);
              }}
              value={category}
              inputClassName="me-4 mt-1"
            />
            <SearchInput
              name="search"
              value={searchValue}
              onChange={(value) => {
                setSearchValue(value);
              }}
              placeholder={search}
            />
          </div>
          <div className="bg-white m-4 py-4 px-5">
            {breadcrumb()}
            <AlertNote
              alertText={
                general.noteOfChangingProductsWillAffectOffers
              }
              wrapperClasses="mt-5 mb-4"
            />
            <div className="warehouses-page d-flex justify-content-center align-items-center flex-column">
              <EmptyState
                subTitle={general.noSearch}
                showActionButton={false}
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="wholesaler-details-container">
          <Navbar
            NavbarTitle={
              <span
                className={`d-flex align-items-center ${
                  !wholesaler?.logo && "py-3"
                }`}
              >
                {wholesaler?.username || ""}
                {wholesaler?.logo ? (
                  <div
                    style={{
                      backgroundImage: `url(${wholesaler?.logo})`
                    }}
                    className="navbar-logo ms-4"
                  ></div>
                ) : (
                  ""
                )}
              </span>
            }
          />
          <div className="d-flex justify-content-end align-items-center pe-4 mt-3 wholesaler-products-wrapper">
            <Filter
              placeholder={categories}
              options={categoriesOptions}
              name="Category"
              onChange={(value) => {
                setCategory(value);
              }}
              value={category}
              inputClassName="me-4 mt-1"
            />
            <SearchInput
              name="search"
              value={searchValue}
              onChange={(value) => {
                setSearchValue(value);
              }}
              placeholder={search}
            />
          </div>
          <div className="bg-white mx-4 my-3 py-4 px-5">
            {breadcrumb()}
            <AlertNote
              alertText={
                general.noteOfChangingProductsWillAffectOffers
              }
              wrapperClasses="mt-5 mb-4"
            />
            <div className="row">
              {productsList.map(
                ({ id, product, price, is_in_stock, has_offer }) => {
                  return (
                    <div
                      key={product.id}
                      className=" mb-4 subProducts-child col-3"
                    >
                      <ProductCard
                        prdImgs={productImagesSlides(product.images)}
                        initialSlide={product.images?.findIndex(
                          (image) => image.is_default
                        )}
                        prdDescription={product.description}
                        prdCategory={
                          product.super_product.product_category.name
                        }
                        prdNameParent={product.concatenated_name}
                        prdSpecifications={product.specifications}
                        deactivated={product.status !== "active"}
                        actions
                        isOutOfStock={!is_in_stock}
                        hasOffer={has_offer}
                        handleViewProduct={() => {
                          handleView(id);
                        }}
                        insideWholesalerProducts
                        actionsElement={
                          <div className="d-flex justify-content-between align-items-center   ">
                            <div className="priceLabel text-white ms-2">
                              <span>{price}</span>
                              <span className="ms-1">{pound}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between ">
                              <div
                                className="d-flex align-items-center flex-column delete mx-1"
                                onClick={() => {
                                  deleteProduct(id);
                                }}
                              >
                                <DeleteIcon className="fsize-10 icon-delete" />
                                <span className="fsize-10 ">
                                  {deleteBtn}
                                </span>
                              </div>
                              <div
                                className="d-flex align-items-center  flex-column edit mx-1 "
                                onClick={() => {
                                  handleEdit(id);
                                }}
                              >
                                <EditIcon />
                                <span className=" fsize-10">
                                  {edit}
                                </span>
                              </div>
                              <div className="d-flex flex-column align-items-center flex-column mt-2 mx-1">
                                <Switch
                                  checked={is_in_stock || false}
                                  name="inStock"
                                  onChange={() => {
                                    toggleStock(is_in_stock, id);
                                  }}
                                />
                                <p className="toggle-text fsize-10">
                                  {is_in_stock ? inStock : runOut}
                                </p>
                              </div>
                            </div>
                          </div>
                        }
                      />
                    </div>
                  );
                }
              )}
            </div>
            <div className="d-flex justify-content-center py-3">
              {meta.pages > 1 && (
                <Pagination
                  count={meta.pages}
                  page={meta.currentPage || page}
                  handleChange={(event, page) => {
                    setPage(page);
                  }}
                  defaultPage={1}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <>
      {wholesalerProductsList()}
      <Modal
        open={openEdit}
        onClose={handleCloseEditModal}
        className="editModal"
        modalBody={
          <WarhouseProductModal
            onClose={handleCloseEditModal}
            product={product}
            productId={product.id}
            page={page}
            query={searchValue}
            product_category_id={category?.id}
            isEdit
            isWholesalerOption={false}
          />
        }
      />
    </>
  );
};

export default WholeSalerProducts;
