import React from "react";
import PropTypes from "prop-types";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const InfoTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.tooltip.backgroundGrey,
    fontSize: "1rem",
    // width: "10rem",
    color: theme.tooltip.colorBlack
  },
  arrow: {
    color: theme.tooltip.backgroundGrey,
    fontSize: "1rem"
  }
}))(Tooltip);

const RadioButtonsGroup = ({
  wrapperClassName,
  label,
  labelClassName,
  required,
  className,
  handleChange,
  value,
  listOfRadios,
  endAdornment,
  disabled,
  hasDisabledReplacementBody,
  disabledReplacementBody,
  alignedColumn,
  alignedColumnClasses
}) => {
  return (
    <FormControl component="fieldset" className={wrapperClassName}>
      <FormLabel component="legend" className={labelClassName}>
        {label}
        {required ? <span className="err-asterisk">*</span> : ""}
        {endAdornment}
      </FormLabel>
      {hasDisabledReplacementBody ? (
        disabledReplacementBody
      ) : (
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={value}
          onChange={handleChange}
          className={className}
        >
          {listOfRadios.map((radio, index) => (
            <div
              className={alignedColumn ? alignedColumnClasses : ""}
              key={index}
            >
              <FormControlLabel
                value={radio.value}
                control={<Radio />}
                label={radio.label}
                disabled={disabled ? disabled : radio.disabled}
              />
              {radio.endAddornment && (
                <InfoTooltip
                  title={<div>{radio.info}</div>}
                  placement="right"
                  arrow
                >
                  <span>{radio.endAddornment}</span>
                </InfoTooltip>
              )}
              <div className="d-flex align-items-center ">
                {radio.value == value && radio.children}
              </div>
            </div>
          ))}
        </RadioGroup>
      )}
    </FormControl>
  );
};
export default RadioButtonsGroup;

RadioButtonsGroup.propTypes = {
  wrapperClassName: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  listOfRadios: PropTypes.array,
  endAdornment: PropTypes.element,
  disabled: PropTypes.bool,
  hasDisabledReplacementBody: PropTypes.bool,
  disabledReplacementBody: PropTypes.element,
  alignedColumn: PropTypes.bool,
  alignedColumnClasses: PropTypes.string
};
