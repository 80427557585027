import { put, call, takeEvery } from "redux-saga/effects";
import * as types from "./types";
import * as apis from "../../network/apis/promoCodes";
import {
  getPromoCodesResponse,
  getPromoCodeResponse,
  getPromoCodesRequest,
  getOnlyCodesResponse
} from "./actions";
import { showHideSnackbar } from "../Snackbar/actions";
import messages from "../../assets/locale/messages";

export function* getPromoCodesSaga({ payload }) {
  try {
    const response = yield call(apis.getPromoCodes, payload);
    yield put(
      getPromoCodesResponse({
        list: response.data.promo_codes,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export function* getOnlyCodesSaga({ payload }) {
  try {
    const response = yield call(apis.getPromoCodes, payload);
    yield put(
      getOnlyCodesResponse({
        codes: response.data.promo_codes
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export function* getPromoCodeSaga({ payload }) {
  try {
    const response = yield call(apis.getPromoCode, payload);
    yield put(getPromoCodeResponse(response.data.promo_code));
  } catch (err) {
    console.log(err);
  }
}

export function* addPromoCodeSaga({ payload }) {
  try {
    yield call(apis.addPromoCode, payload.data);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].promocodes.createSuccess
      })
    );
    const { page, searchValue, filterStatus, filterOffer } = payload;
    yield put(
      getPromoCodesRequest({
        page,
        items: 8,
        promo_code_data: searchValue,
        is_activated: filterStatus?.value,
        offered_by: filterOffer?.value
      })
    );
  } catch (err) {
    yield put(
      getPromoCodeResponse({
        addResponse: err.response.data
      })
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}

export function* editPromoCodeSaga({ payload }) {
  try {
    yield call(apis.editPromoCode, payload);
    const response = yield call(apis.getPromoCode, payload.id);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].promocodes.editSuccess
      })
    );
    const { page, searchValue, filterStatus, filterOffer } = payload;
    yield put(
      getPromoCodesRequest({
        page,
        items: 8,
        promo_code_data: searchValue,
        is_activated: filterStatus?.value,
        offered_by: filterOffer?.value
      })
    );
    yield put(getPromoCodeResponse(response.data.promo_code));
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export function* deletePromoCodeSaga({ payload }) {
  try {
    yield call(apis.deletePromoCode, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].promocodes.deleteSuccess
      })
    );
    const { page, searchValue, filterStatus, filterOffer } = payload;
    yield put(
      getPromoCodesRequest({
        items: 8,
        page,
        promo_code_data: searchValue,
        is_activated: filterStatus?.value,
        offered_by: filterOffer?.value
      })
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response.data.message
      })
    );
  }
}

export default function* PromoCodesSagas() {
  yield takeEvery(types.GET_PROMOCODES_REQUEST, getPromoCodesSaga);
  yield takeEvery(types.GET_ONLY_CODES_REQUEST, getOnlyCodesSaga);
  yield takeEvery(types.GET_PROMOCODE_REQUEST, getPromoCodeSaga);
  yield takeEvery(types.ADD_PROMOCODE_REQUEST, addPromoCodeSaga);
  yield takeEvery(types.EDIT_PROMOCODE_REQUEST, editPromoCodeSaga);
  yield takeEvery(
    types.DELETE_PROMOCODE_REQUEST,
    deletePromoCodeSaga
  );
}
