export const ROUTE_PATHS = {
  root: "/",
  login: "/login",
  warehouses: "/warehouses",
  addWarehouse: "/warehouses/add",
  editWarehouse: "/warehouses/:id/edit",
  viewWarehouse: "/warehouses/:id",
  wholesalers: "/wholesalers",
  addWholesaler: "/wholesalers/add",
  editWholesaler: "/wholesalers/:id/edit",
  warehouseAddLocation: "/warehouses/add/location",
  warehouseEditLocation: "/warehouses/:id/edit/location",
  warehouseAddCoverageAreas: "/warehouses/:id/edit/covargeArea",
  warehouseEditCoverageAreas:
    "/warehouses/:id/edit/covargeArea/:zoneId",
  CoverageAreas: "/warehouses/add/covargeArea",
  wholesalerDetails: "/wholesalers/:id",
  wholesalerProducts: "/wholesalers/:id/products",
  viewWholesalerProduct: "/wholesalers/:id/products/:productId",
  productCategories: "/categories",
  allProducts: "/all-products",
  superProducts: "/categories/:id/super-products",
  subProducts: "/categories/:id/super-products/:superId/products",
  addSubProduct:
    "/categories/:id/super-products/:superId/products/add",
  editSubProduct:
    "/categories/:id/super-products/:superId/products/:prdId/edit",
  retailers: "/retailers",
  viewRetailer: "/retailers/:id",
  orders: "/orders",
  viewOrder: "/orders/:id",
  ordersProducts: "/orders/:id/orders-products",
  notifications: "/notifications",
  promoCodes: "/promocodes",
  banners: "/banners",
  addBanner: "/banners/add",
  editBanner: "/banners/:id/edit",
  offers: "/offers",
  addOffer: "/offers/add",
  editOffer: "/offers/:id/edit"
};
