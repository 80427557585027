import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  FilledInput,
  FormHelperText
} from "@material-ui/core";

import {
  acceptedImagesFilesTypes,
  acceptedImagesFilesMaxSize
} from "../../utils/Constants";
import messages from "../../assets/locale/messages";
import "./Upload.scss";

const Upload = ({
  accept,
  onChange,
  label,
  required,
  disabled,
  img,
  name,
  multiple,
  className,
  onChangeError,
  isBanner,
  bannerClassName
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { general } = messages[lang];
  const [error, setError] = useState(null);

  useEffect(() => {
    document.getElementById("raised-button-file").accept = accept;
    if (multiple) {
      document.getElementById("raised-button-file").multiple = true;
    }
  }, [accept]);

  const validateImagesFiles = (imagesFilesList) => {
    return Array.from(imagesFilesList).every((file) => {
      if (!acceptedImagesFilesTypes.includes(file.type)) {
        setError(general.uploadTypeError);
        onChangeError && onChangeError(general.uploadTypeError);
        return false;
      }
      if (file.size > acceptedImagesFilesMaxSize) {
        setError(general.uploadSizeError);
        onChangeError && onChangeError(general.uploadSizeError);
        return false;
      }
      setError(null);
      onChangeError && onChangeError(null);
      return true;
    });
  };

  return (
    <div className={className}>
      <FilledInput
        style={{ display: "none" }}
        id="raised-button-file"
        type="file"
        accept={accept}
        onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            if (!validateImagesFiles(e.target.files)) return;
            if (multiple) {
              const files = e.target.files;
              const filesAsArray = [...files];
              let filesSrc = [];
              filesAsArray.forEach((file) => {
                filesSrc.push(URL.createObjectURL(file));
              });
              onChange(filesSrc, files);
            } else {
              var reader = new FileReader();
              reader.onload = (event) => {
                onChange(event.target.result, e.target.files[0]);
              };
              reader.readAsDataURL(e.target.files[0]);
            }
          }
        }}
        required={required}
        disabled={disabled}
        name={name}
      />
      <label
        htmlFor="raised-button-file"
        className={`${
          isBanner
            ? "d-flex justify-content-center position-relative"
            : ""
        }`}
      >
        {img ? (
          <div className={`${isBanner ? "position-relative" : ""}`}>
            <div
              style={{ backgroundImage: `url(${img})` }}
              className={`logoInput pointer ${
                isBanner ? bannerClassName : ""
              }`}
            ></div>
            {isBanner && (
              <div
                style={{ backgroundImage: `url(${img})` }}
                className="blur-image"
              ></div>
            )}
          </div>
        ) : (
          <Button
            variant="contained"
            component="span"
            className="btn-upload"
            disabled={disabled}
          >
            {label}
          </Button>
        )}
      </label>
      <FormHelperText error={true} className="font-medium fsize-12">
        {error}
      </FormHelperText>
    </div>
  );
};

Upload.propTypes = {
  accept: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  img: PropTypes.any,
  name: PropTypes.string,
  multiple: PropTypes.bool,
  className: PropTypes.string,
  onChangeError: PropTypes.func,
  isBanner: PropTypes.bool,
  bannerClassName: PropTypes.string
};

Upload.defaultProps = {
  accept: "image/*",
  label: "Upload",
  required: false,
  disabled: false
};

export default Upload;
