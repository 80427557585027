export const GET_OFFERS_REQUEST = "GET_OFFERS_REQUEST";

export const GET_OFFERS_RESPONSE = "GET_OFFERS_RESPONSE";

export const ADD_OFFER_REQUEST = "ADD_OFFER_REQUEST";

export const GET_OFFER_REQUEST = "GET_OFFER_REQUEST";

export const GET_OFFER_RESPONSE = "GET_OFFER_RESPONSE";

export const GET_PREVIOUS_PRODUCT_OFFERS_REQUEST =
  "GET_PREVIOUS_PRODUCT_OFFERS_REQUEST";

export const GET_PREVIOUS_PRODUCT_OFFERS_RESPONSE =
  "GET_PREVIOUS_PRODUCT_OFFERS_RESPONSE";

export const EDIT_OFFER_REQUEST = "EDIT_OFFER_REQUEST";

export const EDIT_OFFER_RESPONSE = "EDIT_OFFER_RESPONSE";

export const DELETE_OFFER_REQUEST = "DELETE_OFFER_REQUEST";

export const DELETE_OFFER_RESPONSE = "DELETE_OFFER_RESPONSE";
