import { put, call, takeEvery } from "redux-saga/effects";
import * as types from "./types";
import * as apis from "../../network/apis/orders";
import {
  getOrdersResponse,
  getOrderDetailsResponse,
  getOrdersFullListResponse,
  getOrderPDFResponse
} from "./actions";
import { showHideSnackbar } from "../Snackbar/actions";
import messages from "../../assets/locale/messages";

export function* getOrdersSaga({ payload }) {
  try {
    const response = yield call(apis.getOrders, payload);
    yield put(
      getOrdersResponse({
        list: response.data.orders,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export function* getOrdersFullListSaga({ payload }) {
  try {
    const response = yield call(apis.getOrders, payload);
    yield put(
      getOrdersFullListResponse({
        fullList: response.data.orders
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export function* getOrderDetailsSaga({ payload }) {
  try {
    const response = yield call(apis.getOrderDetails, payload);
    yield put(getOrderDetailsResponse(response.data.order));
  } catch (err) {
    console.log(err);
  }
}

export function* updateOrderRequest({ payload }) {
  try {
    yield call(apis.updateOrder, payload);
    const response = yield call(apis.getOrderDetails, payload.id);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].orders.viewOrder.editSuccess
      })
    );
    yield put(getOrderDetailsResponse(response.data.order));
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export function* deleteOrderProducts({ payload }) {
  try {
    const response = yield call(apis.deleteOrderProducts, payload);
    yield put(getOrderDetailsResponse(response.data.order));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].orders.orderProducts.deleteMsg
      })
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export function* editOrderProductsRequest({ payload }) {
  try {
    const response = yield call(apis.editOrderProducts, payload);
    yield put(getOrderDetailsResponse(response.data.order));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].orders.viewOrder.editProductSuccess
      })
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}
export function* addOrderProductsRequest({ payload }) {
  try {
    const response = yield call(apis.addOrderProducts, payload);
    yield put(getOrderDetailsResponse(response.data.order));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: response?.data?.message
      })
    );
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export function* getOrderPDFSaga({ payload }) {
  try {
    const response = yield call(apis.getOrderPDF, payload);
    yield put(getOrderPDFResponse(response.data.pdf_url));
  } catch (err) {
    console.log(err);
  }
}

export default function* OrdersSagas() {
  yield takeEvery(types.GET_ORDERS_REQUEST, getOrdersSaga);
  yield takeEvery(
    types.GET_ORDER_DETAILS_REQUEST,
    getOrderDetailsSaga
  );
  yield takeEvery(types.UPDATE_ORDER_REQUEST, updateOrderRequest);
  yield takeEvery(
    types.DELETE_ORDER_PRODUCTS_REQUEST,
    deleteOrderProducts
  );
  yield takeEvery(
    types.EDIT_ORDER_PRODUCTS_REQUEST,
    editOrderProductsRequest
  );
  yield takeEvery(
    types.ADD_ORDER_PRODUCTS_REQUEST,
    addOrderProductsRequest
  );
  yield takeEvery(
    types.GET_ORDERS_FULLLIST_REQUEST,
    getOrdersFullListSaga
  );
  yield takeEvery(types.GET_ORDER_PDF_REQUEST, getOrderPDFSaga);
}
