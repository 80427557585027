import React from "react";
import PropTypes from "prop-types";

export const ProductsIcon = ({ selectedIcon }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#fff"
        d="M14.075 0h3.387A2.549 2.549 0 0120 2.56v3.415a2.549 2.549 0 01-2.538 2.56h-3.386a2.549 2.549 0 01-2.539-2.56V2.56A2.549 2.549 0 0114.075 0"
        opacity="0.4"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M2.539 0h3.385a2.549 2.549 0 012.539 2.56v3.415a2.549 2.549 0 01-2.539 2.56H2.54A2.549 2.549 0 010 5.974V2.56A2.549 2.549 0 012.539 0zm0 11.466h3.385a2.549 2.549 0 012.539 2.56v3.414A2.55 2.55 0 015.924 20H2.54A2.55 2.55 0 010 17.44v-3.415a2.549 2.549 0 012.539-2.56zm14.923 0h-3.386a2.549 2.549 0 00-2.539 2.56v3.414A2.55 2.55 0 0014.075 20h3.387A2.55 2.55 0 0020 17.44v-3.415a2.549 2.549 0 00-2.538-2.56z"
        clipRule="evenodd"
        opacity={!selectedIcon ? "0.5" : "1"}
      ></path>
    </svg>
  );
};

ProductsIcon.propTypes = {
  selectedIcon: PropTypes.bool
};
