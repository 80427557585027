const kuzloEn = {
  Warehouses: "Warehouses",
  general: {
    invalidDate: "Invalid Date",
    conditions: "(Conditions)",
    filesAccepted:
      "Max Size: 2MB, Accepted Formats: jpg, jpeg, png, and bmp",
    uploadTypeError:
      "Accepted Formats are jpg, jpeg, png, and bmp only",
    uploadSizeError: "Max Size is 2MB",
    le: "LE",
    noSearch: "There are no results matching your search",
    percentage: "%",
    noteOfChangingProductsWillAffectOffers:
      "Kindly note that upon deleting, deactivating the product or changing the product price, all associated offers will be automatically expired",
    offerValue: "offer value",
    startDate: "start date",
    endDate: "end date",
    startTime: "start time",
    endTime: "end Time",
    enabled: "enabled",
    disabled: "disabled",
    offers: "Offers",
    offer: "Offer"
  },
  notifications: {
    title: "Notifications",
    showMore: "Show More",
    markRead: "Mark as read",
    noNotifications: "There are no notifications yet"
  },
  promocodes: {
    title: "Promocodes",
    list: "Promocodes List",
    add: "Add Promocode",
    status: "Status",
    offerd: "Offered By",
    searchPlaceholder: "Search for promocode (title or code)",
    view: "View & Edit",
    start: "Start",
    end: "End",
    enabled: "Enabled",
    disabled: "Disabled",
    active: "Active",
    inActive: "Inactive",
    serviceProvider: "Kuzlo",
    wholesaler: "Wholesaler",
    delete: "Delete",
    emptyState: "There are no promocodes to display yet",
    deleteConfirmationMsg:
      " Are you sure you want to delete this promocode ",
    cancel: "Cancel",
    newPromocode: "New Promocode",
    promocodeTitle: "Title",
    titlePlaceholder: "Enter name",
    promocode: "Promocodes",
    promocodePlaceholder: "Ex: A60K1",
    description: "Description",
    promocodeStartDate: "Promocode start date & time",
    promocodeEndDate: "Promocode end date & time",
    startDate: "Start Date",
    startTime: "Start Time",
    endDate: "End Date",
    endTime: "End Time",
    addPromoCode: "Add the promocode",
    editPromoCode: "Edit the promocode",
    editSuccess: "Promocode edited successfully",
    createSuccess: "Promocode created successfully",
    deleteSuccess: "Promocode deleted successfully",
    validations: {
      title: "Title is required",
      maxTitle: "Maximum Title Length 30 Characters",
      code: "Code is required",
      maxCode: "Maximum Code Length 15 Characters",
      maxDescription: "Maximum Description Length 60 Characters",
      owner: "Promocode’s owner is required",
      discountType: "Discount type is required",
      numberOnly: "Should Be Only Number",
      discountValue:
        "Discount Value is required and must be positive number for money and from 1 to 100 for percentage",
      amount:
        "Money must be positive number and shouldn’t have decimal places",
      percentage:
        "Percentage must be from 1 to 100 and shouldn’t have decimal places",
      numberUsage: "Number of usage is required",
      startDate: "Promocode start date & time is required",
      endDate: "Promocode end date & time is required",
      futureDate: "Date and Time Should Be in the future",
      beforeStart: "End Date and Time Should Be After Start Date",
      noSpace: "No Space Allowed in Promocode",
      onlyPositive: "Only Positive Numbers",
      wholesaler: "Wholesaler is required",
      codeExist: "Code Already Exist"
    },
    listTitle: "Title",
    listStatus: "Status",
    listOfferedBy: "Offered by",
    listPromoCode: "Promocode",
    listValue: "Value",
    listDate: "Date"
  },
  WarehousesPage: {
    title: "Warehouses list",
    subTitle: "There are no Warehouses to display yet",
    description: "Start now to add Warehouses",
    CreateWarehouseButton: "Create Warehouse"
  },
  CreateWarhousePage: {
    pageTitle: "Create Warhouse",
    labels: {
      warehouseName: "Name of warehouse",
      wholesalerName: "Choose wholesaler name",
      location: "Location",
      CoverageAreas: "Coverage areas",
      add: "Add",
      create: "Create"
    },
    placeholders: {
      warehouseName: "Enter name",
      wholesalerName: "Wholesaler name",
      location: "Pick location"
    },
    createSuccess: "Warehouse added successfully",
    wholsalerMsg: "Wholesaler name for the warehouse can’t be edited"
  },
  EditWarehousePage: {
    pageTitle: "Warehouse name",
    location: "View Map",
    saveChanges: "Save Changes",
    editSuccess: "Wholesaler edited successfully",
    deleteConfirmation: {
      message: "Are you sure you want to delete this zone ?",
      button: "Delete",
      cancel: "Cancel"
    }
  },
  validationWareHouse: {
    warehouseName: "Warehouse name is required",
    wholesalerName: "Wholesaler name is required",
    location: "Location is required",
    coverageArea: "Coverage Area is required",
    arabic: "This field only accepts Arabic input"
  },
  WarehousePickLocation: {
    title: "Warehouse Location",
    subTitle: "Pick the warehouse location on the map",
    searchPlaceholder: "Search for location",
    saveChanges: "Save and Continue"
  },
  warehouseCoverageAreas: {
    CoverageAreas: "Coverage areas",
    search: "Search for location",
    areaName: "Area Name",
    minOrderPrice: "Min order price",
    areaNamePlaceholder: "Enter Name of the Area",
    available: "Available days and times",
    am: "AM",
    pm: "PM",
    from: "from",
    to: "to",
    confirm: "Confirm",
    validation: {
      areaLocationRequired: "Coverage area location is required",
      areaName: "Area Name is required",
      arabic: "This field only accepts Arabic input",
      available:
        "You must set at least one timeslot for the coverage zone",
      slotsOverlap:
        "This timeslot overlaps with another one, please define different times",
      slotFromToInvalid: "Invalid timeslot",
      isrequired: "Required"
    },
    save: "Save and Continue",
    polygonHint:
      " To confirm your zone, click inside your polygon after finishing",
    resetPolygon: "Reset Polygon"
  },
  viewWarehouse: {
    edit: "Edit",
    WarehouseDetails: "Warehouse details",
    WarehouseName: "Warehouse Name",
    WholesalerName: "Wholesaler Name",
    Location: "Location",
    CoverageAreas: "Coverage Areas",
    ViewMap: "View Map",
    warehouseLocation: "Warehouse Location",
    coverageAreaLocation: "Coverage Area Location",
    minOrderPrice: " Minimum Order Price"
  },
  WarehousesList: {
    pageTitle: "Warehouses list",
    name: "Warehouse Name",
    wholesalersName: "Wholesaler Name",
    actions: "Actions",
    view: "View",
    edit: "Edit",
    search: "Search for Warehouse",
    create: "Create"
  },
  auth: {
    login: "Login",
    logout: "Logout",
    welcome: "Welcome!",
    subTitle: "Sign in to your account to continue",
    labels: {
      name: "Name",
      email: "Email",
      password: "Password",
      remember: "Remember me next time",
      signin: "Sign in"
    },
    placeholders: {
      name: "Enter your name",
      email: "Enter your email",
      password: "Enter your password"
    },
    validations: {
      name: "Name is required",
      email: "Email is required",
      validEmail: "Please enter a valid email format",
      password: "Password is required"
    }
  },
  Wholesalers: {
    wholesalers: "Wholesalers",
    list: {
      pageTitle: "Wholesalers list",
      name: "Name",
      mail: "Mail",
      phone: "Phone",
      actions: "Actions",
      view: "View",
      edit: "Edit",
      publish: "Publish",
      published: "Published",
      activated: "Activated",
      deactivated: "Deactivated",
      addNew: "Add new",
      search: "Search for wholesaler",
      subTitle: "There are no Wholesalers to display yet",
      description: "Start now to add wholesalers",
      CreateWholesalerButton: "Add Wholesaler"
    },
    details: {
      details: "Wholesaler details",
      name: "Name",
      email: "Email",
      phone: "Phone",
      address: "Address",
      minOrderPrice: "Min order price",
      logo: "Logo",
      uploadLabel: "Upload",
      bank: "Bank details",
      bankName: "Bank name",
      bankBranch: "Bank branch",
      account: "Account number",
      iban: "IBAN number",
      incontact: "Incontact person info",
      delivery: "Delivery methods",
      payment: "Payment methods",
      status: "Status",
      unVerified: "Unverified",
      verified: "Verified",
      active: "Active",
      inActive: "Inactive",
      join: "Joining date",
      products: "Products",
      seeProducts: "See all products",
      edit: "Edit"
    },
    products: {
      search: "Search for product",
      wholesalerTitle: "Wholesaler",
      products: "Products",
      category: "Category",
      categories: "Categories",
      pound: "le",
      deleteMsg: "Are you sure you want to delete this product ?",
      edit: "Edit",
      inStock: "In Stock",
      runOut: "Run Out",
      deleteBtn: "Delete",
      cancelBtn: "Cancel",
      addToWholsaler: "Product added successfully to wholesaler",
      editProduct: "Product Edited successfully",
      deleteProduct: "Product Deleted successfully"
    },
    viewProduct: {
      Wholesaler: "Wholesaler",
      Products: "Products",
      productDetails: " Product Details",
      inStock: "In Stock",
      outStock: " Out of Stock ",
      deleteProduct: " Remove",
      editProduct: "Edit",
      category: "Category",
      pound: "le",
      description: "Description",
      price: "Price",
      originalPrice: "Original Price",
      quantity: "Quantity",
      minQuantity: "Min Order Quantity",
      maxQuantity: "Max Order Quantity",
      deleteMsg: "Are you sure you want to delete this product ?",
      delete: "Delete",
      appliedOffers: "Applied Offers"
    },
    addEdit: {
      pageTitle: "New Wholesaler",
      details: "Wholesaler details",
      allRequired: "All * are required",
      arabicLang: "(should be arabic)",
      name: "Name",
      email: "Email",
      phone: "Phone",
      address: "Address",
      minOrderPrice: "Min order price",
      logo: "Logo",
      uploadLabel: "Upload",
      bank: "Bank details",
      bankName: "Bank name",
      bankBranch: "Bank branch",
      account: "Account number",
      iban: "IBAN number",
      incontact: "Incontact person",
      delivery: "Delivery methods",
      payment: "Payment methods",
      direct: "Direct",
      serviceProvider: "Kuzlo",
      pickup: "Pick up",
      cash: "Cash",
      online: "Online",
      create: "Create",
      profile: "Profile info",
      save: "Save changes",
      addSuccess: "Wholesaler added successfully",
      editSuccess: "Wholesaler edited successfully",
      placeholders: {
        name: "Enter name",
        email: "Enter email@gmail.com",
        phone: "Enter number",
        address: "Enter address",
        bankName: "Enter name",
        bankBranch: "Enter branch name",
        account: "Enter account number",
        iban: "Enter IBAN number"
      },
      validations: {
        name: "Name is required",
        email: "Email is required",
        validEmail: "Please enter a valid email format",
        phone: "Phone is required",
        validPhone: "Please enter a valid phone number",
        address: "Address is required",
        bankName: "Bank name is required",
        bankBranch: "Bank branch is required",
        accountNumber: "Account Number is required",
        ibanNumber: "IBAN number is required",
        validIbanNumber:
          "Please enter a valid IBAN, IBAN must be 29 characters",
        delivery: "Please choose at least one Delivery Method",
        payment: "Please choose at least one Payment Method",
        arabic: "This field only accepts Arabic input"
      }
    }
  },
  productCategories: {
    products: "Products",
    allProducts: "All Products",
    categories: "Categories",
    categoriesSideMenu: "Categories & Super Products",
    title: "Kuzlo Products’s Categories",
    offers: "عروض",
    emptyState: {
      subTitle: "There are no products to display yet",
      description: "Start now to create your products",
      buttonLabel: "Create Product Category"
    },
    addCategory: "Add Category",
    search: "Search for category",
    rename: "Rename",
    viewProducts: "View Products",
    categoryName: "Category Name",
    arabicLang: "(should be arabic)",
    placeholder: "Enter name",
    create: "Create",
    save: "Save",
    validations: {
      categoryName: "Category name is required",
      arabic: "This field only accepts Arabic input"
    },
    createSuccess: "Category added successfully",
    editSuccess: "Category edited successfully",
    deleteSuccess: "Category deleted successfully",
    deleteConfirmation: {
      message: "Are you sure you want to delete this category ?",
      button: "Delete",
      cancel: "Cancel"
    }
  },
  retailers: {
    Retailers: "Retailers",
    RetailersList: "Retailers List",
    RetailerEmptyState: "There are no retailers to display yet",
    RetailerName: "Retailer name  ",
    search: "Enter store name or phone",
    Name: "Name",
    JoiningDate: "Joining Date",
    from: "From",
    to: "To",
    dateError: "start date should be before end",
    StoreName: "Store Name",
    Phone: "Phone",
    view: "View Details",
    blocked: "Blocked",
    incompleteProfile: "Incomplete profile"
  },
  viewRetailers: {
    RetailerDetails: "Retailer Details",
    Retailers: "Retailers",
    UserName: "User Name",
    StoreName: "Store Name",
    Phone: "Phone",
    JoiningDate: "Joining Date",
    EmailAddress: "Email Address",
    blockRetailer: "Block Retailer",
    unBloclRetailer: "Unblock Retailer",
    modalBlock: "Are you sure you want to block this retailer ?",
    modalBlockOngoingOrder1: "This retailer has ongoing orders,",
    modalBlockOngoingOrder2:
      "Are you sure you want to block their account?",

    block: "Block",
    unBlock: "Unblock",
    unBlockModal: "Are you sure you want to unblock this retailer ?",
    cancel: "Cancel",
    editSuccess: "Retailer edited successfully",
    address: "Default Address",
    area: "Area",
    street: "St Name",
    government: "Government",
    buildingNumber: "Building No",
    sign: "Guidance sign"
  },
  superProducts: {
    categories: "Categories",
    title: "Category’s Super Products List",
    emptyState: {
      subTitleOne: "There are no super products in",
      subTitleTwo: "category to display yet",
      description: "Start now to create your products",
      buttonLabel: "Create Super Product"
    },
    addSuperProduct: "Add Super Product",
    search: "Search for product",
    rename: "Rename",
    viewSubProducts: "View Sub Products",
    superProductName: "Super Product Name",
    arabicLang: "(should be arabic)",
    placeholder: "Enter name",
    create: "Create",
    save: "Save Changes",
    addSuccess: "Super Product added successfully",
    editSuccess: "Super Product edited successfully",
    validations: {
      superProductName: "Super Product name is required",
      arabic: "This field only accepts Arabic input"
    }
  },
  subProducts: {
    categories: "Categories",
    products: "Products",
    emptyState: {
      subTitleOne: "There are no sub products in",
      subTitleTwo: "product to display yet",
      description: "Start now to create your sub products",
      buttonLabel: "Create Sub Product",
      totalTitle: "There are no products to display yet"
    },
    deleteConfirmationMsg:
      "Are you sure you want to delete this sub product ?",
    delete: "Delete",
    cancel: "Cancel",
    addProduct: "Add Product",
    search: "Search for product",
    addToWholsaler: "Add to a Wholesaler",
    superProducts: "Super products",
    addEdit: {
      title: "New Sub Product",
      add: "Add",
      edit: "Edit",
      subProductName: "Sub Product Name",
      specs: "Specifications",
      desc: "Description",
      create: "Create",
      save: "Save Changes",
      uploadLabel: "Upload",
      prdImages: "Product Images",
      activate: "Activate / Deactivate Product",
      activated: "Activated",
      deactivated: "Deactivated",
      arabicLang: "(should be arabic)",
      defaultTooltip: "make it as a default product image",
      addSuccess: "Product added successfully",
      editSuccess: "Product edited successfully",
      specTooltip: "To add more write, comma after each item",
      placeholders: {
        subProductName: "Enter Name",
        specs: "Example كامل الدسم, حليب, خالي الدسم, ألبان",
        desc: "Enter product description"
      },
      validations: {
        arabic: "This field only accepts Arabic input",
        subProductName: "Sub Product name is required"
      }
    },
    productCard: {
      description: "الوصف",
      category: "التصنيف",
      specs: "ذات صلة",
      price: "سعر المنتج",
      quantity: "  الكميه المطلوبه",
      noProducts: "لا يوجد",
      outOfStock: "Out of Stock"
    }
  },
  orders: {
    name: "Orders",
    excell: "Download Excel sheet",
    ordersList: "Orders List",
    searchPlaceholder: "Order no or Retailer’s phone",
    Orderstatus: "Order status",
    Notes: "Notes",
    WholesalerName: "Wholesaler name ",
    retailerName: "Retailer’s Store name  ",
    Ongoing: "Ongoing",
    History: "History",
    view: "View Details",
    emptyState: "There are no products to display yet",
    export: "Export PDF",
    deliveryDate: "Delivery Date",
    expectedDeliveryDays: "Expected Delivery Days",
    filters: "Filters",
    startBeforeEnd: "Start Date should be before End Date",
    EndAfterStart: "End Date should be After Start Date",
    filterOrder: {
      pending: "Pending ",
      in_progress: "In Progress",
      out_for_delivery: "Out for Delivery",
      delivered: "Delivered",
      cancelled: "Cancelled",
      rejected: "Rejected",
      start: "Start Date",
      end: "End Date"
    },
    orderTable: {
      orderNumber: "Order No",
      storeName: "Store Name",
      WholesalerName: "Wholesaler",
      date: "Checkout Date",
      status: "Order Status",
      emptyState: "There are no orders to display yet",
      numberOfProducts: "Number of products"
    },
    viewOrder: {
      details: "Order Details",
      wholesalerDetails: "Wholesaler Details",
      name: "Name",
      outOfStock: "out of stock",
      download: "Download PDF",
      orderStatus: "Order Status",
      warehouse: "Warehouse",
      retailerDetails: "Retailer Details",
      store: "Store",
      phone: "Phone",
      deliveryAdress: "Delivery address",
      order: "Order",
      orderNumber: "Order No",
      orderValue: "Order value",
      orderValueAfterPromo: "Value after promo code",
      orderValueAfterDiscounts: "Value after discounts",
      deliveryMethod: "Delivery method",
      paymentMethod: "Payment method",
      date: "Checkout date",
      status: "Choose the Status",
      cancel: "Cancel",
      reject: "Reject",
      currentStatus: "Current Status",
      expectedDelivery: "Expected Delivery Date",
      from: "From",
      to: "To",
      orderProducts: "Order Products",
      viewProducts: "View all products",
      productList: "Order’s Products list",
      totalCard: "الاجمالي",
      productName: "Product Name",
      productsNumber: "Order’s Products No",
      Products: "Products",
      price: "Price",
      priceUnit: "Price / Unit",
      quentity: "Quantity",
      total: "Total",
      pending: "Pending",
      inProgress: "In progress",
      outForDelivery: "Out for delivery",
      deliverd: "Delivered",
      le: "le",
      leA: " ج.م",
      days: "Days",
      confirm: "Confirm",
      orderDate: "Order date",
      errorMsg:
        "To be In Progress, you should enter the expected Delivery time",
      editSuccess: "Order Status edited successfully",
      editProductSuccess: "Order edited successfully",
      addProductSuccess: "Products Added to Order successfully",
      export: "Export as PDF",
      deleteMsgErrorPart1: "You can’t delete all order’s products",
      deleteMsgErrorPart2:
        "at least order should contain a one product",
      retailerLocation: "Location",
      shareLocation: "Share Location",
      linkCopied: "Link Copied Successfully",
      appliedPromoCode: "Applied Promocode",
      promoCodeDiscount: "Promocode Discount"
    },
    orderProducts: {
      navabrTitle: "Order’s Products",
      removeSelectedProducts: "Remove Selected Product",
      orderList: "Orders List",
      orderDetails: "Orders Details",
      addProduct: "Add Product",
      save: "Save Changes",
      deleteMsg: "Order Products Deleted Successfully",
      deleteConfirmationMsg:
        "Are you sure you want to delete this selected products ?",
      delete: "Delete",
      cancel: "Cancel",
      categories: "Categories",
      searchPlaceholder: "Search for product",
      piceErrorMsg: "price should be more than 0",
      quantityErrorMsg: "quantity should be more than 0"
    }
  },
  productsModal: {
    price: "Price",
    category: " Category",
    description: "Description",
    pound: "le",
    select: "Choose wholesaler’s name",
    selectPlaceholder: "select wholsaler",
    selectErrorMessage: "Wholesaler Name is required",
    quantity: "Quantity",
    minQuantity: "Min Order Quantity",
    maxQuantity: "Max Order Quantity",
    modalButton: "Submit to Wholesaler’s Products",
    priceErrorReq: "Price is required",
    priceErrorMin: "Price must be greater than 0 EGP",
    priceNumber: "please enter a valid price",
    quantityError: "Quantity must be greater than or equal to 0",
    minError: "Min Quantity must be greater than 0",
    maxError: "Max Quantity must be greater than  0",
    minMaxError: "Max Quantity must be greater than Min Quantity"
  },
  banners: {
    title: "Banners",
    list: "Banners List",
    add: "Add Banner",
    edit: "Edit",
    delete: "Delete",
    cancel: "Cancel",
    status: "Status",
    hidden: "Hidden",
    unhidden: "Unhidden",
    save: "Save",
    create: "Create",
    showBanner: "Show Banner",
    hideBanner: "Hide Banner",
    emptyState: {
      subTitle: "There are no banners to display yet",
      noFilterResultsSubTitle:
        "There are no banners matches this Filter"
    },
    createSuccess: "Banner added successfully",
    deleteSuccess: "Banner deleted successfully",
    editSuccess: "Banner edited successfully",
    deleteConfirmationMsg:
      "Are you sure you want to delete this Banner ?",
    addPage: {
      pageTitle: "New Banner"
    },
    editPage: {
      pageTitle: "Edit Banner"
    },
    validations: {
      maxTitle: "Maximum Title Length 25 Characters",
      maxSubTitle: "Maximum Title Length 40 Characters",
      image: "Banner Image Required",
      durationRequired: "Duration Required",
      durationPositveNumbers:
        "Duration accepts positive numbers only",
      url: "URL accepts URL format only"
    },
    lables: {
      title: "Title",
      subTitle: "Subtitle",
      image: "Banner Image",
      ratio: "preferred ratio 2 : 1",
      uploadLabel: "Upload",
      duration: "Duration",
      seconds: "(in seconds)",
      url: "URL",
      hiddenLabel: "Display Banner",
      hidden: "Hidden",
      unhidden: "Unhidden",
      displayed: "Displayed"
    },
    placeholders: {
      title: "Enter Title",
      subTitle: "Enter Subtitle",
      url: "URL"
    }
  },
  offers: {
    sideMenuTitle: "Offers",
    list: {
      topTitle: "Offers",
      add: "Add Offer"
    },
    deleteSuccess: "Offer deleted successfully",
    deleteConfirmationMsg:
      " Are you sure you want to delete this Offer ",
    expireConfirmationMsg:
      " Are you sure you want to expire this Offer ",
    delete: "Delete",
    expire: "Expire",
    expireNow: "Expire now",
    offerWithDash: "Offer - ",
    addEditPage: {
      addPageTitle: "New Offer - On product",
      editPageTitle: "Offer details",
      viewOffer: "View Offer",
      wholesaler: "Wholesaler",
      offerdBy: "Offered by",
      chooseWholesaler: "Choose wholesaler",
      wholesalerName: "Wholesaler Name",
      chooseProduct: "Choose a product",
      product: "Product",
      addOffer: "Add the offer",
      editOffer: "Save changes",
      emptyState: "There are no products to display yet",
      productName: "Product Name",
      category: "Category",
      price: "Price",
      le: "le",
      confirm: "Confirm",
      searchPlaceholder: "Search for product name",
      categories: "Categories",
      offerValue: "Offer value",
      amount: "Amount",
      percentage: "Percentage",
      products: "products",
      productsQuote: "Note : please choose one product only",
      startDateAndTime: "Start date & time",
      startDate: "Start Date",
      startTime: "Start Time",
      endDateAndTime: "End date & time",
      endDate: "End Date",
      endTime: "End Time",
      originalPrice: "السعر الاصلي",
      currentPrice: "السعر الحالي",
      pound: "جنيه",
      prevProductOffersQoute: "This product has already other offers",
      viewDetails: "View details",
      start: "Start",
      end: "End",
      by: "by",
      status: "Status",
      value: "Value",
      enabled: "Enabled",
      disabled: "Disabled",
      validations: {
        wholesalerRequired: "Wholesaler is required",
        productRequired: "Product is required",
        selectWholesalerFirst: "Select Wholesaler First",
        offerType: "Offer type is required",
        amount:
          "Amount must be positive number with max two decimal places",
        percentage:
          "Percentage must be positive number  up to 99 with max two decimal places",
        startDate: "Offer start date & time is required",
        endDate: "Offer end date & time is required",
        discountMoreThanPrice:
          "Discount Must Be Less Than Product Price"
      },
      createSuccess: "Offer added successfully",
      outOfStock: "out of stock",
      expirationDate: "Expiration Date",
      expirationReason: "Expiration Reason",
      productNamePlaceHolder: "Please select a product"
    },
    offersList: {
      emptyState: "There are no Offers to display yet",
      filtersPlaceHolders: {
        status: "Status",
        subStatus: "Sub Status",
        offeredBy: "Offered by"
      },
      filterStatus: {
        active: "Active",
        pending: "Pending",
        expired: "Expired"
      },
      filterSubStatus: {
        enabled: "Enabled",
        disabled: "Disabled"
      },
      offeredBy: "Offered by",
      searchPlaceHolder: "Search for offer (Product name)",
      outOfStock: "out of stock",
      start: "Start",
      end: "End",
      cancel: "Cancel",
      table: {
        headlines: {
          status: "Status",
          title: "Title",
          offerdBy: "Offered by",
          date: "Date",
          time: "Time",
          viewAndEdit: "View & Edit",
          enabled: "Enabled",
          delete: "Delete",
          expire: "Expire"
        }
      }
    },
    editSuccess: "Offer edited successfully"
  }
};

export default kuzloEn;
