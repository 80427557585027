import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import { InputLabel } from "@material-ui/core";
import {
  Add,
  Edit,
  LocationOn,
  Delete,
  DeleteOutline
} from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";

import messages from "../../../assets/locale/messages";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Navbar from "../../../components/Navbar";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { getWholesalersRequest } from "../../../store/Wholesalers/actions";
import {
  addWharehouseRequest,
  editWarehouseRequest,
  getWarehouseRequest,
  getWarehouseResponse,
  deleteZoneRequest
} from "../../../store/Warehouses/actions";
import { setWarehouseLocation } from "../../../store/GoogleMaps/actions";
import Modal from "../../../components/Modal";
import { ARABIC_REGEX } from "../../../utils/Patterns";
import "./AddEditWarehouse.scss";

const AddEditWarehouse = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const wholesalers = useSelector((state) => state.wholesalers.list);
  const warehouseLocation = useSelector(
    (state) => state.googleMaps.warehouse.location
  );
  const warehouse = useSelector(
    (state) => state.warehouses.warehouse
  );

  const [wholsalersOptions, setWholsalersOption] = useState([]);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [zoneId, setZoneId] = useState(null);

  useEffect(() => {
    dispatch(
      getWholesalersRequest({ lookup: "options", page: -1, items: 1 })
    );
    if (id) {
      dispatch(getWarehouseRequest(id));
    }
    return () => {
      dispatch(getWarehouseResponse(null));
      dispatch(setWarehouseLocation({ coords: null }));
    };
  }, []);

  useEffect(() => {
    const options = wholesalers.map((wholesaler) => ({
      label: wholesaler.username,
      id: wholesaler.id
    }));
    setWholsalersOption(options);
  }, [wholesalers]);

  useEffect(() => {
    if (warehouse && id) {
      setValues({
        warehouseName: warehouse?.name || "",
        wholesalerId: warehouse?.wholesaler_id || "",
        location: {
          lat: warehouseLocation.coords
            ? warehouseLocation.coords.lat
            : warehouse?.lat || 0,
          lng: warehouseLocation.coords
            ? warehouseLocation.coords.lng
            : warehouse?.lng || 0
        }
      });
    }
  }, [warehouse]);

  const {
    CreateWarhousePage,
    EditWarehousePage,
    validationWareHouse
  } = messages[lang];

  const handleEditArea = (zone) => {
    history.push({
      pathname: ROUTE_PATHS.warehouseEditCoverageAreas
        .replace(":id", id)
        .replace(":zoneId", zone.id),
      state: {
        zone,
        otherZones: warehouse?.zones.filter(
          (fZone) => fZone.polygon !== zone.polygon
        )
      }
    });
  };
  const handelAddArea = () => {
    history.push({
      pathname: ROUTE_PATHS.warehouseAddCoverageAreas.replace(
        ":id",
        id
      ),
      state: {
        otherZones: warehouse?.zones
      }
    });
  };

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    setFieldTouched,
    dirty,
    isValid,
    setValues
  } = useFormik({
    initialValues: {
      warehouseName:
        (warehouseLocation.coords &&
          props.location.state?.warehouseName) ||
        "",
      wholesalerId:
        (warehouseLocation.coords &&
          +props.location.state?.wholesalerId) ||
        "",
      location: null,
      coverageAreas: []
    },
    validationSchema: Yup.object({
      warehouseName: Yup.string()
        .required(validationWareHouse.warehouseName)
        .matches(ARABIC_REGEX, validationWareHouse.arabic),
      wholesalerId: Yup.string().required(
        validationWareHouse.wholesalerId
      ),
      location: Yup.object()
        .nullable()
        .shape({
          lng: Yup.string(),
          lat: Yup.string()
        })
        .required(validationWareHouse.location)
    }),
    onSubmit: (values) => {
      let warehouseData = {};
      warehouseData.name = values.warehouseName;
      warehouseData.lat = values.location.lat;
      warehouseData.lng = values.location.lng;
      warehouseData.wholesaler_id = values.wholesalerId;
      if (id) {
        dispatch(editWarehouseRequest({ data: warehouseData, id }));
      } else {
        dispatch(addWharehouseRequest(warehouseData));
      }
    }
  });

  useEffect(() => {
    if (warehouseLocation.coords) {
      const {
        coords: { lng, lat }
      } = warehouseLocation;
      setFieldValue("location", { lat, lng });
      setFieldTouched("location");
    }
  }, [warehouseLocation]);

  const handleDeleteZone = (zoneIdToDelete) => {
    setOpenDeleteConfirm(true);
    setZoneId(zoneIdToDelete);
  };

  const handleCloseDeleteConfirmModal = () => {
    setOpenDeleteConfirm(false);
  };

  const confirmDeleteBtn = () => {
    dispatch(
      deleteZoneRequest({
        warehouse_id: id,
        zone_id: zoneId
      })
    );
    setOpenDeleteConfirm(false);
  };

  return (
    <>
      <Navbar
        NavbarTitle={
          id
            ? EditWarehousePage.pageTitle
            : CreateWarhousePage.pageTitle
        }
      />
      <div className="content-wrapper createEditPage">
        <form onSubmit={handleSubmit} noValidate>
          <div className="row mb-5 pb-5 px-5">
            <div className="col-4  py-5">
              <Input
                id="text"
                label={CreateWarhousePage.labels.warehouseName}
                name="text"
                type="text"
                placeholder={
                  CreateWarhousePage.placeholders.warehouseName
                }
                labelClassName="pb-2"
                required
                value={values["warehouseName"]}
                onChange={(value) => {
                  setFieldTouched("warehouseName");
                  setFieldValue("warehouseName", value);
                }}
                isInputHasErr={
                  !!(
                    touched["warehouseName"] &&
                    errors["warehouseName"]
                  )
                }
                errMsg={errors["warehouseName"]}
              />
            </div>
            <div className="col-4 py-5 select">
              <Select
                name="wholesalerId"
                options={wholsalersOptions}
                label={CreateWarhousePage.labels.wholesalerName}
                variant
                block
                labelClassName=" font-medium pb-2"
                required
                placeholder={
                  CreateWarhousePage.placeholders.wholesalerName
                }
                disabled={id ? true : false}
                value={
                  wholsalersOptions.find(
                    (option) => option.id == values["wholesalerId"]
                  ) || null
                }
                onChange={(value) => {
                  setFieldTouched("wholesalerId");
                  setFieldValue(
                    "wholesalerId",
                    value ? value.id : ""
                  );
                }}
                hasError={
                  !!(
                    touched["wholesalerId"] && errors["wholesalerId"]
                  )
                }
                errMsg={errors["wholesalerId"]}
              />
            </div>
            <div className="col-4 py-5 pickLocation">
              <InputLabel className="font-medium pb-3">
                {CreateWarhousePage.labels.location}
                <span className="err">*</span>
              </InputLabel>

              <Link
                className="pickLocationLink pt-5"
                to={{
                  pathname: id
                    ? ROUTE_PATHS.warehouseEditLocation.replace(
                        ":id",
                        id
                      )
                    : ROUTE_PATHS.warehouseAddLocation,
                  state: {
                    warehouseName: values["warehouseName"],
                    wholesalerId: values["wholesalerId"],
                    location: {
                      coords: values["location"]
                        ? {
                            lng: values["location"].lng,
                            lat: values["location"].lat
                          }
                        : null
                    }
                  }
                }}
              >
                {warehouseLocation.coords || values["location"]
                  ? EditWarehousePage.location
                  : CreateWarhousePage.placeholders.location}
              </Link>
              <p className="errorMsg">
                {touched["location"] && errors["location"]}
              </p>
            </div>
            {id && (
              <div className="col-4">
                <div className="d-flex justify-content-between coverageArea">
                  <InputLabel className="font-medium pb-3 mb-2">
                    {CreateWarhousePage.labels.CoverageAreas}
                    <span className="err">*</span>
                  </InputLabel>
                  <Button
                    className=" mb-5 "
                    outlined
                    label={
                      <span className="d-flex align-items-center">
                        <Add fontSize="large" />
                        <span className="ps-1 fsize-14">
                          {CreateWarhousePage.labels.add}
                        </span>
                      </span>
                    }
                    labelClass="fsize-16  px-3 text-center bg-transparent"
                    onClick={handelAddArea}
                  />
                </div>
                {warehouse?.zones.map((zone) => (
                  <div
                    key={zone.id}
                    className="d-flex justify-content-between align-items-center AddcoverageArea mb-4"
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <LocationOn className="mx-2 locationIcon fsize-16" />
                      <p className="fsize-16">{zone.name}</p>
                    </div>

                    <div className="d-flex justify-content-end align-items-center p-3">
                      <Edit
                        className="me-2 editIcon"
                        onClick={() => handleEditArea(zone)}
                      />
                      {warehouse?.zones?.length > 1 && (
                        <Delete
                          className="deleteIcon"
                          onClick={() => handleDeleteZone(zone.id)}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="container d-flex justify-content-center align-items-center  create">
            <Button
              className="w-25 mb-3"
              label={
                <span className="d-flex align-items-center">
                  <span className="ps-1 fsize-14 ">
                    {id
                      ? EditWarehousePage.saveChanges
                      : CreateWarhousePage.labels.create}
                  </span>
                </span>
              }
              type="submit"
              disabled={!dirty || !isValid}
              labelClass="fsize-16 text-white"
            />
          </div>
        </form>
      </div>
      <Modal
        open={openDeleteConfirm}
        onClose={handleCloseDeleteConfirmModal}
        modalBody={
          <div className="deleteConfiramtionContainer">
            <div className="d-flex justify-content-center mb-2">
              <DeleteOutline
                className="deleteIcon"
                onClick={handleCloseDeleteConfirmModal}
              />
            </div>
            <div className="d-flex justify-content-center mb-3">
              <p className="font-semibold text-dark fsize-14 text-center">
                {EditWarehousePage.deleteConfirmation.message}
              </p>
            </div>
          </div>
        }
        actionBtns={
          <div className="container px-4">
            <div className="row">
              <div className="col-md-6">
                <div className=" mx-2 d-flex justify-content-center align-items-center">
                  <Button
                    label={
                      EditWarehousePage.deleteConfirmation.button
                    }
                    type="button"
                    labelClass="fsize-14 text-white"
                    onClick={confirmDeleteBtn}
                    block={true}
                    className="py-2"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="mx-2 d-flex justify-content-center align-items-center pointer"
                  onClick={handleCloseDeleteConfirmModal}
                >
                  <div>
                    <p className="fsize-14 text-orange py-2">
                      {EditWarehousePage.deleteConfirmation.cancel}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default AddEditWarehouse;

AddEditWarehouse.propTypes = {
  location: PropTypes.object
};
