import React from "react";
import PropTypes from "prop-types";
import { Delete, Star, StarOutline } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

import "./ProductImagesSwiper.scss";

SwiperCore.use([Navigation]);

const ProductImagesSwiper = ({
  slides,
  handleDeleteImage,
  tooltipTitle,
  handleDefaultImage,
  isDefault,
  isDeleted,
  slidesCount,
  space
}) => {
  return (
    <div className="product-images-swiper-container">
      <Swiper
        slidesPerView={slidesCount}
        centeredSlides={false}
        spaceBetween={space}
        grabCursor={true}
        navigation
        className="mySwiper"
      >
        {slides.map((image, index) => {
          if (!image._destroy)
            return (
              <SwiperSlide key={index}>
                <div
                  style={{ backgroundImage: `url(${image.url})` }}
                  className="product-image mx-4 d-flex justify-content-between "
                >
                  {isDefault ? (
                    image.isDefault ? (
                      <Star
                        className="pointer text-dark-blue"
                        onClick={() => {
                          handleDefaultImage(index);
                        }}
                      />
                    ) : (
                      <Tooltip
                        title={
                          <span className="fsize-12">
                            {tooltipTitle}
                          </span>
                        }
                      >
                        <StarOutline
                          className="pointer text-dark-blue"
                          onClick={() => {
                            handleDefaultImage(index);
                          }}
                        />
                      </Tooltip>
                    )
                  ) : (
                    ""
                  )}

                  {isDeleted && (
                    <Delete
                      className="pointer text-red"
                      onClick={() => handleDeleteImage(index)}
                    />
                  )}
                </div>
              </SwiperSlide>
            );
        })}
      </Swiper>
    </div>
  );
};

ProductImagesSwiper.propTypes = {
  slides: PropTypes.array.isRequired,
  handleDeleteImage: PropTypes.func,
  tooltipTitle: PropTypes.string,
  handleDefaultImage: PropTypes.func,
  isDefault: PropTypes.bool,
  isDeleted: PropTypes.bool,
  slidesCount: PropTypes.number,
  space: PropTypes.number
};
ProductImagesSwiper.defaultProps = {
  isDefault: true,
  isDeleted: true,
  slidesCount: 6,
  space: 30
};
export default ProductImagesSwiper;
