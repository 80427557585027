import { put, call, takeEvery } from "redux-saga/effects";
import amplitude from "amplitude-js";

import * as types from "./types";
import * as apis from "../../network/apis/retailers";
import {
  getRetailersResponse,
  getRetailerResponse,
  getRetailersLookupResponse
} from "./actions";
import { showHideSnackbar } from "../Snackbar/actions";
import messages from "../../assets/locale/messages";

export function* getRetailersSaga({ payload }) {
  try {
    const response = yield call(apis.getRetailers, payload);
    yield put(
      getRetailersResponse({
        list: response.data.retailers,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export function* getRetailerSaga({ payload }) {
  try {
    const response = yield call(apis.getRetailer, payload);
    yield put(getRetailerResponse(response.data.retailer));
  } catch (err) {
    console.log(err);
  }
}
export function* getRetailersLookup({ payload }) {
  try {
    const response = yield call(apis.getRetailers, payload);
    yield put(getRetailersLookupResponse(response.data.retailers));
  } catch (err) {
    console.log(err);
  }
}

export function* updateRetailerSaga({ payload }) {
  try {
    const response = yield call(apis.updateRetailer, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages["en"].viewRetailers.editSuccess
      })
    );
    yield put(getRetailerResponse(response.data.retailer));

    if (
      payload.data.is_activated === false &&
      response.data.success === true
    ) {
      const eventProperties = {
        "retailer id": response.data.retailer.id,
        "reatiler name": response.data.retailer.username
      };

      amplitude
        .getInstance()
        .logEvent("admin update: retailer blocked", eventProperties);
    }
  } catch (err) {
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: err.response?.data?.message
      })
    );
  }
}

export default function* RetailersSagas() {
  yield takeEvery(types.GET_RETAILERS_REQUEST, getRetailersSaga);
  yield takeEvery(types.GET_RETAILER_REQUEST, getRetailerSaga);
  yield takeEvery(
    types.GET_RETAILERS_LOOKUP_REQUEST,
    getRetailersLookup
  );
  yield takeEvery(types.UPDATE_RETAILER_REQUEST, updateRetailerSaga);
}
