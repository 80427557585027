import { axiosInstance } from "./";

const getWarehouses = async (params) => {
  return await axiosInstance.get(`warehouses`, { params });
};

const addWarehouse = async (payload) =>
  await axiosInstance.post(`warehouses`, payload);

const editWarehouse = async ({ data, id }) =>
  await axiosInstance.patch(`warehouses/${id}`, data);

const getWarhouse = async (id) =>
  axiosInstance.get(`warehouses/${id}`);

const createZone = async (payload) =>
  axiosInstance.post(`zones`, payload);

const editZone = async ({ id, data }) =>
  axiosInstance.patch(`zones/${id}`, data);

const deleteZone = async (id) => axiosInstance.delete(`zones/${id}`);

const getWeekDays = async () => axiosInstance.get(`week_days`);

export {
  getWarehouses,
  addWarehouse,
  editWarehouse,
  getWarhouse,
  createZone,
  editZone,
  deleteZone,
  getWeekDays
};
