import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  offer: {},
  previousProductOffers: {}
};

const offersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_OFFERS_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };
    case types.GET_OFFER_RESPONSE:
      return { ...state, offer: action.payload };
    case types.GET_PREVIOUS_PRODUCT_OFFERS_RESPONSE:
      return { ...state, previousProductOffers: action.payload };
    default:
      return state;
  }
};

export default offersReducer;
