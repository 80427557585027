import React from "react";
import PropTypes from "prop-types";

export const Promocodes = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="#fff"
        fillOpacity={props.selectedIcon ? "1" : "0.5"}
        d="M5.938 0h.085C7.27 0 8.426.66 9.06 1.736L10 3.338l.941-1.602A3.524 3.524 0 0113.977 0h.085A3.438 3.438 0 0117.5 3.438c0 .562-.137 1.058-.375 1.562h1.625c.691 0 1.25.559 1.25 1.25v2.5c0 .691-.559 1.25-1.25 1.25H1.25A1.25 1.25 0 010 8.75v-2.5C0 5.559.56 5 1.25 5h1.625c-.24-.504-.375-1-.375-1.563A3.438 3.438 0 015.938 0zM7.44 2.687a1.648 1.648 0 00-1.418-.812h-.085a1.562 1.562 0 100 3.125H8.8L7.44 2.687zm6.622-.812h-.086c-.582 0-1.122.309-1.418.812L11.199 5h2.864a1.562 1.562 0 100-3.125zM1.25 11.25h7.5V20H3.125a1.875 1.875 0 01-1.875-1.875V11.25zm10 8.75v-8.75h7.5v6.875c0 1.035-.84 1.875-1.875 1.875H11.25z"
      ></path>
    </svg>
  );
};

Promocodes.propTypes = {
  selectedIcon: PropTypes.bool
};
