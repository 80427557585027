import { axiosInstance } from "./";

const getOrders = async (params) =>
  await axiosInstance.get(`orders`, { params });

const getOrderDetails = async (id) =>
  await axiosInstance.get(`orders/${id}`);

const updateOrder = async ({ data, id }) =>
  await axiosInstance.patch(`orders/${id}`, data);

const deleteOrderProducts = async ({ order_id, orders_products }) =>
  await axiosInstance.delete(
    `orders/${order_id}/orders_products/bulk_destroy`,
    { data: { orders_products } }
  );

const editOrderProducts = async ({ order_id, orders_products }) =>
  await axiosInstance.patch(
    `orders/${order_id}/orders_products/bulk_update`,
    { orders_products }
  );
const addOrderProducts = async ({ order_id, orders_products }) =>
  await axiosInstance.post(`orders/${order_id}/orders_products`, {
    orders_products
  });
const getOrderPDF = async (id) =>
  await axiosInstance.get(`orders/${id}/export_pdf`);

export {
  getOrders,
  getOrderDetails,
  updateOrder,
  deleteOrderProducts,
  editOrderProducts,
  addOrderProducts,
  getOrderPDF
};
