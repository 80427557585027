import * as types from "./types";

export const getSuperProductsRequest = (data) => ({
  type: types.GET_SUPERPRODUCTS_REQUEST,
  payload: data
});

export const getSuperProductsResponse = (data) => ({
  type: types.GET_SUPERPRODUCTS_RESPONSE,
  payload: data
});

export const getSuperProductRequest = (data) => ({
  type: types.GET_SUPERPRODUCT_REQUEST,
  payload: data
});

export const getSuperProductResponse = (data) => ({
  type: types.GET_SUPERPRODUCT_RESPONSE,
  payload: data
});

export const addSuperProductRequest = (data) => ({
  type: types.ADD_SUPERPRODUCT_REQUEST,
  payload: data
});

export const editSuperProductRequest = (data) => ({
  type: types.EDIT_SUPERPRODUCT_REQUEST,
  payload: data
});
