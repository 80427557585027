import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  FilledInput,
  InputLabel,
  InputAdornment,
  IconButton,
  FormHelperText
} from "@material-ui/core";
import {
  VisibilityOutlined,
  VisibilityOffOutlined
} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import "./Input.scss";

const InputTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.tooltip.backgroundBlue
  },
  arrow: {
    color: theme.tooltip.backgroundBlue,
    fontSize: "1rem"
  }
}))(Tooltip);

const Input = ({
  name,
  value,
  onChange,
  id,
  label,
  type,
  placeholder,
  required,
  defaultValue,
  isInputHasErr,
  errMsg,
  disabled,
  multiline,
  rows,
  maxRows,
  minRows,
  startAdornment,
  endAdornment,
  fullWidth,
  helperText,
  inputClass,
  inputWrapperClass,
  labelClassName,
  helperTextClass,
  labelAdornment,
  tooltip,
  tooltipTitle,
  tooltipPlacement,
  inputEndAdorment,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [openTip, setOpenTip] = useState(false);

  return (
    <div className={`input-wrapper ${inputWrapperClass}`}>
      <InputLabel htmlFor={id || name} className={labelClassName}>
        <>
          {label}
          {required ? <span className="err-asterisk">*</span> : ""}
          {labelAdornment && (
            <span className="ms-2">{labelAdornment}</span>
          )}
        </>
      </InputLabel>
      <InputTooltip
        title={tooltipTitle || ""}
        placement={tooltipPlacement}
        open={openTip}
        arrow
      >
        <>
          <FilledInput
            id={id || name}
            name={name}
            type={showPassword ? "text" : type}
            value={value}
            defaultValue={defaultValue}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            error={isInputHasErr}
            fullWidth={fullWidth}
            multiline={multiline}
            rows={rows}
            maxRows={maxRows}
            minRows={minRows}
            className={`${inputClass} ${
              isInputHasErr ? "error-border" : ""
            }`}
            disableUnderline={true}
            autoComplete="off"
            endAdornment={
              type === "password" ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOutlined />
                    ) : (
                      <VisibilityOffOutlined />
                    )}
                  </IconButton>
                </InputAdornment>
              ) : (
                endAdornment
              )
            }
            startAdornment={
              startAdornment ? (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ) : null
            }
            onFocus={() => {
              if (tooltip) {
                setOpenTip(true);
              }
            }}
            onBlur={() => {
              if (tooltip) {
                setOpenTip(false);
              }
            }}
            {...props}
          />
          {inputEndAdorment}
        </>
      </InputTooltip>

      <FormHelperText
        error={isInputHasErr}
        className={helperTextClass || "font-medium fsize-12"}
      >
        {isInputHasErr ? errMsg : helperText}
      </FormHelperText>
    </div>
  );
};

export default Input;

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  isInputHasErr: PropTypes.bool,
  errMsg: PropTypes.string,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  inputEndAdorment: PropTypes.element,
  startAdornment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  endAdornment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  inputClass: PropTypes.string,
  inputWrapperClass: PropTypes.string,
  labelClassName: PropTypes.string,
  helperTextClass: PropTypes.string,
  labelAdornment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  tooltip: PropTypes.bool,
  tooltipTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  tooltipPlacement: PropTypes.string
};

Input.defaultProps = {
  type: "text",
  fullWidth: true,
  value: ""
};
