import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

import Button from "../Button";
import "./CategoryCard.scss";

const ProductCategoryTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.tooltip.backgroundGrey,
    fontSize: "1rem",
    width: "8.571rem",
    color: theme.tooltip.colorBlack
  },
  arrow: {
    color: theme.tooltip.backgroundGrey,
    fontSize: "1rem"
  }
}))(Tooltip);

const CategoryCard = ({
  categoryName,
  handleEditClick,
  editText,
  handleDeleteClick,
  handleView,
  viewBtnText,
  EditIcon,
  DeleteIcon,
  isEditable
}) => {
  return (
    <div className="categories-item-container d-flex flex-column align-items-center w-100">
      {isEditable && (
        <div className="w-100 d-flex justify-content-between px-3 pt-2">
          <div
            className="pt-2 d-flex  align-items-center justify-content-between pointer"
            onClick={handleEditClick}
          >
            {EditIcon}
            <span className="rename-text mx-1 font-reguler">
              {editText}
            </span>
          </div>
          <div className="pt-2" onClick={handleDeleteClick}>
            {DeleteIcon}
          </div>
        </div>
      )}

      <div className="flex-grow-1 d-flex justify-content-center align-items-center ">
        <h5 className="fsize-24 font-cairo-regular category-name text-dark">
          {categoryName && categoryName?.length > 20 ? (
            <ProductCategoryTooltip
              title={<div>{categoryName}</div>}
              placement="left"
              arrow
            >
              <span>{categoryName?.substring(0, 20) + "..."}</span>
            </ProductCategoryTooltip>
          ) : (
            <span> {categoryName}</span>
          )}
        </h5>
      </div>
      <div className="w-100 pb-3 px-3">
        <Button
          className="view-products-btn"
          label={viewBtnText}
          type="button"
          labelClass="fsize-12 text-orange"
          onClick={handleView}
          block={true}
          outlined
        />
      </div>
    </div>
  );
};

export default CategoryCard;
CategoryCard.propTypes = {
  categoryName: PropTypes.string,
  handleEditClick: PropTypes.func,
  editText: PropTypes.string,
  handleDeleteClick: PropTypes.func,
  handleView: PropTypes.func,
  viewBtnText: PropTypes.string,
  EditIcon: PropTypes.element,
  DeleteIcon: PropTypes.element,
  isEditable: PropTypes.bool
};

CategoryCard.defaultProps = {
  isEditable: true
};
