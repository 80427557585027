import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Edit, Schedule } from "@material-ui/icons";

import Button from "../../../components/Button";
import Navbar from "../../../components/Navbar";
import MapModal from "../../../components/MapModal";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { getTwelveHrFormat } from "../../../utils/Helpers";
import { Location } from "../../../utils/Icons/Location";
import { Price } from "../../../utils/Icons/Price";
import messages from "../../../assets/locale/messages";
import { getWarehouseRequest } from "../../../store/Warehouses/actions";
import "./ViewWarehouse.scss";

const ViewWarehouse = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const [savedCoords, setSavedCoords] = useState({ coords: null });
  const [savedPolygon, setSavedPolygon] = useState([]);
  const [modalHeader, setModalHeader] = useState("");

  const lang = useSelector((state) => state.locale.lang);
  const { viewWarehouse, general } = messages[lang];
  const warehouse = useSelector(
    (state) => state.warehouses.warehouse
  );

  useEffect(() => {
    if (id) {
      dispatch(getWarehouseRequest(id));
    }
  }, [id]);

  const handelEdit = () => {
    history.push(ROUTE_PATHS.editWarehouse.replace(":id", id));
  };

  const renderSingleZone = (zone) => (
    <div className="areas" key={zone.id}>
      <div className="ps-2">
        <p className="areaName mb-4 ">{zone.name}</p>
      </div>
      <p>
        <Location className="locationaIcon me-2" />
        <span
          onClick={() =>
            handleShowCoverageArea({
              area: zone.polygon,
              name: zone.name
            })
          }
          className="fsize-16 viewMap2 "
        >
          {viewWarehouse.ViewMap}
        </span>
      </p>
      <p className="schedule fsize-16 font-semibold d-flex ">
        <Schedule className="scheduleIcon me-2" />
        <div>
          {zone?.timetable_slots.map((slot) => (
            <p key={slot.id}>
              <span className="me-3">{slot.name_en}</span>
              {slot?.time_slots?.map((timeslot, i) => (
                <span key={timeslot.id}>
                  <span className="gray me-2">
                    <span className="gray me-2">
                      {i !== 0 && ","}
                    </span>
                    <span className="gray">from</span>
                  </span>
                  {getTwelveHrFormat(timeslot.from)}
                  <span className="gray mx-2">to</span>
                  {getTwelveHrFormat(timeslot.to)}
                </span>
              ))}
            </p>
          ))}
        </div>
      </p>
      <p className="fsize-16 font-semibold d-flex ">
        <Price className="me-2" />
        <p className=" min-price-label">
          {viewWarehouse.minOrderPrice}
        </p>
        <p className="min-price mx-2">
          {zone.min_delivery_order_price}
          <span className="mx-2"> {general.le}</span>
        </p>
      </p>
    </div>
  );

  const handleShowLocation = () => {
    setSavedCoords({
      coords: {
        lat: warehouse.lat,
        lng: warehouse.lng
      }
    });
    setModalHeader(viewWarehouse.warehouseLocation);
    setSavedPolygon([]);
    setOpen(true);
  };

  const handleShowCoverageArea = ({ area, name }) => {
    const numberCoords = area.map((coords) => ({
      lat: +coords.lat,
      lng: +coords.lng
    }));
    setSavedPolygon([...numberCoords]);
    setModalHeader(name);
    setSavedCoords({
      coords: null
    });
    setOpen(true);
  };

  const handleClose = () => {
    setSavedCoords({ coords: null });
    setSavedPolygon([]);
    setOpen(false);
  };

  return (
    <>
      <Navbar NavbarTitle={warehouse?.name}>
        <Button
          label={
            <span className="d-flex align-items-center">
              <Edit />
              <span className="ps-1">{viewWarehouse.edit}</span>
            </span>
          }
          labelClass="fsize-16 text-white editButton "
          onClick={handelEdit}
        />
      </Navbar>
      {warehouse && (
        <div className="content-wrapper viewWarehouse p-5">
          <p className="fsize-18 font-regular ps-2">
            {viewWarehouse.WarehouseDetails}
          </p>
          <div className="row mb-4 ps-2">
            <div className="col-4">
              <p className="mb-0 fsize-14">
                {viewWarehouse.WarehouseName}
              </p>
              <p className="warehouseName fsize-16 font-semibold">
                {warehouse.name}
              </p>
            </div>
            <div className="col-4">
              <p className="mb-0 fsize-14">
                {viewWarehouse.WholesalerName}
              </p>
              <p className="warehouseName fsize-16 font-semibold">
                {warehouse.wholesaler_name}
              </p>
            </div>
          </div>
          <div className="mb-5">
            <p className="mb-0">
              <Location className="locationaIcon me-2" />
              <span className="pt-2 fsize-14">
                {viewWarehouse.Location}
              </span>
            </p>
            <p
              onClick={handleShowLocation}
              className="fsize-16 viewMap "
            >
              {viewWarehouse.ViewMap}
            </p>
          </div>
          <div className="dividerLine mb-4"></div>
          <p className="fsize-18 font-medium mb-4 ps-2">
            {viewWarehouse.CoverageAreas}
          </p>
          {warehouse?.zones?.map((zone) => renderSingleZone(zone))}
          {open && (
            <MapModal
              open={open}
              savedCoords={savedCoords}
              savedPolygon={savedPolygon}
              handleClose={handleClose}
              modalHeaderLabel={modalHeader}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ViewWarehouse;
