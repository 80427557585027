import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { Add, Remove } from "@material-ui/icons";
import { TableCell, TableRow } from "@material-ui/core";
import { DeleteOutline, ErrorOutline } from "@material-ui/icons";

import Table from "../../../components/Table";
import Navbar from "../../../components/Navbar";
import messages from "../../../assets/locale/messages";
import Breadcrumb from "../../../components/Breadcrumb";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import AddOrderProducts from "../../../components/AddOrderProducts";
import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { PRICE_REGEX } from "../../../utils/Patterns";
import DeleteIcon from "../../../utils/Icons/Delete";
import {
  getOrderDetailsRequest,
  getOrderDetailsResponse,
  deleteOrderProductsRequest,
  editOrderProductsRequest
} from "../../../store/Orders/actions";
import { getProductCategoriesRequest } from "../../../store/ProductCategories/actions";
import "./OrderProducts.scss";

const OrderProducts = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { orders, general } = messages[lang];
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [productsArr, setProductsArr] = useState([]);
  const [disabledBtn, setDisabledBTn] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [editedProducts, setEditedProducts] = useState([]);

  const orderDetails = useSelector(
    (state) => state.orders.orderDetails
  );

  useEffect(() => {
    if (orderDetails) {
      let checkedArray = orderDetails?.products_array?.map(
        (product) => {
          return { ...product, checked: false };
        }
      );
      setProductsArr(checkedArray);
    }
  }, [orderDetails]);

  useEffect(() => {
    if (id) {
      dispatch(getOrderDetailsRequest(id));
    }
    return () => {
      dispatch(getOrderDetailsResponse(null));
    };
  }, [id]);

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedProduct([]);
  };

  useEffect(() => {
    dispatch(
      getProductCategoriesRequest({
        page: -1,
        items: 1
      })
    );
  }, []);

  const selectDeleteProduct = (id, checked) => {
    const index = productsArr.findIndex(
      (product) => product.id === id
    );
    productsArr[index].checked = checked;
    setProductsArr([...productsArr]);
    if (checked) {
      selectedProduct.push(id);
      setSelectedProduct([...selectedProduct]);
    } else {
      selectedProduct.splice(index, 1);
      setSelectedProduct([...selectedProduct]);
    }
  };

  const handlePricePerUnit = (id, value) => {
    if (value.match(PRICE_REGEX) || value === "") {
      if (value == 0) {
        setDisabledBTn(true);
      } else {
        setDisabledBTn(false);
      }
      const index = productsArr.findIndex(
        (product) => product.id === id
      );

      if (productsArr[index].new_price_per_product !== null) {
        productsArr[index].new_price_per_product = value;
        productsArr[index].total_price_after_discount =
          productsArr[index].quantity *
          productsArr[index].new_price_per_product;
      } else {
        productsArr[index].price_per_product = value;
        productsArr[index].total_price =
          productsArr[index].quantity *
          productsArr[index].price_per_product;
      }

      setProductsArr([...productsArr]);

      const editedIndex = editedProducts.findIndex(
        (product) => product.id === id
      );

      if (editedIndex == -1) {
        editedProducts.push(productsArr[index]);
        setEditedProducts([...editedProducts]);
      }
    }
  };

  const handleQuentityPerProduct = (id, value) => {
    if (/^[1-9][0-9]*$/.test(+value) || value === "") {
      const index = productsArr.findIndex(
        (product) => product.id === id
      );
      if (value == 0) {
        setDisabledBTn(true);
      } else {
        setDisabledBTn(false);
      }

      if (productsArr[index].new_price_per_product !== null) {
        productsArr[index].quantity = value;
        productsArr[index].total_price_after_discount =
          productsArr[index].quantity *
          productsArr[index].new_price_per_product;
      } else {
        productsArr[index].quantity = value;
        productsArr[index].total_price =
          productsArr[index].quantity *
          productsArr[index].price_per_product;
      }

      setProductsArr([...productsArr]);

      const editedIndex = editedProducts.findIndex(
        (product) => product.id === id
      );

      if (editedIndex == -1) {
        editedProducts.push(productsArr[index]);
        setEditedProducts([...editedProducts]);
      }
    }
  };

  const handleDeleteSelectedProduct = () => {
    dispatch(
      deleteOrderProductsRequest({
        order_id: id,
        orders_products: selectedProduct
      })
    );
    setOpenDeleteModal(false);
    setSelectedProduct([]);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleBulkEdit = () => {
    const data = editedProducts.map((product) => ({
      id: product.id,
      price_per_product:
        product.new_price_per_product !== null
          ? product.new_price_per_product
          : product.price_per_product,
      quantity: product.quantity
    }));
    dispatch(
      editOrderProductsRequest({
        order_id: id,
        orders_products: data
      })
    );
    setEditedProducts([]);
    if (editedProducts.length <= 0) {
      setDisabledBTn(true);
    }
  };

  const renderDeleteBodyModal = () => {
    if (
      orderDetails?.products_array?.length - selectedProduct.length >
      0
    ) {
      return (
        <div className="deleteConfiramtionContainer">
          <div className="d-flex justify-content-center mb-2">
            <DeleteOutline className="fsize-40 " />
          </div>
          <div className="d-flex justify-content-center mb-3">
            <p className="font-semibold text-dark fsize-14 text-center">
              {orders.orderProducts.deleteConfirmationMsg}
            </p>
          </div>
          <div className="d-flex justify-content-center ">
            <Button
              label={<div>{orders.orderProducts.delete}</div>}
              className="px-5"
              labelClass="fsize-14 text-white "
              onClick={() => {
                handleDeleteSelectedProduct();
              }}
            />
            <Button
              outlined
              className="ms-4 px-5 cancel-btn"
              label={<div>{orders.orderProducts.cancel}</div>}
              onClick={() => {
                setOpenDeleteModal(false);
              }}
              labelClass="fsize-14  text-center"
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="deleteConfiramtionContainer">
          <ErrorOutline
            fontSize="large"
            className="mb-3  error-icon"
          />

          <div className=" mb-3 text-dark fsize-14 text-center">
            <p className="mb-0">
              {orders.viewOrder.deleteMsgErrorPart1}
            </p>
            <p>{orders.viewOrder.deleteMsgErrorPart2}</p>
          </div>
        </div>
      );
    }
  };
  return (
    <>
      <Navbar NavbarTitle={orders.orderProducts.navabrTitle} />
      {orderDetails && (
        <div className="content-wrapper p-5 order-products">
          <div className="d-flex justify-content-between align-items-center ">
            <Breadcrumb
              linksArray={[
                {
                  link: ROUTE_PATHS.orders,
                  name: orders.orderProducts.orderList
                },
                {
                  link: ROUTE_PATHS.viewOrder.replace(":id", id),
                  name: orders.orderProducts.orderDetails
                },
                {
                  name: orders.orderProducts.navabrTitle
                }
              ]}
              className="mb-4"
            />
            <div className="d-flex align-items-center justify-content-end">
              <Button
                outlined
                className="me-4 remove-btn"
                disabled={
                  selectedProduct.length < 1 ||
                  location?.state.hideActions
                }
                label={
                  <div className="d-flex  align-items-center  py-1">
                    <DeleteIcon />
                    <span className="fsize-16 ps-2">
                      {orders.orderProducts.removeSelectedProducts}
                    </span>
                  </div>
                }
                onClick={() => {
                  setOpenDeleteModal(true);
                }}
                labelClass="fsize-12  text-center"
              />
              <Modal
                open={openDeleteModal}
                onClose={handleCloseDeleteModal}
                className="delete-order-modal"
                modalBody={renderDeleteBodyModal()}
              />
              <Button
                label={
                  <div className="d-flex align-items-center">
                    <Add fontSize="large" />
                    <span className="ps-1">
                      {orders.orderProducts.addProduct}
                    </span>
                  </div>
                }
                disabled={location?.state?.hideActions}
                labelClass="fsize-16 text-white "
                onClick={() => {
                  setOpenModal(true);
                }}
              />
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                className="order-modal"
                modalBody={
                  <AddOrderProducts
                    order_id={id}
                    wholesaler_id={orderDetails?.wholesaler_id}
                    onClose={handleCloseModal}
                  />
                }
              />
            </div>
          </div>
          <div className="w-80">
            <Table
              headlines={[
                "",
                orders.viewOrder.productName,
                orders.viewOrder.priceUnit,
                orders.viewOrder.quentity,
                orders.viewOrder.total
              ]}
              rows={productsArr?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell className="font-medium  text-dark-blue fsize-14   d-flex">
                    <Checkbox
                      color="secondary"
                      id={`orderProduct${row.id}`}
                      name={row.name}
                      checked={row.checked || false}
                      onChange={(checked) => {
                        selectDeleteProduct(row.id, checked);
                      }}
                      disabled={location?.state?.hideActions}
                    />
                    <div
                      className="order-product-image"
                      style={{
                        backgroundImage: `url(${
                          row.image ||
                          process.env.REACT_APP_PRODUCTS_PLACEHOLDER
                        })`,
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat"
                      }}
                    ></div>
                  </TableCell>
                  <TableCell className="font-medium  text-dark-blue fsize-14">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>{row.name}</div>
                      {row.is_offer && (
                        <div className="ms-3 p-2 offer-label-product">
                          {general.offer}
                        </div>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="font-medium  text-dark-blue fsize-14  d-flex position-relative">
                    <Input
                      id="price"
                      name="price"
                      value={
                        row.new_price_per_product !== null
                          ? row.new_price_per_product
                          : row.price_per_product
                      }
                      inputWrapperClass="w-60 price-input"
                      isInputHasErr={
                        row.new_price_per_product !== null
                          ? row.new_price_per_product == 0
                          : row.price_per_product == 0
                      }
                      errMsg={orders.orderProducts.piceErrorMsg}
                      disabled={location?.state?.hideActions}
                      onChange={(value) => {
                        handlePricePerUnit(row.id, value);
                      }}
                    />
                    <span className="price">
                      {orders.viewOrder.le}
                    </span>
                  </TableCell>
                  <TableCell className="font-medium  text-dark-blue fsize-14 quantity ">
                    <Input
                      id="quantity"
                      name="quantity"
                      isInputHasErr={row.quantity == 0}
                      errMsg={orders.orderProducts.quantityErrorMsg}
                      disabled={location?.state?.hideActions}
                      startAdornment={
                        <Remove
                          onClick={() => {
                            if (!location?.state.hideActions) {
                              handleQuentityPerProduct(
                                row.id,
                                +row.quantity - 1
                              );
                            }
                          }}
                        />
                      }
                      endAdornment={
                        <Add
                          onClick={() => {
                            if (!location?.state.hideActions) {
                              handleQuentityPerProduct(
                                row.id,
                                +row.quantity + 1
                              );
                            }
                          }}
                        />
                      }
                      value={row.quantity}
                      inputWrapperClass="w-75 quantity-input"
                      onChange={(value) => {
                        handleQuentityPerProduct(row.id, value);
                      }}
                    />
                  </TableCell>
                  <TableCell className="font-medium  total-price fsize-14  px-0 ">
                    {row.total_price_after_discount !== null
                      ? row.total_price_after_discount.toFixed(2)
                      : row.total_price.toFixed(2)}{" "}
                    {orders.viewOrder.le}
                  </TableCell>
                </TableRow>
              ))}
            />
          </div>
          <div className="d-flex align-items-center justify-content-center mt-5">
            <Button
              label={
                <div className="d-flex align-items-center">
                  {orders.orderProducts.save}
                </div>
              }
              labelClass="fsize-16 text-white"
              className="w-25"
              disabled={
                editedProducts.length < 1 ||
                disabledBtn ||
                location?.state?.hideActions
              }
              onClick={() => {
                handleBulkEdit();
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default OrderProducts;
