import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Edit } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";

import messages from "../../../assets/locale/messages";
import EgyptFlag from "../../../assets/svgs/flag-egypt.svg";
import Button from "../../../components/Button";
import Navbar from "../../../components/Navbar";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import {
  getWholesalerRequest,
  getWholesalerResponse
} from "../../../store/Wholesalers/actions";
import { formatDate } from "../../../utils/Helpers";
import "./WholesalerDetails.scss";

const WholesalerDetails = () => {
  const lang = useSelector((state) => state.locale.lang);
  const wholesaler = useSelector(
    (state) => state.wholesalers.wholesaler
  );
  const isLoading = useSelector((state) => state.loader);

  const {
    Wholesalers: {
      details: {
        details,
        name,
        email,
        phone,
        address,
        bank,
        bankName,
        bankBranch,
        account,
        iban,
        incontact,
        delivery,
        payment,
        status,
        unVerified,
        verified,
        active,
        inActive,
        join,
        products,
        seeProducts,
        edit
      }
    }
  } = messages[lang];

  const history = useHistory();

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWholesalerRequest(id));

    return () => {
      dispatch(getWholesalerResponse(null));
    };
  }, []);

  const capitalize = (str) =>
    str?.charAt(0).toUpperCase() + str?.slice(1);

  return (
    <div className="wholesaler-details-container">
      <Navbar
        NavbarTitle={
          <span
            className={`d-flex align-items-center ${
              !wholesaler?.logo && "py-3"
            }`}
          >
            {(!isLoading && wholesaler?.username) || ""}
            {!isLoading && wholesaler?.logo ? (
              <div
                style={{ backgroundImage: `url(${wholesaler.logo})` }}
                className="navbar-logo ms-4"
              ></div>
            ) : (
              ""
            )}
          </span>
        }
      >
        <Button
          label={
            <span className="d-flex align-items-center">
              <Edit />
              <span className="ps-1">{edit}</span>
            </span>
          }
          labelClass="fsize-16 text-white px-4"
          onClick={() =>
            history.push(
              ROUTE_PATHS.editWholesaler.replace(":id", id)
            )
          }
        />
      </Navbar>
      {!isLoading && wholesaler ? (
        <div className="px-5 bg-white mx-4 my-4">
          <div className="border-bottom border-gray-hr-light py-4">
            <div className="font-medium text-dark fsize-18">
              {details}
            </div>
            <div className="row mt-4">
              <div className="col-4">
                <div className="text-dark font-reguler">{name}</div>
                <div className="text-dark-blue font-semibold fsize-16">
                  {wholesaler.username}
                </div>
              </div>
              <div className="col-4">
                <div className="text-dark font-reguler">{email}</div>
                <div className="text-dark-blue font-semibold fsize-16">
                  {wholesaler.email}
                </div>
              </div>
              <div className="col-4">
                <div className="text-dark font-reguler">{phone}</div>
                <div className="d-flex align-items-center">
                  <div className="flag-container bg-gray-flag p-2">
                    <img
                      className="img-fluid"
                      src={EgyptFlag}
                      alt="eg"
                    />
                  </div>
                  <div className="text-dark-blue font-semibold fsize-16">
                    +20 {wholesaler.phone_number}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-8">
                <div className="text-dark font-reguler">
                  {address}
                </div>
                <div className="text-dark-blue font-semibold fsize-16">
                  {wholesaler.address}
                </div>
              </div>
            </div>
          </div>
          <div className="border-bottom border-gray-hr-light py-4">
            <div className="font-medium text-dark fsize-18">
              {bank}
            </div>
            <div className="row mt-4">
              <div className="col-4">
                <div className="text-dark font-reguler">
                  {bankName}
                </div>
                <div className="text-dark-blue font-semibold fsize-16">
                  {wholesaler.bank_name}
                </div>
              </div>
              <div className="col-4">
                <div className="text-dark font-reguler">
                  {bankBranch}
                </div>
                <div className="text-dark-blue font-semibold fsize-16">
                  {wholesaler.bank_branch}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-4">
                <div className="text-dark font-reguler">
                  {account}
                </div>
                <div className="text-dark-blue font-semibold fsize-16">
                  {wholesaler.bank_account_number}
                </div>
              </div>
              <div className="col-4">
                <div className="text-dark font-reguler">{iban}</div>
                <div className="text-dark-blue font-semibold fsize-16">
                  {wholesaler.bank_iban}
                </div>
              </div>
            </div>
          </div>
          <div className="border-bottom border-gray-hr-light py-4">
            <div className="font-medium text-dark fsize-18">
              {incontact}
            </div>
            <div className="row mt-4">
              <div className="col">
                <div className="text-dark font-reguler">{name}</div>
                <div className="text-dark-blue font-semibold fsize-16">
                  {wholesaler.incontact_name}
                </div>
              </div>
              <div className="col">
                <div className="text-dark font-reguler">{email}</div>
                <div className="text-dark-blue font-semibold fsize-16">
                  {wholesaler.incontact_email}
                </div>
              </div>
              <div className="col">
                <div className="text-dark font-reguler">{phone}</div>
                <div className="d-flex align-items-center">
                  <div className="flag-container bg-gray-flag p-2">
                    <img
                      className="img-fluid"
                      src={EgyptFlag}
                      alt="eg"
                    />
                  </div>
                  <div className="text-dark-blue font-semibold fsize-16">
                    +20 {wholesaler.incontact_phone_number}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom border-gray-hr-light py-4 d-flex">
            <div className="col-4">
              <div className="font-medium text-dark fsize-18">
                {delivery}
              </div>
              <div className="row mt-4">
                {wholesaler.delivery_methods.map((method) => (
                  <div className="col-3" key={method.id}>
                    <div className="text-dark-blue font-semibold fsize-16">
                      {capitalize(method.name)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-4">
              <div className="font-medium text-dark fsize-18">
                {payment}
              </div>
              <div className="row mt-4">
                {wholesaler.payment_methods.map((method) => (
                  <div className="col-3" key={method.id}>
                    <div className="text-dark-blue font-semibold fsize-16">
                      {capitalize(method.name)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row border-bottom border-gray-hr-light py-4 d-flex">
            <div className="col-4">
              <div className="font-medium text-dark fsize-18">
                {status}
              </div>
              <div className="row mt-4">
                <div className="col">
                  <div className="text-dark-blue font-semibold fsize-16">
                    {!wholesaler.is_email_verified && unVerified}
                    {wholesaler.is_email_verified &&
                      !wholesaler.is_published &&
                      verified}
                    {wholesaler.is_published &&
                      wholesaler.is_activated &&
                      active}
                    {wholesaler.is_published &&
                      !wholesaler.is_activated &&
                      inActive}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="font-medium text-dark fsize-18">
                {join}
              </div>
              <div className="row mt-4">
                <div className="col">
                  <div className="text-dark-blue font-semibold fsize-16">
                    {formatDate(wholesaler.created_at)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom border-gray-hr-light py-4 d-flex">
            <div className="col-4">
              <div className="font-medium text-dark fsize-18">
                {products}
              </div>
              <div className="row mt-4">
                <div className="col">
                  <Button
                    className="border-2 btn-all-products"
                    onClick={() =>
                      history.push(
                        ROUTE_PATHS.wholesalerProducts.replace(
                          ":id",
                          wholesaler.id
                        )
                      )
                    }
                    outlined
                    block
                    label={seeProducts}
                    labelClass="px-4 fsize-16 font-medium"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default WholesalerDetails;
