import * as types from "./types";

export const getRetailersRequest = (data) => ({
  type: types.GET_RETAILERS_REQUEST,
  payload: data
});

export const getRetailersResponse = (data) => ({
  type: types.GET_RETAILERS_RESPONSE,
  payload: data
});

export const getRetailerRequest = (data) => ({
  type: types.GET_RETAILER_REQUEST,
  payload: data
});

export const getRetailerResponse = (data) => ({
  type: types.GET_RETAILER_RESPONSE,
  payload: data
});

export const getRetailersLookupRequest = (data) => ({
  type: types.GET_RETAILERS_LOOKUP_REQUEST,
  payload: data
});

export const getRetailersLookupResponse = (data) => ({
  type: types.GET_RETAILERS_LOOKUP_RESPONSE,
  payload: data
});

export const updateRetailerRequest = (payload) => ({
  type: types.UPDATE_RETAILER_REQUEST,
  payload
});
