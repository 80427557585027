export const GET_PRODUCTCATEGORIES_REQUEST =
  "GET_PRODUCTCATEGORIES_REQUEST";

export const GET_PRODUCTCATEGORIES_RESPONSE =
  "GET_PRODUCTCATEGORIES_RESPONSE";

export const ADD_PRODUCTCATEGORY_REQUEST =
  "ADD_PRODUCTCATEGORY_REQUEST";

export const GET_PRODUCTCATEGORY_REQUEST =
  "GET_PRODUCTCATEGORY_REQUEST";

export const GET_PRODUCTCATEGORY_RESPONSE =
  "GET_PRODUCTCATEGORY_RESPONSE";

export const EDIT_PRODUCTCATEGORY_REQUEST =
  "EDIT_PRODUCTCATEGORY_REQUEST";

export const DELETE_PRODUCTCATEGORY_REQUEST =
  "DELETE_PRODUCTCATEGORY_REQUEST";
