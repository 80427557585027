import { axiosInstance } from "./";

const getProductCategories = async (params) => {
  return await axiosInstance.get(`product_categories`, { params });
};

const addProductCategory = async (payload) =>
  await axiosInstance.post(`product_categories`, payload);

const getProductCategory = async (id) =>
  await axiosInstance.get(`product_categories/${id}`);

const editProductCategory = async ({ data, id }) =>
  await axiosInstance.patch(`product_categories/${id}`, data);

const deleteProductCategory = async ({ id }) =>
  await axiosInstance.delete(`product_categories/${id}`);

export {
  getProductCategories,
  addProductCategory,
  getProductCategory,
  editProductCategory,
  deleteProductCategory
};
