import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Navbar from "../../../../components/Navbar";
import AlertNote from "../../../../components/AlertNote";
import Button from "../../../../components/Button";
import ProductImagesSwiper from "../../../../components/ProductImagesSwiper";
import Switch from "../../../../components/Switch";
import Modal from "../../../../components/Modal";
import WarhouseProductModal from "../../../../components/WarehouseProductModal";
import Breadcrumb from "../../../../components/Breadcrumb";
import messages from "../../../../assets/locale/messages";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import DeleteIcon from "../../../../utils/Icons/Delete";
import EditIcon from "../../../../utils/Icons/Edit";
import {
  getWholesalerRequest,
  getWholsalerProductRequest,
  editWholesalerProductRequest,
  deleteWholesalerProductRequest
} from "../../../../store/Wholesalers/actions";
import { getPreviousProductOffersRequest } from "../../../../store/Offers/actions";
import AppliedOffersCard from "../AppliedOffersCard";
import "./ViewWholesalerProduct.scss";

const ViewWholesalerProduct = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const wholesaler = useSelector(
    (state) => state.wholesalers.wholesaler
  );
  const product = useSelector(
    (state) => state.wholesalers.wholesalerProduct
  );
  const previousProductOffers = useSelector(
    (state) => state.offers.previousProductOffers
  );
  const [openEdit, setOpenEdit] = useState(false);

  const { viewProduct } = messages[lang].Wholesalers;
  const { general } = messages[lang];
  const { id, productId } = useParams();

  const breadcrumb = () => (
    <Breadcrumb
      linksArray={[
        {
          link: ROUTE_PATHS["wholesalerDetails"].replace(":id", id),
          name: viewProduct.Wholesaler
        },
        {
          link: ROUTE_PATHS["wholesalerProducts"]
            .replace(":id", id)
            .replace(":productId", productId),

          name: viewProduct.Products
        },
        {
          name: viewProduct.productDetails
        }
      ]}
    />
  );

  const renderdSlides = (images) => {
    if (images) {
      if (images.length == 0) {
        return (
          <div
            style={{
              backgroundImage: `url(${process.env.REACT_APP_PRODUCTS_PLACEHOLDER})`,
              backgroundSize: "cover"
            }}
            className="product-placeholder-image mt-3 "
          ></div>
        );
      } else if (images?.length <= 5) {
        return (
          <div className="d-flex justify-content-start mt-3">
            {images?.map((image, index) => (
              <div
                key={index}
                style={{
                  backgroundImage: `url(${image.url})`,
                  backgroundSize: "cover"
                }}
                className="product-image me-3 "
              ></div>
            ))}
          </div>
        );
      } else {
        return (
          <ProductImagesSwiper
            slides={images}
            slidesCount={4}
            isDefault={false}
            isDeleted={false}
          />
        );
      }
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getWholesalerRequest(id));
    }
  }, [id]);

  useEffect(() => {
    dispatch(
      getWholsalerProductRequest({ wholsalerId: id, productId })
    );
  }, [id, productId]);

  useEffect(() => {
    dispatch(
      getPreviousProductOffersRequest({
        wholesalers_product_id: productId,
        applied_offers: true
      })
    );
  }, [productId]);

  const handleCloseEditModal = () => {
    setOpenEdit(false);
  };

  const handleEdit = () => {
    setOpenEdit(true);
  };

  const toggleStock = (inStock) => {
    dispatch(
      editWholesalerProductRequest({
        wholsalerId: id,
        productId,
        data: {
          is_in_stock: !inStock
        },
        insideWholesalerProductDetails: true
      })
    );
  };

  const deleteProduct = () => {
    dispatch(
      deleteWholesalerProductRequest({
        wholsalerId: id,
        productId
      })
    );
  };

  return (
    <>
      <Navbar
        NavbarTitle={
          <span
            className={`d-flex align-items-center ${
              !wholesaler?.logo && "py-3"
            }`}
          >
            {wholesaler?.username || ""}
            {wholesaler?.logo ? (
              <div
                style={{
                  backgroundImage: `url(${wholesaler?.logo})`
                }}
                className="navbar-logo ms-4"
              ></div>
            ) : (
              ""
            )}
          </span>
        }
      />
      <div className="bg-white m-4 py-4 px-5 view-wholsaler-product">
        <div className="d-flex justify-content-between align-items-center">
          {breadcrumb()}
          <div className="d-flex  ">
            <div className="d-flex flex-column align-items-center">
              <Switch
                checked={product?.is_in_stock || false}
                name="inStock"
                onChange={() => {
                  toggleStock(product.is_in_stock);
                }}
              />
              <p className="toggle-text">
                {product?.is_in_stock
                  ? viewProduct.inStock
                  : viewProduct.outStock}
              </p>
            </div>
            <Button
              label={
                <div className="d-flex align-items-center">
                  <DeleteIcon />
                  <span className="ps-2 ">
                    {viewProduct.deleteProduct}
                  </span>
                </div>
              }
              labelClass="fsize-16 py-1"
              className="mb-4  mx-3  remove-btn"
              outlined
              onClick={() => {
                deleteProduct();
              }}
            />

            <Button
              label={
                <div className="d-flex align-items-center ">
                  <EditIcon />
                  <span className="ps-2">
                    {viewProduct.editProduct}
                  </span>
                </div>
              }
              labelClass="fsize-16 text-white text-center  px-4  py-1"
              className="mb-4 "
              onClick={() => {
                handleEdit();
              }}
            />
            <Modal
              open={openEdit}
              onClose={handleCloseEditModal}
              className="editModal"
              modalBody={
                <WarhouseProductModal
                  onClose={handleCloseEditModal}
                  product={product}
                  productId={product.id}
                  isEdit
                  isWholesalerOption={false}
                />
              }
            />
          </div>
        </div>
        <AlertNote
          alertText={general.noteOfChangingProductsWillAffectOffers}
          wrapperClasses="my-4"
        />
        <div className="w-75 pt-4">
          <div className="row">
            <div className="col-6 text-dark-blue fsize-16 font-bold ">
              {product?.product?.concatenated_name}
            </div>
            <div className="col-6">
              <span className="text-product-card-dark font-light">
                {viewProduct.category} :
              </span>
              <span className="text-product-card-dark pe-1 fsize-16">
                {
                  product?.product?.super_product?.product_category
                    .name
                }
              </span>
            </div>
          </div>
          {renderdSlides(product?.product?.images)}
          {product?.product?.description && (
            <div className="pt-5 ">
              <span className="text-product-card-dark fsize-14 my-2 font-light">
                {viewProduct.description}:
              </span>
              <span className="p-3 fsize-16 text-product-card-dark my-2 pe-3">
                {product?.product?.description}
              </span>
            </div>
          )}

          <div className=" py-4 d-flex w-50 text-right flex-wrap text-product-card">
            {product?.product?.specifications?.map((spec, index) =>
              spec.length ? (
                <span
                  key={index}
                  className="view-spec-label specsColor  mb-1 px-4 py-1"
                >
                  {spec}
                </span>
              ) : (
                ""
              )
            )}
          </div>
          <div className="row">
            <div className="col-3">
              <p className="label-color fsize-16 font-medium ">
                {viewProduct.originalPrice}
              </p>
              <div className="d-flex align-items-center">
                <p className="fsize-30 err-asterisk ">
                  {product?.price}
                </p>
                <p className="text-dark-blue ps-2 fsize-16">
                  {viewProduct.pound}
                </p>
              </div>
            </div>
            <div className="col-3">
              <p className="label-color fsize-16 font-medium">
                {viewProduct.quantity}
              </p>
              <p className="fsize-30 err-asterisk">
                {product?.quantity}
              </p>
            </div>
            {product.min_ordering_quantity &&
              product.min_ordering_quantity >= 0 && (
                <div className="col-3">
                  <p className="label-color fsize-16 font-medium">
                    {viewProduct.minQuantity}
                  </p>
                  <p className="fsize-30 err-asterisk">
                    {product.min_ordering_quantity}
                  </p>
                </div>
              )}
            {product.max_ordering_quantity &&
              product.max_ordering_quantity >= 0 && (
                <div className="col-3 ps-4">
                  <p className="label-color fsize-16 font-medium">
                    {viewProduct.maxQuantity}
                  </p>
                  <p className="fsize-30 err-asterisk">
                    {product.max_ordering_quantity}
                  </p>
                </div>
              )}
          </div>
        </div>
        {previousProductOffers.length > 0 && (
          <>
            <div className="mt-3 applied-offers-container">
              <p className=" mb-4 fsize-16 font-medium applied-offers-text">
                {viewProduct.appliedOffers}
              </p>

              <div className="applied-offers-cards">
                {previousProductOffers?.map((offer, index) => (
                  <AppliedOffersCard
                    key={`offer-${index}`}
                    offer={offer}
                    wholesalerProductId={productId}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ViewWholesalerProduct;
