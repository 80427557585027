import { axiosInstance } from "./";

const getBanners = async (params) =>
  await axiosInstance.get(`banners`, { params });

const addBanner = async (payload) =>
  await axiosInstance.post(`banners`, payload);

const getBanner = async (id) =>
  await axiosInstance.get(`banners/${id}`);

const editBanner = async ({ data, id }) =>
  await axiosInstance.patch(`banners/${id}`, data);

const deleteBanner = async ({ id }) =>
  await axiosInstance.delete(`banners/${id}`);

export { getBanners, addBanner, getBanner, editBanner, deleteBanner };
