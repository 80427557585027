import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandMore } from "@material-ui/icons";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Collapse from "@material-ui/core/Collapse";
import amplitude from "amplitude-js";

import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import { LogoutIcon } from "../../utils/Icons/Logout";
import { OrdersIcon } from "../../utils/Icons/Orders";
import { Promocodes } from "../../utils/Icons/Promocodes";
import { Banners } from "../../utils/Icons/Banners";
import { Offers } from "../../utils/Icons/Offers";
import { RetailersIcon } from "../../utils/Icons/Retailers";
import { ProductsIcon } from "../../utils/Icons/Products";
// import { EmptyNotifications } from "../../utils/Icons/EmptyNotifications";
// import { ActiveNotifications } from "../../utils/Icons/ActiveNotifications";
import { Home } from "../../utils/Icons/Home";
import { Users } from "../../utils/Icons/Users";
import { getDeviceId } from "../../utils/Helpers";
import { logoutRequest } from "../../store/Auth/actions";
import { getAllNotificationsRequest } from "../../store/Notifications/actions";
import messages from "../../assets/locale/messages";
import "./SideMenu.scss";

const SideMenu = () => {
  const dispatch = useDispatch();
  let location = useLocation();

  const lang = useSelector((state) => state.locale.lang);
  // const notificationsList = useSelector(
  //   (state) => state.notifications.fullList
  // );
  const message = messages[lang];
  const [path, setPath] = useState("");
  // const [unseenNotificationsCount, setUnseenNotificationsCount] =
  //   useState(0);

  // useEffect(() => {
  //   dispatch(getAllNotificationsRequest());
  // }, []);

  useEffect(() => {
    if (location.pathname === ROUTE_PATHS.allProducts) {
      amplitude
        .getInstance()
        .logEvent("admin browse: view products list");
    }
  }, [location]);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  useEffect(() => {
    setPath(location.pathname);

    if (location.pathname === ROUTE_PATHS.notifications) {
      dispatch(getAllNotificationsRequest());
    }
  }, [location, setPath]);

  // useEffect(() => {
  //   // Get unseen notifications count
  //   let count = 0;
  //   notificationsList.forEach((elt) => {
  //     if (!elt.seen) {
  //       count++;
  //     }
  //   });
  //   setUnseenNotificationsCount(count);
  // }, [notificationsList]);

  // const renderNotificationsCount = () =>
  //   unseenNotificationsCount ? (
  //     <div
  //       className={`notifications-count text-center ${
  //         unseenNotificationsCount >= 100 && "fsize-11 large-count"
  //       }`}
  //     >
  //       {unseenNotificationsCount >= 100
  //         ? "99+"
  //         : unseenNotificationsCount}
  //     </div>
  //   ) : null;

  const activetRoute = (activekeys) => {
    return activekeys.some((key) => path.includes(key));
  };

  const sideMenu = [
    {
      id: 1,
      title: message.Wholesalers.wholesalers,
      icon: <Users />,
      iconSelected: <Users selectedIcon />,
      slug: ROUTE_PATHS.wholesalers,
      activekeys: ["wholesalers"],
      hasChildren: false,
      children: []
    },
    {
      id: 2,
      title: message.Warehouses,
      icon: <Home />,
      iconSelected: <Home selectedIcon />,
      slug: ROUTE_PATHS.warehouses,
      activekeys: ["warehouses"],
      hasChildren: false,
      children: []
    },
    {
      id: 3,
      title: message.retailers.Retailers,
      icon: <RetailersIcon />,
      iconSelected: <RetailersIcon selectedIcon />,
      slug: ROUTE_PATHS.retailers,
      activekeys: ["retailers"],
      hasChildren: false,
      children: []
    },
    {
      id: 4,
      title: message.orders.name,
      icon: <OrdersIcon />,
      iconSelected: <OrdersIcon selectedIcon />,
      slug: ROUTE_PATHS.orders,
      activekeys: ["orders"],
      hasChildren: false,
      children: []
    },
    {
      id: 5,
      title: message.productCategories.products,
      icon: <ProductsIcon />,
      iconSelected: <ProductsIcon selectedIcon />,
      activekeys: ["categories", "all-products"],
      hasChildren: true,
      children: [
        {
          id: 5.1,
          title: message.productCategories.allProducts,
          slug: ROUTE_PATHS.allProducts,
          activekeys: ["all-products"]
        },
        {
          id: 5.2,
          title: message.productCategories.categoriesSideMenu,
          slug: ROUTE_PATHS.productCategories,
          activekeys: ["categories"]
        }
      ]
    },
    {
      id: 6,
      title: message.promocodes.title,
      icon: <Promocodes />,
      iconSelected: <Promocodes selectedIcon />,
      slug: ROUTE_PATHS.promoCodes,
      activekeys: ["promocodes"],
      hasChildren: false,
      children: []
    },
    {
      id: 7,
      title: message.banners.title,
      icon: <Banners />,
      iconSelected: <Banners selectedIcon />,
      slug: ROUTE_PATHS.banners,
      activekeys: ["banners"],
      hasChildren: false,
      children: []
    },
    {
      id: 8,
      title: message.offers.sideMenuTitle,
      icon: <Offers />,
      iconSelected: <Offers selectedIcon />,
      slug: ROUTE_PATHS.offers,
      activekeys: ["offers"],
      hasChildren: false,
      children: []
    }
    // ,{
    //   id: 9,
    //   title: message.notifications.title,
    //   icon: unseenNotificationsCount ? (
    //     <ActiveNotifications />
    //   ) : (
    //     <EmptyNotifications />
    //   ),
    //   iconSelected: unseenNotificationsCount ? (
    //     <ActiveNotifications selectedIcon />
    //   ) : (
    //     <EmptyNotifications selectedIcon />
    //   ),
    //   slug: ROUTE_PATHS.notifications,
    //   activekeys: ["notifications"],
    //   endAdorment: renderNotificationsCount(),
    //   hasChildren: false,
    //   children: []
    // }
  ];
  const drawerWidth = "100%";

  const useStyles = makeStyles((theme) => ({
    links: {
      color: theme.palette.white,
      textDecoration: "none",
      "&:hover, &.Mui-selected": {
        color: theme.palette.white
      },
      width: drawerWidth
    },

    listItem: {
      fontSize: "1rem",
      fontWeight: "500",
      fontFamily: "Inter",
      "&:hover, &.Mui-selected": {
        backgroundColor: theme.palette.white
      }
    },
    listItemIcon: {
      color: theme.palette.white
    },
    nested: {
      paddingLeft: theme.spacing(2),
      color: theme.palette.white
    }
  }));
  const classes = useStyles();

  const handleLogout = () => {
    dispatch(
      logoutRequest({
        device_id: getDeviceId()
      })
    );
  };

  const drawer = (
    <div>
      <List className={`${classes.links} side-menu-list`}>
        {sideMenu.map(
          ({
            id,
            title,
            icon,
            iconSelected,
            slug,
            activekeys,
            endAdorment,
            hasChildren,
            children
          }) => {
            return !hasChildren ? (
              <Link to={slug} key={id}>
                <ListItem
                  selected={activetRoute(activekeys)}
                  button
                  className={`${classes.ListItem} p-4 d-flex justify-content-between`}
                >
                  <div className="d-flex align-items-center justify-content-start">
                    <ListItemIcon className={classes.listItemIcon}>
                      {activetRoute(activekeys) ? iconSelected : icon}
                    </ListItemIcon>
                    <span>{title}</span>
                  </div>
                  <div>{endAdorment}</div>
                </ListItem>
              </Link>
            ) : (
              <React.Fragment key={id}>
                <ListItem
                  selected={activetRoute(activekeys)}
                  button
                  className={`${classes.ListItem} p-4`}
                  onClick={handleClick}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    {activetRoute(activekeys) ? iconSelected : icon}
                  </ListItemIcon>
                  {title}
                  {open ? <ExpandMore /> : <ChevronRightIcon />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div">
                    {children.map(
                      ({ id, title, slug, activekeys }) => (
                        <Link to={slug} key={id}>
                          <ListItem
                            className={`${classes.nested} ${
                              activetRoute(activekeys)
                                ? "active-child"
                                : ""
                            } pe-4`}
                          >
                            <p
                              className={`${
                                activetRoute(activekeys)
                                  ? "text-decoration-underline text-white"
                                  : ""
                              } mb-0 ps-4`}
                            >
                              {title}
                            </p>
                          </ListItem>
                        </Link>
                      )
                    )}
                  </List>
                </Collapse>
              </React.Fragment>
            );
          }
        )}
      </List>
      <div className="position-fixed bottom-0">
        <div className="divider m-auto"></div>
        <div className="d-flex">
          <div
            className="d-flex align-items-center p-4 pointer"
            onClick={handleLogout}
          >
            <LogoutIcon />
            <h3 className="fsize-14 text-white mb-0 ms-3 opacity-6">
              {message.auth.logout}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="vh-100 Side-Menu position-fixed">
      <div className="logo-image d-flex justify-content-center align-items-center flex-column">
        <img
          src={process.env.REACT_APP_LOGO_SIDEMENU}
          alt={process.env.REACT_APP_LOGO_ALT}
        />
      </div>
      <div className="divider m-auto"></div>
      <Box sx={{ display: "flex" }}>
        <Box component="nav">
          <Drawer variant="permanent" open>
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </div>
  );
};

export default SideMenu;
