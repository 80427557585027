import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Add } from "@material-ui/icons";

import {
  getBannersRequest,
  deleteBannerRequest,
  editBannerRequest
} from "../../store/Banners/actions";
import { setBannersRoute } from "../../store/Routing/actions";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import messages from "../../assets/locale/messages";
import BannersList from "./BannersList";
import Navbar from "../../components/Navbar";
import Button from "../../components/Button";
import Filter from "../../components/Filter";
import Pagination from "../../components/Pagination";
import Modal from "../../components/Modal";
import { bannersStatus } from "../../utils/Constants";
import DeleteIcon from "../../utils/Icons/Delete";
import "./banners.scss";

const Banners = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const lang = useSelector((state) => state.locale.lang);
  const list = useSelector((state) => state.banners.list);
  const meta = useSelector((state) => state.banners.meta);
  const isLoading = useSelector((state) => state.loader);
  const { banners } = messages[lang];

  const bannersRoutingData = useSelector(
    (state) => state.routing.banners
  );

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [deleteBannerId, setDeleteBannerId] = useState(null);
  const [filterStatus, setFilterStatus] = useState(
    bannersRoutingData?.filterStatus || null
  );

  const [page, setPage] = useState(bannersRoutingData?.page || 1);

  const getBanners = (pageNumber, is_hidden) => {
    dispatch(
      getBannersRequest({
        page: pageNumber,
        items: 9,
        is_hidden
      })
    );
  };

  useEffect(() => {
    if (bannersRoutingData) {
      dispatch(setBannersRoute(null));
    }
  }, [bannersRoutingData]);

  useEffect(() => {
    getBanners(page, filterStatus?.value);
  }, [page, filterStatus]);

  const confirmDeleteBtn = () => {
    dispatch(
      deleteBannerRequest({
        id: deleteBannerId,
        page,
        filterStatus
      })
    );

    setOpenDeleteConfirm(false);
  };

  const handleHideClick = (isHidden, id) => {
    dispatch(
      editBannerRequest({
        data: { is_hidden: !isHidden },
        id,
        page,
        filterStatus
      })
    );
  };

  const handleEditClick = (id) => {
    dispatch(
      setBannersRoute({
        page,
        filterStatus
      })
    );
    history.push(ROUTE_PATHS["editBanner"].replace(":id", id));
  };

  const handleDeleteClick = (bannerIdDelete) => {
    setDeleteBannerId(bannerIdDelete);
    setOpenDeleteConfirm(true);
  };

  const handleCloseDeleteConfirmModal = () => {
    setOpenDeleteConfirm(false);
  };

  return (
    <>
      <Navbar NavbarTitle={banners.list}>
        <Button
          label={
            <span className="d-flex align-items-center">
              <Add fontSize="large" />
              <span className="ps-1">{banners.add}</span>
            </span>
          }
          labelClass="fsize-16 text-white pe-2"
          onClick={() => {
            history.push(ROUTE_PATHS.addBanner);
          }}
        />
      </Navbar>
      {((list.length == 0 && filterStatus) || list.length > 0) && (
        <div className="filters px-4 py-3 d-flex align-items-center ">
          <Filter
            placeholder={banners.status}
            options={bannersStatus}
            value={filterStatus}
            name="promocode status"
            onChange={(value) => {
              setPage(1);
              setFilterStatus(value);
            }}
            inputClassName=" mt-1 "
            filterWrapperClass=" me-4 "
          />
        </div>
      )}
      <div className="mx-4 mb-3 banners-list-container pt-5 bg-white">
        <BannersList
          isLoading={isLoading}
          filterStatus={filterStatus}
          handleAddBannerClick={() => {
            history.push(ROUTE_PATHS.addBanner);
          }}
          handleEditClick={handleEditClick}
          handleHideClick={handleHideClick}
          handleDeleteClick={handleDeleteClick}
        />
        <div className="d-flex justify-content-center py-3">
          {meta.pages > 1 && (
            <Pagination
              count={meta.pages}
              page={meta.currentPage || page}
              handleChange={(event, page) => {
                setPage(page);
              }}
              defaultPage={1}
            />
          )}
        </div>
      </div>
      <Modal
        open={openDeleteConfirm}
        onClose={handleCloseDeleteConfirmModal}
        modalWrapperClass="delete-banner-modal"
        modalBody={
          <>
            <DeleteIcon className="delete-icon mt-2 mb-4" />
            <p>{banners.deleteConfirmationMsg}</p>
          </>
        }
        actionBtns={
          <div className="container px-4">
            <div className="row">
              <div className="col-md-6">
                <div className=" mx-2 d-flex justify-content-center align-items-center">
                  <Button
                    label={banners.delete}
                    type="button"
                    labelClass="fsize-14 text-white"
                    onClick={confirmDeleteBtn}
                    block={true}
                    className="py-2"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="mx-2 d-flex justify-content-center align-items-center pointer"
                  onClick={handleCloseDeleteConfirmModal}
                >
                  <div>
                    <p className="fsize-14 text-orange py-2">
                      {banners.cancel}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default Banners;
