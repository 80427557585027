export const SET_WHOLESALERS_ROUTE = "SET_WHOLESALERS_ROUTE";
export const SET_WAREHOUSES_ROUTE = "SET_WAREHOUSES_ROUTE";
export const SET_RETAILERS_ROUTE = "SET_RETAILERS_ROUTE";
export const SET_ORDERS_ROUTE = "SET_ORDERS_ROUTE";
export const SET_CATEGORIES_ROUTE = "SET_CATEGORIES_ROUTE";
export const SET_SUPER_PRODUCTS_ROUTE = "SET_SUPER_PRODUCTS_ROUTE";
export const SET_PRODUCTS_ROUTE = "SET_PRODUCTS_ROUTE";
export const SET_WHOLESALER_PRODUCTS_ROUTE =
  "SET_WHOLESALER_PRODUCTS_ROUTE";
export const SET_PROMOCODES_ROUTE = "SET_PROMOCODES_ROUTE";
export const SET_BANNERS_ROUTE = "SET_BANNERS_ROUTE";
export const SET_OFFERS_ROUTE = "SET_OFFERS_ROUTE";
