import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  superProduct: null
};

const superProductsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_SUPERPRODUCTS_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };
    case types.GET_SUPERPRODUCT_RESPONSE:
      return { ...state, superProduct: action.payload };
    default:
      return state;
  }
};

export default superProductsReducer;
