import React from "react";
import PropTypes from "prop-types";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { Link } from "react-router-dom";

const Breadcrumb = ({ linksArray, className }) => {
  const renderLink = (child, link) => {
    return link ? <Link to={link}>{child}</Link> : <>{child}</>;
  };

  return (
    <div className={`d-flex align-items-center ${className}`}>
      {linksArray.map((link, index) => {
        return (
          <span key={index}>
            {renderLink(
              <span
                className={`${
                  link.isArabic
                    ? "font-cairo-regular fsize-20"
                    : "font-reguler fsize-18"
                } ${
                  index < linksArray.length - 1
                    ? "text-dark"
                    : "text-dark-blue"
                }`}
              >
                {link.name}
              </span>,
              link.link
            )}
            {index < linksArray.length - 1 && (
              <span className="mx-2">
                <DoubleArrowIcon color="primary" />
              </span>
            )}
          </span>
        );
      })}
    </div>
  );
};

Breadcrumb.propTypes = {
  linksArray: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string,
      isArabic: PropTypes.bool
    })
  ),
  className: PropTypes.string
};

export default Breadcrumb;
