export const setAdmin = ({ admin }, storage = "session") => {
  if (storage === "session") {
    sessionStorage.setItem("admin", JSON.stringify(admin));
  } else {
    localStorage.setItem("admin", JSON.stringify(admin));
  }
};

export const clearAdminData = () => {
  localStorage.removeItem("device_id");
  localStorage.removeItem("admin");
  sessionStorage.removeItem("admin");
};
