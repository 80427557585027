import * as types from "./types";

export const getOrdersRequest = (data) => ({
  type: types.GET_ORDERS_REQUEST,
  payload: data
});

export const getOrdersResponse = (data) => ({
  type: types.GET_ORDERS_RESPONSE,
  payload: data
});

export const getOrdersFullListRequest = (data) => ({
  type: types.GET_ORDERS_FULLLIST_REQUEST,
  payload: data
});

export const getOrdersFullListResponse = (data) => ({
  type: types.GET_ORDERS_FULLLIST_RESPONSE,
  payload: data
});

export const getOrderDetailsRequest = (payload) => ({
  type: types.GET_ORDER_DETAILS_REQUEST,
  payload
});

export const getOrderDetailsResponse = (payload) => ({
  type: types.GET_ORDER_DETAILS_RESPONSE,
  payload
});

export const updateOrderRequest = (payload) => ({
  type: types.UPDATE_ORDER_REQUEST,
  payload
});

export const deleteOrderProductsRequest = (data) => ({
  type: types.DELETE_ORDER_PRODUCTS_REQUEST,
  payload: data
});

export const editOrderProductsRequest = (payload) => ({
  type: types.EDIT_ORDER_PRODUCTS_REQUEST,
  payload
});

export const addOrderProductsRequest = (payload) => ({
  type: types.ADD_ORDER_PRODUCTS_REQUEST,
  payload
});

export const getOrderPDFRequest = (data) => ({
  type: types.GET_ORDER_PDF_REQUEST,
  payload: data
});

export const getOrderPDFResponse = (data) => ({
  type: types.GET_ORDER_PDF_RESPONSE,
  payload: data
});
