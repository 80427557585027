export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_RESPONSE = "GET_ORDERS_RESPONSE";

export const GET_ORDERS_FULLLIST_REQUEST =
  "GET_ORDERS_FULLLIST_REQUEST";
export const GET_ORDERS_FULLLIST_RESPONSE =
  "GET_ORDERS_FULLLIST_RESPONSE";

export const GET_ORDER_DETAILS_REQUEST = "GET_ORDER_DETAILS_REQUEST";
export const GET_ORDER_DETAILS_RESPONSE =
  "GET_ORDER_DETAILS_RESPONSE";

export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";

export const DELETE_ORDER_PRODUCTS_REQUEST =
  "DELETE_ORDER_PRODUCTS_REQUEST";

export const EDIT_ORDER_PRODUCTS_REQUEST =
  "EDIT_ORDER_PRODUCTS_REQUEST";

export const ADD_ORDER_PRODUCTS_REQUEST =
  "ADD_ORDER_PRODUCTS_REQUEST";

export const GET_ORDER_PDF_REQUEST = "GET_ORDER_PDF_REQUEST";
export const GET_ORDER_PDF_RESPONSE = "GET_ORDER_PDF_RESPONSE";
