import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormHelperText } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";

import messages from "../../assets/locale/messages";
import ProductImagesSwiper from "../ProductImagesSwiper";
import Input from "../Input";
import Button from "../Button";
import { PRICE_REGEX } from "../../utils/Patterns";
import {
  getWholesalersRequest,
  createWholesalerProduct,
  editWholesalerProductRequest
} from "../../store/Wholesalers/actions";
import Select from "../Select";
import "./WarehouseProductModal.scss";

const WarhouseProductModal = ({
  productId,
  onClose,
  isWholesalerOption,
  product,
  isEdit,
  page,
  query,
  product_category_id
}) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const wholesalers = useSelector((state) => state.wholesalers.list);
  const { productsModal } = messages[lang];
  const [wholsalersOptions, setWholsalersOptions] = useState([]);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    dispatch(
      getWholesalersRequest({ lookup: "options", page: -1, items: 1 })
    );
  }, []);

  useEffect(() => {
    const options = wholesalers.map((wholesaler) => ({
      label: wholesaler.username,
      id: wholesaler.id
    }));
    setWholsalersOptions(options);
  }, [wholesalers]);

  const getInitialValues = () => {
    let initailvalues = {
      price: "",
      quantity: "",
      min_ordering_quantity: "",
      max_ordering_quantity: ""
    };
    if (isWholesalerOption) {
      initailvalues.wholesaler_id = "";
    }
    return initailvalues;
  };

  const getValidationSchema = () => {
    let validation = {
      price: Yup.number()
        .typeError(productsModal.priceNumber)
        .required(productsModal.priceErrorReq)
        .test(
          "min0",
          productsModal.priceErrorMin,
          (price) => price > 0
        ),
      quantity: Yup.number().min(0, productsModal.quantityError),
      min_ordering_quantity: Yup.number().min(
        1,
        productsModal.minError
      ),
      max_ordering_quantity: Yup.number().min(
        1,
        productsModal.maxError
      )
    };
    if (isWholesalerOption) {
      validation.wholesaler_id = Yup.string().required(
        productsModal.selectErrorMessage
      );
    }
    return validation;
  };
  useEffect(() => {
    if (isEdit) {
      setValues({
        price: product.price,
        quantity: product.quantity || "",
        min_ordering_quantity: product.min_ordering_quantity || "",
        max_ordering_quantity: product.max_ordering_quantity || ""
      });
    }
  }, [product]);

  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    touched,
    setFieldTouched,
    dirty,
    isValid,
    setValues
  } = useFormik({
    initialValues: getInitialValues(),
    validationSchema: Yup.object(getValidationSchema()),
    onSubmit: ({
      price,
      wholesaler_id,
      quantity,
      min_ordering_quantity,
      max_ordering_quantity
    }) => {
      if (
        +max_ordering_quantity < +min_ordering_quantity &&
        max_ordering_quantity !== ""
      ) {
        setErrMsg(productsModal.minMaxError);
      } else {
        let productValues = {
          product_id: productId,
          price,
          min_ordering_quantity,
          max_ordering_quantity
        };
        if (quantity) {
          productValues.quantity = quantity;
        }
        if (!isEdit) {
          dispatch(
            createWholesalerProduct({
              id: wholesaler_id,
              data: {
                ...productValues
              }
            })
          );
        } else {
          dispatch(
            editWholesalerProductRequest({
              wholsalerId: product.wholesaler.id,
              productId: product.id,
              page,
              query,
              product_category_id,
              data: {
                ...productValues
              }
            })
          );
        }
        onClose(false);
      }
    }
  });

  const renderdSlides = (images) => {
    if (images) {
      if (images?.length <= 4) {
        return (
          <div className="d-flex justify-content-start">
            {images?.map((image, index) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundImage: `url(${image.url})`,
                    backgroundSize: "cover"
                  }}
                  className="product-image mx-2 "
                ></div>
              );
            })}
          </div>
        );
      } else {
        return (
          <ProductImagesSwiper
            slides={images}
            isDefault={false}
            isDeleted={false}
            space={10}
            slidesCount={4}
          />
        );
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} noValidate>
        {isWholesalerOption && (
          <div className="w-50 text-left padding">
            <Select
              name="wholesaler_id"
              options={wholsalersOptions}
              label={productsModal.select}
              variant
              block
              labelClassName=" font-medium pb-2"
              required
              placeholder={productsModal.selectPlaceholder}
              value={
                wholsalersOptions.find(
                  (option) => option.id == values["wholesaler_id"]
                ) || null
              }
              onChange={(value) => {
                setFieldTouched("wholesaler_id");
                setFieldValue("wholesaler_id", value ? value.id : "");
              }}
              hasError={
                !!(
                  touched["wholesaler_id"] && errors["wholesaler_id"]
                )
              }
              errMsg={errors["wholesaler_id"]}
            />
          </div>
        )}

        <div className="row pe-5 py-4 text-left  padding">
          <div className="col-6 pb-3 fsize-16 pe-5 ps-name text-dark-blue ">
            {isEdit
              ? product?.product?.concatenated_name
              : product?.concatenated_name}
          </div>
          <div className="col-6">
            <span className="text-product-card-dark font-light ">
              {productsModal.category}:
            </span>
            <span className="text-product-card-dark pe-1 ">
              {isEdit
                ? product?.product?.super_product?.product_category
                    ?.name
                : product?.super_product?.product_category?.name}
            </span>
          </div>
        </div>
        <div className="mb-5 padding">
          {renderdSlides(
            isEdit ? product?.product?.images : product?.images
          )}
        </div>
        {(product?.product?.description || product?.description) && (
          <div className="d-flex  align-items-start mb-5 padding">
            <div className="text-product-card-dark fsize-14 my-2 pe-1 font-light">
              {productsModal.description}:
            </div>
            <div className="fsize-16 text-product-card-dark my-2 pe-3">
              {isEdit
                ? product?.product?.description
                : product?.description}
            </div>
          </div>
        )}

        <div className="row  mb-5 padding ">
          <div className="col-3  position-relative d-flex align-items-start text-left">
            <Input
              label={productsModal.price}
              id="price"
              name="price"
              required
              value={values["price"]}
              labelClassName=" mb-3 label-color text-left fsize-16"
              inputWrapperClass="modal-input"
              onChange={(value) => {
                setFieldTouched("price");
                if (value.match(PRICE_REGEX) || value === "") {
                  setFieldValue("price", value);
                }
              }}
              isInputHasErr={!!(touched["price"] && errors["price"])}
              errMsg={errors["price"]}
            />
            <p className="text-dark-blue pe-1 fsize-16 price">
              {productsModal.pound}
            </p>
          </div>
          <div className="col-3 text-left">
            <Input
              label={productsModal.quantity}
              id="quantity"
              name="quantity"
              value={values["quantity"]}
              labelClassName=" mb-3 label-color text-left fsize-16"
              inputWrapperClass="modal-input"
              onChange={(value) => {
                setFieldTouched("quantity");
                if (value.split("").every((num) => !isNaN(num))) {
                  setFieldValue("quantity", value);
                }
              }}
              isInputHasErr={
                !!(touched["quantity"] && errors["quantity"])
              }
              errMsg={errors["quantity"]}
            />
          </div>
          <div className="col-3 text-left px-0">
            <Input
              label={productsModal.minQuantity}
              id="min_ordering_quantity"
              name="min_ordering_quantity"
              labelClassName=" mb-3 label-color text-left fsize-15"
              value={values["min_ordering_quantity"]}
              inputWrapperClass="modal-input"
              onChange={(value) => {
                setFieldTouched("min_ordering_quantity");
                if (value.split("").every((num) => !isNaN(num))) {
                  setFieldValue("min_ordering_quantity", value);
                }
              }}
              isInputHasErr={
                !!(
                  touched["min_ordering_quantity"] &&
                  errors["min_ordering_quantity"]
                )
              }
              errMsg={errors["min_ordering_quantity"]}
            />
          </div>
          <div className="col-3 text-left px-0">
            <Input
              label={productsModal.maxQuantity}
              id="max_ordering_quantity"
              name="max_ordering_quantity"
              labelClassName=" mb-3 label-color text-left fsize-15"
              value={values["max_ordering_quantity"]}
              inputWrapperClass="modal-input"
              onChange={(value) => {
                setFieldTouched("max_ordering_quantity");
                if (value.split("").every((num) => !isNaN(num))) {
                  setFieldValue("max_ordering_quantity", value);
                }
              }}
              isInputHasErr={
                !!(
                  touched["max_ordering_quantity"] &&
                  errors["max_ordering_quantity"]
                )
              }
              errMsg={errors["max_ordering_quantity"]}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5 align-items-center padding">
          <Button
            label={productsModal.modalButton}
            type="submit"
            labelClass="fsize-14 font-white py-1"
            className="my-5 w-50 "
            block
            disabled={!dirty || !isValid}
          />
        </div>
        {errMsg && (
          <FormHelperText
            error={!!errMsg}
            className="font-medium fsize-12 createErrorMsg "
          >
            <ErrorOutline />
            {errMsg}
          </FormHelperText>
        )}
      </form>
    </div>
  );
};

export default WarhouseProductModal;
WarhouseProductModal.propTypes = {
  productId: PropTypes.number,
  onClose: PropTypes.func,
  productData: PropTypes.object,
  isWholesalerOption: PropTypes.bool,
  product: PropTypes.object,
  isEdit: PropTypes.bool,
  page: PropTypes.number,
  query: PropTypes.string,
  product_category_id: PropTypes.number
};
