import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Table as MTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@material-ui/core";

import messages from "../../assets/locale/messages";
import "./Table.scss";

const Table = ({
  headlines,
  rows,
  tableClasses,
  tableContainerClasses,
  hasActions,
  notHasSizeLimitaions
}) => {
  const lang = useSelector((state) => state.locale.lang);

  const {
    list: { actions }
  } = messages[lang].Wholesalers;

  return (
    <TableContainer
      component={Paper}
      className={`table-container ${tableContainerClasses}`}
    >
      <MTable className={tableClasses}>
        <TableHead>
          <TableRow>
            {headlines.map((head) => (
              <TableCell
                className={`text-gray font-reguler fsize-14 ${
                  notHasSizeLimitaions ? "" : "w-25"
                }`}
                key={head}
              >
                {head}
              </TableCell>
            ))}
            {hasActions && (
              <TableCell className="text-gray font-reguler fsize-14 w-25">
                {actions}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </MTable>
    </TableContainer>
  );
};

Table.propTypes = {
  headlines: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.node,
  hasActions: PropTypes.bool,
  notHasSizeLimitaions: PropTypes.bool,
  tableClasses: PropTypes.string,
  tableContainerClasses: PropTypes.string
};

Table.defaultProps = {
  headlines: [],
  hasActions: false
};

export default Table;
