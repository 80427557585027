import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import messages from "../../../../assets/locale/messages";
import { CustomPrevOffersCalendar } from "../../../../utils/Icons/CustomPrevOffersCalendar";
import { CustomPrevOffersClock } from "../../../../utils/Icons/CustomPrevOffersClock";
import {
  offerActiveStatus,
  offerPendingStatus,
  offerExpiredStatus
} from "../../../../utils/Constants";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import { formatDate } from "../../../../utils/Helpers";
import "./PreviousOffersModal.scss";

const PreviousOffersModal = ({ prevOffer, closeModal }) => {
  const history = useHistory();

  const lang = useSelector((state) => state.locale.lang);
  const { offers } = messages[lang];

  const checkOfferStatus = (status) => {
    if (offerActiveStatus.includes(status)) {
      return "active";
    } else if (offerPendingStatus.includes(status)) {
      return "pending";
    } else if (offerExpiredStatus.includes(status)) {
      return "expired";
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center mb-3 mt-4 previous-offers-modal-container ">
      <div
        className="d-flex justify-content-center w-100 fsize-20 font-cairo-semibold text-product-card-text mb-2 previous-offer-title"
        onClick={() => {
          history.push(
            ROUTE_PATHS["editOffer"].replace(":id", prevOffer.id)
          );
          closeModal();
        }}
      >
        {prevOffer.name}
      </div>
      <div className="d-flex justify-content-center w-100 align-items-baseline">
        <span className="mx-2 gray-text fsize-14 text-capitalize">
          {offers.addEditPage.by}
        </span>
        <span className="gray-shade fsize-16">
          {prevOffer.wholesaler_name}
        </span>
      </div>

      <div className="d-flex justify-content-around px-4 mt-4 w-100 ">
        <div className="d-flex justify-content-start align-items-baseline">
          <span className="mx-2 py-1 gray-text fsize-14">
            {offers.addEditPage.status}
          </span>
          <span
            className={`status status-${checkOfferStatus(
              prevOffer.status
            )} `}
          >
            {prevOffer.status}
          </span>
        </div>
        <div className="d-flex justify-content-start align-items-baseline">
          <span className="mx-2 gray-text fsize-14 pt-2">
            {offers.addEditPage.value}
          </span>
          <div className="text-uppercase prev-offer-value fsize-16 font-cairo-semibold">
            {prevOffer.discount_value}{" "}
            {`${
              prevOffer.discount_type === "amount"
                ? offers.addEditPage.le
                : "%"
            }`}
          </div>
        </div>
      </div>
      <div className="w-100 px-4 mt-3 price-container">
        <p className="w-100 price-texts">
          <span className="label-price me-3 gray-text fsize-14 pt-2">
            {offers.addEditPage.price}
          </span>
          <span className="ms-2 me-1 old-price">
            {prevOffer.price}
          </span>
          <span className="me-2 currency line-through">
            {offers.addEditPage.le}
          </span>
          <span className="ms-2 me-1 new-price">
            {prevOffer.new_price}
          </span>
          <span className="currency">{offers.addEditPage.le}</span>
        </p>
      </div>
      <div className="mt-3 w-100 dates-container position-relative">
        <div className="d-flex justify-content-between  w-100 ps-5 pe-3">
          <div className="d-flex">
            <p className="my-0 date-title ms-4 me-2 gray-text ">
              {offers.addEditPage.start}
            </p>
            <p className="my-0 date-text-color fsize-14 color-primary-light font-light">
              {formatDate(prevOffer.start_datetime, "DD/MM/YYYY")}
            </p>
          </div>
          <div className="d-flex">
            <p className="my-0 me-3 gray-text ">
              {offers.addEditPage.start}
            </p>
            <p className="my-0  fsize-14 color-primary-light font-light">
              {formatDate(prevOffer.start_datetime, "hh:mm A")}
            </p>
          </div>
        </div>

        <div className="d-flex justify-content-between mt-3 w-100 ps-5 pe-3">
          <div className="d-flex">
            <p className="my-0 date-title ms-4 me-3 gray-text ">
              {offers.addEditPage.end}
            </p>
            <p className="my-0 date-text-color fsize-14 color-primary-light font-light">
              {formatDate(prevOffer.end_datetime, "DD/MM/YYYY")}
            </p>
          </div>
          <div className="d-flex">
            <p className="my-0 me-4 gray-text ">
              {offers.addEditPage.end}
            </p>
            <p className="my-0  fsize-14 color-primary-light font-light">
              {formatDate(prevOffer.end_datetime, "hh:mm A")}
            </p>
          </div>
        </div>

        <div className="calendar-icon-container">
          <CustomPrevOffersCalendar />
        </div>
        <div className="clock-icon-container">
          <CustomPrevOffersClock />
        </div>
      </div>
    </div>
  );
};

export default PreviousOffersModal;

PreviousOffersModal.propTypes = {
  prevOffer: PropTypes.object,
  closeModal: PropTypes.func
};
